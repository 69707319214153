













import Vue from 'vue';
import Markdown from '@/components/markdown/Markdown.vue';
import { AnchorPoint } from '@/models/site.model';

type Data = {};
type Methods = {};
type Computed = {};
type Props = { markdown: string; anchorPoint: AnchorPoint };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Popover',
  components: { Markdown },
  props: { markdown: String, anchorPoint: Object },
  data() {
    return { error: false };
  },
});
