<template>
  <div>Logging in...</div>
</template>

<script>
import authServiceFactory from '../../api/auth';

export default {
  name: 'BeginLogin',
  created() {
    authServiceFactory().then((auth) =>
      auth.redirectToLogin(this.$router.resolve({ name: 'root' }).route.path),
    );
  },
};
</script>
