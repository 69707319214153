import { ActionContext, Module } from 'vuex';

import { RootState } from '../types';
import { NoticeState } from '@/store/modules/types';
import { ApiNeverLoaded } from '@/api/data';
import { recordApiCall } from '@/store/modules/apiCalls';

const notice: Module<NoticeState, RootState> = {
  state: () => ({
    noticeFromApi: ApiNeverLoaded,
  }),
  mutations: {
    setNoticeDetails(state, noticeDetails) {
      state.noticeFromApi = noticeDetails;
    },
  },
  actions: {
    getNotice({
      commit,
      rootGetters,
      rootState,
    }: ActionContext<NoticeState, RootState>) {
      recordApiCall({ rootState, rootGetters, commit }, 'getNotice', (client) =>
        client.getNotice(),
      ).then((response) => {
        commit('setNoticeDetails', response);
      });
    },
    updateLastReadNoticeAt(
      {
        commit,
        rootGetters,
        rootState,
        dispatch,
      }: ActionContext<NoticeState, RootState>,
      readNoticeAt: string,
    ) {
      commit('setNoticeDetails', ApiNeverLoaded);
      recordApiCall(
        { rootState, rootGetters, commit },
        'updateLastReadNoticeAt',
        (client) => client.updateLastReadNoticeAt(readNoticeAt),
      ).then((response) => {
        dispatch('getNotice', response);
      });
    },
  },
};

export default notice;
