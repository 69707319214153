<template>
  <div class="field">
    <markdown-description :field="field" />
    <rpl-form :formData="formData" @keydown.native.enter.prevent />
    <data-item-delete :field="field" :dataItem="dataItem" />
    <save-status
      :touched="touched"
      :saved="saved"
      :saving="saving"
      :error="error"
      className="above"
    />
  </div>
</template>

<script>
import { RplForm } from '@dpc-sdp/ripple-form';
import Vue from 'vue';
import fieldMixin from './mixin/fieldMixin';
import FieldCheckbox from '@/components/pages/form/fields/FieldCheckbox.vue';
import MarkdownDescription from '@/components/pages/form/fields/MarkdownDescription.vue';

Vue.component('fieldAppHubCheckbox', FieldCheckbox);
export default {
  components: { MarkdownDescription, RplForm },
  name: 'BooleanField',
  mixins: [fieldMixin],
  data() {
    return {
      internalValue: this.dataItem.value === 'true',
    };
  },
  computed: {
    dataItemValue() {
      return this.internalValue;
    },
    value() {
      return this.dataItem.value;
    },
  },
  methods: {
    changed(model, newVal, oldVal, field) {
      if (this.isValid(newVal, field)) {
        this.internalValue = newVal;
        this.save(newVal);
      }
    },
    schema() {
      return {
        type: 'appHubCheckbox',
        inlineLabel: this.field.label,
      };
    },
  },
  watch: {
    // This component uses the value from dataItem indirectly, so watch for external changes to dataItem value and update accordingly
    value(val) {
      if (val !== this.internalValue.toString()) {
        this.internalValue = val === 'true';
      }
    },
  },
};
</script>
<style lang="scss">
.saveStatus.above {
  top: auto;
  bottom: calc(100% - 5px);
}
</style>
