import { ActionContext, Commit, Module } from 'vuex';

import { RootState } from '../types';
import {
  FormCategorySummary,
  FormDefinitions,
  FormTemplate,
} from '@/models/form.model';
import { FormDefinitionsState } from './types';
import { ApiNeverLoaded, DataFromApi, getData, hasData } from '@/api/data';
import { recordApiCall } from './apiCalls';

const setData = (commit: Commit, payload: DataFromApi<FormDefinitions>) => {
  commit('reset');
  commit('setFormCategory', payload);
  if (hasData(payload)) {
    const data = getData(payload);
    if (data.formCategories) {
      commit('setFormCategories', data.formCategories);
    }
    if (data.formTemplates) {
      commit('setFormTemplates', data.formTemplates);
    }
  }
};

const formDefinitions: Module<FormDefinitionsState, RootState> = {
  state: () => ({
    formCategoryFromApi: ApiNeverLoaded,
    formCategories: [],
    formTemplates: [],
  }),
  mutations: {
    setFormCategory(
      state: FormDefinitionsState,
      formCategoryFromApi: DataFromApi<FormDefinitions>,
    ) {
      state.formCategoryFromApi = formCategoryFromApi;
    },
    setFormCategories(
      state: FormDefinitionsState,
      formCategories: FormCategorySummary[],
    ) {
      state.formCategories = formCategories;
    },
    setFormTemplates(
      state: FormDefinitionsState,
      formTemplates: FormTemplate[],
    ) {
      state.formTemplates = formTemplates;
    },
    reset(state: FormDefinitionsState) {
      state.formCategoryFromApi = ApiNeverLoaded;
      state.formCategories = [];
      state.formTemplates = [];
    },
  },
  actions: {
    loadRootFormDefinitions({
      commit,
      rootGetters,
      rootState,
    }: ActionContext<FormDefinitionsState, RootState>) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getRootFormDefinitions',
        (client) => client.getRootFormDefinitions(),
      ).then((data) => setData(commit, data));
    },
    loadFormCategory(
      {
        commit,
        rootGetters,
        rootState,
      }: ActionContext<FormDefinitionsState, RootState>,
      id: string,
    ) {
      recordApiCall(
        { rootState, rootGetters, commit },
        'getFormCategory',
        (client) => client.getFormCategory(id),
      ).then((data) => setData(commit, data));
    },
  },
  getters: {
    formCategory: (state) => state.formCategoryFromApi,
  },
};

export default formDefinitions;
