<template>
  <RplContainer>
    <RplRow>
      <RplCol
        :colsBp="{
          m: 12,
          l: 6,
        }"
      >
        <div
          v-for="style in calculateStyles()"
          v-bind:key="style.name"
          class="styleField"
        >
          <span>{{ style.name }}</span>
          <input
            :type="style.value.startsWith('#') ? 'color' : 'text'"
            :value="style.value"
            @change="(evt) => onChange(style, evt)"
          />
        </div>
      </RplCol>
      <RplCol
        :colsBp="{
          m: 12,
          l: 6,
        }"
      >
        <textarea class="themeBox" ref="themeBox" :value="theme" />
        <Button theme="primary" @click="updateTheme">Update</Button>
      </RplCol>
    </RplRow>
  </RplContainer>
</template>

<script>
import { RplContainer, RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import Button from '@/components/ripple/Button.vue';
import { applySiteTheme } from '@/themes';

export default {
  name: 'Theme',
  components: { RplContainer, RplRow, RplCol, Button },
  data() {
    return {
      theme: JSON.stringify(this.$store.getters.tenantTheme),
    };
  },
  methods: {
    calculateStyles() {
      const css = [].slice
        .call(document.styleSheets)
        .map((styleSheet) => [].slice.call(styleSheet.cssRules))
        .flat()
        .filter((cssRule) => cssRule.selectorText === ':root');
      const names = Array.from(css[0].style);
      const root = document.documentElement;
      const computedStyles = getComputedStyle(root);
      return names.map((property) => ({
        name: property.substr(2),
        value: computedStyles.getPropertyValue(property).trim(),
      }));
    },
    onChange(style, event) {
      const theme = this.$store.getters.tenantTheme;
      if (this.styles) {
        const themeStyle = theme.styles.find((st) => st.name === style.name);
        if (themeStyle) {
          themeStyle.value = event.target.value;
        } else {
          theme.styles.push({ name: style.name, value: event.target.value });
        }
      } else {
        theme.styles = [{ name: style.name, value: event.target.value }];
      }
      applySiteTheme(theme);
      this.$store.commit('setTheme', theme);
      this.theme = JSON.stringify(theme);
    },
    updateTheme() {
      const theme = JSON.parse(this.$refs.themeBox.value);
      applySiteTheme(theme);
      this.$store.commit('setTheme', theme);
      this.theme = this.$refs.themeBox.value;
    },
  },
  mounted() {
    this.$store.commit('setHeaderText', 'Theme');
  },
};
</script>
<style lang="scss" scoped>
.styleField {
  span {
    margin-right: 1em;
  }
  margin-bottom: 0.5em;
}

.themeBox {
  height: calc(100% - 4em);
  width: 100%;
  box-sizing: border-box;
}
</style>
