




import Vue from 'vue';
import { Column, RowViewModel } from './model/table.model';
import BooleanCell from '@/components/table/components/shared/BooleanCell.vue';
import WrappingCell from '@/components/table/components/shared/WrappingCell.vue';
import FailedEmail from '@/components/table/actions/entity/FailedEmail.vue';

Vue.component('boolean-cell', BooleanCell);
Vue.component('wrapping-cell', WrappingCell);
Vue.component('failed-email', FailedEmail);

type Data = {};

type Computed = {};

type Methods = {};

type Props = {
  rowViewModel: RowViewModel<unknown>;
  viewPath: (row: RowViewModel<unknown>) => string;
  column: Column;
  component: string;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TableComponent',
  components: {},
  props: {
    rowViewModel: Object,
    viewPath: Function,
    column: Object,
    component: String,
  },
});
