













































import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { RplSelect } from '@dpc-sdp/ripple-form';
import { GroupDetails } from '@/models/group.model';
import {
  getData,
  getErrorMessage,
  isApiError,
  isLoadingOrNeverLoaded,
} from '@/api/data';
import logger from '@/logger';
import Button from '@/components/ripple/Button.vue';

type Data = { selectedGroupId?: string };
type Methods = {
  onSelect: (id: string) => void;
  onManageGroupDetails: () => void;
};
type Computed = {
  groups: GroupDetails[];
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
  manageGroupDetailsFormCode?: string;
};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Groups',
  components: { RplIcon, RplSelect, Button },
  data() {
    return { selectedGroupId: undefined };
  },
  computed: {
    isLoading() {
      return isLoadingOrNeverLoaded(this.$store.state.group.groupsFromApi);
    },
    isError() {
      return isApiError(this.$store.state.group.groupsFromApi);
    },
    groups() {
      const groups: GroupDetails[] = getData(
        this.$store.state.group.groupsFromApi,
      );
      return groups?.sort((g1, g2) => g1.name.localeCompare(g2.name)) || [];
    },
    errorMessage() {
      return (
        getErrorMessage(this.$store.state.group.groupsFromApi) ||
        'Error loading groups'
      );
    },
    manageGroupDetailsFormCode() {
      return this.$store.getters.tenantSettings.manageGroupDetailsFormCode;
    },
  },
  methods: {
    onSelect(id: string) {
      this.selectedGroupId = id;
    },
    onManageGroupDetails() {
      this.$router.push({
        name: 'groupDetails',
        params: {
          groupId: this.selectedGroupId || '',
          formCode: this.manageGroupDetailsFormCode || '',
          pathBack: (this.$route.query.pathBack as string) || this.$route.path,
        },
      });
    },
  },
  mounted() {
    this.$store.dispatch('loadGroups');
    this.$store.commit('setHeaderText', 'Manage groups');
    this.selectedGroupId = this.$route.params.id;
  },
  watch: {
    groups() {
      if (this.groups.length > 0) {
        if (!this.selectedGroupId) {
          this.selectedGroupId = this.groups[0].id;
        }
      } else {
        this.selectedGroupId = undefined;
      }
    },
    selectedGroupId(newId, oldId) {
      if (newId) {
        const route = {
          name: 'group',
          params: { id: newId },
          query: this.$route.query,
        };
        if (oldId) {
          this.$router.push(route);
        } else {
          this.$router
            .replace(route)
            .catch(() => logger.debug('ignore duplicate navigation'));
        }
      } else {
        this.$router.push({ name: 'groups', query: this.$route.query });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.tenant.settings.groupManagement) {
        next();
      } else {
        next('notfound');
      }
    });
  },
});
