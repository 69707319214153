import { RawLocation } from 'vue-router';

export interface RplImage {
  url: string;
  alt: string;
  image: SVGElement | string;
}

export interface Breadcrumb {
  text: string;
  url?: string;
}

export interface Style {
  name: string;
  value: string;
}

export interface Theme {
  favicon?: string;
  logo?: RplImage;
  bannerImage?: string | SVGElement;
  styles?: Style[];
  contactDescription?: string;
  contactEmail?: string;
  bannerBackground?: string;
}

export interface TenantConfig {
  groupManagement: boolean;
  pollForNotice?: boolean;
  noticePollInterval: number;
  documentAttributes: (string | { column: string; label: string })[];
  hideDraftsTable: boolean;
  formSubmitNotes?: string;
  defaultCreateForm?: { label: string; code: string };
  manageGroupDetailsFormCode?: string;
  dashboardButtonLabel?: string;
  maxFileSize?: number;
}

export interface Tenant {
  key: string;
  name: string;
  blurb?: string;
  blurbBottom?: string;
  theme?: Theme;
  settings?: TenantConfig;
}

export interface NoticeDetails {
  notice: string;
  noticeAt: string;
}

export interface RouterLink {
  label: string;
  route: RawLocation;
}

export enum BreadcrumbKey {
  Dashboard = 'dashboard',
  Entity = 'entity',
  Form = 'form',
  Category = 'category',
  Group = 'group',
  Error = 'error',
  Team = 'team',
  Leaf = 'leaf',
}

export interface AnchorPoint {
  x: 'left' | 'right';
  y: 'top' | 'bottom';
}
