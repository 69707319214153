













































import Vue from 'vue';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import { RplCheckbox } from '@dpc-sdp/ripple-form';
import {
  RowViewModel,
  TableComponent,
  TableViewModel,
} from '@/components/table/model/table.model';
import SelectAction from '@/components/table/actions/entity/SelectEntityDocumentAction.vue';
import { MAX_FILE_SIZE_BYTES } from '@/config/constants';
import { formatBytes } from '@/models/form.model';
import DataHubTable from '@/components/table/DataTable.vue';
import DownloadAction from '@/components/table/actions/entity/DownloadAction.vue';

interface Data {
  tableComponents: TableComponent[];
  visibleRows: RowViewModel<unknown>[];
}

interface Computed {
  tableViewModel: TableViewModel<unknown>;
  selectedDocumentCount: number;
  allDocumentSelected: boolean;
  downloadSelectedText: string;
  canDownload: boolean;
  bulkDownloadSizeStr: string;
  message?: string;
}

interface Methods {
  onSelectAll: () => void;
  downloadSelected: () => void;
  onRowsFiltered: (rows: RowViewModel<unknown>[]) => void;
}
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EntityDocuments',
  data() {
    return {
      tableComponents: [
        { name: 'selectEntityDocument', component: SelectAction },
        { name: 'download-action', component: DownloadAction },
      ],
      maxFileSizeStr: formatBytes(MAX_FILE_SIZE_BYTES),
      visibleRows: [],
    };
  },
  components: {
    DataHubTable,
    RplTextLink,
    RplCheckbox,
  },
  computed: {
    tableViewModel() {
      return this.$store.getters.getEntityDocumentsTableViewModel;
    },
    selectedDocumentCount() {
      return this.visibleRows.filter((row) =>
        this.$store.getters.isDocumentSelected(row.row.id),
      ).length;
    },
    allDocumentSelected() {
      return this.visibleRows.every((row) =>
        this.$store.getters.isDocumentSelected(row.row.id),
      );
    },
    downloadSelectedText() {
      return `Download ${this.selectedDocumentCount} attachment${
        this.selectedDocumentCount > 1 ? 's' : ''
      } (${this.bulkDownloadSizeStr})`;
    },
    canDownload() {
      return this.$store.getters.getSelectedDownloadSize <= MAX_FILE_SIZE_BYTES;
    },
    bulkDownloadSizeStr() {
      return formatBytes(this.$store.getters.getSelectedDownloadSize);
    },
    message() {
      return this.$store.getters.getEntityDocumentMessage;
    },
  },
  methods: {
    onSelectAll() {
      if (!this.allDocumentSelected) {
        this.$store.dispatch('selectDocuments', this.visibleRows);
      } else {
        this.$store.dispatch('deselectAllDocuments');
      }
    },
    downloadSelected() {
      if (this.canDownload) {
        this.$store.dispatch('downloadSelectedDocuments');
      }
    },
    onRowsFiltered(rows) {
      this.visibleRows = rows;
    },
  },
  mounted() {
    this.visibleRows = this.tableViewModel.rows;
  },
});
