import { Module } from 'vuex';
import { RootState } from '../types';
import { BreadcrumbData, BreadcrumbState } from './types';
import { Breadcrumb, BreadcrumbKey } from '@/models/site.model';
import { paths } from '@/router/paths';

const createBreadcrumb = (
  key: BreadcrumbKey,
  allData: BreadcrumbData[],
): Breadcrumb[] => {
  const data = allData.find((d) => d.key === key);
  if (data) {
    return data.breadcrumbs;
  }
  return [];
};
const breadcrumb: Module<BreadcrumbState, RootState> = {
  state: () => ({
    breadcrumbs: [],
    breadcrumbKeys: [],
    breadcrumbData: [],
  }),
  mutations: {
    setBreadcrumbs(state: BreadcrumbState, crumbs: Breadcrumb[]) {
      state.breadcrumbs = crumbs;
    },
    setBreadcrumbKeys(state: BreadcrumbState, keys: BreadcrumbKey[]) {
      state.breadcrumbKeys = keys;
    },
    addBreadcrumbData(state: BreadcrumbState, data: BreadcrumbData) {
      const existingData = state.breadcrumbData.find((d) => d.key === data.key);
      if (existingData) {
        existingData.breadcrumbs = data.breadcrumbs;
      } else {
        state.breadcrumbData.push(data);
      }
    },
  },
  getters: {
    breadcrumbs: (state, getters) => {
      const breadcrumbsFromKeys = state.breadcrumbKeys.flatMap((key) =>
        createBreadcrumb(key, state.breadcrumbData),
      );
      return [
        { text: getters.siteName, url: paths(getters.tenantId).base() },
        ...(breadcrumbsFromKeys.length
          ? breadcrumbsFromKeys
          : state.breadcrumbs),
      ];
    },
  },
};

export default breadcrumb;
