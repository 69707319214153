










import Vue from 'vue';
import EntityGroupTable from '@/components/pages/EntityGroupTable.vue';
import { TableGroupViewModel } from '@/components/table/model/table.model';

type Data = {};

interface Computed {
  tableGroupViewModels: TableGroupViewModel<unknown>[];
}

type Methods = {};

type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EntityTables',
  components: {
    EntityGroupTable,
  },
  computed: {
    tableGroupViewModels() {
      return this.$store.getters.getSecondaryTableGroupViewModels;
    },
  },
});
