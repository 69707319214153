
































import Vue from 'vue';
import { RplLink, RplTextLink } from '@dpc-sdp/ripple-link';
import {
  DataItem,
  DataItemAction,
  Field,
  PresentationControl,
} from '@/models/form.model';
import Button from '@/components/ripple/Button.vue';
import { getData, LoadedData } from '@/api/data';
import { getApiPath } from '@/api/download';

type Data = {};

type Computed = {
  isButton: boolean;
  hasIcon: boolean;
  icon: string | undefined;
  iconPlacement: string;
};

type Methods = {};

type Props = { field: Field; dataItem: DataItem };

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Button, RplTextLink, RplLink },
  props: { field: Object, dataItem: Object },
  name: 'ActionField',
  computed: {
    isButton() {
      return this.field.presentationControls.includes(
        PresentationControl.Button,
      );
    },
    hasIcon() {
      return this.field.presentationControls.some(
        (control) =>
          control === PresentationControl.DownloadLink ||
          control === PresentationControl.ExternalLink,
      );
    },
    icon() {
      const { presentationControls } = this.field;
      if (presentationControls.includes(PresentationControl.DownloadLink)) {
        return 'download';
      }
      if (presentationControls.includes(PresentationControl.ExternalLink)) {
        return 'external_link';
      }
      return undefined;
    },
    iconPlacement() {
      return this.icon === 'external_link' ? 'after' : 'before';
    },
  },
  methods: {
    onClick() {
      this.$store
        .dispatch('getDataItemAction', {
          dataItemId: this.dataItem.id,
          actionCode: this.field.actionCode,
        })
        .then(async (response: LoadedData<DataItemAction>) => {
          const dataItemAction = getData(response);
          if (dataItemAction.action === 'reloadForm') {
            await this.$store.dispatch('reloadForm');
          } else if (dataItemAction.payload) {
            let url;
            if (dataItemAction.payload.startsWith('/')) {
              await this.$store.dispatch(
                'getAuthCookie',
                this.$store.getters.tenantId,
              );
              url = await getApiPath(
                this.$store.getters.tenantId,
                dataItemAction.payload,
              );
            } else {
              url = dataItemAction.payload;
            }
            const link = document.createElement('a');
            link.target =
              dataItemAction.action === 'replace' ? '_self' : '_blank';
            link.href = url;
            link.click();
          }
        });
    },
  },
});
