<template>
  <div
    class="modal"
    role="dialog"
    aria-labelledby="confirmTitle"
    aria-describedby="confirmMessage"
    aria-modal="true"
    @click="backgroundClick"
    tabindex="-1"
    @keydown.shift.tab.prevent.stop="onShiftTab"
    @keydown.tab.exact.prevent.stop="onTab"
    @keydown.esc="onCancel"
  >
    <div class="dialog" role="document">
      <h5 class="title">
        <span id="confirmTitle">{{ title }}</span>
        <icon-button
          class-name="closeIcon"
          @keydown.enter.space.prevent.stop="onCancel"
          @click="onCancel"
          ref="closeButton"
          symbol="close"
          size="0.8"
          color="primary"
          class="closeIcon"
        />
      </h5>
      <div class="message" id="confirmMessage">{{ message }}</div>
      <div class="modalButtons">
        <Button
          theme="primary"
          :inverse="true"
          ref="cancelButton"
          @keydown.native.enter.prevent.stop="onCancel"
          @click="onCancel"
          >{{ cancelButtonText ? cancelButtonText : 'Cancel' }}</Button
        >
        <Button
          theme="primary"
          ref="confirmButton"
          @click="onConfirm"
          @keydown.native.enter.prevent.stop="onConfirm"
          >{{ confirmButtonText }}</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ripple/Button.vue';
import IconButton from '@/components/IconButton.vue';

let refList;

export default {
  name: 'Confirm',
  components: { IconButton, Button },
  props: ['title', 'message', 'confirmButtonText', 'cancelButtonText'],
  data() {
    return {};
  },
  methods: {
    backgroundClick(event) {
      event.stopPropagation();
    },
    onTab() {
      const focusRefIndex = refList.findIndex((ref) => ref.hasFocus());
      if (focusRefIndex === -1 || focusRefIndex + 1 === refList.length) {
        refList[0].focus();
      } else if (focusRefIndex + 1 < refList.length) {
        refList[focusRefIndex + 1].focus();
      }
    },
    onShiftTab() {
      const focusRefIndex = refList.findIndex((ref) => ref.hasFocus());
      if (focusRefIndex === -1 || focusRefIndex === 0) {
        refList[refList.length - 1].focus();
      } else if (focusRefIndex > 0) {
        refList[focusRefIndex - 1].focus();
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
    onConfirm() {
      this.$emit('confirm');
    },
  },
  mounted() {
    this.$nextTick(() => this.$refs.closeButton.focus());
    refList = [
      this.$refs.closeButton,
      this.$refs.cancelButton,
      this.$refs.confirmButton,
    ];
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: default;
  z-index: 1050;

  .dialog {
    min-width: 500px;
    background-color: white;
    box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.3);

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      margin: 0;
      border-bottom: 1px solid $neutral;

      .closeIcon {
        opacity: 0.6;

        &:hover,
        &:focus-visible {
          opacity: 1;
        }
      }
    }

    .message {
      padding: 1em;
      white-space: pre-line;
    }

    .modalButtons {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      > :first-child {
        margin-right: 1em;
      }
    }
    @media screen and (max-width: 500px) {
      & {
        height: 100%;
        min-width: 100%;
        width: 100%;
      }
    }
  }
}
</style>
