<template>
  <div class="field widget">
    <popover-label :field="field" :schema="schemaField" />
    <widget :schema="schemaField" @set-value="onSetValue" />
    <save-status
      :touched="touched"
      :saved="saved"
      :saving="saving"
      :error="error"
    />
  </div>
</template>

<script>
import fieldMixin from './mixin/fieldMixin';
import Widget from '@/components/pages/form/fields/Widget.vue';
import PopoverLabel from '@/components/pages/form/fields/PopoverLabel.vue';

export default {
  components: { Widget, PopoverLabel },
  name: 'WidgetField',
  mixins: [fieldMixin],
  computed: {
    dataItemValue() {
      return this.dataItem.value;
    },
  },
  methods: {
    changed(model, newVal) {
      this.save(newVal ? JSON.stringify(newVal) : newVal);
    },
    schema() {
      return {
        dataItem: this.dataItem,
      };
    },
    onSetValue(value) {
      this.save(value ? JSON.stringify(value) : value);
    },
  },
};
</script>
<style lang="scss" scoped>
.field.widget {
  margin-bottom: 1.75rem;
}
</style>
