









































































import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import Button from '@/components/ripple/Button.vue';
import {
  getData,
  getErrorMessage,
  hasData,
  isApiError,
  isLoading,
  isLoadingOrNeverLoaded,
} from '@/api/data';
import {
  ColumnDataType,
  RowViewModel,
  TableMetadata,
  TableViewModel,
} from '@/components/table/model/table.model';
import DataHubTable from '@/components/table/DataTable.vue';
import { BreadcrumbKey } from '@/models/site.model';
import { Editor } from '@/models/form.model';

type Data = {};
type Methods = {
  onAddEditor: () => void;
  onCloseMessage: () => void;
  onCloseError: () => void;
  onBack: () => void;
};
type Computed = {
  team: Editor[];
  isError: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  errorMessage: string;
  tableViewModel: TableViewModel<Editor>;
  tableData: RowViewModel<Editor>[];
  tableConfig: TableMetadata;
  message?: string;
  error?: string;
  linkText: string;
};
type Props = {
  formId: string;
  stageTypeId: string;
  name: string;
  formPath: string;
  formName: string;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Editors',
  components: { RplIcon, DataHubTable, Button, RplAlertBase, RplTextLink },
  props: {
    formId: String,
    stageTypeId: String,
    name: String,
    formPath: String,
    formName: String,
  },
  computed: {
    isLoading() {
      return isLoadingOrNeverLoaded(this.$store.state.form.editorsFromApi);
    },
    isError() {
      return isApiError(this.$store.state.form.editorsFromApi);
    },
    team() {
      return getData(this.$store.state.form.editorsFromApi);
    },
    isLoaded() {
      return hasData(this.$store.state.form.editorsFromApi);
    },
    errorMessage() {
      return (
        getErrorMessage(this.$store.state.form.editorsFromApi) ||
        'Error loading team'
      );
    },
    tableData() {
      return [...this.team]
        .sort((c1, c2) => {
          if (c1.name === undefined && c2.name === undefined) {
            return 0;
          }
          if (c1.name === undefined) {
            return -1;
          }
          if (c2.name === undefined) {
            return 1;
          }
          return c1.name.localeCompare(c2.name);
        })
        .map((member) => ({
          row: {
            id: member.id,
            columns: [
              {
                key: 'name',
                value: `${member.name}`,
              },
              { key: 'email', value: member.email },
              {
                key: 'actions',
                actions: [
                  this.team.length > 1
                    ? 'delete-editor'
                    : 'disabled-delete-editor',
                ],
                value: this.formId,
              },
            ],
          },
          data: member,
        }));
    },
    tableConfig() {
      return {
        label: '',
        columns: [
          {
            label: 'Name',
            key: 'name',
            dataType: ColumnDataType.Text,
          },
          {
            label: 'Email',
            key: 'email',
            dataType: ColumnDataType.Text,
          },
          {
            label: '',
            key: 'actions',
            dataType: ColumnDataType.Action,
          },
        ],
        pageSize: 10,
        displayRank: 0,
      };
    },
    tableViewModel() {
      return { config: this.tableConfig, rows: this.tableData };
    },
    message() {
      return this.$route.query.message as string;
    },
    error() {
      return this.$store.state.group.error;
    },
    linkText() {
      return `Back to ${this.formName}`;
    },
  },
  methods: {
    onAddEditor() {
      this.$router.push('add');
    },
    onCloseMessage() {
      this.$store.commit('setMessage', undefined);
    },
    onCloseError() {
      this.$store.commit('setError', undefined);
    },
    onBack() {
      this.$router.push(this.formPath);
    },
  },
  mounted() {
    if (!this.isLoaded && !isLoading(this.$store.state.form.editorsFromApi)) {
      this.$store.dispatch('loadFormEditors', this.formId);
    }
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Leaf,
      breadcrumbs: {
        text: 'Team',
      },
    });
  },
});
