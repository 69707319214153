<template>
  <div
    v-if="touched || saving"
    v-bind:class="[{ saving }, 'saveStatus', className]"
  >
    <div v-if="saving" class="savingText">saving</div>
    <div v-else-if="error" class="error">error</div>
    <div v-else-if="saved" class="savedText">saved</div>
    <div v-else class="notSaved">not saved</div>
    <RplIcon
      v-if="saving"
      class="savingIcon spin"
      symbol="loading_star"
      color="secondary"
    />
  </div>
</template>

<script>
import RplIcon from '@dpc-sdp/ripple-icon';

export default {
  components: { RplIcon },
  name: 'SaveStatus',
  props: ['touched', 'saving', 'saved', 'error', 'className'],
};
</script>

<style lang="scss" scoped>
.saveStatus {
  position: absolute;
  top: 0;
  right: 0;
  color: $secondary-darker;
  font-size: 1rem;
  display: flex;
  align-items: center;

  & > * {
    font-size: 80%;
    font-weight: bold;
  }
  .savingIcon {
    margin-left: 0.5em;
  }
  .notSaved {
    color: $semantic-warning;
  }
  .error {
    color: $semantic-cancel;
  }
}

.no-label {
  .saveStatus {
    top: -10px;
  }
}
</style>
