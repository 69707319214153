/* eslint-disable no-return-assign */
import { ActionContext, Module } from 'vuex';
import { RootState } from '../types';
import { DashboardState } from './types';

import { paths } from '@/router/paths';
import {
  ApiNeverLoaded,
  ApiResponse,
  getData,
  hasData,
  isApiError,
  isLoadingOrNeverLoaded,
} from '@/api/data';
import { recordApiCall } from './apiCalls';
import {
  ColumnDataType,
  RowViewModel,
  TableGroupModel,
  TableGroupViewModel,
} from '@/components/table/model/table.model';
import { buildTableViewModel } from '@/helpers/table';
import { TenantConfig } from '@/models/site.model';

const dashboard: Module<DashboardState, RootState> = {
  state: () => ({
    draftsFromApi: ApiNeverLoaded,
    entitiesFromApi: ApiNeverLoaded,
  }),

  mutations: {
    setDrafts(state: DashboardState, drafts: ApiResponse<TableGroupModel>) {
      state.draftsFromApi = drafts;
    },
    setEntities(
      state: DashboardState,
      entities: ApiResponse<TableGroupModel[]>,
    ) {
      state.entitiesFromApi = entities;
    },
    reset(state: DashboardState) {
      state.draftsFromApi = ApiNeverLoaded;
      state.entitiesFromApi = ApiNeverLoaded;
    },
  },
  getters: {
    getDashboardTableViewModels: (state, getters) => {
      const draftViewModel: TableGroupViewModel<unknown> =
        getters.getDraftViewModel;
      const entityViewModels: TableGroupViewModel<unknown>[] = [
        ...getters.getEntityViewModels,
      ];
      if (draftViewModel) {
        entityViewModels.push(draftViewModel);
      }
      entityViewModels.sort((viewModel1, viewModel2) => {
        let result = viewModel1.displayRank - viewModel2.displayRank;
        if (result === 0) {
          result = viewModel1.tables[0].config.label.localeCompare(
            viewModel2.tables[0].config.label,
          );
        }
        return result;
      });
      return entityViewModels;
    },
    getDraftViewModel: (state: DashboardState, rootGetters) => {
      if (hasData(state.draftsFromApi)) {
        const data = getData(state.draftsFromApi);
        if (data.tables.length > 0) {
          const { tables } = data;
          const table = tables[0];
          const viewPath = (rowViewModel: RowViewModel<unknown>) =>
            paths(rootGetters.tenantId).form(rowViewModel.row.id);
          return {
            tables: [
              buildTableViewModel(
                {
                  ...table.tableMetadata,
                  columns: [
                    ...table.tableMetadata.columns,
                    {
                      label: '',
                      key: 'actions',
                      dataType: ColumnDataType.Action,
                    },
                  ],
                },
                table.tableContents.map((row) => ({
                  id: row.id,
                  columns: [
                    ...row.columns,
                    {
                      key: 'actions',
                      actions: ['delete-form-action'],
                      value: 'draft',
                    },
                  ],
                })),
                viewPath,
              ),
            ],
            displayRank: data.displayRank,
          };
        }
      }
      return undefined;
    },
    getEntityViewModels: (state: DashboardState, rootGetters) => {
      if (hasData(state.entitiesFromApi)) {
        const data = getData(state.entitiesFromApi);
        return data.map(({ tables, displayRank }) => {
          const tableGroup = tables.map((table) => {
            const viewPath = (rowViewModel: RowViewModel<unknown>) =>
              paths(rootGetters.tenantId).entity(rowViewModel.row.id);
            return buildTableViewModel(
              {
                ...table.tableMetadata,
                columns: table.tableMetadata.columns,
              },
              table.tableContents.map((row) => ({
                id: row.id,
                columns: [...row.columns],
              })),
              viewPath,
            );
          });
          return { tables: tableGroup, displayRank };
        });
      }
      return [];
    },
    isDraftsLoading: (state: DashboardState) =>
      isLoadingOrNeverLoaded(state.draftsFromApi) &&
      !isApiError(state.draftsFromApi),
    isEntitiesLoading: (state: DashboardState) =>
      isLoadingOrNeverLoaded(state.entitiesFromApi) &&
      !isApiError(state.entitiesFromApi),
    hasDraftsData: (state: DashboardState) => hasData(state.draftsFromApi),
    hasEntitiesData: (state: DashboardState) => hasData(state.entitiesFromApi),
    createFormRouterLink: (state: DashboardState, rootGetters) => {
      const settings: TenantConfig = rootGetters.tenantSettings;
      if (settings.defaultCreateForm) {
        return {
          label: settings.defaultCreateForm.label,
          route: {
            path: paths(rootGetters.tenantId).createForm(
              settings.defaultCreateForm.code,
            ),
          },
        };
      }
      if (settings.dashboardButtonLabel) {
        return {
          label: settings.dashboardButtonLabel,
          route: { name: 'root' },
        };
      }
      return { label: 'Create application', route: { name: 'root' } };
    },
  },
  actions: {
    loadDashboard(
      {
        rootState,
        commit,
        rootGetters,
      }: ActionContext<DashboardState, RootState>,
      hideDraftsTable: boolean,
    ) {
      if (!hideDraftsTable) {
        recordApiCall(
          { rootState, rootGetters, commit },
          'getDraftFormTable',
          (client) => client.getDraftFormTable(),
        ).then((payload) => commit('setDrafts', payload));
      }
      recordApiCall(
        { rootState, rootGetters, commit },
        'getEntityFormTables',
        (client) => client.getEntityFormTables(),
      ).then((payload) => commit('setEntities', payload));
    },
  },
};

export default dashboard;
