



































import Vue from 'vue';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import { Email, EmailAttachment } from '@/models/entity.model';
import ExpandToggle from '@/components/shared/ExpandToggle.vue';
import Attachment from '@/components/pages/EmailAttachment.vue';

const DEFAULT_ATTACHMENT_COUNT = 0;

type Data = {
  showAll: boolean;
};

type Computed = {
  attachments: EmailAttachment[];
  needsShowAll: boolean;
  attachmentsForDisplay: EmailAttachment[];
};

type Methods = {
  onDownloadAll: () => void;
  onShowAll: () => void;
};

type Props = { email: Email };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EmailAttachments',
  components: { Attachment, ExpandToggle, RplTextLink },
  data() {
    return { showAll: false };
  },
  props: {
    email: Object,
  },
  computed: {
    needsShowAll() {
      return this.attachments.length > DEFAULT_ATTACHMENT_COUNT;
    },
    attachmentsForDisplay() {
      return this.showAll
        ? this.attachments
        : this.attachments.slice(0, DEFAULT_ATTACHMENT_COUNT);
    },
    attachments() {
      return this.email.attachments;
    },
  },
  methods: {
    onDownloadAll() {
      this.$store.dispatch('downloadEmailAttachments', this.email);
    },
    onShowAll() {
      this.showAll = !this.showAll;
    },
  },
});
