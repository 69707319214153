<template>
  <div
    class="form-button"
    @click="schema.clickHandler"
    @keydown.enter="schema.clickHandler"
    role="button"
    tabindex="0"
  >
    {{ schema.buttonText }}
  </div>
</template>

<script>
// This component emulates a button in appearance and behaviour. Due to a limitation in ripple forms we can't use a
// real button as the framework will treat it as a submit button
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
  name: 'FormButton',
};
</script>
<style lang="scss" scoped>
@import '~@dpc-sdp/ripple-global/style';

.form-button {
  display: inline-block;
  color: $secondary;
  border: 1px solid $secondary;
  background-color: white;
  padding: 1rem 3rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-family: rpl-font('bold');

  &:hover {
    color: $secondary-darkest;
    border-color: $secondary-darkest;
  }

  &:focus-visible {
    outline: $primary-lighter auto 1px;
    outline-offset: 6px;
  }
}
</style>
