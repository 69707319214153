<template>
  <markdown class="readOnlyMarkdown" :source="value" />
</template>

<script>
import { abstractField } from 'vue-form-generator';
import Markdown from '@/components/markdown/Markdown.vue';

export default {
  name: 'FieldMarkdown',
  components: { Markdown },
  mixins: [abstractField],
};
</script>
<style lang="scss">
.readOnlyMarkdown {
  & > * {
    margin: 0;
  }
}
</style>
