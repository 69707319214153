





















import Vue from 'vue';
import { ExtendedVue } from 'vue/types/vue';
import { DataItem, Field } from '@/models/form.model';
import RepeatingField from './RepeatingField.vue';
import FieldComponent from './Field.vue';

type Data = {};

type Computed = {};

type Methods = {};

type Props = {
  fields: Field[];
  dataItems: DataItem[];
  parentDataItem: DataItem | undefined;
};

const Fields: ExtendedVue<Vue, Data, Methods, Computed, Props> = Vue.extend<
  Data,
  Methods,
  Computed,
  Props
>({
  name: 'Fields',
  components: {
    RepeatingField,
    FieldComponent,
  },
  props: {
    fields: Array,
    dataItems: Array,
    parentDataItem: Object,
  },
  computed: {
    visibleFields(): Field[] {
      return this.fields.filter((e) => e.visible);
    },
  },
});

export default Fields;
