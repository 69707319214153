












































































import { RplForm } from '@dpc-sdp/ripple-form';
import { RplRow, RplCol } from '@dpc-sdp/ripple-grid';
import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import EntityAttributeComponent from '@/components/pages/EntityAttribute.vue';
import {
  EntityAction,
  EntityAttribute,
  EntityDefinition,
  EntityTimelineEntry,
} from '@/models/entity.model';
import {
  hasData,
  isApiError,
  DataFromApi,
  getErrorMessage,
  ApiErrorType,
  isLoadingOrNeverLoaded,
} from '@/api/data';
import { paths } from '@/router/paths';
import EntityTimeline from '@/components/pages/EntityTimeline.vue';
import EntityConversation from '@/components/pages/EntityConversation.vue';
import EntityTables from '@/components/pages/EntityTables.vue';
import { BreadcrumbKey } from '@/models/site.model';
import EntityTabs from '@/components/pages/EntityTabs.vue';
import CollaboratorPanel from '@/components/pages/CollaboratorPanel.vue';
import Loading from '@/components/ripple/Loading.vue';

type Data = {};
type Computed = {
  displayAttributes: EntityAttribute[];
  attributes: EntityAttribute[];
  entityFromApi: DataFromApi<EntityDefinition>;
  hasData: boolean;
  isLoading: boolean;
  actions: EntityAction[];
  timeline: EntityTimelineEntry[];
  showMessages: boolean;
  errorMessage?: string;
  contactDescription: string;
  contactEmail: string;
  label: string;
  showCollaborators: boolean;
  reloading: boolean;
};
type Methods = {};
type Props = { id: string; status: string; message: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Entity',
  components: {
    Loading,
    EntityTabs,
    CollaboratorPanel,
    EntityTables,
    EntityConversation,
    EntityTimeline,
    RplForm,
    RplCol,
    RplRow,
    EntityAttributeComponent,
    RplIcon,
  },
  props: {
    id: String,
    status: String,
    message: String,
  },
  data() {
    return {
      formData: {
        formState: {
          response: {
            status: this.status,
            message: this.message,
          },
        },
      },
    };
  },
  computed: {
    actions() {
      return this.$store.getters.getEntityActions;
    },
    displayAttributes() {
      return this.attributes.slice(1);
    },
    attributes() {
      return this.$store.getters.getEntityAttributes;
    },
    entityFromApi() {
      return this.$store.getters.entity as DataFromApi<EntityDefinition>;
    },
    hasData() {
      return hasData(this.entityFromApi);
    },
    isLoading() {
      return (
        isLoadingOrNeverLoaded(this.entityFromApi) &&
        !isApiError(this.entityFromApi)
      );
    },
    timeline() {
      return this.$store.getters.getEntityTimeline;
    },
    showMessages() {
      return this.$store.getters.getEntitySettings.showMessages;
    },
    errorMessage() {
      if (
        isApiError(this.entityFromApi) &&
        this.entityFromApi.type === ApiErrorType.ConnectorForbidden
      ) {
        return getErrorMessage(this.entityFromApi);
      }
      return undefined;
    },
    contactDescription() {
      return (
        this.$store.getters.tenantTheme?.contactDescription ||
        'Please contact support'
      );
    },
    contactEmail() {
      return (
        this.$store.getters.tenantTheme?.contactEmail ||
        'planning.support@transport.vic.gov.au'
      );
    },
    label() {
      return this.attributes.length ? this.attributes[0].value : '';
    },
    showCollaborators() {
      return this.$store.getters.getEntitySettings.showCollaborators;
    },
    reloading() {
      return this.$store.state.entity.reloading;
    },
  },
  watch: {
    attributes() {
      if (this.label) {
        this.$store.commit('setHeaderText', this.label);
        this.$store.commit('addBreadcrumbData', {
          key: BreadcrumbKey.Dashboard,
          breadcrumbs: {
            text: 'My dashboard',
            url: paths(this.$store.getters.tenantId).dashboard(),
          },
        });
        this.$store.commit('addBreadcrumbData', {
          key: BreadcrumbKey.Entity,
          breadcrumbs: { text: this.label },
        });
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    // when navigating back from an inline form, reload the entity
    if (to.name === 'entity') {
      this.$store.dispatch('reloadEntityDetails', this.id);
    }
    next();
  },
  created() {
    this.$store.dispatch('getEntityDetails', this.id);
    this.$store.commit('setHeaderText', '');
  },
  destroyed() {
    this.$store.commit('resetEntityState');
  },
});
