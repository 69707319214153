import { VueComponent } from '@/types';

export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum ColumnDataType {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  DateTime = 'DateTime',
  Action = 'Action',
  Component = 'Component',
}

export interface SortOption {
  name: string;
  id: string;
  direction: keyof typeof SortDirection;
  columnKey: string;
}

export interface RowViewModel<T> {
  row: Row;
  data?: T;
}

export interface TableViewModel<T> {
  config: TableMetadata;
  rows: RowViewModel<T>[];
  viewPath?: (row: RowViewModel<T>) => string;
}

export interface Column {
  label: string;
  key: string;
  dataType: ColumnDataType;
  sortable?: boolean;
  filterable?: boolean;
  searchable?: boolean;
  summable?: boolean;
  hidden?: boolean;
  multiFilterable?: boolean;
  component?: string;
}

export interface TableMetadata {
  label: string;
  defaultSortOption?: {
    key: string;
    direction: 'Ascending' | 'Descending';
  };
  columns: Column[];
  pageSize: number;
  hideSpin?: boolean;
  export?: boolean;
  allowColumnConfig?: boolean;
  retainSession?: boolean;
  retainLocal?: boolean;
  hint?: string;
  description?: string;
}

export interface ColumnData {
  key: string;
  value?: string;
  actions?: string[];
  component?: string;
}

export interface Row {
  id: string;
  columns: ColumnData[];
}

export interface Table {
  tableMetadata: TableMetadata;
  tableContents: Row[];
}

export interface TableComponent {
  name: string;
  component: VueComponent;
}

export interface TabViewModel {
  label: string;
  key: string;
  icon?: string;
}

export interface TableGroupViewModel<T> {
  tables: TableViewModel<T>[];
  displayRank: number;
}

export interface TableGroupModel {
  tables: Table[];
  displayRank: number;
}

export interface AdditionalDetails {
  expandLabel?: string;
  hideLabel?: string;
  content: string;
}
