




import Vue from 'vue';

type Data = unknown;
type Computed = {
  siteName: string;
};
type Methods = unknown;
type Props = unknown;

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'LoggedOut',
  computed: {
    siteName() {
      return this.$store.getters.siteName;
    },
  },
  mounted() {
    this.$store.commit('setHeaderText', 'Thank you!');
  },
});
