import { DataItem } from '@/models/form.model';

export const dataItemAndDescendants = (
  dataItem: DataItem,
  descendants: DataItem[],
): DataItem[] => [
  dataItem,
  ...descendants
    .filter((di) => dataItem.id === di.dataItemId)
    .flatMap((di) => dataItemAndDescendants(di, descendants)),
];
