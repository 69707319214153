















import { RplForm } from '@dpc-sdp/ripple-form';
import VueFormGenerator from 'vue-form-generator';
import debounce from 'lodash.debounce';
import mixins from 'vue-typed-mixins';
import Vue from 'vue';
import fieldMixin, { Schema } from './mixin/fieldMixin';
import { PresentationControl } from '@/models/form.model';
import RegexInput from '@/components/pages/form/fields/RegexInput.vue';
import PopoverLabel from '@/components/pages/form/fields/PopoverLabel.vue';
import MarkdownDescription from '@/components/pages/form/fields/MarkdownDescription.vue';

Vue.component('field-regex-input', RegexInput);

const DEBOUNCE_DELAY_MS = 500;

type Data = {
  debounceSaveFunc: { (): void; cancel: () => void };
  lastSavedValue?: string;
};

interface Methods {
  changed: (
    model: unknown,
    newVal: string,
    oldVal: string,
    field: Schema,
  ) => void;
  formatValue: (value: string) => string;
  schema: () => Schema;
}
interface Computed {
  dataItemValue: string;
  hideCents: boolean;
}
type Props = {};

const sanitiseValue = (value: string): string =>
  value.replaceAll(/[^\d.]/g, '');
const formatterWithCents = (): Intl.NumberFormat =>
  new Intl.NumberFormat('en-AU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
const formatterWithoutCents = (): Intl.NumberFormat =>
  new Intl.NumberFormat('en-AU', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export default mixins(fieldMixin).extend<Data, Methods, Computed, Props>({
  components: { MarkdownDescription, PopoverLabel, RplForm },
  name: 'CurrencyField',
  mixins: [fieldMixin],
  data() {
    return {
      debounceSaveFunc: debounce(() => {
        let value = '';
        if (this.model.value !== undefined) {
          const floatValue = parseFloat(sanitiseValue(this.model.value));
          if (!Number.isNaN(floatValue)) {
            value = Math.round(floatValue * 100).toString();
          }
        }
        if (
          this.dataItem.value !== value &&
          value !== this.$data.lastSavedValue
        ) {
          this.$data.lastSavedValue = value;
          this.save(value);
        }
      }, DEBOUNCE_DELAY_MS),
      lastSavedValue: '',
    };
  },
  computed: {
    dataItemValue() {
      if (this.dataItem.value !== undefined) {
        return this.formatValue(this.dataItem.value);
      }
      return '';
    },
    hideCents() {
      return this.field.presentationControls.includes(
        PresentationControl.HideCents,
      );
    },
  },
  methods: {
    changed(model, newVal, oldVal) {
      this.$store.commit('setUpdatingFromUi', true);
      if (newVal !== oldVal) {
        this.debounceSaveFunc();
      }
    },
    formatValue(value) {
      const numValue = parseFloat(value);
      if (!Number.isNaN(numValue)) {
        if (numValue % 100 === 0 || this.hideCents) {
          return formatterWithoutCents().format(numValue / 100);
        }
        return formatterWithCents().format(numValue / 100);
      }
      return '';
    },
    schema() {
      return {
        type: this.readOnlyMarkdown ? 'markdown' : 'regex-input',
        inputType: 'text',
        validator: [
          VueFormGenerator.validators.string,
          VueFormGenerator.validators.regexp.locale({
            invalidFormat: 'Invalid dollar amount',
          }),
          ...this.validators(),
        ],
        pattern: '^[0-9]*([,][0-9]*)*([.][0-9]{0,2})?$',
        validateDebounceTime: DEBOUNCE_DELAY_MS,
        disabled: false,
        regex: this.hideCents ? /[0-9]|,/ : /[0-9]|\.|,/,
      };
    },
  },
});
