




import VueMarkdown from 'vue-markdown-render';
import Vue from 'vue';

type Data = {};

type Computed = { path: string; ariaLabel: string };

type Methods = {};

type Props = { source: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Markdown',
  props: { source: String },
  components: {
    VueMarkdown,
  },
});
