




import Vue from 'vue';
import { RplForm } from '@dpc-sdp/ripple-form';
import VueFormGenerator from 'vue-form-generator';
import FieldPassword from '@/components/ripple/form/FieldPassword.vue';

Vue.component('field-password', FieldPassword);
const DEBOUNCE_DELAY_MS = 500;

type Data = {
  model: {
    currentPassword: string;
    newPassword: string;
    reenteredPassword: string;
  };
  working: boolean;
  response: { response?: { message: string; status: string } };
};
type Methods = { onUpdate: () => void };
interface Computed {
  formData: object;
}
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ChangePassword',
  components: { RplForm },
  data() {
    return {
      model: {
        currentPassword: '',
        newPassword: '',
        reenteredPassword: '',
      },
      working: false,
      response: {},
    };
  },
  computed: {
    formData() {
      const validator = VueFormGenerator.validators.string.locale({
        fieldIsRequired: 'Required',
        textTooBig:
          'Must be no more than {1} characters, currently {0} characters',
      });
      return {
        model: this.model,
        schema: {
          fields: [
            {
              type: 'password',
              label: 'Current Password',
              model: 'currentPassword',
              required: true,
              max: 256,
              validator: [validator],
              validateDebounceTime: DEBOUNCE_DELAY_MS,
            },
            {
              type: 'rpldivider',
            },
            {
              type: 'password',
              label: 'New Password',
              model: 'newPassword',
              name: 'password',
              required: true,
              max: 256,
              pattern:
                '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\w$*.[\\]{}()?"^!@ #%&\\/\\\\,><\':;|_~`=+-]{8,99}$',
              validator: [
                VueFormGenerator.validators.regexp.locale({
                  fieldIsRequired: 'Required',
                  invalidFormat:
                    'Must be at least 8 characters and contain upper case, lower case and number',
                }),
              ],
              validateDebounceTime: DEBOUNCE_DELAY_MS,
            },
            {
              type: 'password',
              label: 'Re-enter Password',
              model: 'reenteredPassword',
              required: true,
              max: 256,
              validator: [
                validator,
                (value: string) =>
                  value === this.model.newPassword
                    ? []
                    : ['Passwords must match'],
              ],
              validateDebounceTime: DEBOUNCE_DELAY_MS,
            },
            {
              type: 'rplsubmitloader',
              buttonText: 'Update',
              loading: this.working,
              autoUpdate: true,
            },
          ],
        },
        formState: this.response,
        formOptions: {
          validateAfterChanged: true,
        },
      };
    },
  },
  methods: {
    onUpdate() {
      this.working = true;
      this.response = {};

      this.$store
        .dispatch('changePassword', this.model)
        .then(() => {
          this.model = {
            currentPassword: '',
            newPassword: '',
            reenteredPassword: '',
          };
          this.response = {
            response: { message: 'Password updated', status: 'success' },
          };
        })
        .catch((e) => {
          this.response = {
            response: { message: e.message, status: 'error' },
          };
        })
        .finally(() => {
          this.working = false;
        });
    },
  },
  mounted() {
    this.$store.commit('setHeaderText', 'Change password');
  },
});
