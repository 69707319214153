<template>
  <div v-bind:class="{ inverse }" class="button-wrapper" @click="click">
    <div v-if="loading" class="loader">
      <RplIcon
        v-if="loading"
        class="spin"
        symbol="loading_star"
        color="primary"
        size="1.5"
      />
    </div>
    <RplButton v-bind="$props" ref="button" :tabindex="tabindex || 0">
      <slot></slot>
    </RplButton>
  </div>
</template>

<script>
import RplButton from '@dpc-sdp/ripple-button';
import RplIcon from '@dpc-sdp/ripple-icon';

export default {
  name: 'Button',
  components: {
    RplButton,
    RplIcon,
  },
  methods: {
    click(event) {
      if (!this.disabled) {
        this.$emit('click', event);
      }
    },
    focus() {
      this.$refs.button.$el.focus();
    },
    hasFocus() {
      return document.activeElement === this.$refs.button.$el;
    },
  },
  props: {
    theme: String,
    disabled: Boolean,
    inverse: Boolean,
    loading: Boolean,
    tabindex: String,
  },
};
</script>
<style lang="scss" scoped>
.button-wrapper {
  position: relative;

  button:focus,
  button:focus-visible {
    outline-offset: 6px;
  }
  .loader {
    position: absolute;
    top: 0;
    right: 0.75rem;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &.inverse {
    button:not(:disabled) {
      background-color: white;
      border-width: 1px;

      &.rpl-button--primary {
        color: $rpl-button-primary-background-color;
        border-color: $rpl-button-primary-background-color;

        &:hover {
          color: $rpl-button-primary-hover-background-color;
          border-color: $rpl-button-primary-hover-background-color;
        }
      }

      &.rpl-button--secondary {
        color: $secondary-darkest;
        border-color: $secondary-darkest;

        &:hover {
          color: $rpl-button-secondary-hover-background-color;
          border-color: $rpl-button-secondary-hover-background-color;
        }
      }
    }
  }
}
</style>
