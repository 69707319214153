
































































































import Vue from 'vue';
import QrcodeVue from 'qrcode.vue';
import authServiceFactory from '@/api/auth';
import Button from '@/components/ripple/Button.vue';
import CopyIcon from '@/assets/copy-regular.svg?component';
import IconButton from '@/components/IconButton.vue';
import Loading from '@/components/ripple/Loading.vue';

type Data = {
  code?: string;
  verificationCode?: string;
  verifying: boolean;
  verified: boolean;
  email?: string;
  errorMessage?: string;
  passwordMessage?: string;
  copied: boolean;
  password?: string;
};
type Methods = {
  onVerify: () => void;
  generateToken: () => void;
  onCopy: () => void;
  onCopiedAnimationOver: () => void;
};
type Computed = {
  qrcode?: string;
  isValidForm: boolean;
  verificationMessage?: string;
};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'MFAVerify',
  components: { Loading, IconButton, Button, QrcodeVue, CopyIcon },
  data() {
    return {
      code: undefined,
      verificationCode: undefined,
      verified: false,
      verifying: false,
      email: undefined,
      errorMessage: undefined,
      passwordMessage: undefined,
      copied: false,
      password: undefined,
    };
  },
  computed: {
    qrcode() {
      if (this.code) {
        return `otpauth://totp/AppHub:${this.email}?secret=${this.code}&issuer=AppHub`;
      }
      return undefined;
    },
    isValidForm() {
      return (
        !!this.password &&
        this.password.trim() !== '' &&
        /^[0-9]{6}$/.test(this.verificationCode || '')
      );
    },
    verificationMessage() {
      if (
        this.errorMessage &&
        this.errorMessage.toLowerCase() === 'code mismatch'
      ) {
        return 'The code is invalid, please ensure that the code from authenticator app has not expired';
      }
      return this.errorMessage;
    },
  },
  methods: {
    onVerify() {
      this.errorMessage = undefined;
      this.passwordMessage = undefined;
      if (!this.password || this.password.trim() === '') {
        this.passwordMessage = 'Please enter password';
      } else {
        this.verifying = true;
        authServiceFactory()
          .then((auth) =>
            auth
              .checkPassword(this.password || '')
              .then((authenticated) => {
                if (authenticated) {
                  auth
                    .verifyMfaCode(this.verificationCode || '')
                    .then((verified) => {
                      this.verified = verified;
                      this.$emit('verified');
                    })
                    .catch((error) => {
                      this.errorMessage = error.message;
                    });
                } else {
                  this.passwordMessage = 'Incorrect password';
                }
              })
              .catch((err) => {
                if (err.name === 'NotAuthorizedException') {
                  this.passwordMessage = 'Incorrect password';
                }
              }),
          )
          .finally(() => {
            this.verifying = false;
          });
      }
    },
    generateToken() {
      authServiceFactory().then((auth) => {
        auth
          .associateSoftwareToken()
          .then((code) => {
            auth.getUser().then((user) => {
              this.email = user?.email;
              this.code = code;
            });
          })
          .catch((err) => this.$emit('error', err));
      });
    },
    onCopy() {
      navigator.clipboard.writeText(this.code || '');
      this.copied = true;
    },
    onCopiedAnimationOver() {
      this.copied = false;
    },
  },
  mounted() {
    this.generateToken();
  },
});
