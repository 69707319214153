


import RplSelect from '@dpc-sdp/ripple-form/Select.vue';
import Vue from 'vue';

type Data = { value: string | string[]; isOpen: boolean };
type Computed = { selectedItems: { name: string; id: string }[] };
type Methods = { close: (evt?: KeyboardEvent) => void };
type Props = {
  allValues: { name: string; id: string }[];
  searchable: boolean;
  filtering: boolean;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'RplAppHubSelect',
  extends: RplSelect,
  props: { allValues: Array, filtering: Boolean, searchable: Boolean },
  computed: {
    selectedItems() {
      const valArray = Array.isArray(this.value) ? this.value : [this.value];
      return this.allValues.filter((val) => valArray.includes(val.id));
    },
  },
  methods: {
    // Magic needed to allow tabbing into the search input, rpl-select otherwise closes on tab
    close(evt) {
      if (!evt || !this.searchable) {
        this.isOpen = false;
      } else {
        const targetElem = evt.target as HTMLElement;
        if (targetElem.tagName.toLowerCase() === 'input') {
          this.isOpen = this.filtering;
        } else if (targetElem.classList.contains('rpl-select__listbox')) {
          const input = targetElem.querySelector('input');
          if (input) {
            input.focus();
          }
        } else {
          this.isOpen = false;
        }
      }
    },
  },
});
