












import Vue from 'vue';
import { Column, RowViewModel } from './model/table.model';
import DeleteAction from '@/components/table/actions/dashboard/DeleteAction.vue';
import NewWindowAction from '@/components/table/actions/dashboard/NewWindowAction.vue';
import DownloadAction from '@/components/table/actions/entity/DownloadAction.vue';
import DeleteGroupMember from '@/components/table/actions/group/DeleteGroupMember.vue';
import DeleteCollaborator from '@/components/table/actions/entity/DeleteCollaborator.vue';
import ExpandRowAction from '@/components/table/actions/ExpandRowAction.vue';
import ShowEmailAction from '@/components/table/actions/entity/ShowEmailAction.vue';
import DeleteEditor from '@/components/table/actions/form/DeleteEditor.vue';
import DisabledDeleteEditor from '@/components/table/actions/form/DisabledDeleteEditor.vue';

Vue.component('new-window-action', NewWindowAction);
Vue.component('delete-form-action', DeleteAction);
Vue.component('download-action', DownloadAction);
Vue.component('delete-group-member', DeleteGroupMember);
Vue.component('delete-collaborator', DeleteCollaborator);
Vue.component('delete-editor', DeleteEditor);
Vue.component('disabled-delete-editor', DisabledDeleteEditor);
Vue.component('expand-row-action', ExpandRowAction);
Vue.component('show-email-action', ShowEmailAction);

type Data = {};

type Computed = {};

type Methods = {
  key: (action: string) => string;
};

type Props = {
  rowViewModel: RowViewModel<unknown>;
  viewPath: (row: RowViewModel<unknown>) => string;
  column: Column;
  actions: string[];
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TableActions',
  components: {},
  props: {
    rowViewModel: Object,
    viewPath: Function,
    column: Object,
    actions: [],
  },
  methods: {
    key(action) {
      return `${this.rowViewModel.row.id}_${action}`;
    },
  },
});
