import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import auth from '../api/auth';
import { Routes } from './routes';
import logger from '@/logger';
import store from '@/store';
import { paths } from './paths';
import { REDIRECT_ROUTE_KEY } from '@/config/constants';

Vue.use(VueRouter);

type Position = { x: number; y: number };

type PositionResult =
  | Position
  | void
  | { selector: string; offset?: Position; behavior?: ScrollBehavior };

type ScrollBehaviorHandler = (
  to: Route,
  from: Route,
  savedPosition: Position | void,
) => PositionResult | Promise<PositionResult> | undefined | null;

const scrollBehavior: ScrollBehaviorHandler = (to, from, savedPosition) => {
  // don't scroll to top for navigation to subroute unless for form stage navigation
  if (
    (to.name !== 'formStage' &&
      to.name !== 'entityFormStage' &&
      to.name === from.name) ||
    to.name?.startsWith('inline') ||
    from.name?.startsWith('inline')
  ) {
    return undefined;
  }

  if (savedPosition) {
    return savedPosition;
  }
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: Routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  if (
    store.getters.tenantId === '' ||
    store.getters.tenantId !== to.params.tenantId
  ) {
    store.commit('setTenantId', to.params.tenantId);
  }

  if (to.meta?.breadcrumb) {
    store.commit('setBreadcrumbs', to.meta.breadcrumb);
  }

  // collect breadcrumb keys from matched route hierarchy
  store.commit(
    'setBreadcrumbKeys',
    to.matched
      .filter((route) => route.meta.breadcrumbKeys)
      .flatMap((route) => route.meta.breadcrumbKeys),
  );

  if (!to.meta?.requireAuth) {
    next();
    return Promise.resolve();
  }

  return auth()
    .then((authService) =>
      authService.isAuthenticated().then((authenticated) => {
        if (!authenticated) {
          logger.error(
            `Trying to access '${to.path}', but we're not logged in to tenant '${store.getters.tenantId}'.`,
          );
          sessionStorage.setItem(REDIRECT_ROUTE_KEY, to.fullPath);
          next(paths(store.getters.tenantId).login());
        } else {
          next();
        }
      }),
    )
    .catch((err) => {
      next(err);
    });
});

export default router;
