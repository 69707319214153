<template>
  <div class="field" ref="field">
    <popover-label :field="field" :schema="schemaField" />
    <markdown-description :field="field" />
    <rpl-form :formData="formData" @keydown.native.enter="onEnter" />
    <data-item-delete :field="field" :dataItem="dataItem" />
    <save-status
      :touched="touched"
      :saved="saved"
      :saving="saving"
      :error="error"
    />
  </div>
</template>

<script>
import { RplForm } from '@dpc-sdp/ripple-form';
import VueFormGenerator from 'vue-form-generator';
import debounce from 'lodash.debounce';
import Vue from 'vue';
import fieldMixin from './mixin/fieldMixin';
import { PresentationControl } from '@/models/form.model';
import PopoverLabel from '@/components/pages/form/fields/PopoverLabel.vue';
import TextEditor from '@/components/pages/form/fields/TextEditor.vue';
import MarkdownDescription from '@/components/pages/form/fields/MarkdownDescription.vue';

Vue.component('field-text-editor', TextEditor);

const DEBOUNCE_DELAY_MS = 500;

export default {
  components: { MarkdownDescription, PopoverLabel, RplForm },
  name: 'TextField',
  mixins: [fieldMixin],
  computed: {
    dataItemValue() {
      return this.dataItem.value;
    },
    schemaType() {
      if (this.readOnlyMarkdown) {
        return 'markdown';
      }
      if (
        this.field.presentationControls.includes(PresentationControl.TextArea)
      ) {
        return 'textArea';
      }
      if (
        this.field.presentationControls.includes(PresentationControl.Editor)
      ) {
        return 'text-editor';
      }
      return 'input';
    },
  },
  methods: {
    changed(model, newVal, oldVal, field) {
      if (this.isValid(newVal, field)) {
        this.$store.commit('setUpdatingFromUi', true);
        this.debounceSaveFunc();
      } else {
        this.$store.commit('setUpdatingFromUi', false);
        this.debounceSaveFunc.cancel();
      }
    },
    schema() {
      return {
        type: this.schemaType,
        inputType: 'text',
        max: parseInt(this.field.max, 0),
        min: parseInt(this.field.min, 0),
        validator: [
          VueFormGenerator.validators.string.locale({
            textTooSmall: 'Minimum of {1} characters is allowed',
            textTooBig: 'Maximum of {1} characters is allowed',
          }),
          ...this.validators(),
        ],
        pattern: this.pattern(),
        comingSoon: this.field.repeating,
        validateDebounceTime: DEBOUNCE_DELAY_MS,
      };
    },
    adjustTextAreaHeight() {
      if (this.schema().type === 'textArea') {
        const textarea = this.$refs.field.querySelector('textarea');
        if (textarea) {
          textarea.style.height = `${textarea.scrollHeight}px`;
        }
      }
    },
    onEnter(event) {
      if (this.schema().type !== 'textArea') {
        event.preventDefault();
      }
    },
  },
  mounted() {
    this.debounceSaveFunc = debounce(
      () => this.save(this.model.value),
      DEBOUNCE_DELAY_MS,
    );
    this.adjustTextAreaHeight();
  },
};
</script>
