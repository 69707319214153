























































































import Vue from 'vue';
import Markdown from '@/components/markdown/Markdown.vue';
import {
  DataItem,
  Field,
  DataType,
  DataItemStatus,
  PresentationControl,
  EnumValue,
} from '@/models/form.model';
import DataTypeField from './DataTypeField.vue';
import ExpandIcon from '@/assets/chevron-circle-up-regular.svg?component';
import TransitionExpand from '@/components/util/TransitionExpand.vue';
import IconButton from '@/components/IconButton.vue';
import RecordStatusIcon from '@/components/pages/form/RecordStatusIcon.vue';
import AccordionField from '@/components/pages/form/fields/AccordionField.vue';
import { dataItemAndDescendants } from '@/helpers/form';

type Data = { collapsed?: boolean };

type Computed = {
  dataItem: DataItem | undefined;
  isLabelOnly: boolean;
  isRecord: boolean;
  readOnlyForm: boolean;
  isCollapsable: boolean;
  rollupText: string;
  toggleCollapseEvent: string | null;
  isCapsule: boolean;
  isAccordion: boolean;
  labelForDisplay: string;
  showStatusIcon: boolean;
};

type Methods = {
  onToggleCollapse: () => void;
  getValues: (
    field: Field,
    values: EnumValue[],
    dataItems: DataItem[],
  ) => string[];
  allChildFields: (field: Field) => Field[];
};

type Props = { field: Field; dataItems: DataItem[]; parentDataItem?: DataItem };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Field',
  components: {
    AccordionField,
    RecordStatusIcon,
    IconButton,
    TransitionExpand,
    DataTypeField,
    Markdown,
    Fields: () => import('./Fields.vue'),
    ExpandIcon,
  },
  props: {
    field: Object,
    dataItems: Array,
    parentDataItem: Object,
  },
  data() {
    return { collapsed: undefined };
  },
  computed: {
    dataItem() {
      return this.dataItems.find(
        (dataItem) =>
          (!this.parentDataItem ||
            this.parentDataItem.id === dataItem.dataItemId) &&
          dataItem.fieldId === this.field.id,
      );
    },
    isLabelOnly() {
      if (this.field.dataType === DataType.Label) {
        return true;
      }
      if (this.field.dataType === DataType.Record && this.field.label === '') {
        const visibleChildFields = this.allChildFields(this.field).filter(
          (field) => field.visible,
        );
        return (
          visibleChildFields.length === 1 &&
          visibleChildFields[0].dataType === DataType.Label
        );
      }
      return false;
    },
    isAccordion() {
      return (
        this.isLabelOnly &&
        this.field.presentationControls.includes(PresentationControl.Accordion)
      );
    },
    isRecord() {
      return this.field.dataType === DataType.Record;
    },
    readOnlyForm() {
      return this.$store.getters.readOnlyForm;
    },
    isCollapsable() {
      return this.field.presentationControls.includes(
        PresentationControl.Collapsable,
      );
    },
    rollupText() {
      const rollupFields = this.field.fields.filter((field) =>
        field.presentationControls.includes(PresentationControl.Rollup),
      );
      return rollupFields
        .flatMap((field) => {
          const dataItems = this.dataItems.filter(
            (dataItem) =>
              dataItem.fieldId === field.id &&
              dataItem.value !== undefined &&
              dataItem.value.trim() !== '',
          );
          if (field.enumValues.length) {
            return this.getValues(field, field.enumValues, dataItems);
          }
          const dataItemsWithEnumValues = dataItems.filter(
            (dataItem) => dataItem.allowableValues.length,
          );
          if (dataItemsWithEnumValues.length) {
            return dataItemsWithEnumValues.flatMap((dataItem) =>
              this.getValues(field, dataItem.allowableValues, [dataItem]),
            );
          }
          return dataItems.map((dataItem) => dataItem.value);
        })
        .join(', ');
    },
    toggleCollapseEvent() {
      return this.isCollapsable ? 'click' : null;
    },
    isCapsule() {
      return this.field.presentationControls.includes(
        PresentationControl.Capsule,
      );
    },
    labelForDisplay() {
      if (this.field.dataType === DataType.Label) {
        return this.dataItem?.value || '';
      }
      if (this.field.dataType === DataType.Record && this.field.label === '') {
        const visibleChildFields = this.allChildFields(this.field).filter(
          (field) => field.visible,
        );
        if (
          visibleChildFields.length === 1 &&
          visibleChildFields[0].dataType === DataType.Label
        ) {
          return (
            this.dataItems.find((di) => di.fieldId === visibleChildFields[0].id)
              ?.value || ''
          );
        }
      }
      return '';
    },
    showStatusIcon() {
      return !this.field.presentationControls.includes(
        PresentationControl.HideStatusIcon,
      );
    },
  },
  methods: {
    onToggleCollapse() {
      this.collapsed = !this.collapsed;
      sessionStorage.setItem(
        this.dataItem?.id || this.field.id,
        this.collapsed ? 'collapsed' : 'expanded',
      );
    },
    getValues(field, enumValues, dataItems) {
      let values: string[];
      if (
        field.dataType === DataType.MultiValueEnum ||
        field.dataType === DataType.CalculatedMultiValueEnum
      ) {
        values = dataItems.flatMap((dataItem) => {
          if (dataItem.value) {
            return JSON.parse(dataItem.value) as string[];
          }
          return [];
        });
      } else {
        values = dataItems
          .filter((dataItem) => dataItem.value)
          .map((dataItem) => dataItem.value || '');
      }
      return values
        .map((value) =>
          enumValues.find((enumValue) => enumValue.value === value),
        )
        .map((enumValue) => enumValue?.label || '');
    },
    allChildFields(field) {
      return [
        ...field.fields,
        ...field.fields.flatMap((childField) =>
          this.allChildFields(childField),
        ),
      ];
    },
  },
  created() {
    if (this.isCollapsable) {
      const item = sessionStorage.getItem(this.dataItem?.id || this.field.id);
      if (item) {
        this.collapsed = item === 'collapsed';
      } else if (this.dataItem) {
        this.collapsed = dataItemAndDescendants(
          this.dataItem,
          this.dataItems,
        ).every((dataItem) => dataItem.status === DataItemStatus.Complete);
      }
    }
  },
});
