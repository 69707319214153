<script>
import formPikaday from '@dpc-sdp/ripple-form/formPikaday.vue';
import Vue from 'vue';
import defaults from 'lodash/defaults';

/* this is an extension of formPikaday, needed to overcome misconfiguration of
pikaday, see comments below */

export default Vue.extend({
  name: 'RplAppHubPikaday',
  extends: formPikaday,
  methods: {
    async pikadayInit() {
      // eslint-disable-next-line import/no-extraneous-dependencies
      const Pikaday = await import('pikaday').then((module) =>
        module.default ? module.default : module,
      );
      // formPikaday doesn't configure a default date so the picker always opens at the current date.
      // Corrected here by setting defaultDate where available
      this.picker = new Pikaday(
        defaults(
          {
            ...this.pickerOptions,
            defaultDate: this.value ? new Date(this.value) : undefined,
            setDefaultDate: true,
          },
          {
            // formPikaday configures field as the container element not the input - leads to a number of issues
            // including inability to select month or year from picker dropdown
            // Corrected here by setting field to the input element
            field: this.$el.querySelector('input'),
            container: this.$refs.pikacontainer,
            onSelect: () => {
              this.displayVal = this.picker.toString();
              this.$emit('change', this.picker.getDate());
            },
          },
        ),
      );
      this.$emit('init', this.picker);
    },
  },
});
</script>
