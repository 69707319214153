
























import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import moment from 'moment';
import { Message, MessageStatus } from '@/models/entity.model';

type Data = {};
type Computed = { sending: boolean; error: boolean; own: boolean };
type Methods = {
  sentAt: () => string;
};
type Props = { message: Message };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Message',
  props: { message: Object },
  components: { RplIcon },
  computed: {
    sending() {
      return this.message.status === MessageStatus.Sending;
    },
    error() {
      return this.message.status === MessageStatus.Error;
    },
    own() {
      return (
        this.message.requestingUser ||
        this.message.status !== MessageStatus.Sent
      );
    },
  },
  methods: {
    sentAt() {
      if (this.message.sentAt) {
        const today = moment();
        const sentAt = moment(this.message.sentAt, moment.ISO_8601);
        if (sentAt.isValid()) {
          let format;
          if (sentAt.startOf('day').isSame(today.startOf('day'))) {
            format = 'HH:mm';
          } else if (sentAt.startOf('year').isSame(today.startOf('year'))) {
            format = 'DD/MM HH:mm';
          } else {
            format = 'DD/MM/YYYY HH:mm';
          }
          return moment(this.message.sentAt, moment.ISO_8601).format(format);
        }
      }
      return '';
    },
  },
});
