<template>
  <button
    class="text"
    @click="(event) => $emit('click', event)"
    @keydown="(event) => $emit('keydown', event)"
    ref="button"
    type="button"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'TextButton',
  methods: {
    focus() {
      this.$refs.button.focus();
    },
    hasFocus() {
      return document.activeElement === this.$refs.button;
    },
  },
};
</script>

<style lang="scss" scoped>
button.text {
  all: unset;
  display: flex;
  cursor: pointer;

  &:focus-visible {
    outline: var(--primary-lighter) auto 1px;
    outline-offset: 2px;
  }
}
</style>
