import { ActionContext, Module } from 'vuex';
import moment from 'moment';
import {
  DocumentSelection,
  DownloadRequest,
  DownloadRequestPollResponse,
  DownloadRequestStatus,
  Email,
  EntityAttribute,
  EntityDefinition,
  EntityDocument,
  EntityMessage,
  EntitySettings,
  Message,
  MessageStatus,
} from '@/models/entity.model';
import { RootState } from '../types';
import { EntityState } from './types';
import { buildTableViewModel, columnValue } from '@/helpers/table';
import { formatBytes } from '@/models/form.model';
import { recordApiCall } from '@/store/modules/apiCalls';
import {
  ApiLoading,
  ApiNeverLoaded,
  ApiResponse,
  DataFromApi,
  getData,
  hasData,
  isApiError,
} from '@/api/data';
import { TenantConfig } from '@/models/site.model';
import {
  ColumnDataType,
  Row,
  RowViewModel,
  TableGroupModel,
  TableGroupViewModel,
} from '@/components/table/model/table.model';
import { Collaborator, Team } from '@/models/team.model';

const DEFAULT_DETAILS: EntityDefinition = {
  entityId: '',
  attributes: [],
  entityDocument: { documents: [] },
  actions: [],
  timeline: [],
  messages: [],
  tables: [],
  settings: {
    showMessages: true,
    showCollaborators: false,
    hideCollaboratorGroup: false,
  },
};

const entity: Module<EntityState, RootState> = {
  state: () => ({
    detailsFromApi: ApiNeverLoaded,
    conversationFromApi: ApiNeverLoaded,
    teamFromApi: ApiNeverLoaded,
    inFlightMessages: [],
    loadingMessages: false,
    lastMessageUpdate: undefined,
    selectedDocuments: [],
    downloadRequests: [],
    reloading: false,
  }),

  mutations: {
    setEntityDetails(
      state: EntityState,
      details: ApiResponse<EntityDefinition>,
    ) {
      state.detailsFromApi = details;
    },
    resetEntityState(state: EntityState) {
      state.detailsFromApi = ApiNeverLoaded;
    },
    addInFlightMessage(state: EntityState, message: Message) {
      state.inFlightMessages.push(message);
    },
    removeInFlightMessage(state: EntityState, message: Message) {
      state.inFlightMessages = state.inFlightMessages.filter(
        (m) => m !== message,
      );
    },
    setMessageStatus(
      state: EntityState,
      args: { message: Message; status: MessageStatus },
    ) {
      const message = state.inFlightMessages.find(
        (msg) => msg === args.message,
      );
      if (message) {
        message.status = args.status;
      }
    },
    setMessages(state: EntityState, messages: ApiResponse<EntityMessage[]>) {
      state.conversationFromApi = messages;
    },
    setLoadingMessages(state: EntityState, loading: boolean) {
      state.loadingMessages = loading;
    },
    setLastMessageUpdate(state: EntityState, when: Date) {
      state.lastMessageUpdate = when;
    },
    setLoading(state: EntityState, loading: boolean) {
      state.loadingMessages = loading;
    },
    selectDocument(state: EntityState, document: DocumentSelection) {
      if (
        !state.selectedDocuments.some(
          (selectedDocument: DocumentSelection) =>
            selectedDocument.id === document.id,
        )
      ) {
        state.selectedDocuments.push(document);
      }
    },
    deselectDocument(state: EntityState, document: DocumentSelection) {
      state.selectedDocuments = state.selectedDocuments.filter(
        (doc) => doc.id !== document.id,
      );
    },
    addDownloadRequest(state: EntityState, request: DownloadRequest) {
      state.downloadRequests.push(request);
    },
    updateDownloadRequest(
      state: EntityState,
      payload: {
        downloadRequestId: string;
        pollResponse: DownloadRequestPollResponse;
      },
    ) {
      const downloadRequest = state.downloadRequests.find(
        (dr) => dr.downloadRequestId === payload.downloadRequestId,
      );
      if (downloadRequest) {
        downloadRequest.status = payload.pollResponse.status;
        downloadRequest.message = payload.pollResponse.feedback;
      }
    },
    removeDownloadRequest(state: EntityState, downloadRequestId: string) {
      state.downloadRequests = state.downloadRequests.filter(
        (dr) => dr.downloadRequestId !== downloadRequestId,
      );
    },
    setTeam(state: EntityState, team: ApiResponse<Team>) {
      state.teamFromApi = team;
    },
    setReloading(state: EntityState, reloading: boolean) {
      state.reloading = reloading;
    },
  },
  getters: {
    entity(state: EntityState): DataFromApi<EntityDefinition> {
      return state.detailsFromApi;
    },
    entityId(state: EntityState): string {
      return hasData(state.detailsFromApi)
        ? getData(state.detailsFromApi).entityId
        : DEFAULT_DETAILS.entityId;
    },
    getEntityAttributes(state: EntityState): EntityAttribute[] {
      return hasData(state.detailsFromApi)
        ? getData(state.detailsFromApi).attributes
        : DEFAULT_DETAILS.attributes;
    },
    getEntitySettings(state: EntityState): EntitySettings {
      return hasData(state.detailsFromApi)
        ? getData(state.detailsFromApi).settings
        : DEFAULT_DETAILS.settings;
    },
    getEntityDocuments(state: EntityState) {
      return hasData(state.detailsFromApi)
        ? getData(state.detailsFromApi).entityDocument.documents
        : DEFAULT_DETAILS.entityDocument.documents;
    },
    getEntityDocumentMessage(state: EntityState): string | undefined {
      return hasData(state.detailsFromApi)
        ? getData(state.detailsFromApi).entityDocument.message
        : undefined;
    },
    getEntityActions(state: EntityState) {
      return hasData(state.detailsFromApi)
        ? getData(state.detailsFromApi).actions
        : DEFAULT_DETAILS.actions;
    },
    getEntityTimeline(state: EntityState) {
      return hasData(state.detailsFromApi)
        ? getData(state.detailsFromApi).timeline
        : DEFAULT_DETAILS.timeline;
    },
    getEntityConversation(state: EntityState) {
      let rawConversation: EntityMessage[];
      if (hasData(state.conversationFromApi)) {
        rawConversation = getData(state.conversationFromApi);
      } else if (hasData(state.detailsFromApi)) {
        rawConversation = getData(state.detailsFromApi).messages;
      } else {
        rawConversation = [];
      }
      const conversation: Message[] = rawConversation.map((message) => ({
        ...message,
        status: MessageStatus.Sent,
      }));

      return {
        messages: [
          {
            from: 'System',
            sentAt: '',
            text: 'Enter messages below to communicate with the department.',
            status: MessageStatus.Sent,
          },
          ...conversation,
          ...state.inFlightMessages,
        ],
      };
    },
    getEntityDocumentsTableViewModel(state: EntityState, getters) {
      return buildTableViewModel(
        getters.getDocumentTableDefinition,
        getters.getRows,
      );
    },
    getRows(state: EntityState, getters) {
      return getters.getEntityDocuments.map(
        (doc: EntityDocument) =>
          ({
            id: doc.documentId,
            columns: [
              {
                key: 'fileName',
                component: 'wrapping-cell',
                value: doc.fileName,
              },
              { key: 'uploadedOn', value: doc.uploadedOn },
              { key: 'uploadedBy', value: doc.uploadedBy },
              { key: 'category', value: doc.category },
              { key: 'status', value: doc.status },
              { key: 'classification', value: doc.classification },
              { key: 'size', value: formatBytes(doc.size) },
              { key: 'data', component: 'selectEntityDocument' },
              {
                key: 'actions',
                actions: ['download-action'],
                value: doc.documentId,
              },
            ],
          } as Row),
      );
    },
    isDocumentSelected: (state: EntityState) => (id: string) =>
      state.selectedDocuments.some((doc) => doc.id === id),
    getSelectedDocuments: (state: EntityState, getters) =>
      state.selectedDocuments.filter((doc) =>
        getters.getEntityDocumentsTableViewModel.rows.some(
          (viewModel: RowViewModel<unknown>) => viewModel.row.id === doc.id,
        ),
      ),
    areAllDocumentsSelected: (state: EntityState, getters) =>
      getters.getEntityDocumentsTableViewModel.rows.every(
        (viewModel: RowViewModel<unknown>) =>
          getters.isDocumentSelected(viewModel.row.id),
      ),
    getActiveDownloadRequests(state: EntityState) {
      return state.downloadRequests.filter(
        (dr) => dr.status !== DownloadRequestStatus.Complete,
      );
    },
    getSelectedDownloadSize(state: EntityState, getters) {
      return getters.getSelectedDocuments.reduce(
        (size: number, doc: DocumentSelection) => {
          const entityDocument: EntityDocument =
            getters.getEntityDocuments.find(
              (entityDoc: EntityDocument) => entityDoc.documentId === doc.id,
            );
          if (entityDocument) {
            return size + entityDocument.size;
          }
          return size;
        },
        0,
      );
    },
    getDocumentTableDefinition(state: EntityState, rootGetters) {
      const config: TenantConfig = rootGetters.tenantSettings;
      const { documentAttributes } = config;
      const includeColumn = (columnName: string): boolean => {
        if (!documentAttributes || !documentAttributes.length) {
          return false;
        }
        const columns = documentAttributes.filter((attr) => {
          if (typeof attr === 'string') {
            return attr === columnName;
          }
          return attr.column === columnName;
        });
        return columns.length > 0;
      };
      const columnLabel = (columnName: string): string | undefined => {
        if (!documentAttributes || !documentAttributes.length) {
          return undefined;
        }
        const columns = documentAttributes.filter((attr) => {
          if (typeof attr !== 'string') {
            return attr.column === columnName;
          }
          return false;
        });
        return columns.length > 0 && typeof columns[0] !== 'string'
          ? columns[0].label
          : undefined;
      };

      return {
        label: '',
        columns: [
          { label: '', key: 'data', dataType: ColumnDataType.Component },
          ...(includeColumn('fileName')
            ? [
                {
                  label: columnLabel('fileName') || 'Attachment name',
                  key: 'fileName',
                  dataType: ColumnDataType.Component,
                  filterable: true,
                  searchable: true,
                  sortable: true,
                },
              ]
            : []),
          ...(includeColumn('uploadedOn')
            ? [
                {
                  label: columnLabel('uploadedOn') || 'Uploaded on',
                  key: 'uploadedOn',
                  dataType: ColumnDataType.Date,
                  filterable: true,
                  searchable: true,
                  sortable: true,
                },
              ]
            : []),
          ...(includeColumn('uploadedBy')
            ? [
                {
                  label: columnLabel('uploadedBy') || 'Uploaded by',
                  key: 'uploadedBy',
                  dataType: ColumnDataType.Text,
                  filterable: true,
                  searchable: true,
                  sortable: true,
                },
              ]
            : []),
          ...(includeColumn('category')
            ? [
                {
                  label: columnLabel('category') || 'Category',
                  key: 'category',
                  dataType: ColumnDataType.Text,
                  filterable: true,
                  searchable: true,
                  sortable: true,
                },
              ]
            : []),
          ...(includeColumn('status')
            ? [
                {
                  label: columnLabel('status') || 'Status',
                  key: 'status',
                  dataType: ColumnDataType.Text,
                  filterable: true,
                  searchable: true,
                  sortable: true,
                },
              ]
            : []),
          ...(includeColumn('classification')
            ? [
                {
                  label: columnLabel('classification') || 'Classification',
                  key: 'classification',
                  dataType: ColumnDataType.Text,
                  filterable: true,
                  searchable: true,
                  sortable: true,
                },
              ]
            : []),
          ...(includeColumn('size')
            ? [
                {
                  label: columnLabel('size') || 'Size',
                  key: 'size',
                  dataType: ColumnDataType.Text,
                },
              ]
            : []),
          { label: '', key: 'actions', dataType: ColumnDataType.Action },
        ],
        defaultSortOption: includeColumn('uploadedOn')
          ? { key: 'uploadedOn', direction: 'DESCENDING' }
          : { key: 'fileName', direction: 'ASCENDING' },
        pageSize: 10,
        displayRank: 0,
        export: true,
      };
    },
    getPrimaryTableViewModels(state: EntityState, getters) {
      const groupsViewModels: TableGroupViewModel<unknown>[] =
        getters.getEntityTableGroupViewModels;
      return (
        groupsViewModels.find(
          (groupViewModel) => groupViewModel.displayRank === 0,
        )?.tables || []
      );
    },
    getSecondaryTableGroupViewModels(state: EntityState, getters) {
      return getters.getEntityTableGroupViewModels.filter(
        (groupViewModel: TableGroupViewModel<unknown>) =>
          groupViewModel.displayRank !== 0,
      );
    },
    getEntityTableGroupViewModels(state: EntityState) {
      const groups = hasData(state.detailsFromApi)
        ? getData(state.detailsFromApi).tables.map(
            (tableGroupModel: TableGroupModel) => ({
              tables: tableGroupModel.tables.map((table) =>
                buildTableViewModel(table.tableMetadata, table.tableContents),
              ),
              displayRank: tableGroupModel.displayRank,
            }),
          )
        : [];
      return groups.sort((o1, o2) => {
        let result = o1.displayRank - o2.displayRank;
        if (result === 0) {
          result = o1.tables[0].config.label.localeCompare(
            o2.tables[0].config.label,
          );
        }
        return result;
      });
    },
  },
  actions: {
    getEntityDetails(
      { commit, rootGetters, rootState }: ActionContext<EntityState, RootState>,
      entityId: string,
    ) {
      commit('setEntityDetails', ApiNeverLoaded);
      commit('setMessages', ApiNeverLoaded);
      commit('setTeam', ApiNeverLoaded);
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getEntity',
        (client) => client.getEntity(entityId),
      ).then((response) => {
        commit('setEntityDetails', response);
        commit('setMessages', ApiNeverLoaded);
        commit('setLastMessageUpdate', new Date());
      });
    },
    reloadEntityDetails(
      { commit, rootGetters, rootState }: ActionContext<EntityState, RootState>,
      entityId: string,
    ) {
      commit('setReloading', true);
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getEntity',
        (client) => client.getEntity(entityId),
      )
        .then((response) => {
          commit('setEntityDetails', response);
        })
        .finally(() => commit('setReloading', false));
    },
    sendMessage(
      {
        commit,
        rootGetters,
        rootState,
        dispatch,
      }: ActionContext<EntityState, RootState>,
      args: { entityId: string; message: Message },
    ) {
      commit('addInFlightMessage', args.message);
      return recordApiCall(
        { rootState, rootGetters, commit },
        'sendMessage',
        (client) => client.sendMessage(args.entityId, args.message.text),
      )
        .then((response) => {
          if (isApiError(response)) {
            throw new Error();
          } else {
            dispatch('loadMessages', args.entityId).then(() =>
              commit('removeInFlightMessage', args.message),
            );
          }
        })
        .catch(() =>
          commit('setMessageStatus', {
            message: args.message,
            status: MessageStatus.Error,
          }),
        );
    },
    loadMessages(
      { commit, rootGetters, rootState }: ActionContext<EntityState, RootState>,
      entityId: string,
    ) {
      commit('setLoadingMessages', true);
      return recordApiCall(
        { rootState, rootGetters, commit },
        'loadMessages',
        (client) => client.loadMessages(entityId),
      ).then((response) => {
        commit('setMessages', response);
        commit('setLastMessageUpdate', new Date());
        commit('setLoadingMessages', false);
      });
    },
    selectAllDocuments({
      getters,
      commit,
    }: ActionContext<EntityState, RootState>) {
      getters.getEntityDocumentsTableViewModel.rows.forEach(
        (viewModel: RowViewModel<unknown>) =>
          commit('selectDocument', {
            id: viewModel.row.id,
            name: columnValue(viewModel.row.columns, 'fileName'),
          }),
      );
    },
    selectDocuments(
      { commit }: ActionContext<EntityState, RootState>,
      documents: RowViewModel<unknown>[],
    ) {
      documents.forEach((row) =>
        commit('selectDocument', {
          id: row.row.id,
          name: columnValue(row.row.columns, 'fileName'),
        }),
      );
    },
    deselectAllDocuments({
      getters,
      commit,
    }: ActionContext<EntityState, RootState>) {
      getters.getEntityDocumentsTableViewModel.rows.forEach(
        (viewModel: RowViewModel<unknown>) =>
          commit('deselectDocument', { id: viewModel.row.id }),
      );
    },
    downloadDocuments(
      {
        commit,
        getters,
        rootGetters,
        rootState,
      }: ActionContext<EntityState, RootState>,
      args: { fileName: string; documents: DocumentSelection[] },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'prepareDocumentDownload',
        (client) =>
          client.prepareDocumentDownload(
            getters.entityId,
            args.fileName,
            args.documents,
          ),
      ).then((response) => {
        if (hasData(response)) {
          const downloadRequestApiResponse = getData(response);
          commit('addDownloadRequest', {
            downloadRequestId: downloadRequestApiResponse.downloadRequestId,
            status: DownloadRequestStatus.Pending,
            entityId: getters.entityId,
            fileName: args.fileName,
            fileCount: args.documents.length,
          });
        }
      });
    },
    downloadSelectedDocuments({
      getters,
      dispatch,
    }: ActionContext<EntityState, RootState>) {
      const entityAttributes = getters.getEntityAttributes;
      let fileName: string;
      const nowStr = moment().format('yyyy-MM-DD-HHmmss');
      if (entityAttributes.length > 1) {
        fileName = `${entityAttributes[1].value.replaceAll(
          ' ',
          '_',
        )}_${entityAttributes[0].value.replaceAll(' ', '_')}_${nowStr}.zip`;
      } else if (entityAttributes.length > 0) {
        fileName = `${entityAttributes[0].value.replaceAll(
          ' ',
          '_',
        )}_${nowStr}.zip`;
      } else {
        fileName = `${nowStr}.zip`;
      }
      return dispatch('downloadDocuments', {
        fileName,
        documents: getters.getSelectedDocuments,
      });
    },
    downloadEmailAttachments(
      { dispatch }: ActionContext<EntityState, RootState>,
      email: Email,
    ) {
      const fileName = `${
        email.subject.substring(0, 100).replaceAll(' ', '_') || 'attachments'
      }.zip`;
      const documents: DocumentSelection[] = email.attachments.map(
        (attachment) => ({ id: attachment.id, name: attachment.fileName }),
      );
      return dispatch('downloadDocuments', { fileName, documents });
    },
    pollBulkDownloads({
      commit,
      state,
      rootGetters,
      rootState,
    }: ActionContext<EntityState, RootState>) {
      state.downloadRequests.forEach((downloadRequest: DownloadRequest) => {
        if (downloadRequest.status === DownloadRequestStatus.Pending) {
          return recordApiCall(
            { rootState, rootGetters, commit },
            'pollDownloadRequest',
            (client) =>
              client.pollDownloadRequest(
                downloadRequest.entityId,
                downloadRequest.downloadRequestId,
              ),
          ).then((response) => {
            if (!isApiError(response)) {
              const pollResponse = getData(response);
              commit('updateDownloadRequest', {
                downloadRequestId: downloadRequest.downloadRequestId,
                pollResponse,
              });
              if (
                pollResponse.status === DownloadRequestStatus.Complete &&
                pollResponse.url
              ) {
                window.location.href = pollResponse.url;
                commit(
                  'removeDownloadRequest',
                  downloadRequest.downloadRequestId,
                );
              }
            }
          });
        }
        return Promise.resolve();
      });
    },
    cancelBulkDownload(
      { commit }: ActionContext<EntityState, RootState>,
      downloadRequestId: string,
    ) {
      commit('removeDownloadRequest', downloadRequestId);
      return Promise.resolve();
    },
    loadEntityCollaborators(
      {
        state,
        commit,
        rootGetters,
        rootState,
      }: ActionContext<EntityState, RootState>,
      entityId: string,
    ) {
      commit('setTeam', ApiLoading(state.teamFromApi));
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getEntityCollaborators',
        (client) => client.getEntityCollaborators(entityId),
      ).then((response) => {
        commit('setTeam', response);
      });
    },
    addExistingUserToTeam(
      { commit, rootGetters, rootState }: ActionContext<EntityState, RootState>,
      args: { email: string; entityId: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'addExistingUserToTeam',
        (client) => client.addExistingUserToTeam(args.email, args.entityId),
      ).then((response: DataFromApi<Team>) => {
        if (!isApiError(response)) {
          commit('setTeam', response);
        }
        return response;
      });
    },
    addNewUserToTeam(
      { commit, rootGetters, rootState }: ActionContext<EntityState, RootState>,
      args: {
        email: string;
        firstName: string;
        lastName: string;
        entityId: string;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'addNewUserToTeam',
        (client) =>
          client.addNewUserToTeam(
            args.email,
            args.firstName,
            args.lastName,
            args.entityId,
          ),
      ).then((response: DataFromApi<Team>) => {
        if (!isApiError(response)) {
          commit('setTeam', response);
        }
        return response;
      });
    },
    addGroupToTeam(
      { commit, rootGetters, rootState }: ActionContext<EntityState, RootState>,
      args: { groupId: string; entityId: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'addGroupToTeam',
        (client) => client.addGroupToTeam(args.groupId, args.entityId),
      ).then((response: DataFromApi<Team>) => {
        if (!isApiError(response)) {
          commit('setTeam', response);
        }
        return response;
      });
    },
    removeUserFromTeam(
      { commit, rootGetters, rootState }: ActionContext<EntityState, RootState>,
      args: { member: Collaborator; entityId: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'removeUserFromTeam',
        (client) => client.removeUserFromTeam(args.member.id, args.entityId),
      ).then((response: DataFromApi<Team>) => {
        if (!isApiError(response)) {
          commit('setTeam', response);
        }
        return response;
      });
    },
    removeGroupFromTeam(
      { commit, rootGetters, rootState }: ActionContext<EntityState, RootState>,
      args: { member: Collaborator; entityId: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'removeGroupFromTeam',
        (client) => client.removeGroupFromTeam(args.member.id, args.entityId),
      ).then((response: DataFromApi<Team>) => {
        if (!isApiError(response)) {
          commit('setTeam', response);
        }
        return response;
      });
    },
  },
};

export default entity;
