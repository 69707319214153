import { ActionContext, Module } from 'vuex';

import { FormType } from '@/models/form.model';
import { RootState } from '../types';
import { FormTypeState } from './types';
import { ApiLoading, ApiNeverLoaded, DataFromApi } from '@/api/data';
import { recordApiCall } from './apiCalls';

const formType: Module<FormTypeState, RootState> = {
  state: () => ({
    formTypeFromApi: ApiNeverLoaded,
  }),
  mutations: {
    setFormType(state: FormTypeState, data: DataFromApi<FormType>) {
      state.formTypeFromApi = data;
    },
    clearFormTypeState(state: FormTypeState) {
      state.formTypeFromApi = ApiNeverLoaded;
    },
  },
  actions: {
    loadFormType(
      {
        commit,
        rootGetters,
        state,
        rootState,
      }: ActionContext<FormTypeState, RootState>,
      id: string,
    ) {
      commit('setFormType', ApiLoading(state.formTypeFromApi));
      return recordApiCall(
        { commit, rootGetters, rootState },
        'getFormType',
        (client) => client.getFormType(id),
      ).then((data) => {
        commit('setFormType', data);
      });
    },
  },
};

export default formType;
