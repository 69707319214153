<template>
  <div class="rpl-accordion">
    <h2 class="rpl-accordion__title-top" :id="titleId" v-if="title">
      {{ title }}
    </h2>
    <div class="rpl-accordion__collapse">
      <button class="rpl-accordion__collapse-btn" @click="closeOpenAll">
        {{ closeOpenLabel }}
      </button>
    </div>
    <ol class="rpl-accordion__list" v-if="type === 'numbered'">
      <li
        class="rpl-accordion__list-item"
        v-for="(accordion, index) in accordions"
        :key="index"
        :class="{
          'rpl-accordion__list-item--expanded': accordionIsOpen(index),
        }"
      >
        <h2
          class="rpl-accordion__title"
          :class="{ 'rpl-accordion__title--expanded': accordionIsOpen(index) }"
        >
          <button
            @click="accordionClick(index)"
            class="rpl-accordion__button"
            :class="{
              'rpl-accordion__button--expanded': accordionIsOpen(index),
            }"
            :aria-expanded="accordionIsOpen(index).toString()"
            :aria-controls="accordionId(index)"
          >
            <span aria-hidden="true" class="rpl-accordion__title-number">{{
              index + 1
            }}</span>
            <span>{{ accordion.title }}</span>
            <rpl-icon
              symbol="arrow_down_tertiary"
              color="primary"
              class="rpl-accordion__icon"
              :class="{
                'rpl-accordion__icon--expanded': accordionIsOpen(index),
              }"
            />
          </button>
        </h2>
        <div
          class="rpl-accordion__content"
          :id="accordionId(index)"
          :ref="accordionId(index)"
        >
          <markdown
            class="rpl-accordion__content-inner"
            :source="accordion.content"
          />
        </div>
      </li>
    </ol>
    <ul class="rpl-accordion__list" v-else>
      <li
        class="rpl-accordion__list-item"
        v-for="(accordion, index) in accordions"
        :key="index"
        :class="{
          'rpl-accordion__list-item--expanded': accordionIsOpen(index),
        }"
      >
        <h2
          class="rpl-accordion__title"
          :class="{ 'rpl-accordion__title--expanded': accordionIsOpen(index) }"
        >
          <button
            @click="accordionClick(index)"
            class="rpl-accordion__button"
            :class="{
              'rpl-accordion__button--expanded': accordionIsOpen(index),
            }"
            :aria-expanded="accordionIsOpen(index).toString()"
            :aria-controls="accordionId(index)"
          >
            <span class="rpl-accordion__button-text">{{
              accordion.title
            }}</span>
            <rpl-icon
              symbol="arrow_down_tertiary"
              color="primary"
              class="rpl-accordion__icon"
              :class="{
                'rpl-accordion__icon--expanded': accordionIsOpen(index),
              }"
            />
          </button>
        </h2>
        <div
          class="rpl-accordion__content"
          :id="accordionId(index)"
          :ref="accordionId(index)"
        >
          <markdown
            class="rpl-accordion__content-inner"
            :source="accordion.content"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import RplAccordion from '@dpc-sdp/ripple-accordion';
import Markdown from '@/components/markdown/Markdown.vue';

export default {
  name: 'AppHubAccordion',
  extends: RplAccordion,
  components: { Markdown },
};
</script>
