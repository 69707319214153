


















































































import Vue from 'vue';
import { RplTextLink, RplLink } from '@dpc-sdp/ripple-link';
import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import authServiceFactory from '@/api/auth';
import Button from '@/components/ripple/Button.vue';
import InfoIcon from '@/assets/info-circle-regular.svg?component';
import ShieldIcon from '@/assets/shield-check-solid.svg?component';
import MFAVerify from '@/components/pages/MFAVerify.vue';
import Loading from '@/components/ripple/Loading.vue';

type Data = {
  enabled: boolean;
  code?: string;
  verifying: boolean;
  toggleLoading: boolean;
  verified: boolean;
  loading: boolean;
  errorMessage?: string;
};
type Methods = {
  onToggle: () => void;
  onReset: () => void;
  onCancel: () => void;
  loadMFAStatus: () => Promise<void>;
  onVerified: () => void;
  onCloseAlert: () => void;
  enableMFA: () => void;
  disableMFA: () => void;
  onVerifyError: (err: Error) => void;
};
type Computed = {};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'MFA',
  components: {
    Loading,
    RplTextLink,
    MFAVerify,
    Button,
    InfoIcon,
    ShieldIcon,
    RplAlertBase,
    RplLink,
  },
  data() {
    return {
      enabled: false,
      verifying: false,
      toggleLoading: false,
      verified: false,
      loading: false,
      errorMessage: undefined,
    };
  },
  methods: {
    onToggle() {
      this.verified = false;
      this.errorMessage = undefined;
      if (this.enabled) {
        this.disableMFA();
      } else {
        this.enableMFA();
      }
    },
    onReset() {
      this.verifying = true;
      this.verified = false;
      this.errorMessage = undefined;
    },
    onCancel() {
      this.loadMFAStatus();
      this.verifying = false;
    },
    loadMFAStatus() {
      this.loading = true;
      return authServiceFactory()
        .then((auth) =>
          auth.isMFAEnabled().then((enabled) => {
            this.enabled = enabled;
          }),
        )
        .catch((err: Error) => {
          this.errorMessage = err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onVerified() {
      this.loadMFAStatus();
      this.verifying = false;
      this.verified = true;
      this.enableMFA();
    },
    onCloseAlert() {
      this.verified = false;
    },
    enableMFA() {
      this.toggleLoading = true;
      authServiceFactory().then((auth) => {
        auth
          .enableMFA()
          .then(() => this.loadMFAStatus())
          .catch((err: Error) => {
            if (err.name === 'InvalidParameterException') {
              this.verifying = true;
            } else {
              this.errorMessage = err.message;
            }
          })
          .finally(() => {
            this.toggleLoading = false;
          });
      });
    },
    disableMFA() {
      this.toggleLoading = true;
      authServiceFactory().then((auth) => {
        auth
          .disableMFA()
          .then(() => this.loadMFAStatus())
          .catch((err: Error) => {
            this.errorMessage = err.message;
          })
          .finally(() => {
            this.toggleLoading = false;
          });
      });
    },
    onVerifyError(err) {
      this.verifying = false;
      this.errorMessage = err.message;
    },
  },
  mounted() {
    this.$store.commit('setHeaderText', 'Manage two-step verification');
    this.loadMFAStatus();
  },
});
