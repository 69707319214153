
















import Vue from 'vue';
import PopoverToggle from '@/components/PopoverToggle.vue';
import { Field } from '@/models/form.model';

type Data = {};
type Methods = {};
type Computed = {};
type Props = { field: Field; schema: { id: string; required: boolean } };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'PopoverLabel',
  components: { PopoverToggle },
  props: { field: Object, schema: Object },
});
