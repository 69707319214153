
















import Vue from 'vue';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import { paths } from '@/router/paths';
import { BreadcrumbKey } from '@/models/site.model';

type Data = {};
type Computed = { linkText: string; linkPath: string };
type Methods = { onBack: () => void };
type Props = { entityId: string; name: string; formId: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EntityForm',
  components: { RplTextLink },
  props: {
    entityId: String,
    name: String,
    formId: String,
  },
  computed: {
    linkText() {
      return `Back to ${this.name}`;
    },
    linkPath() {
      return paths(this.$store.getters.tenantId).entity(this.entityId);
    },
  },
  methods: {
    onBack() {
      this.$router.push({ name: 'entity', params: { id: this.entityId } });
    },
  },
  created() {
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Dashboard,
      breadcrumbs: {
        text: 'My dashboard',
        url: paths(this.$store.getters.tenantId).dashboard(),
      },
    });
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Entity,
      breadcrumbs: {
        text: this.name,
        url: paths(this.$store.getters.tenantId).entity(this.entityId),
      },
    });
  },
});
