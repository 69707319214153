











































































































import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import Stages from './form/Stages.vue';
import Confirm from '@/components/Confirm.vue';
import {
  DataFromApi,
  getData,
  getDataOrThrow,
  hasData,
  isApiError,
  isLoadingOrNeverLoaded,
} from '@/api/data';
import {
  Form,
  FormCategorySummary,
  FormJob,
  FormStatus,
  FormType,
  StageType,
} from '@/models/form.model';
import { paths } from '@/router/paths';
import StageStatusIcon from '@/components/pages/form/StageStatusIcon.vue';
import { BreadcrumbKey } from '@/models/site.model';
import ProcessingOverlay from '@/components/pages/ProcessingOverlay.vue';
import Markdown from '@/components/markdown/Markdown.vue';

type Data = {
  inProgress: boolean;
  confirmProceed: boolean;
  proceed?: () => void;
  stagesOpen: boolean;
};
type Methods = {
  toggleStages: () => void;
  hideStages: () => void;
  onJobContinue: () => void;
};
type Computed = {
  formFromApi: DataFromApi<Form>;
  formTypeFromApi: DataFromApi<FormType>;
  hasData: boolean;
  isLoading: boolean;

  submissionErrorMessage?: string;
  submissionErrorTitle: string;
  isSubmissionError: boolean;

  form?: Form;
  formType?: FormType;
  stageType?: StageType;
  submitting: boolean;
  resetting: boolean;
  rejected: boolean;
  rejectionMessage?: string;
  entityId: string;
  stageDetails: string;
  stagesOpenEvent: string | null;
  pathBack?: string;
  formJob?: FormJob;
  inline?: boolean;
};
type Props = { formId: string; layout?: string; hideTeam?: boolean };

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Markdown,
    ProcessingOverlay,
    StageStatusIcon,
    Stages,
    RplIcon,
    Confirm,
  },
  name: 'Form',
  props: { formId: String, layout: String, hideTeam: Boolean },
  data() {
    return {
      inProgress: true,
      confirmProceed: false,
      proceed: undefined,
      stagesOpen: false,
    };
  },
  computed: {
    formFromApi() {
      return this.$store.getters.form as DataFromApi<Form>;
    },
    formTypeFromApi() {
      return this.$store.state.formType.formTypeFromApi;
    },
    hasData() {
      return hasData(this.formFromApi) && hasData(this.formTypeFromApi);
    },
    isLoading() {
      return (
        (isLoadingOrNeverLoaded(this.formFromApi) ||
          isLoadingOrNeverLoaded(this.formTypeFromApi)) &&
        !isApiError(this.formFromApi) &&
        !isApiError(this.formTypeFromApi)
      );
    },
    submissionErrorMessage() {
      return this.form?.form.errorMessage;
    },
    submissionErrorTitle() {
      return (
        this.form?.form.errorTitle ||
        'We have encountered a problem submitting your application.'
      );
    },
    isSubmissionError() {
      return this.form?.form.status === FormStatus.Error;
    },
    form() {
      if (hasData(this.formFromApi)) {
        return getData(this.formFromApi);
      }
      return undefined;
    },
    formType() {
      if (hasData(this.formTypeFromApi)) {
        return getData(this.formTypeFromApi);
      }
      return undefined;
    },
    stageType() {
      return this.$store.state.form.stageType;
    },
    submitting() {
      return this.$store.getters.formIsSubmitting;
    },
    resetting() {
      return this.$store.state.form.resetting;
    },
    entityId() {
      return this.$store.state.form.entityId;
    },
    rejected() {
      return this.form?.form.status === FormStatus.Rejected;
    },
    rejectionMessage() {
      return this.form?.form.rejectionMessage;
    },
    stageDetails() {
      if (this.formType && this.stageType) {
        return `Step ${this.formType.stages.indexOf(this.stageType) + 1} of ${
          this.formType.stages.length
        }`;
      }
      return '';
    },
    stagesOpenEvent() {
      return this.stagesOpen ? 'mousedown' : null;
    },
    pathBack() {
      return this.$route.params.pathBack;
    },
    formJob() {
      return (
        this.$store.state.form.formJob ||
        this.$store.state.form.widgetProcessingJob
      );
    },
    inline() {
      return this.layout === 'inline';
    },
  },
  methods: {
    toggleStages() {
      this.stagesOpen = !this.stagesOpen;
    },
    hideStages() {
      this.stagesOpen = false;
    },
    onJobContinue() {
      this.$store.dispatch('reloadForm');
    },
  },
  created() {
    this.$store.dispatch('loadForm', this.formId).then(() => {
      if (!this.entityId) {
        this.$store
          .dispatch(
            'loadFormType',
            getDataOrThrow<Form>(this.$store.state.form.formFromApi).form
              .formTypeId,
          )
          .then(() => {
            if (!this.$route.params.stageTypeId && this.formType) {
              this.$router.replace({
                name: this.$route.meta?.stageRouteName,
                params: { stageTypeId: this.formType.stages[0].id || '' },
              });
            }
          });
      }
    });
  },
  destroyed() {
    this.$store.dispatch('clearForm');
  },
  watch: {
    form(val) {
      if (val && !this.inline) {
        this.$store.commit('setHeaderText', val.form.label);
        this.$store.commit('addBreadcrumbData', {
          key: BreadcrumbKey.Category,
          breadcrumbs: val.categories.map((category: FormCategorySummary) => ({
            text: category.label,
            url: paths(this.$store.getters.tenantId).formCategory(category.id),
          })),
        });
        this.$store.commit('addBreadcrumbData', {
          key: BreadcrumbKey.Form,
          breadcrumbs: [{ text: val.form.label }],
        });
      }
    },
    entityId(id) {
      if (id) {
        if (this.pathBack) {
          if (this.pathBack.startsWith('/')) {
            this.$router.replace(this.pathBack);
          } else {
            document.location.href = this.pathBack;
          }
        } else {
          this.$router.replace({
            name: 'entity',
            params: {
              id,
            },
          });
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.uploadingCount > 0) {
      this.confirmProceed = true;
      this.proceed = next;
      return false;
    }
    next();
    return true;
  },
});
