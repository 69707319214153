import moment from 'moment';

const FORMATS = { date: 'DD MMM YYYY', datetime: 'DD MMM YYYY h:mm A' };

export const formatDate = (value: string) => {
  if (value) {
    const dateObj = moment(value, moment.ISO_8601);
    if (dateObj.isValid()) {
      return dateObj.format(FORMATS.date);
    }
  }

  return 'Date not available';
};

export const formatDateTime = (value: string) => {
  if (value) {
    const dateObj = moment(value, moment.ISO_8601);
    if (dateObj.isValid()) {
      return dateObj.format(FORMATS.datetime);
    }
  }

  return 'Date not available';
};
