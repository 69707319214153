import { ApiError, ApiErrorType } from './data';

export const NotFoundError = (message: string): ApiError => ({
  state: 'ERROR',
  type: ApiErrorType.NotFound,
  message,
});

export const REQUEST_CANCELLED_ERROR: ApiError = {
  state: 'ERROR',
  message: 'The request was cancelled',
  type: ApiErrorType.RequestCancelled,
};

export const LinkUserError = (message: string): ApiError => ({
  state: 'ERROR',
  type: ApiErrorType.UserNotLinked,
  message,
  siteError: true,
});

export const NoTenantError = (message: string): ApiError => ({
  state: 'ERROR',
  type: ApiErrorType.NoTenant,
  message,
  siteError: true,
});

export const ConnectorForbiddenError = (message: string): ApiError => ({
  state: 'ERROR',
  type: ApiErrorType.ConnectorForbidden,
  message,
});

export const TenantAccessError = (message: string): ApiError => ({
  state: 'ERROR',
  type: ApiErrorType.TenantAccess,
  message,
  siteError: true,
});

export const UserMigrationFailedError = (message: string): ApiError => ({
  state: 'ERROR',
  type: ApiErrorType.UserMigrationFailed,
  message,
});
