
















































































































































import Vue from 'vue';
import {
  RplSearchForm,
  RplSearchResultsLayout,
  RplSearchResultsTable,
} from '@dpc-sdp/ripple-search';
import { RplCol } from '@dpc-sdp/ripple-grid';
import { RplFormEventBus, RplSelect } from '@dpc-sdp/ripple-form';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import _get from 'lodash/get';
import mixins from 'vue-typed-mixins';
import {
  formatDate,
  formatDateOrDateTime,
  isBetween,
  isSameOrAfter,
  isSameOrBefore,
} from '@/helpers/date';
import {
  Column,
  ColumnDataType,
  RowViewModel,
  SortDirection,
  SortOption,
  TableViewModel,
} from './model/table.model';
import { VueComponent } from '@/types';
import {
  buildSortOption,
  columnActions,
  columnComponent,
  columnValue,
  findColumn,
  getSortOption,
  sortRows,
} from '@/helpers/table';
import ReloadIcon from '@/assets/sync.svg?component';
import TableActions from './TableActions.vue';
import TableComponent from '@/components/table/TableComponent.vue';
import Clearform from './Clearform.vue';
import ClearSearch from './ClearSearch.vue';
import ColumnConfig from '@/components/table/ColumnConfig.vue';
import retainState from '@/components/mixin/retainState';
import TotalsRow from '@/components/table/TotalsRow.vue';
import PopoverToggle from '@/components/PopoverToggle.vue';
import Markdown from '@/components/markdown/Markdown.vue';
import PopoverLabel from '@/components/pages/form/fields/PopoverLabel.vue';

Vue.component('tableActions', TableActions);
Vue.component('tableComponent', TableComponent);
Vue.component('field-clearform', Clearform);

type FormattedRow = {
  [id: string]: string | object;
} & { rowModel: RowViewModel<unknown> };

interface Data {
  page: number;
  filterTerm?: string;
  sortOptionId?: string;
  filterModel: Record<string, string | string[] | undefined>;
  activeColumnFilters: { column: Column; filterValue: string | string[] }[];
  perPage: number;
  visibleColumns: string[];
  summableProps: { columns: Column[]; rows: RowViewModel<unknown>[] };
}

interface Computed {
  filteredRows: RowViewModel<unknown>[];
  rows: FormattedRow[];
  count: number;
  pagedItems: FormattedRow[];
  pagination: { totalSteps: number; initialStep: number; stepsAround: number };
  displayableColumns: Column[];
  columnConfig: { label: string; key: string; component?: string }[];
  sortOptions?: SortOption[];
  handleRowClicks: boolean;
  filterable: boolean;
  searchable: boolean;
  filterForm?: unknown;
  columnOptions: { name: string; id: string }[];
  includeTotals: boolean;
}

type Methods = {
  formatByDataType: (r: RowViewModel<unknown>) => {
    [id: string]: string | object;
  };
  onPagerChange: (page: number) => void;
  onChangeSortOption: (sortOptionId: string) => void;
  onChangePageSize: (pageSize: string) => void;
  onClick: (event: Event) => void;
  onFilter: (filterTerm: string) => void;
  onClearFilter: () => void;
  onClearSearch: () => void;
  filterRow: (
    column: Column,
    rvm: RowViewModel<unknown>,
    filterTerm: string | string[],
  ) => boolean;
  searchRow: (
    column: Column,
    rvm: RowViewModel<unknown>,
    filterTerm: string,
  ) => boolean;
  onKeydownFilter: (event: KeyboardEvent) => void;
  onDownArrowTable: (event: KeyboardEvent) => void;
  onUpArrowTable: (event: KeyboardEvent) => void;
  onSelectRow: (event: KeyboardEvent) => void;
  onTableBlur: (event: FocusEvent) => void;
  exportToCSV: () => void;
  onConfigureColumn: (config: { id: string; selected: boolean }) => void;
};

type Props = {
  tableViewModel: TableViewModel<unknown>;
  emitRowClicks: boolean;
  emptyMessage: string;
  initialFilterTerm?: string;
  components?: {
    name: string;
    component: () => VueComponent;
  }[];
  loading: boolean;
  expandFilters?: boolean;
  showPageSize?: boolean;
};

export default mixins(retainState).extend<Data, Methods, Computed, Props>({
  name: 'DataTable',
  props: {
    tableViewModel: Object,
    emitRowClicks: Boolean,
    emptyMessage: String,
    initialFilterTerm: String,
    components: Array,
    loading: Boolean,
    expandFilters: Boolean,
    showPageSize: Boolean,
  },
  components: {
    PopoverLabel,
    Markdown,
    PopoverToggle,
    ColumnConfig,
    RplSearchResultsLayout,
    RplSearchResultsTable,
    RplCol,
    RplSelect,
    RplSearchForm,
    ReloadIcon,
    RplTextLink,
  },
  data() {
    return {
      page: 1,
      filterTerm: this.initialFilterTerm || '',
      sortOptionId: undefined,
      filterModel: {},
      activeColumnFilters: [],
      perPage: this.tableViewModel.config.pageSize || 5,
      visibleColumns: [],
      summableProps: { columns: [], rows: [] },
    };
  },
  computed: {
    filteredRows() {
      const columnFilters = this.activeColumnFilters;
      const searchableColumns = this.tableViewModel.config.columns.filter(
        (column: Column) => column.searchable,
      );
      return this.tableViewModel.rows.filter(
        (row) =>
          (searchableColumns.length === 0 ||
            searchableColumns.some((column) =>
              this.searchRow(column, row, this.filterTerm || ''),
            )) &&
          columnFilters.every((colFilter) =>
            this.filterRow(colFilter.column, row, colFilter.filterValue),
          ),
      );
    },
    rows() {
      let rows;
      if (this.sortOptionId) {
        const sortOption = getSortOption(this.sortOptions, this.sortOptionId);
        if (sortOption) {
          rows = sortRows(
            this.filteredRows,
            sortOption,
            findColumn(
              this.tableViewModel.config.columns,
              sortOption.columnKey,
            ),
          );
        } else {
          rows = this.filteredRows;
        }
      } else {
        rows = this.filteredRows;
      }
      return rows.map(
        (viewModel): FormattedRow => ({
          ...this.formatByDataType(viewModel),
          rowModel: viewModel,
        }),
      );
    },
    pagedItems() {
      return this.rows.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage,
      );
    },
    count() {
      return this.rows.length;
    },
    pagination() {
      const totalSteps = Math.ceil(this.rows.length / this.perPage);
      return {
        totalSteps: totalSteps === 1 ? 0 : totalSteps,
        initialStep: this.page,
        stepsAround: 2,
      };
    },
    displayableColumns() {
      return this.tableViewModel.config.columns.filter(
        (column) => !column.hidden && this.visibleColumns.includes(column.key),
      );
    },
    columnConfig() {
      return this.displayableColumns.map((column) => {
        const additionalProperties: { component?: string; cols?: string } = {};
        if (column.dataType === ColumnDataType.Action) {
          additionalProperties.component = 'tableActions';
        } else if (column.dataType === ColumnDataType.Component) {
          additionalProperties.component = 'tableComponent';
        } else if (column.dataType === ColumnDataType.Number) {
          additionalProperties.cols = 'center';
        }
        return {
          label: column.label,
          key: column.key,
          ...additionalProperties,
        };
      });
    },
    sortOptions() {
      return this.tableViewModel.config.columns
        .filter((col) => col.sortable)
        .flatMap((col) => [
          buildSortOption(col, SortDirection.Asc),
          buildSortOption(col, SortDirection.Desc),
        ]);
    },
    handleRowClicks() {
      return this.emitRowClicks === true;
    },
    filterable() {
      return this.tableViewModel.config.columns.some((col) => col.filterable);
    },
    searchable() {
      return this.tableViewModel.config.columns.some((col) => col.searchable);
    },
    filterForm() {
      const fields = this.tableViewModel.config.columns
        .filter(
          (col) => col.filterable && this.visibleColumns.includes(col.key),
        )
        .map((col) => {
          switch (col.dataType) {
            case ColumnDataType.Date:
            case ColumnDataType.DateTime:
              return {
                type: 'rpldatepicker',
                range: true,
                label: col.label,
                model: col.key,
                styleClasses: 'form-group--inline',
                startPlaceholder: 'dd/mm/yyyy',
                endPlaceholder: 'dd/mm/yyyy',
              };
            default: {
              const uniqueColumnValues = new Set<string>();
              this.tableViewModel.rows.forEach((rvm) => {
                const columnData = rvm.row.columns.find(
                  (column) => column.key === col.key,
                );
                if (columnData?.value && columnData.value.trim() !== '') {
                  uniqueColumnValues.add(columnData.value);
                }
              });
              return {
                type: 'rplselect',
                multiselect: col.multiFilterable,
                label: col.label,
                model: col.key,
                styleClasses: 'form-group--col-three',
                values: [
                  ...(col.multiFilterable ? [] : [{ id: '', value: '' }]),
                  ...Array.from(uniqueColumnValues)
                    .sort((a: string, b: string) =>
                      a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                      }),
                    )
                    .map((v) => ({ id: v, name: v })),
                ],
              };
            }
          }
        });
      return fields.length > 0
        ? {
            model: this.filterModel,
            tag: 'RplFieldset',
            schema: {
              groups: [
                {
                  fields,
                },
                {
                  fields: [
                    {
                      type: 'rplsubmitloader',
                      buttonText: 'Apply filters',
                      loading: false,
                      styleClasses: 'form-group--inline',
                    },
                    {
                      type: 'clearform',
                      buttonText: 'Clear search filters',
                      loading: false,
                      styleClasses: 'form-group--inline',
                      clearHandler: () => this.onClearFilter(),
                    },
                  ],
                },
              ],
            },
            formState: {},
          }
        : undefined;
    },
    columnOptions() {
      return this.tableViewModel.config.columns
        .filter((column) => !column.hidden)
        .map((col) => ({
          name: col.label || 'N/A',
          id: col.key,
          disabled: true,
        }));
    },
    includeTotals() {
      return this.tableViewModel.config.columns.some(
        (column) => column.summable,
      );
    },
  },
  methods: {
    onPagerChange(page: number) {
      this.page = page;
    },
    onChangeSortOption(sortOptionId: string) {
      this.page = 1;
      this.$emit('sort-option-changed', sortOptionId);
      this.sortOptionId = sortOptionId;
    },
    onChangePageSize(pageSize: string) {
      this.perPage = parseInt(pageSize, 10);
    },
    onClick(event: Event) {
      if (this.handleRowClicks && event.target) {
        const rowElement = (event.target as Element).closest('tr[data-tid]');
        if (rowElement) {
          const attribute = rowElement.attributes.getNamedItem('data-tid');
          if (attribute) {
            const rowIndex = parseInt(attribute.value.replace('row-', ''), 10);
            if (!Number.isNaN(rowIndex)) {
              this.$emit('row-clicked', this.pagedItems[rowIndex].rowModel);
            }
          }
        }
      }
    },
    formatByDataType(item) {
      const formattedItem: Record<string, string | object> = {};
      this.tableViewModel.config.columns.forEach((column: Column) => {
        const colValue = columnValue(item.row.columns, column.key);
        if (column.dataType === ColumnDataType.Action) {
          formattedItem[column.key] = {
            rowViewModel: item,
            column,
            actions: columnActions(item.row.columns, column.key),
            viewPath: this.tableViewModel.viewPath,
          };
        } else if (column.dataType === ColumnDataType.Component) {
          formattedItem[column.key] = {
            rowViewModel: item,
            column,
            component:
              columnComponent(item.row.columns, column.key) || column.component,
            viewPath: this.tableViewModel.viewPath,
          };
        } else if (colValue !== undefined) {
          if (column.dataType === ColumnDataType.Date) {
            formattedItem[column.key] = formatDate(colValue);
          } else if (column.dataType === ColumnDataType.DateTime) {
            formattedItem[column.key] = formatDateOrDateTime(colValue);
          } else if (column.dataType === ColumnDataType.Number) {
            formattedItem[column.key] =
              Number.parseFloat(colValue).toLocaleString();
          } else {
            formattedItem[column.key] = colValue;
          }
        }
      });
      return formattedItem;
    },
    onFilter(filterTerm) {
      this.page = 1;
      this.$emit('filter', filterTerm);
      this.filterTerm = filterTerm;
      this.activeColumnFilters = this.tableViewModel.config.columns
        .filter((column: Column) => column.filterable)
        .filter(
          (column: Column) =>
            _get(this.filterModel, column.key, '').toString().trim() !== '',
        )
        .map((column) => ({
          filterValue: _get(this.filterModel, column.key, ''),
          column,
        }));
    },
    onClearFilter() {
      Object.keys(this.filterModel).forEach((key) => {
        const value = this.filterModel[key];
        if (Array.isArray(value)) {
          // Hackery required to overcome a bug in ripple - setting to undefined clears the date range fields, subsequently setting to empty array resets the filter count
          this.filterModel[key] = undefined;
          setTimeout(() => {
            this.filterModel[key] = [];
          }, 0);
        } else {
          this.filterModel[key] = undefined;
        }
      });
      this.activeColumnFilters = [];
      this.onFilter('');
    },
    onClearSearch() {
      this.onFilter('');
    },
    filterRow(column, rvm, filterTerm) {
      const valueToFilter = columnValue(rvm.row.columns, column.key);
      if (
        valueToFilter &&
        (column.dataType === ColumnDataType.Date ||
          column.dataType === ColumnDataType.DateTime)
      ) {
        if (filterTerm[0] && filterTerm[1]) {
          return isBetween(valueToFilter, filterTerm[0], filterTerm[1]);
        }
        if (filterTerm[0]) {
          return isSameOrAfter(valueToFilter, filterTerm[0]);
        }
        if (filterTerm[1]) {
          return isSameOrBefore(valueToFilter, filterTerm[1]);
        }
        return true;
      }
      if (valueToFilter && Array.isArray(filterTerm)) {
        return filterTerm.includes(valueToFilter);
      }
      return filterTerm.toString().trim() === valueToFilter;
    },
    searchRow(column, rvm, filterTerm) {
      let valueToFilter = columnValue(rvm.row.columns, column.key);
      if (valueToFilter && column.dataType === ColumnDataType.Date) {
        valueToFilter = formatDate(valueToFilter.toString());
      } else if (valueToFilter && column.dataType === ColumnDataType.DateTime) {
        valueToFilter = formatDateOrDateTime(valueToFilter.toString());
      }
      // get the value from the column filter field
      const filterValue = new RegExp(filterTerm.trim(), 'i');
      return filterValue.test(valueToFilter || '');
    },
    onKeydownFilter(event) {
      // Because we can't control the tab order within ripple search form,
      // intercept enter and space keydown events and treat as if clear search action when search term present
      const target = event.target as HTMLElement;
      if (target.className === 'rpl-search-form__btn' && this.filterTerm) {
        this.onClearSearch();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    onDownArrowTable(event) {
      const elem = event.target as HTMLElement;
      const selectedRow = elem.querySelector('tbody tr.selected');
      let nextRow;
      if (selectedRow) {
        selectedRow.classList.remove('selected');
        nextRow = selectedRow.nextElementSibling;
      }
      if (!nextRow) {
        nextRow = elem.querySelector('tbody tr:first-child');
      }
      if (nextRow) {
        nextRow.classList.add('selected');
      }
    },
    onUpArrowTable(event) {
      const elem = event.target as HTMLElement;
      const selectedRow = elem.querySelector('tbody tr.selected');
      let prevRow;
      if (selectedRow) {
        selectedRow.classList.remove('selected');
        prevRow = selectedRow.previousElementSibling;
      }
      if (!prevRow) {
        prevRow = elem.querySelector('tbody tr:last-child');
      }
      if (prevRow) {
        prevRow.classList.add('selected');
      }
    },
    onSelectRow(event) {
      const elem = event.target as HTMLElement;
      const selectedRow = elem.querySelector(
        'tbody tr.selected',
      ) as HTMLElement;
      if (selectedRow) {
        selectedRow.click();
      }
    },
    onTableBlur(event) {
      const elem = event.target as HTMLElement;
      const selectedRow = elem.querySelector('tbody tr.selected');
      if (selectedRow) {
        selectedRow.classList.remove('selected');
      }
    },
    onConfigureColumn(config) {
      if (config.selected) {
        this.visibleColumns.push(config.id);
      } else {
        this.visibleColumns.splice(this.visibleColumns.indexOf(config.id), 1);
      }
    },
    exportToCSV() {
      const dataColumns = this.tableViewModel.config.columns.filter(
        (col) =>
          !col.hidden &&
          col.dataType !== ColumnDataType.Action &&
          col.label &&
          this.visibleColumns.includes(col.key),
      );
      const data = [
        dataColumns.map((col) => col.label),
        ...this.rows.map((row) =>
          dataColumns.map((col) => {
            if (col.dataType === ColumnDataType.Component) {
              return columnValue(row.rowModel.row.columns, col.key) || '';
            }
            return row[col.key] || '';
          }),
        ),
      ];
      const blob = new Blob(
        [data.map((row) => row.map((val) => `"${val}"`).join(',')).join('\n')],
        { type: 'text/csv;charset=utf-8,' },
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${(this.tableViewModel.config.label || 'export')
        .toLowerCase()
        .replaceAll(' ', '_')}.csv`;
      link.click();
      link.remove();
    },
  },
  watch: {
    rows() {
      this.summableProps.columns = this.displayableColumns;
      this.summableProps.rows = this.filteredRows;
    },
    // Handle scenario where last item on a page is removed
    pagedItems(items: FormattedRow[]) {
      if (items.length === 0 && this.page !== 1) {
        this.page -= 1;
      }
    },
    filteredRows() {
      this.$emit('rows-filtered', this.filteredRows);
    },
  },
  mounted() {
    RplFormEventBus.$on('clearform', () => {
      this.filterModel = {};
      this.activeColumnFilters = [];
    });

    // manually mount a clear search button at the appropriate place in the dom
    if (this.searchable) {
      const clearSearch = new ClearSearch();
      const container = document.createElement('div');
      const searchFormComponent = this.$refs.searchForm as Vue;
      if (searchFormComponent) {
        const elem = searchFormComponent.$el.querySelector(
          '.rpl-search-form__field',
        );
        if (elem) {
          elem.append(container);
          clearSearch.$on('click', this.onClearSearch);
          clearSearch.$mount(container);
        }
      }
    }
    // manually mount a totals footer row
    if (this.includeTotals) {
      const tableComponent = this.$refs.table as Vue;
      const totalsRow = new TotalsRow() as Vue & { _props: unknown };
      // eslint-disable-next-line no-underscore-dangle
      totalsRow._props = this.summableProps;
      const table = tableComponent.$el;
      if (table) {
        const tfoot = document.createElement('tfoot');
        table.append(tfoot);
        totalsRow.$mount();
        tfoot.append(totalsRow.$el);
      }
    }
  },
  created() {
    if (this.components) {
      this.components.forEach((c) => Vue.component(c.name, c.component));
    }
    const { defaultSortOption } = this.tableViewModel.config;
    if (defaultSortOption) {
      this.sortOptionId = `${defaultSortOption.key}${
        defaultSortOption.direction.toLowerCase() === 'ascending'
          ? SortDirection.Asc
          : SortDirection.Desc
      }`;
    }
    this.visibleColumns = this.columnOptions.map((opt) => opt.id);
    this.configureRetainState(
      this.tableViewModel.config.retainLocal,
      this.tableViewModel.config.retainSession,
    );
  },
});
