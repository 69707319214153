import { ActionContext, Module } from 'vuex';

import { RootState } from '../types';
import { WidgetConfig, WidgetState } from '@/store/modules/types';
import { recordApiCall } from '@/store/modules/apiCalls';
import { getData, hasData } from '@/api/data';

const widget: Module<WidgetState, RootState> = {
  state: () => ({
    configs: [],
  }),
  mutations: {
    addWidgetConfig(state, config: WidgetConfig) {
      state.configs.push(config);
    },
  },
  getters: {
    getWidgetConfig: (state) => (dataItemId: string) =>
      state.configs.find((config) => config.dataItemId === dataItemId)?.config,
  },
  actions: {
    loadWidgetConfig(
      { commit, rootGetters, rootState }: ActionContext<WidgetState, RootState>,
      args: { formId: string; dataItemId: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getWidget',
        (client) => client.getWidget(args.formId, args.dataItemId),
      ).then((response) => {
        if (hasData(response)) {
          const data = getData(response);
          const widgetConfig: WidgetConfig = {
            dataItemId: args.dataItemId,
            config: data,
          };
          commit('addWidgetConfig', widgetConfig);
          return data;
        }
        throw new Error("Can't load widget config");
      });
    },
  },
};

export default widget;
