





import Vue from 'vue';

type Data = {};
type Methods = { onClick: () => void };
type Computed = {};
type Props = { url: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CreateFormLink',
  props: { url: String },
  data() {
    return {};
  },
  methods: {
    onClick() {
      if (this.url) {
        this.$router.push({ path: this.url });
      }
    },
  },
});
