






























import Vue, { VueConstructor } from 'vue';
import fieldMixin from './mixin/fieldMixin';
import {
  Column,
  RowViewModel,
  Table,
  TableViewModel,
} from '@/components/table/model/table.model';
import { buildTableViewModel, columnData, columnValue } from '@/helpers/table';
import PopoverToggle from '@/components/PopoverToggle.vue';
import DataTable from '@/components/table/DataTable.vue';

type Data = {};
type Methods = {
  changed: (table: Table) => void;
};
type Computed = {
  dataItemValue?: string;
  tableViewModel: TableViewModel<unknown>;
  rawTable: Table;
};
type Props = {};

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof fieldMixin>>
).extend<Data, Methods, Computed, Props>({
  name: 'TableField',
  components: { PopoverToggle, DataTable },
  mixins: [fieldMixin],
  computed: {
    dataItemValue() {
      return this.dataItem.value;
    },
    tableViewModel() {
      const tableModel: Table = this.rawTable;
      return buildTableViewModel(
        tableModel.tableMetadata,
        tableModel.tableContents,
      );
    },
    rawTable() {
      return JSON.parse(this.dataItem.value || '');
    },
  },
  methods: {
    changed(table) {
      this.save(JSON.stringify(table));
    },
  },
  created() {
    const { changed } = this;
    const { rawTable }: { rawTable: Table } = this;
    type Data = {};
    type Methods = {
      onChange: () => void;
    };
    type Computed = {
      checked: boolean;
    };
    type Props = { rowViewModel: RowViewModel<unknown>; column: Column };
    Vue.component<Data, Methods, Computed, Props>('select-action', {
      props: { rowViewModel: Object, column: Object },
      computed: {
        checked() {
          return (
            (
              columnValue(this.rowViewModel.row.columns, this.column.key) || ''
            ).toLowerCase() === 'true'
          );
        },
      },
      methods: {
        onChange() {
          const table = { ...rawTable };
          const row = table.tableContents.find(
            (r) => r.id === this.rowViewModel.row.id,
          );
          if (row) {
            const colData = columnData(row.columns, this.column.key);
            if (colData) {
              colData.value = colData.value === 'true' ? 'false' : 'true';
              changed(table);
            }
          }
        },
      },
      render(createElement) {
        return createElement('input', {
          key: this.rowViewModel.row.id,
          attrs: { type: 'checkbox', checked: this.checked },
          on: { change: this.onChange },
        });
      },
    });
  },
});
