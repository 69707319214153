<template>
  <div class="field">
    <popover-label :field="field" :schema="schemaField" />
    <markdown-description :field="field" />
    <rpl-form :formData="formData" @keydown.native.enter.prevent />
    <data-item-delete :field="field" :dataItem="dataItem" />
    <save-status
      :touched="touched"
      :saved="saved"
      :saving="saving"
      :error="error"
    />
  </div>
</template>

<script>
import { RplForm } from '@dpc-sdp/ripple-form';
import VueFormGenerator from 'vue-form-generator';
import moment from 'moment';
import Vue from 'vue';
import fieldMixin from './mixin/fieldMixin';
import PopoverLabel from '@/components/pages/form/fields/PopoverLabel.vue';
import RplAppHubDatePicker from '@/components/ripple/RplAppHubDatePicker.vue';
import MarkdownDescription from '@/components/pages/form/fields/MarkdownDescription.vue';

Vue.component('field-apphub-datepicker', RplAppHubDatePicker);

export default {
  components: { MarkdownDescription, PopoverLabel, RplForm },
  name: 'DateField',
  mixins: [fieldMixin],
  computed: {
    dataItemValue() {
      if (this.dataItem.value) {
        if (this.readOnlyMarkdown) {
          return moment(this.dataItem.value, moment.ISO_8601).format(
            'DD/MM/YYYY',
          );
        }
        return moment(this.dataItem.value, moment.ISO_8601).format();
      }
      return undefined;
    },
  },
  methods: {
    changed(model, newVal, oldVal, field) {
      if (this.isValid(newVal, field)) {
        this.save(moment(newVal).format('YYYY-MM-DD'));
      }
    },
    schema() {
      return {
        type: this.readOnlyMarkdown ? 'markdown' : 'apphub-datepicker',
        validator: [VueFormGenerator.validators.date, ...this.validators()],
        pattern: this.pattern(),
      };
    },
  },
};
</script>
<style lang="scss">
.disabled.field-rpldatepicker {
  .rpl-date-picker {
    pointer-events: none;

    svg {
      display: none;
    }
  }
}
</style>
