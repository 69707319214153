















import { abstractField } from 'vue-form-generator';
import mixins from 'vue-typed-mixins';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import DatePicker from '@/components/ripple/RplAppHubDatePicker.vue';

type Data = { time: string };
type Computed = { tm: { hh: string; mm: string } };
type Methods = {
  timeChanged: (evt: { data: { H: string; m: string } }) => void;
  formatToTime: () => string;
  setTimeValue: (h: string, m: string) => void;
};
type Props = { model: { value: string } };

export default mixins(abstractField).extend<Data, Methods, Computed, Props>({
  name: 'FieldDateTimePicker',
  mixins: [abstractField],
  props: { model: Object },
  components: { VueTimepicker, DatePicker },
  data() {
    return { time: '' };
  },
  methods: {
    timeChanged(evt) {
      if (this.model.value && evt.data.H && evt.data.m) {
        this.setTimeValue(evt.data.H, evt.data.m);
      } else if (this.model.value) {
        this.time = '00:00';
        this.setTimeValue('0', '0');
      }
    },
    formatToTime() {
      if (this.model.value) {
        const date = new Date(this.model.value);
        const formattedTime = date.toLocaleTimeString([], {
          hour12: true,
          hour: '2-digit',
          minute: '2-digit',
        });
        return formattedTime.replace(/^00/, '12');
      }
      return '';
    },
    setTimeValue(h, m) {
      if (this.model.value) {
        const date = new Date(this.model.value);
        date.setHours(parseInt(h, 10), parseInt(m, 10));
        // this.value is a data property declared in abstractField mixin - typescript unable to infer
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.value = date.toISOString();
      }
    },
  },
  watch: {
    value() {
      this.time = this.formatToTime();
    },
  },
  mounted() {
    this.time = this.formatToTime();
  },
});
