









import Vue from 'vue';
import {
  getData,
  hasData,
  isLoading,
  isLoadingOrNeverLoaded,
} from '@/api/data';
import CapsulePanel from '@/components/pages/CapsulePanel.vue';
import { Editor } from '@/models/form.model';

type Data = {};
type Methods = {};
type Computed = {
  isLoading: boolean;
  team: Editor[];
  editorsPath: string;
  allowInvite: boolean;
};
type Props = { formId: string; formName: string; routeName: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EditorPanel',
  components: { CapsulePanel },
  props: { formId: String, formName: String, routeName: String },
  computed: {
    isLoading() {
      return isLoadingOrNeverLoaded(this.$store.state.form.editorsFromApi);
    },
    team() {
      return getData(this.$store.state.form.editorsFromApi) || [];
    },
    editorsPath() {
      return this.$router.resolve({
        name: this.routeName,
        params: { ...this.$route.params, formName: this.formName },
      }).route.path;
    },
    allowInvite() {
      return this.$store.getters.inviteFormEditors;
    },
  },
  methods: {},
  created() {
    if (
      !hasData(this.$store.state.form.editorsFromApi) &&
      !isLoading(this.$store.state.form.editorsFromApi)
    ) {
      this.$store.dispatch('loadFormEditors', this.formId);
    }
  },
});
