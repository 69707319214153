import { ActionContext, Module } from 'vuex';

import { RootState } from '../types';
import { FeatureState } from '@/store/modules/types';
import defaultGetConfig from '@/config';

const feature: Module<FeatureState, RootState> = {
  state: () => ({
    showErrors: false,
    optional2fa: false,
  }),
  mutations: {
    setFeatureFlags(state, features) {
      Object.assign(state, features);
    },
  },
  actions: {
    loadFeatureFlags({ commit }: ActionContext<FeatureState, RootState>) {
      defaultGetConfig().then((config) =>
        commit('setFeatureFlags', config.featureFlags),
      );
    },
  },
};

export default feature;
