








import Vue from 'vue';
import RplLink from '@dpc-sdp/ripple-link';
import { Column, RowViewModel } from '@/components/table/model/table.model';
import { columnValue } from '@/helpers/table';

type Data = {};

type Computed = { path: string; ariaLabel: string; text: string };

type Methods = {};

type Props = {
  rowViewModel: RowViewModel<unknown>;
  viewPath: (row: RowViewModel<unknown>) => string;
  column: Column;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'LinkCell',
  components: { RplLink },
  props: { rowViewModel: Object, viewPath: Function, column: Object },
  computed: {
    path() {
      return this.$router.resolve({
        path: this.viewPath(this.rowViewModel),
      }).href;
    },
    ariaLabel() {
      const rowType = columnValue(
        this.rowViewModel.row.columns,
        this.column.key,
      );
      return `Open ${rowType || ''} ${
        this.rowViewModel.row.id
      } in a new window`;
    },
    text() {
      return columnValue(this.rowViewModel.row.columns, this.column.key) || '';
    },
  },
});
