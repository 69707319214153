































import Vue from 'vue';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import { RplForm } from '@dpc-sdp/ripple-form';
import VueFormGenerator from 'vue-form-generator';
import { paths } from '@/router/paths';
import { BreadcrumbKey } from '@/models/site.model';
import {
  DataFromApi,
  getData,
  getErrorMessage,
  hasData,
  isApiError,
  isLoading,
} from '@/api/data';
import { GroupDetails } from '@/models/group.model';
import { UserProfile } from '@/models/user.model';
import { Team } from '@/models/team.model';
import FieldSelect from '@/components/pages/form/fields/FieldSelect.vue';
import Loading from '@/components/ripple/Loading.vue';

Vue.component('field-app-hub-select', FieldSelect);

enum CollaboratorType {
  IND = 'Individual',
  GRP = 'Group',
}
type Data = {
  working: boolean;
  loading: boolean;
  model: {
    type?: CollaboratorType;
    email: string;
    firstName?: string;
    lastName?: string;
    group?: string;
  };
  captureUserDetails: boolean;
  response: { response?: { message: string; status: string } };
};
type Computed = {
  linkText: string;
  linkPath: string;
  collaboratorTypeFormData: object;
  collaboratorFormData: object;
  individualFields: object[];
  groupFields: object[];
  team: Team;
  groups: GroupDetails[];
  pathBack: string;
  includeGroups: boolean;
};
type Methods = {
  onBack: () => void;
  showFormMessage: (message: string, status: string) => void;
  onContinue: () => void;
  addExistingUserToTeam: () => void;
  addNewUserToTeam: () => void;
  addGroupToTeam: () => void;
};
type Props = {
  entityId: string;
  name: string;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CollaboratorForm',
  components: { Loading, RplTextLink, RplForm },
  props: {
    entityId: String,
    name: String,
  },
  data() {
    return {
      working: false,
      loading: true,
      model: {
        type: undefined,
        email: '',
        firstName: '',
        lastName: '',
        group: '',
      },
      captureUserDetails: false,
      response: {},
    };
  },
  computed: {
    linkText() {
      return `Back to team`;
    },
    linkPath() {
      return this.pathBack;
    },
    team() {
      return getData(this.$store.state.entity.teamFromApi);
    },
    collaboratorTypeFormData() {
      return {
        model: this.model,
        tag: 'rpl-fieldset',
        schema: {
          fields: [
            {
              label: 'Select if you are sharing with an individual or group',
              hint: "Only groups you're a member of will be available to add",
              type: 'radios',
              model: 'type',
              values: [
                {
                  id: CollaboratorType.IND,
                  value: CollaboratorType.IND,
                  name: CollaboratorType.IND,
                },
                {
                  id: CollaboratorType.GRP,
                  value: CollaboratorType.GRP,
                  name: CollaboratorType.GRP,
                },
              ],
            },
          ],
          groups: [
            {
              inline: true,
              fields: [
                {
                  type: 'done-button',
                  buttonText: 'Cancel',
                  clickHandler: this.onBack,
                  styleClasses: 'form-group--inline',
                  visible: !this.model.type,
                },
              ],
            },
          ],
        },
        formState: {},
        formOptions: {
          validateAfterChanged: false,
          validateOnSubmit: true,
          validateAfterLoad: false,
        },
      };
    },
    collaboratorFormData() {
      return {
        model: this.model,
        tag: 'rpl-fieldset',
        schema: {
          fields:
            this.model.type === CollaboratorType.IND
              ? this.individualFields
              : this.groupFields,
          groups: [
            {
              inline: true,
              fields: [
                {
                  type: 'done-button',
                  buttonText: 'Cancel',
                  clickHandler: this.onBack,
                  styleClasses: 'form-group--inline',
                },
                {
                  type: 'rplsubmitloader',
                  buttonText: 'Add',
                  loading: this.working,
                  autoUpdate: true,
                  styleClasses: 'form-group--inline',
                },
              ],
            },
          ],
        },
        formState: this.response,
        formOptions: {
          validateAfterChanged: false,
          validateOnSubmit: true,
          validateAfterLoad: false,
        },
      };
    },
    groups() {
      const groups: GroupDetails[] = getData(
        this.$store.state.group.groupsFromApi,
      );
      return groups?.sort((g1, g2) => g1.name.localeCompare(g2.name)) || [];
    },
    individualFields() {
      return [
        {
          type: 'input',
          inputType: 'email',
          label: 'Email address of new member',
          model: 'email',
          required: true,
          max: 2048,
          validator: VueFormGenerator.validators.email.locale({
            fieldIsRequired: 'Email is required',
            invalidEmail: 'Invalid email address',
          }),
          disabled: this.captureUserDetails,
        },
        {
          type: 'input',
          inputType: 'text',
          label: 'First name',
          model: 'firstName',
          required: true,
          max: 2048,
          visible: this.captureUserDetails,
          validator: VueFormGenerator.validators.string.locale({
            fieldIsRequired: 'First name is required',
          }),
        },
        {
          type: 'input',
          inputType: 'text',
          label: 'Last name',
          model: 'lastName',
          required: true,
          max: 2048,
          visible: this.captureUserDetails,
          validator: VueFormGenerator.validators.string.locale({
            fieldIsRequired: 'Last name is required',
          }),
        },
      ];
    },
    groupFields() {
      return [
        {
          type: 'app-hub-select',
          label: 'Select a group',
          model: 'group',
          required: true,
          values: this.groups.map((group) => ({
            id: group.id,
            value: group.id,
            name: group.name,
          })),
          validator: (val: string) => (val ? [] : ['Required']),
        },
      ];
    },
    pathBack() {
      return this.$router.resolve({
        name: 'collaborators',
        params: {
          entityId: this.entityId,
          name: this.name,
        },
      }).route.path;
    },
    includeGroups() {
      return !this.$store.getters.getEntitySettings.hideCollaboratorGroup;
    },
  },
  methods: {
    onBack() {
      this.$router.push(this.pathBack);
    },
    showFormMessage(message, status) {
      this.response = { response: { message, status } };
    },
    onContinue() {
      if (this.model.type === CollaboratorType.IND) {
        if (
          this.team
            .filter((member) => !member.isGroup)
            .some(
              (member) =>
                member.email.toLowerCase().trim() ===
                this.model.email.toLowerCase().trim(),
            )
        ) {
          this.showFormMessage(
            `User ${this.model.email} is already a team member.`,
            'error',
          );
          return;
        }
        this.working = true;
        if (this.captureUserDetails) {
          this.addNewUserToTeam();
        } else {
          this.addExistingUserToTeam();
        }
      } else if (this.model.type === CollaboratorType.GRP) {
        const group = this.groups.find((grp) => grp.id === this.model.group);
        if (
          group &&
          this.team
            .filter((member) => member.isGroup)
            .some(
              (member) =>
                member.name.toLowerCase().trim() ===
                group.name.toLowerCase().trim(),
            )
        ) {
          this.showFormMessage(
            `Group ${group.name} is already a team member.`,
            'error',
          );
          return;
        }
        this.addGroupToTeam();
      }
    },
    addNewUserToTeam() {
      this.$store
        .dispatch('addNewUserToTeam', {
          ...this.model,
          entityId: this.entityId,
        })
        .then((addUserResponse: DataFromApi<Team>) => {
          if (!isApiError(addUserResponse)) {
            this.$router.push({
              path: this.pathBack,
              query: {
                message: `${this.model.email} has been added to the team`,
              },
            });
          } else {
            this.showFormMessage(getErrorMessage(addUserResponse), 'error');
          }
          this.working = false;
        })
        .finally(() => {
          this.working = false;
        });
    },
    addExistingUserToTeam() {
      this.showFormMessage('Searching for user', 'info');
      this.$store
        .dispatch('findUser', this.model.email)
        .then((response: DataFromApi<UserProfile>) => {
          if (!isApiError(response)) {
            if (hasData(response) && getData(response).firstName) {
              this.showFormMessage('User found, adding to team', 'info');
              this.$store
                .dispatch('addExistingUserToTeam', {
                  email: this.model.email,
                  entityId: this.entityId,
                })
                .then((addUserResponse: DataFromApi<Team>) => {
                  if (!isApiError(addUserResponse)) {
                    this.$router.push({
                      path: this.pathBack,
                      query: {
                        message: `${this.model.email} has been added to the team`,
                      },
                    });
                  } else {
                    this.showFormMessage(
                      getErrorMessage(addUserResponse),
                      'error',
                    );
                  }
                })
                .finally(() => {
                  this.working = false;
                });
            } else {
              this.showFormMessage(
                'User not found, we need a few more details',
                'info',
              );
              this.captureUserDetails = true;
              this.working = false;
            }
          } else {
            this.showFormMessage(getErrorMessage(response), 'error');
            this.working = false;
          }
        });
    },
    addGroupToTeam() {
      this.$store
        .dispatch('addGroupToTeam', {
          groupId: this.model.group,
          entityId: this.entityId,
        })
        .then((response: DataFromApi<Team>) => {
          if (!isApiError(response)) {
            const group = this.groups.find(
              (grp) => grp.id === this.model.group,
            );
            this.$router.push({
              path: this.pathBack,
              query: {
                message: `${group?.name} has been added to the team`,
              },
            });
          } else {
            this.showFormMessage(getErrorMessage(response), 'error');
          }
        });
    },
  },
  watch: {
    includeGroups() {
      if (!this.includeGroups) {
        this.model.type = CollaboratorType.IND;
      }
    },
  },
  mounted() {
    this.$store.commit('setHeaderText', 'Share with others');
    this.$store.commit('setSubTitle', this.name);
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Dashboard,
      breadcrumbs: {
        text: 'My dashboard',
        url: paths(this.$store.getters.tenantId).dashboard(),
      },
    });
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Entity,
      breadcrumbs: {
        text: this.name,
        url: paths(this.$store.getters.tenantId).entity(this.entityId),
      },
    });
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Team,
      breadcrumbs: {
        text: 'Team',
        url: this.linkPath,
      },
    });
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Leaf,
      breadcrumbs: {
        text: 'Share with others',
      },
    });
  },
  destroyed() {
    this.$store.commit('setSubTitle', undefined);
    this.$store.commit('resetEntityState');
  },
  created() {
    const promises = [
      this.$store.dispatch('getEntityDetails', this.entityId).then(() => {
        if (this.includeGroups) {
          return this.$store.dispatch('loadGroups');
        }
        return undefined;
      }),
    ];
    const { teamFromApi } = this.$store.state.entity;
    if (!hasData(teamFromApi) && !isLoading(teamFromApi)) {
      promises.push(
        this.$store.dispatch('loadEntityCollaborators', this.entityId),
      );
    }
    Promise.all(promises).finally(() => {
      this.loading = false;
    });
  },
});
