






import Vue from 'vue';
import { Column, RowViewModel } from '@/components/table/model/table.model';
import { columnValue } from '@/helpers/table';

type Data = {};
type Computed = { value: string };
type Methods = {};
type Props = { rowViewModel: RowViewModel<unknown>; column: Column };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'WrappingCell',
  props: { rowViewModel: Object, column: Object },
  computed: {
    value() {
      return columnValue(this.rowViewModel.row.columns, this.column.key) || '';
    },
  },
});
