import { TableGroupModel } from '@/components/table/model/table.model';

export enum EntityAttributeType {
  Number = 'Number',
  Text = 'Text',
  DateTime = 'DateTime',
  Date = 'Date',
  HTML = 'HTML',
}

export enum EntityActionGroupType {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export interface EntityIdentifier {
  entityId: string;
  message?: string;
}

export interface EntitySettings {
  showMessages: boolean;
  showCollaborators: boolean;
  hideCollaboratorGroup: boolean;
}

export interface EntityDefinition {
  entityId: string;
  attributes: EntityAttribute[];
  entityDocument: EntityDocuments;
  actions: EntityAction[];
  timeline: EntityTimelineEntry[];
  messages: EntityMessage[];
  tables: TableGroupModel[];
  settings: EntitySettings;
}

export interface EntityAttribute {
  label: string;
  dataType: keyof typeof EntityAttributeType;
  value: string;
}

export interface EntityDocuments {
  message?: string;
  documents: EntityDocument[];
}

export interface EntityDocument {
  documentId: string;
  fileName: string;
  label: string;
  uploadedOn?: string;
  uploadedBy?: string;
  category?: string;
  status?: string;
  classification?: string;
  size: number;
}

export interface EntityAction {
  label: string;
  description: string;
  actionCode: string;
  group?: keyof typeof EntityActionGroupType;
  inline?: boolean;
}

export interface EntityTimelineEntry {
  title: string;
  subtitle?: string;
  current?: boolean;
}

export enum MessageStatus {
  Sending = 'Sending',
  Sent = 'Sent',
  Error = 'Error',
}

export interface EntityMessage {
  from: string;
  sentAt: string;
  text: string;
  requestingUser: boolean;
}
export type Message = EntityMessage & {
  sentAt?: string;
  status: MessageStatus;
};

export interface Conversation {
  messages: Message[];
}

export interface DocumentSelection {
  id: string;
  name: string;
}

export enum DownloadRequestStatus {
  Pending = 'pending',
  Failed = 'failed',
  Complete = 'complete',
}

export interface DownloadRequest {
  downloadRequestId: string;
  entityId: string;
  status: DownloadRequestStatus;
  message?: string;
  fileName: string;
  fileCount: number;
  url?: string;
}

export interface DownloadRequestApiResponse {
  downloadRequestId: string;
}

export interface DownloadRequestPollResponse {
  status: DownloadRequestStatus;
  feedback?: string;
  url?: string;
}

export interface EmailAttachment {
  id: string;
  fileName: string;
  fileSize: number;
  createdAt: string;
  uploadedBy: string;
  category: string;
  status: string;
}

export interface Email {
  resourceId: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
  createdAt: string;
  status: string;
  attachments: EmailAttachment[];
}
