<template>
  <div>
    <markdown v-if="blurb" class="blurb" :source="blurb" />
    <FormDefinitions />
    <markdown
      v-if="blurbBottom"
      class="blurb blurbBottom"
      :source="blurbBottom"
    />
  </div>
</template>

<script>
import Markdown from '@/components/markdown/Markdown.vue';
import FormDefinitions from './formDefinitions/FormDefinitions.vue';

export default {
  name: 'RootFormDefinitions',
  components: {
    FormDefinitions,
    Markdown,
  },
  computed: {
    siteName() {
      return this.$store.getters.siteName;
    },
    blurb() {
      return this.$store.getters.tenantBlurb || '';
    },
    blurbBottom() {
      return this.$store.getters.tenantBlurbBottom || '';
    },
  },
  created() {
    this.$store.dispatch('loadRootFormDefinitions');
  },
  mounted() {
    this.$store.commit('setHeaderText', this.siteName);
  },
  watch: {
    siteName(name) {
      this.$store.commit('setHeaderText', name);
    },
  },
};
</script>
<style lang="scss" scoped>
.blurb {
  margin: -3.5em 0 2em 0;
  max-width: 800px;
}

.blurbBottom {
  margin: 0;
}
</style>
