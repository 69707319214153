












































import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import RplIcon from '@dpc-sdp/ripple-icon';
import Vue from 'vue';
import { DownloadRequest, DownloadRequestStatus } from '@/models/entity.model';

type Data = { closed: boolean };
type Props = {};
interface Computed {
  downloads: DownloadRequest[];
}
interface Methods {
  onClose: () => void;
  cancelDownload: (id: string) => void;
  isPending: (request: DownloadRequest) => boolean;
  isFailure: (request: DownloadRequest) => boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Downloads',
  components: { RplAlertBase, RplIcon },
  data() {
    return { closed: false };
  },
  computed: {
    downloads() {
      return this.$store.getters.getActiveDownloadRequests;
    },
  },
  methods: {
    onClose() {
      this.closed = true;
      this.downloads.forEach((download: DownloadRequest) => {
        if (download.status === DownloadRequestStatus.Failed) {
          this.cancelDownload(download.downloadRequestId);
        }
      });
    },
    cancelDownload(id) {
      this.$store.dispatch('cancelBulkDownload', id);
    },
    isPending(request) {
      return request.status === DownloadRequestStatus.Pending;
    },
    isFailure(request) {
      return request.status === DownloadRequestStatus.Failed;
    },
  },
  watch: {
    downloads(
      newDownloads: DownloadRequest[],
      oldDownloads: DownloadRequest[],
    ) {
      if (newDownloads.length >= oldDownloads.length) {
        this.closed = false;
      }
    },
  },
});
