














import Vue from 'vue';
import { CombinedVueInstance } from 'vue/types/vue';
import RplSelect from '@/components/ripple/RplAppHubSelect.vue';
import SelectSearch from '@/components/ripple/Search.vue';

type Data = {
  filterTerm?: string;
  searchData: { resultCount?: number };
  selectSearch?: CombinedVueInstance<Vue, {}, {}, {}, unknown>;
};
type Computed = {
  filteredValues: { name: string }[];
  searchable: boolean;
  filtering: boolean;
};
type Methods = {
  mountSelectSearch: () => void;
  unmountSelectSearch: () => void;
};
type Props = {
  config: unknown;
  values: { name: string }[];
  value: string | string[];
  disabled: boolean;
};
const SEARCHABLE_THRESHOLD = 10;

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'AppHubSelect',
  components: {
    RplSelect,
  },
  props: {
    config: Object,
    values: Array,
    value: [String, Array],
    disabled: Boolean,
  },
  data() {
    return {
      filterTerm: undefined,
      searchData: { resultCount: undefined },
      selectSearch: undefined,
    };
  },
  computed: {
    filteredValues() {
      if (!this.searchable || !this.filterTerm) {
        return this.values;
      }
      const filterValue = new RegExp(this.filterTerm.trim(), 'i');
      return this.values.filter((value) => filterValue.test(value.name));
    },
    searchable() {
      return this.values.length > SEARCHABLE_THRESHOLD;
    },
    filtering() {
      return this.searchable && !!this.filterTerm;
    },
  },
  methods: {
    mountSelectSearch() {
      if (!this.selectSearch) {
        const container = document.createElement('div');
        const selectComponent = this.$refs.select as Vue;
        if (selectComponent) {
          const elem = selectComponent.$el.querySelector(
            '.rpl-select__listbox',
          );
          if (elem) {
            const { searchData } = this;
            const selectSearch = new SelectSearch({
              propsData: {
                ariaLabel: 'Search for items in dropdown',
              },
              data() {
                return searchData;
              },
            });
            elem.prepend(container);
            selectSearch.$on('search', (filterTerm: string) => {
              this.filterTerm = filterTerm;
            });
            selectSearch.$mount(container);
            this.selectSearch = selectSearch;
          }
        }
      }
    },
    unmountSelectSearch() {
      if (this.selectSearch) {
        this.filterTerm = undefined;
        this.selectSearch.$el.remove();
        this.selectSearch.$destroy();
        this.selectSearch = undefined;
      }
    },
  },
  mounted() {
    if (this.searchable) {
      this.mountSelectSearch();
    }
  },
  watch: {
    filteredValues() {
      this.searchData.resultCount = this.filterTerm
        ? this.filteredValues.length
        : undefined;
    },
    searchable() {
      if (this.searchable) {
        this.mountSelectSearch();
      } else {
        this.unmountSelectSearch();
      }
    },
  },
});
