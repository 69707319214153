var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[{ clickable: _vm.handleRowClicks }, 'app-hub-table'],on:{"click":_vm.onClick}},[(_vm.tableViewModel.config.label)?_c('div',{staticClass:"table-heading"},[_c('div',{staticClass:"table-label",class:{ 'with-description': _vm.tableViewModel.config.description }},[_c('h4',[_vm._v(" "+_vm._s(_vm.tableViewModel.config.label)+" ")]),(_vm.tableViewModel.config.hint)?_c('popover-toggle',{attrs:{"markdown":_vm.tableViewModel.config.hint}}):_vm._e()],1)]):_vm._e(),(_vm.tableViewModel.config.description)?_c('markdown',{staticClass:"description",attrs:{"source":_vm.tableViewModel.config.description}}):_vm._e(),(_vm.filterable || _vm.searchable)?_c('div',{staticClass:"filter-control",class:{ filtering: _vm.filterTerm }},[_c('rpl-search-form',{ref:"searchForm",staticClass:"filter",attrs:{"searchPlaceholder":"Search by keyword","allowBlank":true,"prefillSearchTerm":_vm.filterTerm,"filterForm":_vm.filterForm,"filterText":"Filter ","textSearch":_vm.searchable,"expandFilters":_vm.expandFilters,"validateOnSubmit":false,"submitFormOnClear":false,"scrollToMessage":false},on:{"search":_vm.onFilter},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.onKeydownFilter.apply(null, arguments)}}})],1):_vm._e(),_c('RplSearchResultsLayout',{staticClass:"table",attrs:{"searchResults":_vm.pagedItems,"count":_vm.count,"pager":_vm.pagination,"perPage":_vm.perPage},on:{"pager-change":_vm.onPagerChange},scopedSlots:_vm._u([{key:"sort",fn:function(){return [_c('div',{staticClass:"controls"},[(_vm.showPageSize && _vm.pagedItems.length)?_c('div',{staticClass:"control rpl-form"},[_c('label',[_vm._v("Page size:")]),_c('RplSelect',{staticClass:"select truncating page-size",attrs:{"values":[
              { id: '5', name: '5' },
              { id: '10', name: '10' },
              { id: '20', name: '20' },
              { id: '50', name: '50' },
              { id: '100', name: '100' } ],"selectedCharLength":200,"state":_vm.perPage.toString()},on:{"rpl-select-update":_vm.onChangePageSize}})],1):_vm._e(),(_vm.$listeners.reload)?_c('div',{staticClass:"control fill",attrs:{"title":"Click to reload"},on:{"click":function($event){return _vm.$emit('reload')}}},[_c('reload-icon',{staticClass:"reload-icon",class:{ spin: _vm.loading }})],1):_vm._e(),(_vm.pagedItems.length && _vm.sortOptions.length)?_c('div',{staticClass:"control rpl-form"},[_c('label',[_vm._v("Sort by:")]),_c('RplSelect',{staticClass:"select truncating",attrs:{"values":_vm.sortOptions,"config":{
              label: 'Sort by:',
              fieldId: _vm.tableViewModel.config.label,
            },"selectedCharLength":200,"state":_vm.sortOptionId},on:{"rpl-select-update":_vm.onChangeSortOption}})],1):_vm._e(),_vm._t("default"),(_vm.tableViewModel.config.allowColumnConfig)?_c('column-config',{attrs:{"column-options":_vm.columnOptions,"selected-columns":_vm.visibleColumns},on:{"configure-column":_vm.onConfigureColumn}}):_vm._e()],2)]},proxy:true},{key:"noresults",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.emptyMessage)}})]},proxy:true},{key:"results",fn:function(scoped){return [_c('RplCol',{staticClass:"table-container",attrs:{"cols":"full","tabindex":_vm.handleRowClicks ? '0' : undefined},nativeOn:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.onDownArrowTable.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.onUpArrowTable.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.onSelectRow.apply(null, arguments)}],"blur":function($event){return _vm.onTableBlur.apply(null, arguments)}}},[_vm._t("header"),_c('RplSearchResultsTable',{ref:"table",attrs:{"columnConfig":_vm.columnConfig,"items":scoped.searchResults}}),(_vm.tableViewModel.config.export)?_c('div',{staticClass:"export"},[(true)?_c('rpl-text-link',{attrs:{"text":"Export to .csv","url":"","iconSymbol":"download","iconColor":"primary","iconPlacement":"before"},nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.exportToCSV.apply(null, arguments)}}}):_vm._e()],1):_vm._e(),_vm._t("footer")],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }