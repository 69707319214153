











import Vue from 'vue';
import { DataItem, DataItemStatus, DataType, Field } from '@/models/form.model';
import TextField from './fields/TextField.vue';
import BooleanField from './fields/BooleanField.vue';
import DateField from './fields/DateField.vue';
import EnumField from './fields/EnumField.vue';
import MultiValueEnumField from './fields/MultiValueEnumField.vue';
import IntegerField from './fields/IntegerField.vue';
import RealField from './fields/RealField.vue';
import AttachmentField from './fields/AttachmentField.vue';
import CurrencyField from './fields/CurrencyField.vue';
import ActionField from './fields/ActionField.vue';
import WidgetField from './fields/WidgetField.vue';
import TableField from '@/components/pages/form/fields/TableField.vue';
import DateTimeField from '@/components/pages/form/fields/DateTimeField.vue';

type Data = {};
type Computed = {
  highlight: boolean;
};
interface Methods {
  componentForDataType: () => string;
}
type Props = {
  field: Field;
  dataItem: DataItem | undefined;
  readOnlyForm: boolean;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    'text-field': TextField,
    'boolean-field': BooleanField,
    'date-field': DateField,
    'date-time-field': DateTimeField,
    'enum-field': EnumField,
    'multi-value-enum-field': MultiValueEnumField,
    'integer-field': IntegerField,
    'real-field': RealField,
    'attachment-field': AttachmentField,
    'currency-field': CurrencyField,
    'action-field': ActionField,
    'widget-field': WidgetField,
    'table-field': TableField,
  },
  name: 'DataTypeField',
  props: { field: Object, dataItem: Object, readOnlyForm: Boolean },
  computed: {
    highlight() {
      return (
        this.$store.state.form.highlight &&
        this.dataItem?.status === DataItemStatus.Incomplete
      );
    },
  },
  methods: {
    componentForDataType() {
      switch (this.field.dataType) {
        case DataType.Boolean:
          return 'boolean-field';
        case DataType.Date:
          return 'date-field';
        case DataType.DateTime:
          return 'date-time-field';
        case DataType.Enum:
        case DataType.CalculatedEnum:
          return 'enum-field';
        case DataType.MultiValueEnum:
        case DataType.CalculatedMultiValueEnum:
          return 'multi-value-enum-field';
        case DataType.Integer:
          return 'integer-field';
        case DataType.Real:
          return 'real-field';
        case DataType.Text:
          return 'text-field';
        case DataType.Attachment:
          return 'attachment-field';
        case DataType.Currency:
          return 'currency-field';
        case DataType.Action:
          return 'action-field';
        case DataType.Widget:
          return 'widget-field';
        case DataType.Table:
          return 'table-field';
        default:
          return 'text-field';
      }
    },
  },
});
