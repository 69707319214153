












/* eslint-disable no-param-reassign,no-unused-expressions */
import Vue from 'vue';

type Data = {};
type Computed = {};
type Methods = {
  enter: (element: HTMLElement) => void;
  afterEnter: (element: HTMLElement) => void;
  leave: (element: HTMLElement) => void;
  afterLeave: () => void;
};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TransitionExpand',
  methods: {
    enter(element) {
      const { width } = getComputedStyle(element);
      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const { height } = getComputedStyle(element);
      element.style.width = '';
      element.style.position = '';
      element.style.visibility = '';
      element.style.height = '0';

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
      this.$emit('expanded');
    },
    leave(element) {
      const { height } = getComputedStyle(element);

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = '0';
      });
    },
    afterLeave() {
      this.$emit('collapsed');
    },
  },
});
