

















import Vue from 'vue';
import IconButton from '@/components/IconButton.vue';

type Props = {};

type Data = {};

type Computed = {};

type Methods = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ClearSearch',
  components: { IconButton },
});
