import DptLogo from '@/assets/vic-dtp-logo.png';
import BannerBackground from '@/assets/planning-vic-banner-corner.svg';
import { Theme } from '@/models/site.model';

export const defaultLogo = {
  alt: 'Home, Victoria State Government Department of Transport and Planning, logo',
  url: 'http://planning.vic.gov.au',
  image: DptLogo,
};

export const defaultBannerBackground = BannerBackground;

export const applySiteTheme = (theme: Theme | undefined) => {
  if (theme) {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    if (theme.favicon && favicon) {
      favicon.href = theme.favicon;
    }
    if (theme.styles) {
      const root = document.documentElement;
      theme.styles.forEach((style) =>
        root.style.setProperty(`--${style.name}`, style.value),
      );
    }
  }
};
