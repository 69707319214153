<template>
  <div>
    <h4>
      We are still creating your account, please wait for 2 minutes then
      <a href="" title="Reload">reload.</a>
    </h4>
    <h4>
      If the problem persists, please contact
      <a href="mailto:planning.support@transport.vic.gov.au"
        >planning.support@transport.vic.gov.au</a
      >.
    </h4>
  </div>
</template>

<script>
export default {
  name: 'NotLinkedPage',
};
</script>
