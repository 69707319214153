




import Vue from 'vue';
import { BreadcrumbKey } from '@/models/site.model';
import { paths } from '@/router/paths';

type Data = {};
type Methods = {};
type Computed = {
  formPath: string;
  entityPath: string;
};
type Props = {
  formId: string;
  stageTypeId: string;
  formName: string;
  name: string;
  entityId: string;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EntityFormEditors',
  props: {
    formId: String,
    stageTypeId: String,
    formName: String,
    name: String,
    entityId: String,
  },
  computed: {
    formPath() {
      return this.$router.resolve({
        name: 'entityFormStage',
        params: { ...this.$route.params },
      }).route.path;
    },
    entityPath() {
      return this.$router.resolve({
        name: 'entity',
        params: { id: this.entityId },
      }).route.path;
    },
  },
  mounted() {
    this.$store.commit('setHeaderText', 'Team');
    this.$store.commit('setSubTitle', this.formName);
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Dashboard,
      breadcrumbs: {
        text: 'My dashboard',
        url: paths(this.$store.getters.tenantId).dashboard(),
      },
    });
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Entity,
      breadcrumbs: {
        text: this.name,
        url: this.entityPath,
      },
    });
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Form,
      breadcrumbs: {
        text: this.formName,
        url: this.formPath,
      },
    });
  },
  destroyed() {
    this.$store.commit('setSubTitle', undefined);
  },
});
