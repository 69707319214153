













import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import { Column, RowViewModel } from '@/components/table/model/table.model';
import { columnValue } from '@/helpers/table';

type Data = {};

type Computed = { path: string; ariaLabel: string };

type Methods = {};

type Props = {
  rowViewModel: RowViewModel<unknown>;
  viewPath: (row: RowViewModel<unknown>) => string;
  column: Column;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'NewWindowAction',
  components: { RplIcon },
  props: { rowViewModel: Object, viewPath: Function, column: Object },
  computed: {
    path() {
      return this.$router.resolve({
        path: this.viewPath(this.rowViewModel),
      }).href;
    },
    ariaLabel() {
      const rowType = columnValue(
        this.rowViewModel.row.columns,
        this.column.key,
      );
      return `Open ${rowType || ''} ${
        this.rowViewModel.row.id
      } in a new window`;
    },
  },
});
