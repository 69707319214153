import {
  Form,
  FormType,
  FormDefinitions,
  CreatedForm,
  Attachment,
  FormStatusInformation,
  DataItemAction,
  FormJob,
  Editor,
  EditorDto,
} from '@/models/form.model';
import {
  DocumentSelection,
  DownloadRequestApiResponse,
  DownloadRequestPollResponse,
  EntityDefinition,
  EntityIdentifier,
  EntityMessage,
} from '@/models/entity.model';
import defaultGetConfig from '@/config';
import { ApiConfig } from '@/config/types';
import logger from '@/logger';
import connector from './connector';
import { ApiCallName, ApplicationHubClient, Cancellable } from './types';
import AUTH_SERVICE_FACTORY from './auth';
import { unmarshalEditors, unmarshalFormType } from './unmarshallers';
import { FormTypeDto } from './types.dto';
import { marshallAddDataItem } from './marshallers';
import { ApiPromise } from './data';
import { NoticeDetails, Tenant } from '@/models/site.model';
import { UserProfile } from '@/models/user.model';
import { Group, GroupDetails } from '@/models/group.model';
import { TableGroupModel } from '@/components/table/model/table.model';
import { Team } from '@/models/team.model';

export const API_VERSION = 'v1';

const clientInitialiser = (client: ReturnType<typeof connector>) => ({
  createFormFromTemplate: async (
    formTypeCode: string,
    initialValues?: {},
  ): ApiPromise<CreatedForm> =>
    client.post<CreatedForm, unknown>(
      `/forms`,
      {
        formTemplateCode: formTypeCode,
        initialValues,
      },
      'create-from-form-template',
    ),
  createFormFromAction: async (
    actionCode: string,
    entityId: string,
  ): ApiPromise<CreatedForm> =>
    client.post<CreatedForm, unknown>(
      '/forms',
      { actionCode, entityId },
      'create-from-action',
    ),
  deleteForm: async (formId: string): ApiPromise<unknown> =>
    client.delete(`/forms/${formId}`),
  submitForm: async (formId: string): ApiPromise<Form | EntityIdentifier> =>
    client.post(`/forms/${formId}`),
  resetForm: async (formId: string): ApiPromise<Form> =>
    client.patch(`/forms/${formId}`, {}, 'reset-form'),
  getForm: async (formId: string): ApiPromise<Form | EntityIdentifier> =>
    client.get(`/forms/${formId}`),
  getFormStatus: async (formId: string): ApiPromise<FormStatusInformation> =>
    client.get(`/forms/${formId}/status`),
  getFormJob: async (formId: string, jobId: string): ApiPromise<FormJob> =>
    client.get(`/forms/${formId}/jobs/${jobId}`),
  getFormCategory: async (categoryId: string): ApiPromise<FormDefinitions> =>
    client.get(`/form-categories/${categoryId}`),
  getDraftFormTable: async (): ApiPromise<TableGroupModel> =>
    client.get('/forms', 'form-list'),
  getEntityFormTables: async (): ApiPromise<TableGroupModel[]> =>
    client.get('/entities', 'entity-list'),
  getFormType: async (formTypeId: string): ApiPromise<FormType> =>
    client
      .get<FormTypeDto>(`/form-types/${formTypeId}`)
      .then(unmarshalFormType),
  getEntity: async (entityId: string): ApiPromise<EntityDefinition> =>
    client.get(`/entities/${entityId}`),
  getRootFormDefinitions: async (): ApiPromise<FormDefinitions> =>
    client.get('/root-form-categories'),
  saveDataItemValue: async (
    formId: string,
    dataItemId: string,
    value: string | undefined,
  ): ApiPromise<Form | EntityIdentifier> => {
    logger.debug(`formId:${formId}, dataItemId:${dataItemId}, value:${value}`);
    return client.patch(
      `/forms/${formId}`,
      {
        dataItemId,
        value: value !== undefined ? value : '',
      },
      'update-data-item',
    );
  },
  uploadAttachment: async (
    formId: string,
    dataItemId: string,
    file: File,
    progressHandler: (progress: number) => void,
  ): Promise<Cancellable<string>> => {
    logger.debug(
      `UploadAttachment for formId:${dataItemId}, dataItemId:${dataItemId}, value:${file.name}`,
    );
    return client.putFile(
      `/forms/${formId}/item/${dataItemId}/attachment`,
      progressHandler,
      file,
    );
  },
  getAttachment: async (attachment: Attachment): ApiPromise<Attachment> => {
    const { dataItemId, formId, id } = attachment;
    logger.debug(
      `GetStatus for formId:${formId}, dataItemId:${dataItemId}, attachmentId: ${id}`,
    );
    return client.get(`/forms/${formId}/item/${dataItemId}/attachment/${id}`);
  },
  getAttachments: async (
    attachmentIds: string[],
    formId: string,
  ): ApiPromise<Attachment[]> => {
    logger.debug(`GetStatus for attachmentIds: ${attachmentIds}`);
    return client.put(`/forms/${formId}/attachment`, attachmentIds);
  },
  deleteAttachment: async (attachment: Attachment): ApiPromise<unknown> => {
    const { dataItemId, formId, id } = attachment;
    logger.debug(
      `Delete formId:${formId}, dataItemId:${dataItemId}, attachmentId: ${id}`,
    );
    return client.delete(
      `/forms/${formId}/item/${dataItemId}/attachment/${id}`,
    );
  },
  completeAttachment: async (
    attachment: Attachment,
  ): ApiPromise<Attachment> => {
    const { dataItemId, formId, id } = attachment;
    logger.debug(
      `Complete formId:${formId}, dataItemId:${dataItemId}, attachmentId: ${id}`,
    );
    return client.put(
      `/forms/${formId}/item/${dataItemId}/attachment/${id}/complete`,
      {},
    );
  },
  renameAttachment: async (
    attachment: Attachment,
    newName: string,
  ): ApiPromise<void> => {
    const { dataItemId, formId, id } = attachment;
    logger.debug(
      `Rename formId:${formId}, dataItemId:${dataItemId}, attachmentId: ${id}, newName: ${newName}`,
    );
    return client.patch(
      `/forms/${formId}/item/${dataItemId}/attachment/${id}/name`,
      { name: newName },
    );
  },
  addDataItem: async (
    formId: string,
    fieldId: string,
    stageId: string,
    dataItemId: string | undefined,
  ): ApiPromise<Form | EntityIdentifier> => {
    logger.debug(
      `formId:${formId}, fieldId: ${fieldId}, stageId: ${stageId}, parentDataItemId:${dataItemId}`,
    );
    return client.patch(
      `/forms/${formId}`,
      marshallAddDataItem({ fieldId, stageId, dataItemId }),
      'add-data-item',
    );
  },
  deleteDataItem: async (
    formId: string,
    dataItemId: string,
  ): ApiPromise<Form | EntityIdentifier> => {
    logger.debug(`formId:${formId}, dataItemId:${dataItemId}`);
    return client.patch(
      `/forms/${formId}`,
      {
        dataItemId,
      },
      'delete-data-item',
    );
  },
  getDataItemAction: async (
    formId: string,
    dataItemId: string,
    actionCode: string,
  ): ApiPromise<DataItemAction> => {
    logger.debug(`formId:${formId}, dataItemId:${dataItemId}`);
    return client.get(
      `/forms/${formId}/item/${dataItemId}/action/${actionCode}`,
    );
  },
  performDataItemAction: async (
    formId: string,
    dataItemId: string,
    actionCode: string,
    payload: unknown,
  ): ApiPromise<DataItemAction> => {
    logger.debug(`formId:${formId}, dataItemId:${dataItemId}`);
    return client.post(
      `/forms/${formId}/item/${dataItemId}/action/${actionCode}`,
      payload,
    );
  },
  getTenant: async (tenantId: string): ApiPromise<Tenant> => {
    logger.debug(`tenantId:${tenantId}`);
    return client.get(``);
  },
  getAuthCookie: async (tenantId: string): ApiPromise<void> => {
    logger.debug(`tenantId:${tenantId}`);
    return client.get(`/cookie`);
  },
  getWidget: async (formId: string, dataItemId: string): ApiPromise<string> => {
    logger.debug(`formId:${formId}, dataItemId:${dataItemId}`);
    return client.get(`/forms/${formId}/item/${dataItemId}/widget`);
  },
  sendMessage: async (entityId: string, message: string): ApiPromise<void> => {
    logger.debug(`entityId:${entityId}, message:${message}`);
    return client.post<void, unknown>(`/entities/${entityId}/message`, {
      text: message,
    });
  },
  loadMessages: async (entityId: string): ApiPromise<EntityMessage[]> => {
    logger.debug(`entityId:${entityId}`);
    return client.get(`/entities/${entityId}/messages`);
  },
  getNotice: async (): ApiPromise<NoticeDetails | undefined> => {
    logger.debug(`get notice`);
    return client.get(`/notice`);
  },
  updateLastReadNoticeAt: async (
    lastReadNoticeAt: string,
  ): ApiPromise<void> => {
    logger.debug(`lastReadNoticeAt: ${lastReadNoticeAt}`);
    return client.post('/notice', { lastReadNoticeAt });
  },
  prepareDocumentDownload: async (
    entityId: string,
    fileName: string,
    documents: DocumentSelection[],
  ): ApiPromise<DownloadRequestApiResponse> => {
    logger.debug(`prepareDocumentDownload: ${documents}`);
    return client.post(`/entities/${entityId}/documents`, {
      zipName: fileName,
      files: documents.map((document) => ({
        name: document.name,
        documentId: document.id,
      })),
    });
  },
  pollDownloadRequest: async (
    entityId: string,
    downloadRequestId: string,
  ): ApiPromise<DownloadRequestPollResponse> => {
    logger.debug(`polling download request: ${downloadRequestId}`);
    return client.get(`/entities/${entityId}/documents/${downloadRequestId}`);
  },
  updateUserProfile: async (profile: UserProfile): ApiPromise<void> => {
    logger.debug(`update user profile: ${profile.toString()}`);
    return client.patch(`/users`, profile);
  },
  getGroups: async (): ApiPromise<GroupDetails[]> => {
    logger.debug(`getting groups`);
    return client.get('/users/contact-groups');
  },
  getGroup: async (groupId: string): ApiPromise<Group> => {
    logger.debug(`getting group`);
    return client.get(`/users/contact-groups/${groupId}`);
  },
  findUser: async (email: string): ApiPromise<UserProfile> => {
    logger.debug(`finding user ${email}`);
    return client.get(`/users/find/${encodeURIComponent(email)}`);
  },
  addExistingUserToGroup: async (
    email: string,
    groupId: string,
  ): ApiPromise<Group> => {
    logger.debug(`adding user ${email} to group ${groupId}`);
    return client.patch(
      `/users/contact-groups/${groupId}`,
      { email },
      'add-existing-user-to-contact-group',
    );
  },
  addNewUserToGroup: async (
    email: string,
    firstName: string,
    lastName: string,
    groupId: string,
  ): ApiPromise<Group> => {
    logger.debug(`adding new user ${email} to group ${groupId}`);
    return client.patch(
      `/users/contact-groups/${groupId}`,
      { email, firstName, lastName },
      'add-new-user-to-contact-group',
    );
  },
  removeContactFromGroup: async (
    contactId: string,
    groupId: string,
  ): ApiPromise<Group> => {
    logger.debug(`removing contact ${contactId} from group ${groupId}`);
    return client.delete(
      `/users/contact-groups/${groupId}/contacts/${contactId}`,
    );
  },
  getEntityCollaborators: async (entityId: string): ApiPromise<Team> => {
    logger.debug(`getting team for ${entityId}`);
    return client.get(`/entities/${entityId}/team`);
  },
  addExistingUserToTeam: async (
    email: string,
    entityId: string,
  ): ApiPromise<Team> => {
    logger.debug(`adding user ${email} to team ${entityId}`);
    return client.patch(
      `/entities/${entityId}/team`,
      { email },
      'add-existing-user-to-team',
    );
  },
  addNewUserToTeam: async (
    email: string,
    firstName: string,
    lastName: string,
    entityId: string,
  ): ApiPromise<Team> => {
    logger.debug(`adding new user ${email} to team ${entityId}`);
    return client.patch(
      `/entities/${entityId}/team`,
      { email, firstName, lastName },
      'add-new-user-to-team',
    );
  },
  addGroupToTeam: async (
    groupId: string,
    entityId: string,
  ): ApiPromise<Team> => {
    logger.debug(`adding group ${groupId} to team ${entityId}`);
    return client.patch(
      `/entities/${entityId}/team`,
      { groupId },
      'add-group-to-team',
    );
  },
  removeUserFromTeam: async (
    memberId: string,
    entityId: string,
  ): ApiPromise<Team> => {
    logger.debug(`removing user ${memberId} from team ${entityId}`);
    return client.delete(
      `/entities/${entityId}/team/${memberId}`,
      'remove-user-from-team',
    );
  },
  removeGroupFromTeam: async (
    memberId: string,
    entityId: string,
  ): ApiPromise<Team> => {
    logger.debug(`removing group ${memberId} from team ${entityId}`);
    return client.delete(
      `/entities/${entityId}/team/${memberId}`,
      'remove-group-from-team',
    );
  },
  getFormEditors: async (formId: string): ApiPromise<Editor[]> => {
    logger.debug(`getting editors for ${formId}`);
    return client
      .get<EditorDto[]>(`/forms/${formId}/team`)
      .then((response) => unmarshalEditors(response));
  },
  addExistingUserToEditors: async (
    email: string,
    formId: string,
  ): ApiPromise<Editor[]> => {
    logger.debug(`adding user ${email} to editors for form ${formId}`);
    return client
      .patch<EditorDto[], { email: string }>(
        `/forms/${formId}/team`,
        { email },
        'add-existing-editor-to-team',
      )
      .then((response) => unmarshalEditors(response));
  },
  addNewUserToEditors: async (
    email: string,
    firstName: string,
    lastName: string,
    formId: string,
  ): ApiPromise<Editor[]> => {
    logger.debug(`adding new user ${email} to editors for form ${formId}`);
    return client
      .patch<
        EditorDto[],
        { email: string; firstName: string; lastName: string }
      >(
        `/forms/${formId}/team`,
        { email, firstName, lastName },
        'add-new-editor-to-team',
      )
      .then((response) => unmarshalEditors(response));
  },
  removeUserFromEditors: async (
    editorId: string,
    formId: string,
  ): ApiPromise<Editor[]> => {
    logger.debug(`removing user ${editorId} from editors for form ${formId}`);
    return client
      .delete<EditorDto[]>(`/forms/${formId}/team/${editorId}`)
      .then((response) => unmarshalEditors(response));
  },
});

export const API_CALL_NAMES: Array<ApiCallName> = Object.keys(
  clientInitialiser({} as ReturnType<typeof connector>),
).filter((name) => name !== 'uploadAttachment') as Array<ApiCallName>;

export const applicationHubClient = (
  tenant: string,
  getConfigApiPromise: () => Promise<ApiConfig> = defaultGetConfig,
  tokenServiceFactory = AUTH_SERVICE_FACTORY,
): Promise<ApplicationHubClient> =>
  getConfigApiPromise()
    .then(async ({ apiUrl }) => {
      const tokenService = await tokenServiceFactory();
      const baseUrl = `${apiUrl}/${API_VERSION}/${tenant}`;
      const client = connector(baseUrl, '', tokenService);
      return clientInitialiser(client);
    })
    .catch((err) => {
      logger.error('unable to create API client: ', err);
      throw err;
    });
