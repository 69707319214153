<template>
  <div class="field" :class="{ editing, disabled: schema.disabled }">
    <div v-if="!schema.disabled" :id="toolbarId"></div>
    <div :id="editorId"></div>
  </div>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */
import { abstractField } from 'vue-form-generator';
import tinymce from 'tinymce';
import 'tinymce/themes/silver/theme.min';
import 'tinymce/models/dom/model.min';
import 'tinymce/icons/default/icons.min';
import 'tinymce/themes/silver/';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';

export default {
  name: 'TextEditor',
  mixins: [abstractField],
  props: { schema: Object, model: Object },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    editorId() {
      return `editor_${this.schema.id}`;
    },
    toolbarId() {
      return `toolbar_${this.schema.id}`;
    },
    editorSelector() {
      return `#${this.editorId}`;
    },
    toolbarSelector() {
      return `#${this.toolbarId}`;
    },
  },
  methods: {
    onActivate() {
      this.editing = true;
    },
    onDeactivate() {
      this.editing = false;
    },
  },
  beforeDestroy() {
    tinymce.remove(tinymce.get(this.editorId));
  },
  async mounted() {
    await tinymce.init({
      id: this.editorId,
      selector: this.editorSelector,
      plugins: 'lists autoresize link autolink table',
      toolbar: 'bold italic underline | numlist bullist | link | table',
      promotion: false,
      statusbar: false,
      menubar: false,
      readonly: this.schema.disabled,
      inline: true,
      toolbar_persist: !this.schema.disabled,
      fixed_toolbar_container: this.toolbarSelector,
      content_css: false,
      skin: false,
      link_assume_external_targets: 'https',
    });
    const editor = tinymce.get(this.editorId);
    editor.setContent(this.value);
    const updateValue = () => {
      const content = editor.getContent();
      if (content !== this.value) {
        this.value = content;
      }
    };
    editor.on('keyup', updateValue);
    editor.on('change', updateValue);
    editor.on('focus', this.onActivate);
    editor.on('blur', this.onDeactivate);
  },
};
</script>
<style lang="scss">
.tox.tox-tinymce-inline .tox-editor-header {
  border-radius: 0.25rem 0.25rem 0 0;
  border: 1px solid $neutral-border;
  border-bottom: none;
}

.mce-content-body {
  background-color: var(--primary-lightest);
  border: 1px solid var(--neutral-border);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-sizing: border-box;
  color: var(--primary);
  height: auto;
  width: 100%;
  padding: 1rem;
  margin: 0;
  outline: none;

  .mce-item-table:not([border]) {
    td {
      border: 1px dashed $neutral-border;
    }
  }
}

.editing {
  outline: $primary-lighter auto 1px;

  .tox.tox-tinymce-inline .tox-editor-header,
  .mce-content-body {
    border-color: $primary-lighter;
  }
}

.disabled {
  .tox.tox-tinymce-inline .tox-editor-header,
  .mce-content-body {
    background-color: $disabled;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}
</style>
