














import Vue from 'vue';
import IncompleteIcon from '@/assets/pie.svg?component';
import StageIcon from '@/assets/circle-solid.svg?component';
import ErrorIcon from '@/assets/exclamation-circle-solid.svg?component';
import { FormStage, StageStatus, StageType } from '@/models/form.model';

type Data = {};

type Computed = {
  stage: FormStage;
  isComplete: boolean;
  isIncomplete: boolean;
  isInError: boolean;
  statusDescription: string;
  statusStyle: string;
};

type Methods = {
  calculateStageStatus: (stage: FormStage) => StageStatus;
};

type Props = { stageType: StageType };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'StageStatusIcon',
  props: { stageType: Object },
  components: {
    IncompleteIcon,
    ErrorIcon,
    StageIcon,
  },
  computed: {
    stage() {
      return this.$store.getters.formStage(this.stageType.id);
    },
    isComplete() {
      return this.calculateStageStatus(this.stage) === StageStatus.Complete;
    },
    isIncomplete() {
      return this.stage.status === StageStatus.Incomplete;
    },
    isInError() {
      return this.stage.status === StageStatus.InError;
    },
    statusDescription() {
      switch (this.stage.status) {
        case StageStatus.Complete:
          return 'Required details provided';
        case StageStatus.Incomplete:
          return 'Missing required details';
        case StageStatus.InError:
          return 'Stage contains invalid data';
        default:
          return this.stage.status;
      }
    },
    statusStyle() {
      switch (this.stage.status) {
        case StageStatus.Complete:
          return 'complete';
        case StageStatus.Incomplete:
          return 'incomplete';
        case StageStatus.InError:
          return 'inerror';
        default:
          return 'unvisited';
      }
    },
  },
  methods: {
    calculateStageStatus(stage) {
      return this.$store.getters.hasActiveUploadsForStage(stage.stageTypeId)
        ? StageStatus.Incomplete
        : stage.status;
    },
  },
});
