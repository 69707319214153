

















import Vue from 'vue';
import { Conversation, MessageStatus } from '@/models/entity.model';
import Messages from '@/components/pages/Messages.vue';

type Data = {};
type Computed = {
  conversation: Conversation;
  lastUpdatedAt: Date | undefined;
  loading: boolean;
};
type Methods = {
  sendMessage: (message: string) => void;
  loadMessages: () => void;
};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EntityConversation',
  components: { Messages },
  computed: {
    conversation() {
      return this.$store.getters.getEntityConversation;
    },
    lastUpdatedAt() {
      return this.$store.state.entity.lastMessageUpdate;
    },
    loading() {
      return this.$store.state.entity.loadingMessages;
    },
  },
  methods: {
    sendMessage(message) {
      this.$store.dispatch('sendMessage', {
        entityId: this.$store.getters.entityId,
        message: {
          from: this.$store.getters.userDisplayName,
          text: message,
          status: MessageStatus.Sending,
        },
      });
    },
    loadMessages() {
      this.$store.dispatch('loadMessages', this.$store.getters.entityId);
    },
  },
});
