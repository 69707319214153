<template>
  <div class="layout">
    <div class="attachments" v-if="attachments.length > 0">
      <div v-bind:key="attachment.id" v-for="attachment in attachments">
        <attachment
          :attachment="attachment"
          @file-delete="(args) => $emit('file-delete', args)"
          @file-rename="(args) => $emit('file-rename', args)"
          :show-details="showDetails"
          :edit-file-name="editFileName"
          :file-name-validations="fileNameValidations"
        />
      </div>
    </div>
    <template v-if="canAdd">
      <div
        class="dropZone"
        @click.prevent.stop="onChooseFiles"
        @dragover.prevent
        @dragenter.prevent="dragEnter"
        @dragleave="cancelDrag"
        @drop.prevent="drop"
        @dragend="cancelDrag"
      >
        <rpl-icon
          symbol="upload"
          color="tertiary"
          size="l"
          class="uploadIcon"
        />
        <div id="upload-label">Drag and drop files here or click to upload</div>
        <div class="sizeHint">
          {{ maxFileSizeStr }} limit per file{{ maxFilesMsg }}
        </div>
      </div>
      <input
        type="file"
        ref="fileInput"
        @change="onFilesSelected"
        multiple="multiple"
        tabindex="0"
        :disabled="disabled"
        aria-labelledby="upload-label"
      />
    </template>
    <div v-else class="max-uploads-message">
      Maximum number of uploads ({{ maxFiles }}) reached
    </div>
  </div>
</template>

<script>
import RplIcon from '@dpc-sdp/ripple-icon';
import Attachment from './Attachment.vue';
import { AttachmentStatus, formatBytes } from '@/models/form.model';

export default {
  name: 'Attachments',
  components: { RplIcon, Attachment },
  props: {
    attachments: Array,
    disabled: Boolean,
    maxFiles: Number,
    maxFileSize: Number,
    showDetails: Boolean,
    editFileName: Boolean,
    fileNameValidations: Array,
  },
  computed: {
    maxFilesMsg() {
      if (this.maxFiles) {
        return `, maximum of ${this.maxFiles} file${
          this.maxFiles === '1' ? '' : 's'
        }`;
      }
      return '';
    },
    canAdd() {
      if (this.maxFiles) {
        const validAttachmentCount = this.attachments.filter(
          (attachment) => attachment.status !== AttachmentStatus.Error,
        ).length;
        return validAttachmentCount < this.maxFiles;
      }
      return true;
    },
    maxFileSizeStr() {
      return formatBytes(this.maxFileSize);
    },
  },
  methods: {
    onChooseFiles() {
      this.$refs.fileInput.click();
    },
    onFilesSelected() {
      this.$emit('files-selected', this.$refs.fileInput.files);
      this.$refs.fileInput.value = null;
    },
    dragEnter(event) {
      if (event.dataTransfer.types.includes('Files')) {
        event.currentTarget.classList.add('target');
      }
    },
    drop(event) {
      const { files } = event.dataTransfer;
      if (files && files.length > 0) {
        this.$emit('files-selected', files);
      }
      this.cancelDrag(event);
    },
    cancelDrag(event) {
      event.currentTarget.classList.remove('target');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@dpc-sdp/ripple-global/style';

.layout {
  display: flex;
  flex-direction: column;

  input[type='file'] {
    position: fixed;
    top: -1000px;
  }

  input:disabled + .dropZone {
    cursor: not-allowed;

    &:hover {
      background-color: var(--tertiary-lightest);
    }
  }

  h4 {
    margin: 0 0 0.5em 0;
  }

  .message {
    margin: 1em 1em 5em 1em;
  }

  .comingSoon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $neutral;
  }

  .attachments {
    padding: 1em 0 0 0;
    border-radius: 0.3rem;
    border: 1px solid $neutral;

    &:nth-last-child(3) {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  input:focus + .dropZone {
    outline: $primary-lighter solid 2px;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .dropZone {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    cursor: pointer;
    background-color: $primary-lightest;
    border: 1px solid $neutral-border;
    border-radius: 0.3rem;

    &:not(:first-child) {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    &:hover {
      background-color: $neutral-lighter;
    }

    .uploadIcon {
      margin-bottom: 0.5em;
    }

    .sizeHint {
      font-size: 80%;
    }

    &.target {
      background-color: $secondary-lighter;
    }

    * {
      pointer-events: none;
    }
  }

  .max-uploads-message {
    padding: 0.5rem;
    text-align: center;
    font-family: rpl-font('bold');
  }
}
</style>
