











import Vue from 'vue';
import moment from 'moment';
import { Form } from '@/models/form.model';
import { DataFromApi, getData, hasData } from '@/api/data';

type Data = {};
type Methods = {};
type Computed = {
  lastEditedReadable?: string;
  lastEditedFormatted?: string;
  form?: Form;
  lastEditedBy?: string;
};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FormEditStatus',
  computed: {
    form() {
      const formFromApi = this.$store.getters.form as DataFromApi<Form>;
      if (hasData(formFromApi)) {
        return getData(formFromApi);
      }
      return undefined;
    },
    lastEditedReadable() {
      if (this.form?.lastEditTime) {
        return moment(this.form.lastEditTime).fromNow();
      }
      return undefined;
    },
    lastEditedFormatted() {
      if (this.form?.lastEditTime) {
        return moment(this.form.lastEditTime).format('DD/MM/yyyy HH:mm');
      }
      return undefined;
    },
    lastEditedBy() {
      if (this.form?.userDetails) {
        return `${this.form.userDetails.firstName} ${this.form.userDetails.lastName}`;
      }
      return undefined;
    },
  },
  methods: {},
});
