import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import formDefinitions from './modules/formDefinitions';
import apiCalls from './modules/apiCalls';
import form from './modules/form';
import user from './modules/user';
import formType from './modules/formType';
import { RootState, CoreState } from './types';
import dashboard from './modules/dashboard';
import polling from './modules/polling';
import entity from './modules/entity';
import breadcrumb from '@/store/modules/breadcrumb';
import feature from '@/store/modules/feature';
import tenant from '@/store/modules/tenant';
import widget from '@/store/modules/widget';
import notice from '@/store/modules/notice';
import group from '@/store/modules/group';

Vue.use(Vuex);

const defaultState: CoreState = {
  siteName: '',
  headerText: undefined,
  subTitle: undefined,
};

export const storeOptions: StoreOptions<RootState> = {
  state: () => ({ ...defaultState } as RootState), // Type assertion stops Typescript complaining about the modules
  getters: {
    headerText: (state) => state.headerText,
    siteName: (state) => state.siteName,
    subTitle: (state) => state.subTitle,
  },
  mutations: {
    setHeaderText(state, text) {
      state.headerText = text;
    },
    setSiteName(state, siteName) {
      state.siteName = siteName;
    },
    setSubTitle(state, subTitle) {
      state.subTitle = subTitle;
    },
  },
  modules: {
    tenant,
    apiCalls,
    formDefinitions,
    formType,
    form,
    dashboard,
    user,
    entity,
    polling,
    breadcrumb,
    feature,
    widget,
    notice,
    group,
  },
};

export default new Vuex.Store<RootState>(storeOptions);
