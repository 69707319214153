import { tenantBasePath, pathFragments } from './internal/paths';

/**
 * This returns path helpers intended to be used outside the router.
 *
 * @param tenantId tenantId
 */
export const paths = (tenantId: string) => {
  const basePath = `${tenantBasePath(tenantId)}/`;

  // The paths here must be kept in sync with the construction in routes.ts
  return {
    base: () => basePath,
    // login
    loggedOut: () => `${basePath}${pathFragments.loggedOut()}`,
    login: () => `${basePath}${pathFragments.login()}`,
    logout: () => `${basePath}${pathFragments.logout()}`,
    mfa: () => `${basePath}${pathFragments.mfa()}`,
    // forms
    form: (formId: string) => `${basePath}${pathFragments.form(formId)}`,
    stage: (formId: string, stageId: string) =>
      `${basePath}${pathFragments.form(formId)}/${pathFragments.stage(
        stageId,
      )}`,
    // navigation
    dashboard: () => `${basePath}${pathFragments.dashboard()}`,
    formCategory: (formCategoryId: string) =>
      `${basePath}${pathFragments.formCategory(formCategoryId)}`,
    entity: (entityId: string) =>
      `${basePath}${pathFragments.entity(entityId)}`,
    entityCollaborators: (entityId: string, entityName: string) =>
      `${basePath}${pathFragments.entityCollaborators(entityId, entityName)}`,
    // actions
    createForm: (templateCode: string) =>
      `${basePath}${pathFragments.createForm(templateCode)}`,
    profile: () => `${basePath}${pathFragments.profile()}`,
    groups: () => `${basePath}${pathFragments.groups()}`,
    group: (groupId: string) => `${basePath}${pathFragments.group(groupId)}`,
    addGroupMember: (groupId: string) =>
      `${basePath}${pathFragments.addGroupMember(groupId)}`,
    changePassword: () => `${basePath}${pathFragments.changePassword()}`,
  };
};

export default paths;
