



























import Vue from 'vue';
import StageStatusIcon from './StageStatusIcon.vue';
import { Form, FormType, StageType } from '@/models/form.model';
import { getData, hasData } from '@/api/data';
import FormEditStatus from '@/components/pages/form/FormEditStatus.vue';
import EditorPanel from '@/components/pages/EditorPanel.vue';

type Data = {};

type Computed = {
  stageTypes: StageType[];
  stageRouteName: string;
  editorsRouteName: string;
  activeStageType?: StageType;
};

type Methods = {
  onClick: () => void;
  isActive: (stageType: StageType) => boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

type Props = { form: Form; hideTeam: boolean };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Stages',
  props: { form: Object, hideTeam: Boolean },
  components: {
    EditorPanel,
    FormEditStatus,
    StageStatusIcon,
  },
  computed: {
    stageTypes() {
      return hasData(this.$store.state.formType.formTypeFromApi)
        ? getData<FormType>(this.$store.state.formType.formTypeFromApi).stages
        : [];
    },
    stageRouteName() {
      return this.$route.meta?.stageRouteName;
    },
    editorsRouteName() {
      return this.$route.meta?.editorsRouteName;
    },
    activeStageType() {
      return this.$store.state.form.stageType;
    },
  },
  methods: {
    onClick() {
      this.$emit('stage-clicked');
    },
    isActive(stageType) {
      return this.activeStageType === stageType;
    },
    onMouseEnter() {
      this.$store.commit('setHighlight', true);
    },
    onMouseLeave() {
      this.$store.commit('setHighlight', false);
    },
  },
});
