












import Vue from 'vue';
import { CombinedVueInstance } from 'vue/types/vue';
import {
  AdditionalDetails,
  Column,
  RowViewModel,
} from '@/components/table/model/table.model';
import { columnValue } from '@/helpers/table';
import logger from '@/logger';
import Markdown from '@/components/markdown/Markdown.vue';
import ExpandToggle from '@/components/shared/ExpandToggle.vue';

type Data = {
  expanded: boolean;
  contentComponent?: CombinedVueInstance<Vue, {}, {}, {}, unknown>;
  details?: AdditionalDetails;
  addedRow?: HTMLTableRowElement;
};

type Computed = {
  content: string;
  eLabel: string;
  hLabel: string;
};

type Methods = {
  onClick: (evt: MouseEvent) => void;
  cleanup: () => void;
};

type Props = {
  expandLabel?: string;
  hideLabel?: string;
  rowViewModel: RowViewModel<unknown>;
  viewPath: (row: RowViewModel<unknown>) => string;
  column: Column;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ExpandRowAction',
  components: { ExpandToggle },
  props: {
    rowViewModel: Object,
    viewPath: Function,
    column: Object,
    expandLabel: String,
    hideLabel: String,
  },
  data() {
    return { expanded: false, contentComponent: undefined };
  },
  computed: {
    eLabel() {
      return this.expandLabel || this.details?.expandLabel || 'Show details';
    },
    hLabel() {
      return this.hideLabel || this.details?.hideLabel || 'Hide details';
    },
    content() {
      if (this.details) {
        return this.details.content;
      }
      return '';
    },
  },
  methods: {
    onClick(evt) {
      const elem = evt.target as HTMLElement;
      const tr = elem.closest('tr');
      if (tr) {
        if (this.expanded) {
          this.cleanup();
          this.expanded = false;
        } else {
          const tds = tr.querySelectorAll('td');
          this.addedRow = document.createElement('tr');
          this.addedRow.classList.add('additional');
          const newCell = document.createElement('td');
          newCell.colSpan = tds?.length || 1;
          this.addedRow.appendChild(newCell);
          tr.insertAdjacentElement('afterend', this.addedRow);
          const { content } = this.$slots;
          if (content) {
            this.contentComponent = new Vue({
              render: () => content[0],
              parent: this,
            });
          } else {
            this.contentComponent = new Markdown({
              parent: this,
              propsData: { source: this.content },
            });
          }
          newCell.appendChild(this.contentComponent.$mount().$el);
          this.expanded = true;
        }
      }
    },
    cleanup() {
      if (this.contentComponent) {
        this.contentComponent.$el.remove();
        this.contentComponent.$destroy();
      }
      if (this.addedRow) {
        this.addedRow.remove();
        this.addedRow = undefined;
      }
    },
  },
  created() {
    if (!this.$slots.content) {
      const colValue = columnValue(
        this.rowViewModel.row.columns,
        this.column.key,
      );
      if (colValue) {
        try {
          this.details = JSON.parse(colValue) as AdditionalDetails;
        } catch (e) {
          logger.error('Could not parse additional details from column value');
        }
      }
    }
  },
  beforeDestroy() {
    if (this.expanded) {
      this.cleanup();
    }
  },
});
