
















import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { getData, hasData, isApiError, LoadedData } from '@/api/data';
import { CreatedForm } from '@/models/form.model';

type Data = { error: boolean };
type Methods = {};
type Computed = {};
type Props = { templateCode: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CreateForm',
  components: { RplIcon },
  props: { templateCode: String },
  data() {
    return { error: false };
  },
  mounted() {
    this.$store
      .dispatch('createFormFromTemplate', {
        templateCode: this.templateCode,
        initialValues: this.$route.query,
      })
      .then((response: LoadedData<CreatedForm>) => {
        if (hasData(response)) {
          this.$router.replace({
            name: 'form',
            params: { formId: getData(response).id },
          });
        } else if (isApiError(response)) {
          this.error = true;
        }
      });
  },
});
