









import { RplHeroBanner } from '@dpc-sdp/ripple-hero-banner';
import Vue from 'vue';

type Data = {};
interface Computed {
  headerText: string;
  bannerBackground: string;
  subTitle?: string;
}
type Methods = {};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'HeroBanner',
  components: {
    RplHeroBanner,
  },
  computed: {
    headerText() {
      return this.$store.getters.headerText;
    },
    bannerBackground() {
      return this.$store.getters.tenantTheme.bannerImage;
    },
    subTitle() {
      return this.$store.getters.subTitle;
    },
  },
  props: { theme: String, disabled: Boolean, inverse: Boolean },
});
