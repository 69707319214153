import Vue from 'vue';
import RplGlobal from '@dpc-sdp/ripple-global';
import VueGtag from 'vue-gtag';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { formatDate, formatDateTime } from './formats';
import defaultGetConfig from '@/config';

// Expose Vue globally to allow Vue.extend in dynamically loaded widgets
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
window.Vue = Vue;

// ripple custom options
// https://github.com/dpc-sdp/ripple/tree/develop/packages/components/Atoms/Global#rploptions
Vue.use(RplGlobal, { isDev: true, viclogo: false });
Vue.filter('formatDate', (value: string) => formatDate(value));
Vue.filter('formatDateTime', (value: string) => formatDateTime(value));
defaultGetConfig().then((config) =>
  Vue.use(
    VueGtag,
    {
      config: { id: 'G-7DB9QMVEEV' },
      disableScriptLoad: !config.featureFlags.collectAnalytics,
    },
    router,
  ),
);
new Vue({ router, render: (h) => h(App), store }).$mount('#app');
