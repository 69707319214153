






















import Vue from 'vue';
import {
  TableViewModel,
  TabViewModel,
} from '@/components/table/model/table.model';
import DataHubTable from '@/components/table/DataTable.vue';
import AppHubTabs from '@/components/ripple/AppHubTabs.vue';

type Data = { activeTab: string };
type Props = { tables: TableViewModel<unknown>[] };

interface Methods {
  switchTab: (tab: string) => void;
}

interface Computed {
  activeTable?: TableViewModel<unknown>;
  tabs: TabViewModel[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EntityGroupTable',
  props: {
    tables: Array,
  },
  components: {
    AppHubTabs,
    DataHubTable,
  },
  data() {
    return {
      activeTab: this.tables[0].config.label,
    };
  },
  computed: {
    activeTable() {
      return this.tables.find(
        (table: TableViewModel<unknown>) =>
          table.config.label === this.activeTab,
      );
    },
    tabs() {
      return this.tables.map((table) => ({
        label: table.config.label,
        key: table.config.label,
      }));
    },
  },
  methods: {
    switchTab(tab: string) {
      this.activeTab = tab;
    },
  },
});
