var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rpl-accordion"},[(_vm.title)?_c('h2',{staticClass:"rpl-accordion__title-top",attrs:{"id":_vm.titleId}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{staticClass:"rpl-accordion__collapse"},[_c('button',{staticClass:"rpl-accordion__collapse-btn",on:{"click":_vm.closeOpenAll}},[_vm._v(" "+_vm._s(_vm.closeOpenLabel)+" ")])]),(_vm.type === 'numbered')?_c('ol',{staticClass:"rpl-accordion__list"},_vm._l((_vm.accordions),function(accordion,index){return _c('li',{key:index,staticClass:"rpl-accordion__list-item",class:{
        'rpl-accordion__list-item--expanded': _vm.accordionIsOpen(index),
      }},[_c('h2',{staticClass:"rpl-accordion__title",class:{ 'rpl-accordion__title--expanded': _vm.accordionIsOpen(index) }},[_c('button',{staticClass:"rpl-accordion__button",class:{
            'rpl-accordion__button--expanded': _vm.accordionIsOpen(index),
          },attrs:{"aria-expanded":_vm.accordionIsOpen(index).toString(),"aria-controls":_vm.accordionId(index)},on:{"click":function($event){return _vm.accordionClick(index)}}},[_c('span',{staticClass:"rpl-accordion__title-number",attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(index + 1))]),_c('span',[_vm._v(_vm._s(accordion.title))]),_c('rpl-icon',{staticClass:"rpl-accordion__icon",class:{
              'rpl-accordion__icon--expanded': _vm.accordionIsOpen(index),
            },attrs:{"symbol":"arrow_down_tertiary","color":"primary"}})],1)]),_c('div',{ref:_vm.accordionId(index),refInFor:true,staticClass:"rpl-accordion__content",attrs:{"id":_vm.accordionId(index)}},[_c('markdown',{staticClass:"rpl-accordion__content-inner",attrs:{"source":accordion.content}})],1)])}),0):_c('ul',{staticClass:"rpl-accordion__list"},_vm._l((_vm.accordions),function(accordion,index){return _c('li',{key:index,staticClass:"rpl-accordion__list-item",class:{
        'rpl-accordion__list-item--expanded': _vm.accordionIsOpen(index),
      }},[_c('h2',{staticClass:"rpl-accordion__title",class:{ 'rpl-accordion__title--expanded': _vm.accordionIsOpen(index) }},[_c('button',{staticClass:"rpl-accordion__button",class:{
            'rpl-accordion__button--expanded': _vm.accordionIsOpen(index),
          },attrs:{"aria-expanded":_vm.accordionIsOpen(index).toString(),"aria-controls":_vm.accordionId(index)},on:{"click":function($event){return _vm.accordionClick(index)}}},[_c('span',{staticClass:"rpl-accordion__button-text"},[_vm._v(_vm._s(accordion.title))]),_c('rpl-icon',{staticClass:"rpl-accordion__icon",class:{
              'rpl-accordion__icon--expanded': _vm.accordionIsOpen(index),
            },attrs:{"symbol":"arrow_down_tertiary","color":"primary"}})],1)]),_c('div',{ref:_vm.accordionId(index),refInFor:true,staticClass:"rpl-accordion__content",attrs:{"id":_vm.accordionId(index)}},[_c('markdown',{staticClass:"rpl-accordion__content-inner",attrs:{"source":accordion.content}})],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }