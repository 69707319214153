












import Vue from 'vue';
import { getEntityDocumentDownloadURL } from '@/api/download';
import { Column, RowViewModel } from '@/components/table/model/table.model';
import { columnValue } from '@/helpers/table';
import IconButton from '@/components/IconButton.vue';

type Data = {};
type Computed = {
  hasValue: boolean;
};
interface Methods {
  onClick: (event: Event) => void;
}
type Props = { rowViewModel: RowViewModel<unknown>; column: Column };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DownloadAction',
  components: { IconButton },
  props: { rowViewModel: Object, column: Object },
  computed: {
    hasValue() {
      return (
        columnValue(this.rowViewModel.row.columns, this.column.key) !==
        undefined
      );
    },
  },
  methods: {
    async onClick(event: Event) {
      event.stopPropagation();
      event.preventDefault();
      await this.$store.dispatch('getAuthCookie', this.$store.getters.tenantId);
      const link = document.createElement('a');
      const nameColumn =
        columnValue(this.rowViewModel.row.columns, 'fileName') || '';
      link.href = await getEntityDocumentDownloadURL(
        this.$store.getters.tenantId,
        this.$store.getters.entityId,
        columnValue(this.rowViewModel.row.columns, this.column.key) || '',
        nameColumn,
      );
      link.setAttribute('download', nameColumn);
      link.click();
    },
  },
});
