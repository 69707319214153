type AddDataItemArgs = {
  fieldId: string;
  stageId: string;
  dataItemId: string | undefined;
};

export const marshallAddDataItem = ({
  fieldId,
  stageId,
  dataItemId,
}: AddDataItemArgs) => ({
  fieldId,
  parent: dataItemId ? { dataItemId } : { stageId },
});
