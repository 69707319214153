







































import Vue from 'vue';
import {
  EntityDefinition,
  EntityAction,
  EntityActionGroupType,
} from '@/models/entity.model';
import AppHubTabs from '@/components/ripple/AppHubTabs.vue';
import EntityActionComp from '@/components/pages/EntityAction.vue';
import { getData } from '@/api/data';
import EntityDocuments from '@/components/pages/EntityDocuments.vue';
import { TableViewModel } from '@/components/table/model/table.model';

type Data = {
  actionInProgress: boolean;
  activeTab?: string;
};
type Computed = {
  entity: EntityDefinition;
  tabs: { label: string; key: string }[];
  actions: EntityAction[];
  activeActions: EntityAction[];
  primaryActions: EntityAction[];
  secondaryActions: EntityAction[];
  primaryTableViewModels: TableViewModel<unknown>[];
  activeTable?: TableViewModel<unknown>;
  hasPrimaryActions: boolean;
  hasSecondaryActions: boolean;
};
type Props = { label: string };
type Methods = {
  onSwitchTab: (tab: string) => void;
  actionToggled: (action: boolean) => void;
};
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EntityTabs',
  components: { EntityDocuments, EntityActionComp, AppHubTabs },
  props: {
    label: String,
  },
  data() {
    return {
      actionInProgress: false,
      activeTab: undefined,
    };
  },
  computed: {
    entity() {
      return getData(this.$store.getters.entity);
    },
    tabs() {
      return [
        ...(this.hasPrimaryActions
          ? [{ label: 'Next steps', key: 'tasks' }]
          : []),
        ...(this.hasSecondaryActions
          ? [{ label: 'Other actions', key: 'maintenance' }]
          : []),
        { label: 'Attachments', key: 'attachments' },
        ...this.primaryTableViewModels.map((vm) => ({
          label: vm.config.label,
          key: vm.config.label,
        })),
      ];
    },
    actions() {
      return this.$store.getters.getEntityActions;
    },
    activeActions() {
      return this.activeTab === 'tasks'
        ? this.primaryActions
        : this.secondaryActions;
    },
    primaryActions() {
      return this.actions.filter(
        (action) =>
          !action.group || action.group === EntityActionGroupType.Primary,
      );
    },
    secondaryActions() {
      return this.actions.filter(
        (action) => action.group === EntityActionGroupType.Secondary,
      );
    },
    primaryTableViewModels() {
      return this.$store.getters.getPrimaryTableViewModels;
    },
    activeTable() {
      return this.primaryTableViewModels.find(
        (vm) => vm.config.label === this.activeTab,
      );
    },
    hasPrimaryActions() {
      return !!this.primaryActions.length;
    },
    hasSecondaryActions() {
      return !!this.secondaryActions.length;
    },
  },
  methods: {
    onSwitchTab(tab) {
      this.activeTab = tab;
    },
    actionToggled(action) {
      this.actionInProgress = action;
    },
  },
  mounted() {
    if (this.tabs.length) {
      this.activeTab = this.tabs[0].key;
    }
  },
  watch: {
    tabs() {
      if (!this.tabs.some((tab) => tab.key === this.activeTab)) {
        this.activeTab = this.tabs.length ? this.tabs[0].key : undefined;
      }
    },
  },
});
