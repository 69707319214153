














import { abstractField } from 'vue-form-generator';
import mixins from 'vue-typed-mixins';

type Data = {};

type Computed = {};

type Methods = { onKeydown: (event: KeyboardEvent) => void };

type Props = { schema: { regex?: RegExp } };
const allowedKeys: string[] = [
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
  'Tab',
];

export default mixins(abstractField).extend<Data, Methods, Computed, Props>({
  mixins: [abstractField],
  props: { schema: Object },
  methods: {
    onKeydown(event) {
      if (
        this.schema.regex &&
        !event.ctrlKey &&
        !event.metaKey &&
        !allowedKeys.includes(event.key) &&
        !event.key.match(new RegExp(this.schema.regex))
      ) {
        event.preventDefault();
      }
    },
  },
});
