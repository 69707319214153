export const tenantBasePath = (tenantId: string) => `/${tenantId}`;

/**
 * If you need paths outside the router, don't use this one - please use `router/paths.ts`
 * This is the definition for the path fragments inside the router.
 */
export const pathFragments = {
  // login
  loggedOut: () => `loggedOut`,
  login: () => `login`,
  logout: () => `logout`,
  mfa: () => 'mfa',
  // forms
  form: (formId: string) => `form/${formId}`,
  stage: (stageId: string) => `stage/${stageId}`,
  // navigation
  dashboard: () => 'dashboard',
  formCategory: (formCategoryId: string) => `formCategory/${formCategoryId}`,
  entity: (entityId: string) => `entity/${entityId}`,
  entityForm: (entityId: string, entityName: string) =>
    `entity/${entityId}/name/${entityName}`,
  entityCollaborators: (entityId: string, entityName: string) =>
    `entity/${entityId}/name/${entityName}/collaborators`,
  collaboratorForm: (entityId: string, entityName: string) =>
    `entity/${entityId}/name/${entityName}/collaborators/add`,
  inlineForm: (code: string, formId: string) =>
    `actionCode/${code}/form/${formId}/:layout`,
  groupDetailsForm: (groupId: string, formCode: string, pathBack: string) =>
    `groupForm/${groupId}/${formCode}/${pathBack}`,
  // actions
  createForm: (templateCode: string) => `createForm/${templateCode}`,
  profile: () => `profile`,
  groups: () => `groups`,
  group: (groupId: string) => `group/${groupId}`,
  addGroupMember: (groupId: string) => `addGroupMember/${groupId}`,
  changePassword: () => 'changePassword',
  error: () => 'error',
  formEditors: (formId: string, stageId: string, formName: string) =>
    `form/${formId}/stage/${stageId}/name/${formName}/editors`,
};
