import { FieldDto, FormTypeDto } from './types.dto';
import { ApiResponse, getData, isApiError, LoadedData } from './data';
import {
  Editor,
  EditorDto,
  Field,
  FormType,
  PresentationControl,
} from '@/models/form.model';

const parsePresentationControls = (
  controlsStr?: string,
): PresentationControl[] => {
  if (controlsStr) {
    const controls = controlsStr.split(',');
    return controls.map((control) => control.trim() as PresentationControl);
  }
  return [];
};

const unmarshalFields = (fieldDtos?: FieldDto[]): Field[] =>
  fieldDtos
    ? fieldDtos.map((fieldDto) => ({
        ...fieldDto,
        presentationControls: parsePresentationControls(
          fieldDto.presentationControl,
        ),
        fields: unmarshalFields(fieldDto.fields),
      }))
    : [];

export const unmarshalFormType = (
  payload: ApiResponse<FormTypeDto>,
): ApiResponse<FormType> =>
  isApiError(payload)
    ? payload
    : LoadedData({
        ...getData(payload),
        stages: getData(payload).stages.map((stageDto) => ({
          ...stageDto,
          fields: unmarshalFields(stageDto.fields),
        })),
      });

export const unmarshalEditors = (
  payload: ApiResponse<EditorDto[]>,
): ApiResponse<Editor[]> =>
  isApiError(payload)
    ? payload
    : LoadedData(
        getData(payload).map((member) => ({
          ...member,
          get name() {
            return `${member.givenName} ${member.familyName}`;
          },
        })),
      );
