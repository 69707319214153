<template>
  <div :class="tenantId">
    <RplSiteFooter
      class="footer"
      :nav="navSections"
      :copyright="copyright"
      :links="links"
      :acknowledgement="acknowledgement"
    />
  </div>
</template>

<script>
import { RplSiteFooter } from '@dpc-sdp/ripple-site-footer';
import { tenantDetails } from './tenantDetails';

export default {
  name: 'Footer',
  props: ['tenantId'],
  components: {
    RplSiteFooter,
  },
  data() {
    return {
      copyright: '© Copyright State Government of Victoria',
      acknowledgement:
        'We acknowledge and respect Victorian Traditional Owners as the original custodians of Victoria’s land and waters, their unique ability to care for Country and deep spiritual connection to it.\nWe honour Elders past and present whose knowledge and wisdom has ensured the continuation of culture and traditional practices. \nWe are committed to genuinely partner, and meaningfully engage, with Victoria’s Traditional Owners and Aboriginal communities to support the protection of Country, the maintenance of spiritual and cultural practices and their broader aspirations in the 21st century and beyond.',
    };
  },
  computed: {
    details() {
      return tenantDetails[this.tenantId] || tenantDetails.default;
    },
    links() {
      return this.details.links;
    },
    navSections() {
      const { links, ...rest } = this.details;
      return Object.values(rest);
    },
  },
};
</script>
<style lang="scss">
@import '~@dpc-sdp/ripple-global/scss/settings';
@import '~@dpc-sdp/ripple-global/scss/tools';

.pol,
.hv {
  .rpl-footer-nav__menu-item--parent .rpl-footer-nav__submenu-item {
    li a {
      display: flex;
      align-items: center;
      &:before {
        fill: #201647;
        width: 25px;
        margin-right: 5px;
      }
      &[href*='tel:']:before {
        content: url('~@/assets/phone.svg');
      }
      &[href*='mailto:']:before {
        content: url('~@/assets/mail.svg');
      }
      &[href*='facebook']:before {
        content: url('~@/assets/facebook.svg');
      }
      &[href*='twitter']:before {
        content: url('~@/assets/twitter.svg');
      }
      &[href*='linkedin']:before {
        content: url('~@/assets/linkedIn.svg');
      }
      &[href*='youtube']:before {
        content: url('~@/assets/youTube.svg');
      }
      &[href*='instagram']:before {
        content: url('~@/assets/instagram.svg');
      }
    }
  }
}

.rpl-site-footer {
  white-space: pre-wrap;
  background-image: none;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 25rem;
  background-color: $secondary-lighter;
  color: $primary;

  a {
    color: $primary;
    &:not([href]) {
      text-decoration: none;
    }
  }

  &__main {
    border-bottom: 1px solid #fff;
  }

  &__bottom {
    flex-direction: column;
  }

  &__logos {
    justify-content: left;
    margin-left: 0;
    img {
      filter: brightness(0) saturate(100%) invert(9%) sepia(32%) saturate(3919%)
        hue-rotate(236deg) brightness(100%) contrast(100%);
    }
  }

  @include rpl_breakpoint_down(m) {
    background-size: 45%;
    &__logos {
      justify-content: center;
    }
  }
}
</style>
