


























































import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import moment from 'moment';
import { Conversation, Message } from '@/models/entity.model';
import ConversationMessage from '@/components/pages/Message.vue';
import ReloadIcon from '@/assets/sync.svg?component';

type Data = { message: string };
type Computed = { lastUpdated: string };
type Methods = {
  isOwn: (message: Message) => boolean;
  onEnter: (evt: KeyboardEvent) => void;
  onChange: (evt: KeyboardEvent) => void;
  trapEnter: (evt: KeyboardEvent) => void;
  scrollToBottom: () => void;
  sendMessage: () => void;
  reloadMessages: () => void;
};
type Props = {
  conversation: Conversation;
  layout: string;
  height: string;
  loading: boolean;
  lastUpdatedAt: Date;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Messages',
  props: {
    conversation: Object,
    layout: String,
    height: String,
    loading: Boolean,
    lastUpdatedAt: Date,
  },
  components: { RplIcon, ConversationMessage, ReloadIcon },
  data() {
    return { message: '' };
  },
  computed: {
    lastUpdated() {
      if (this.lastUpdatedAt) {
        return `last update ${moment(this.lastUpdatedAt).format('HH:mm:ss')}`;
      }
      return '';
    },
  },
  methods: {
    isOwn(message) {
      return message.from === this.$store.getters.userDisplayName;
    },
    trapEnter(evt) {
      if (!evt.shiftKey) {
        evt.preventDefault();
      }
    },
    onChange(evt) {
      const elem = evt.target as HTMLElement;
      this.message = elem.innerText;
    },
    onEnter(evt) {
      if (!evt.shiftKey) {
        this.sendMessage();
      }
    },
    scrollToBottom() {
      return this.$el
        .querySelector('.conversation-message:last-child')
        ?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    },
    sendMessage() {
      if (this.message.trim()) {
        this.$emit('send-message', this.message);
        this.message = '';
        const input = this.$el.querySelector('.input') as HTMLElement;
        if (input) {
          input.innerText = '';
        }
        this.$nextTick(() => this.scrollToBottom());
      }
    },
    reloadMessages() {
      this.$emit('load-messages');
    },
  },
  mounted() {
    this.scrollToBottom();
  },
});
