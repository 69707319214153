











import Vue from 'vue';

type Data = {
  open: boolean;
};

type Computed = {};

type Methods = {
  onToggle: () => void;
  close: (evt: Event) => void;
};

type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Popup',
  data() {
    return {
      open: false,
    };
  },
  methods: {
    onToggle() {
      this.open = !this.open;
      if (this.open) {
        document.addEventListener('click', this.close);
      } else {
        document.removeEventListener('click', this.close);
      }
    },
    close(evt) {
      const container = this.$refs.container as Element;
      if (!container.contains(evt.target as Node)) {
        this.open = false;
        document.removeEventListener('click', this.close);
      }
    },
  },
});
