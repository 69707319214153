






import Vue from 'vue';
import { RplCheckbox } from '@dpc-sdp/ripple-form';
import { RowViewModel } from '@/components/table/model/table.model';
import { columnValue } from '@/helpers/table';

type Data = {};
type Computed = { checked: boolean };
type Methods = { onChange: (checked: boolean) => void };
type Props = { rowViewModel: RowViewModel<unknown> };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SelectEntityDocumentAction',
  props: { rowViewModel: Object },
  components: { RplCheckbox },
  computed: {
    checked() {
      return this.$store.getters.isDocumentSelected(this.rowViewModel.row.id);
    },
  },
  methods: {
    onChange(checked) {
      const name = columnValue(this.rowViewModel.row.columns, 'fileName');
      if (checked) {
        this.$store.commit('selectDocument', {
          id: this.rowViewModel.row.id,
          name,
        });
      } else {
        this.$store.commit('deselectDocument', {
          id: this.rowViewModel.row.id,
          name,
        });
      }
    },
  },
});
