














import Vue from 'vue';
import IncompleteIcon from '@/assets/pie.svg?component';
import StageIcon from '@/assets/circle-solid.svg?component';
import ErrorIcon from '@/assets/exclamation-circle-solid.svg?component';
import { DataItem, DataItemStatus } from '@/models/form.model';

type Data = {};

type Computed = {
  isComplete: boolean;
  isIncomplete: boolean;
  isInError: boolean;
  statusDescription: string;
  statusStyle: string;
  statuses: (keyof typeof DataItemStatus)[];
};

type Methods = {};

type Props = { dataItems: DataItem[] };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'RecordStatusIcon',
  props: { dataItems: Array },
  components: {
    IncompleteIcon,
    ErrorIcon,
    StageIcon,
  },
  computed: {
    statuses() {
      return this.dataItems.map((dataItem) => dataItem.status);
    },
    isComplete() {
      return this.statuses.every(
        (status) => status === DataItemStatus.Complete,
      );
    },
    isIncomplete() {
      return this.statuses.some(
        (status) => status === DataItemStatus.Incomplete,
      );
    },
    isInError() {
      return this.statuses.some((status) => status === DataItemStatus.InError);
    },
    statusDescription() {
      if (this.isComplete) {
        return 'Required details provided';
      }
      if (this.isIncomplete) {
        return 'Missing required details';
      }
      return 'Contains invalid data';
    },
    statusStyle() {
      if (this.isComplete) {
        return 'complete';
      }
      if (this.isIncomplete) {
        return 'incomplete';
      }
      return 'inerror';
    },
  },
});
