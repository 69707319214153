











import Vue from 'vue';
import { ApiError, ApiErrorType } from '@/api/data';
import ErrorMessage from './ErrorMessage.vue';

type Data = {};
type Methods = {};
type Computed = {
  errors: ApiError[];
};

type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ErrorList',
  components: { ErrorMessage },

  computed: {
    errors() {
      return this.$store.getters.apiErrors.filter(
        (error: ApiError) => error.type !== ApiErrorType.UserNotLinked,
      );
    },
  },
});
