














import { RplForm } from '@dpc-sdp/ripple-form';
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import fieldMixin from './mixin/fieldMixin';
import FieldAttachments from './FieldAttachments.vue';
import PopoverLabel from '@/components/pages/form/fields/PopoverLabel.vue';
import { MAX_FILE_SIZE_BYTES } from '@/config/constants';
import { PresentationControl } from '@/models/form.model';
import MarkdownDescription from '@/components/pages/form/fields/MarkdownDescription.vue';

Vue.component('field-attachments', FieldAttachments);

type AttachmentDataItemValue = {
  attachments: unknown[];
  files?: FileList;
  delete?: string;
  rename?: string;
  name?: string;
};
type AttachmentsSchema = {
  type: string;
  max?: number;
  maxFileSize: number;
};

type Data = {};
type Computed = {
  dataItemValue: AttachmentDataItemValue;
  maxFileSize: number;
  allowFileNameEdit: boolean;
};
type Methods = {
  changed: (
    model: AttachmentDataItemValue,
    newVal: AttachmentDataItemValue,
  ) => void;
  schema: () => AttachmentsSchema;
};
type Props = {};

export default mixins(fieldMixin).extend<Data, Methods, Computed, Props>({
  components: { MarkdownDescription, RplForm, PopoverLabel },
  name: 'AttachmentField',
  mixins: [fieldMixin],
  computed: {
    dataItemValue() {
      return {
        attachments: this.$store.getters.attachmentsForDataItem(
          this.dataItem.id,
        ),
        files: undefined,
        delete: undefined,
      };
    },
    maxFileSize() {
      return (
        this.field.maxFileSize ||
        this.$store.getters.tenantSettings.maxFileSize ||
        MAX_FILE_SIZE_BYTES
      );
    },
    allowFileNameEdit() {
      return !!this.field.validations.filter(
        (validation) => validation.type !== 'FileExtension',
      ).length;
    },
  },
  methods: {
    changed(model, newVal) {
      if (newVal.files) {
        this.saving = true;
        this.$store
          .dispatch('uploadAttachments', {
            dataItemId: this.dataItem.id,
            files: newVal.files,
            maxFiles: this.schema().max,
            maxFileSize: this.maxFileSize,
            fileNameValidations: this.field.validations,
          })
          .finally(() => {
            this.saving = false;
          });
      } else if (newVal.delete) {
        this.saving = true;
        this.$store
          .dispatch('deleteAttachment', {
            attachmentId: newVal.delete,
          })
          .finally(() => {
            this.saving = false;
          });
      } else if (newVal.rename) {
        this.saving = true;
        this.$store
          .dispatch('renameAttachment', {
            attachmentId: newVal.rename,
            name: newVal.name,
            maxFiles: this.schema().max,
            maxFileSize: this.maxFileSize,
            fileNameValidations: this.field.validations,
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
    schema() {
      return {
        type: 'attachments',
        max: this.field.max ? parseInt(this.field.max, 0) : undefined,
        maxFileSize: this.maxFileSize,
        showDetails: this.field.presentationControls.includes(
          PresentationControl.ShowDetails,
        ),
        allowFileNameEdit: this.allowFileNameEdit,
        fileNameValidations: this.field.validations,
      };
    },
  },
});
