import Vue from 'vue';
import VueFormGenerator from 'vue-form-generator';
import SaveStatus from '../SaveStatus.vue';
import DataItemDelete from '../actions/DataItemDelete.vue';
import {
  DataItem,
  DataItemStatus,
  Field,
  PresentationControl,
} from '@/models/form.model';
import FieldMarkdown from '@/components/pages/form/fields/FieldMarkdown.vue';

Vue.component('field-markdown', FieldMarkdown);

interface Data {
  saving: boolean;
  dataItemValue?: string;
  timeOfLatestSave?: number;
}
interface Methods {
  validators: () => ValidatorFunc[];
  pattern: () => void;
  isValid: ValidatorFunc;
  save: (value: string | undefined) => void;
  schema?: () => Schema;
  changed?: <T>(model: Model, newVal: T, oldVal?: T, field?: Schema) => void;
  errorMsgValidator: ValidatorFunc;
}

interface Computed {
  saved: boolean;
  touched: boolean;
  error: boolean;
  model: Model;
  schemaField: Schema;
  submitting: boolean;
  formData: unknown;
  readOnlyMarkdown: boolean;
}

interface Props {
  field: Field;
  dataItem: DataItem;
  readOnlyForm: boolean;
}

type ValidatorFunc = (value: string, field: Schema) => string[] | boolean;

/**
 * WARNING: This type is incomplete see the [Vue form generator documentation](https://vue-generators.gitbook.io/vue-generators/) for type information.
 */
export type Schema = {
  validator?: ValidatorFunc[];
};

type Model = { value?: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  props: { field: Object, dataItem: Object, readOnlyForm: Boolean },
  components: { SaveStatus, DataItemDelete },
  data() {
    return {
      saving: false,
    };
  },
  computed: {
    saved() {
      return this.dataItemValue === this.model.value;
    },
    touched() {
      return this.$props.dataItem.touched;
    },
    error() {
      return this.dataItem.status === 'InError';
    },
    model() {
      return { value: this.dataItemValue };
    },
    schemaField() {
      return {
        ...(this.schema ? this.schema() : {}),
        model: 'value',
        disabled:
          this.field.readOnly ||
          this.submitting ||
          this.readOnlyForm ||
          this.dataItem.readOnly,
        required:
          !this.dataItem.readOnly &&
          !this.readOnlyForm &&
          (this.field.required || this.dataItem.required),
        onChanged: this.changed,
        visible: this.field.visible,
        placeholder: this.field.presentationPlaceholder,
        help: this.field.presentationHint,
        id: `${this.field.code}_${this.dataItem.id}`,
        information: this.field.information,
        autocomplete: this.field.autocomplete,
      };
    },
    formData() {
      return {
        model: this.model,
        schema: {
          fields: [this.schemaField],
        },
        formState: {},
        formOptions: {
          validateAfterChanged: true,
          validateAfterLoad:
            this.dataItem.status === DataItemStatus.InError &&
            !!this.dataItem.errorMessage,
        },
      };
    },
    submitting() {
      return this.$store.getters.formIsSubmitting;
    },
    readOnlyMarkdown() {
      return (
        this.field.readOnly &&
        this.field.presentationControls.includes(
          PresentationControl.ReadOnlyMarkdown,
        )
      );
    },
  },
  methods: {
    validators() {
      return [
        ...this.field.validations.map((validator) =>
          VueFormGenerator.validators.regexp.locale({
            invalidFormat: validator.message,
          }),
        ),
        this.errorMsgValidator,
      ];
    },
    errorMsgValidator() {
      return this.dataItem.status === DataItemStatus.InError &&
        this.dataItem.errorMessage
        ? [this.dataItem.errorMessage]
        : [];
    },
    pattern() {
      const validator = this.field.validations.find((v) => v.regexPattern);
      if (validator) {
        return validator.regexPattern;
      }
      return null;
    },
    isValid(value, field) {
      if (value !== '' && field.validator) {
        return !field.validator
          .filter((validator) => validator !== this.errorMsgValidator)
          .some((validator) => {
            const validation = validator(value, field);
            return !(
              validation === undefined ||
              validation === true ||
              (Array.isArray(validation) && validation.length === 0)
            );
          });
      }
      return true;
    },
    save(value) {
      this.$store.commit('setUpdatingFromUi', false);
      this.saving = true;
      const timeOfLatestSave = new Date().getTime();
      this.timeOfLatestSave = timeOfLatestSave;
      this.$store
        .dispatch('saveDataItemValue', {
          id: this.dataItem.id,
          value,
        })
        .then((newForm) => {
          if (
            timeOfLatestSave === this.timeOfLatestSave &&
            !this.$store.state.form.updatingFromUi
          ) {
            this.$store.commit('setForm', newForm);
          }
        })
        .then(() => {
          this.saving = false;
        });
    },
  },
});
