












import Vue from 'vue';
import { Column, RowViewModel } from '@/components/table/model/table.model';
import ExpandRowAction from '@/components/table/actions/ExpandRowAction.vue';
import EmailDetails from '@/components/pages/EmailDetails.vue';
import { Email } from '@/models/entity.model';
import { columnValue } from '@/helpers/table';
import logger from '@/logger';

type Data = {};

type Computed = {
  email?: Email;
};

type Methods = {};

type Props = {
  rowViewModel: RowViewModel<unknown>;
  viewPath: (row: RowViewModel<unknown>) => string;
  column: Column;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ShowEmailAction',
  components: { EmailDetails, ExpandRowAction },
  props: { rowViewModel: Object, viewPath: Function, column: Object },
  computed: {
    email() {
      const emailJson = columnValue(
        this.rowViewModel.row.columns,
        this.column.key,
      );
      if (emailJson) {
        try {
          return JSON.parse(emailJson);
        } catch (e) {
          logger.error(e);
        }
      }
      return undefined;
    },
  },
});
