<template>
  <button
    class="icon"
    @click="(event) => $emit('click', event)"
    @keydown="(event) => $emit('keydown', event)"
    ref="button"
    type="button"
    :title="title"
    :aria-label="ariaLabel || title"
  >
    <slot name="before" />
    <slot v-if="hasIconSlot" name="icon" />
    <rpl-icon
      v-else
      :symbol="symbol"
      :size="size"
      :color="color"
      :class="className"
    />
    <slot name="after" />
  </button>
</template>

<script>
import RplIcon from '@dpc-sdp/ripple-icon';

export default {
  name: 'IconButton',
  components: { RplIcon },
  props: {
    symbol: String,
    size: String,
    color: String,
    className: String,
    title: String,
    ariaLabel: String,
  },
  computed: {
    hasIconSlot() {
      return !!this.$slots.icon;
    },
  },
  methods: {
    focus() {
      this.$refs.button.focus();
    },
    hasFocus() {
      return document.activeElement === this.$refs.button;
    },
  },
};
</script>

<style lang="scss" scoped>
button.icon {
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;

  &:focus-visible {
    outline: var(--primary-lighter) auto 1px;
    outline-offset: 4px;
  }
}
</style>
