


























import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import Confirm from '@/components/Confirm.vue';
import IconButton from '@/components/IconButton.vue';
import logger from '@/logger';
import { Column, RowViewModel } from '@/components/table/model/table.model';
import { columnValue } from '@/helpers/table';

type Data = { confirmDelete: boolean; working: boolean };
type Computed = { ariaLabel: string };
interface Methods {
  onClick: (event: Event) => void;
  onCancel: () => void;
  onConfirm: () => void;
}
type Props = { rowViewModel: RowViewModel<unknown>; column: Column };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DeleteAction',
  components: { Confirm, IconButton, RplIcon },
  props: { rowViewModel: Object, column: Object },
  data() {
    return {
      confirmDelete: false,
      working: false,
    };
  },
  computed: {
    ariaLabel() {
      const rowType = columnValue(
        this.rowViewModel.row.columns,
        this.column.key,
      );
      return `Delete ${rowType || ''} ${this.rowViewModel.row.id}`;
    },
  },
  methods: {
    onClick(event: Event) {
      event.stopPropagation();
      this.confirmDelete = true;
    },
    onConfirm() {
      logger.info(`Deleting ${this.rowViewModel.row.id}`);
      this.working = true;
      this.$store
        .dispatch('deleteForm', this.rowViewModel.row.id)
        .finally(() => {
          this.working = false;
        });
      this.confirmDelete = false;
    },
    onCancel() {
      this.confirmDelete = false;
    },
  },
});
