


































import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import Confirm from '@/components/Confirm.vue';
import IconButton from '@/components/IconButton.vue';

import { DataItem, Field, PresentationControl } from '@/models/form.model';

type Data = { confirmDelete: boolean; working: boolean };
type Computed = { isCapsule: boolean };
interface Methods {
  onClick: (event: Event) => void;
  onCancel: () => void;
  onConfirm: () => void;
  deletable: () => boolean;
}
type Props = {
  field: Field;
  dataItem: DataItem | undefined;
  readOnlyForm?: boolean;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DataItemDelete',
  components: { RplIcon, Confirm, IconButton },
  props: { field: Object, dataItem: Object, readOnlyForm: Boolean },
  data() {
    return {
      confirmDelete: false,
      working: false,
    };
  },
  computed: {
    isCapsule() {
      return this.field.presentationControls.includes(
        PresentationControl.Capsule,
      );
    },
  },
  methods: {
    onClick(event: Event) {
      event.stopPropagation();
      if (!this.working) {
        this.confirmDelete = true;
      }
    },
    onConfirm() {
      this.working = true;
      this.$store
        .dispatch('deleteDataItem', { dataItem: this.dataItem })
        .then((form) => this.$store.commit('setForm', form))
        .finally(() => {
          this.working = false;
        });
      this.confirmDelete = false;
    },
    onCancel() {
      this.confirmDelete = false;
    },
    deletable() {
      return this.$props.dataItem && this.field.repeating;
    },
  },
});
