












import { abstractField } from 'vue-form-generator';
import { RplTextIcon } from '@dpc-sdp/ripple-icon';
import Vue from 'vue';

type Props = {};

type Data = {};

type Computed = { schema: { clearHandler: () => void } };

interface Methods {
  onClear: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Clearform',
  mixins: [abstractField],
  components: { RplTextIcon },
  methods: {
    onClear() {
      this.schema.clearHandler();
    },
  },
});
