









import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import { Column, RowViewModel } from '@/components/table/model/table.model';
import { columnValue } from '@/helpers/table';

type Data = {};
type Computed = { show: boolean; value: string };
type Methods = {};
type Props = { rowViewModel: RowViewModel<unknown>; column: Column };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'BooleanCell',
  components: { RplIcon },
  props: { rowViewModel: Object, column: Object },
  computed: {
    show() {
      return this.value === 'true';
    },
    value() {
      return columnValue(this.rowViewModel.row.columns, this.column.key) || '';
    },
  },
});
