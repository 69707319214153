




import Vue from 'vue';
import AppHubAccordion from '@/components/ripple/AppHubAccordion.vue';
import { Field, PresentationControl } from '@/models/form.model';

type Data = {};
type Computed = {
  accordions: { title: string; content: string };
  single: boolean;
  type: string;
};
type Methods = {};
type Props = { field: Field };

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { AppHubAccordion },
  name: 'AccordionField',
  props: { field: Object },
  computed: {
    accordions() {
      try {
        if (this.field.label) {
          return JSON.parse(this.field.label);
        }
      } catch (e) {
        console.error(
          `Field with id: ${this.field.id} does not contain a valid json label`,
        );
      }
      return [];
    },
    single() {
      return this.field.presentationControls.includes(
        PresentationControl.Single,
      );
    },
    type() {
      return this.field.presentationControls.includes(
        PresentationControl.Numbered,
      )
        ? 'numbered'
        : 'default';
    },
  },
});
