














































import Vue from 'vue';
import Button from '@/components/ripple/Button.vue';
import StageFields from './StageFields.vue';
import { hasData, getData } from '@/api/data';
import { FormType, StageType } from '@/models/form.model';
import ResetForm from '@/components/pages/form/ResetForm.vue';

type Data = {};
type Methods = {
  next: () => void;
  prev: () => void;
  submit: () => void;
  scrollToTop: () => void;
};
type Computed = {
  formType?: FormType;
  stageType?: StageType;
  hasNext: boolean;
  hasPrev: boolean;
  canSubmit: boolean;
  submitting: boolean;
  submitNotes?: string;
  readOnlyForm: boolean;
  canReset: boolean;
  submitButtonLabel?: string;
  inline: boolean;
};
type Props = { stageTypeId: string; layout?: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FormStage',
  components: {
    ResetForm,
    Button,
    StageFields,
  },
  props: { stageTypeId: String, layout: String },
  computed: {
    formType() {
      if (hasData(this.$store.state.formType.formTypeFromApi)) {
        return getData(this.$store.state.formType.formTypeFromApi);
      }
      return undefined;
    },
    stageType() {
      return this.$store.state.form.stageType;
    },
    hasNext() {
      return this.$store.getters.hasNextStage;
    },
    hasPrev() {
      return this.$store.getters.hasPrevStage;
    },
    canSubmit() {
      return this.$store.getters.canSubmit;
    },
    submitting() {
      return this.$store.getters.formIsSubmitting;
    },
    submitNotes() {
      return this.$store.getters.tenantSettings.formSubmitNotes;
    },
    readOnlyForm() {
      return this.$store.getters.readOnlyForm;
    },
    canReset() {
      return !!this.formType?.canReset;
    },
    submitButtonLabel() {
      return this.$store.getters.formTypeSettings?.submitButtonLabel;
    },
    inline() {
      return this.layout === 'inline';
    },
  },
  methods: {
    next() {
      const nextStageType = this.$store.getters.getNextStage;
      this.$router.push({
        name: this.$route.meta?.stageRouteName,
        params: { stageTypeId: nextStageType.id },
      });
    },
    prev() {
      const prevStageType = this.$store.getters.getPrevStage;
      this.$router.push({
        name: this.$route.meta?.stageRouteName,
        params: { stageTypeId: prevStageType.id },
      });
    },
    submit() {
      if (!this.inline) {
        this.scrollToTop();
      }
      this.$store.dispatch('submitForm');
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    if (this.formType) {
      this.$store.commit(
        'setStageType',
        this.formType.stages.find(
          (stageType) => stageType.id === this.stageTypeId,
        ),
      );
    }
  },
  watch: {
    $route() {
      if (this.formType) {
        this.$store.commit(
          'setStageType',
          this.formType.stages.find(
            (stageType) => stageType.id === this.stageTypeId,
          ),
        );
      }
      this.$nextTick(() => {
        const fields = (this.$refs.fields as Vue)?.$el;
        if (fields) {
          const firstFocusable = fields.querySelector(
            'input, select, textarea, [tabindex]:not([tabindex="-1"])',
          ) as HTMLElement;
          if (firstFocusable) {
            firstFocusable.focus();
          }
        }
      });
    },
  },
});
