































import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { FormJob, JobStatus } from '@/models/form.model';
import Markdown from '@/components/markdown/Markdown.vue';
import Button from '@/components/ripple/Button.vue';

type Data = {
  loading: boolean;
};
type Computed = {
  complete: boolean;
  error: boolean;
};
type Methods = {
  onContinue: () => void;
};
type Props = { job: FormJob };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ProcessingOverlay',
  props: { job: Object },
  components: { Button, Markdown, RplIcon },
  data() {
    return { loading: false };
  },
  computed: {
    complete() {
      return this.job.status === JobStatus.Complete;
    },
    error() {
      return this.job.status === JobStatus.Error;
    },
  },
  methods: {
    onContinue() {
      this.loading = true;
      this.$emit('continue');
    },
  },
});
