import { render, staticRenderFns } from "./FormButton.vue?vue&type=template&id=424d4a80&scoped=true&"
import script from "./FormButton.vue?vue&type=script&lang=js&"
export * from "./FormButton.vue?vue&type=script&lang=js&"
import style0 from "./FormButton.vue?vue&type=style&index=0&id=424d4a80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "424d4a80",
  null
  
)

export default component.exports