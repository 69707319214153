





































































import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import Button from '@/components/ripple/Button.vue';

import { getData, hasData, isApiError } from '@/api/data';
import { TableGroupViewModel } from '@/components/table/model/table.model';
import DashboardGroupTables from '@/components/pages/DashboardGroupTables.vue';
import { RouterLink } from '@/models/site.model';
import { FormDefinitions, FormTemplate } from '@/models/form.model';
import { applicationHubClient } from '@/api';

type Data = {
  initialFilterTerm: string | undefined;
  showQuickCreate: boolean;
};

interface Computed {
  hasDraftsData: boolean;
  isDraftsLoading: boolean;
  hasEntitiesData: boolean;
  isEntitiesLoading: boolean;
  isDraftsError: boolean;
  isEntitiesError: boolean;
  tableViewModels: TableGroupViewModel<unknown>[];
  isEmpty: boolean;
  tableGroupViewModels: TableGroupViewModel<unknown>[];
  hideDraftsTable: boolean;
  createFormRouterLink: RouterLink;
}

interface Methods {
  onCreateNew: () => void;
  canAccessTemplate: (
    formDefinitions: FormDefinitions,
    code: string,
  ) => Promise<boolean>;
  canAccessChildTemplate: (
    formDefinitions: FormDefinitions,
    code: string,
    index: number,
  ) => Promise<boolean>;
}

type Props = unknown;

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Dashboard',
  components: {
    Button,
    RplIcon,
    DashboardGroupTables,
  },
  data() {
    return {
      initialFilterTerm: this.$route.query.filter?.toString(),
      showQuickCreate: false,
    };
  },
  computed: {
    tableGroupViewModels() {
      return this.$store.getters.getTableGroupViewModels;
    },
    hideDraftsTable() {
      return this.$store.getters.tenantSettings.hideDraftsTable;
    },
    hasDraftsData() {
      return this.$store.getters.hasDraftsData;
    },
    isDraftsLoading() {
      return this.hideDraftsTable ? false : this.$store.getters.isDraftsLoading;
    },
    hasEntitiesData() {
      return this.$store.getters.hasEntitiesData;
    },
    isEntitiesLoading() {
      return this.$store.getters.isEntitiesLoading;
    },
    isDraftsError() {
      return isApiError(this.$store.state.dashboard.draftsFromApi);
    },
    isEntitiesError() {
      return isApiError(this.$store.state.dashboard.entitiesFromApi);
    },
    tableViewModels() {
      return this.$store.getters.getDashboardTableViewModels;
    },
    isEmpty() {
      if (this.hideDraftsTable) {
        return this.hasEntitiesData && this.tableViewModels.length === 0;
      }
      return (
        this.hasDraftsData &&
        this.hasEntitiesData &&
        this.tableViewModels.length === 0
      );
    },
    createFormRouterLink() {
      return this.$store.getters.createFormRouterLink;
    },
  },
  async created() {
    this.$store.dispatch('loadDashboard', this.hideDraftsTable);
    this.$store.commit('setHeaderText', 'My dashboard');

    if (this.$store.getters.tenantSettings.defaultCreateForm) {
      const { code } = this.$store.getters.tenantSettings.defaultCreateForm;
      const client = await applicationHubClient(this.$store.getters.tenantId);
      const response = await client.getRootFormDefinitions();
      if (hasData(response)) {
        this.showQuickCreate = await this.canAccessTemplate(
          getData(response),
          code,
        );
      }
    } else {
      this.showQuickCreate = true;
    }
  },
  methods: {
    onCreateNew() {
      this.$router.push(this.createFormRouterLink.route);
    },
    async canAccessTemplate(formDefinitions, code) {
      const codeFound = formDefinitions.formTemplates.some(
        (formTemplate: FormTemplate) => formTemplate.code === code,
      );
      return codeFound || this.canAccessChildTemplate(formDefinitions, code, 0);
    },
    async canAccessChildTemplate(formDefinitions, code, index) {
      if (index < formDefinitions.formCategories.length) {
        const client = await applicationHubClient(this.$store.getters.tenantId);
        const response = await client.getFormCategory(
          formDefinitions.formCategories[index].id,
        );
        if (hasData(response)) {
          const foundTemplate = await this.canAccessTemplate(
            getData(response),
            code,
          );
          return (
            foundTemplate ||
            this.canAccessChildTemplate(formDefinitions, code, index + 1)
          );
        }
      }
      return false;
    },
  },
});
