<template>
  <div>
    <Attachments
      :attachments="attachments"
      @files-selected="onFilesSelected"
      @file-delete="onFileDelete"
      @file-rename="onFileRename"
      :disabled="disabled"
      :maxFiles="schema.max"
      :maxFileSize="schema.maxFileSize"
      :show-details="true"
      :edit-file-name="schema.allowFileNameEdit"
      :file-name-validations="schema.fileNameValidations"
    />
  </div>
</template>

<script>
// Adapts Attachments.vue for use within vue-form-generator

import { abstractField } from 'vue-form-generator';
import Attachments from '../Attachments.vue';

export default {
  components: { Attachments },
  mixins: [abstractField],
  computed: {
    attachments() {
      return this.value && this.value.attachments ? this.value.attachments : [];
    },
  },
  methods: {
    onFilesSelected(files) {
      this.value = { files, attachments: this.value.attachments };
    },
    onFileDelete(id) {
      this.value = { attachments: this.value.attachments, delete: id };
    },
    onFileRename(args) {
      this.value = {
        attachments: this.value.attachments,
        rename: args.id,
        name: args.name,
      };
    },
  },
};
</script>
