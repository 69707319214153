/* This is a copy of the fieldRplselect component, needed because of a
requirement for flexible text truncation */
<template>
  <app-hub-select
    :config="config"
    :values="schema.values"
    :value="value"
    :disabled="disabled"
    @update="onUpdate"
  />
</template>
<script>
import { abstractField } from 'vue-form-generator';
import AppHubSelect from '@/components/ripple/AppHubSelect.vue';

export default {
  name: 'FieldSelect',
  mixins: [abstractField],
  components: {
    AppHubSelect,
  },
  watch: {
    value(val) {
      if (val === null || val === undefined) {
        if (this.schema.multiselect) {
          this.value = [];
        } else {
          this.value = '';
        }
      }
      // fixes issue with errors not clearing after selecting new val
      if (val && this.schema.multiselect && val.length > 0) {
        if (this.errors && this.errors.length > 0 && this.vfg) {
          this.vfg.clearValidationErrors();
        }
      }
    },
  },
  created() {
    if (!this.value) {
      if (this.schema.multiselect) {
        this.value = [];
      } else {
        this.value = '';
      }
    }
  },
  methods: {
    onUpdate(val) {
      this.value = val;
    },
  },
  computed: {
    config() {
      return {
        ...this.schema,
        fieldId: this.getFieldID(this.schema),
      };
    },
  },
};
</script>
