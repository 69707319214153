











import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import Vue from 'vue';
import Markdown from '@/components/markdown/Markdown.vue';
import { getData, hasData } from '@/api/data';
import { NoticeDetails } from '@/models/site.model';
import logger from '@/logger';

type Data = {};
type Props = {};
interface Computed {
  notice?: string;
  isLoggedIn: boolean;
}
interface Methods {
  onClose: () => void;
  initialise: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Notice',
  components: { RplAlertBase, Markdown },
  computed: {
    notice() {
      if (hasData(this.$store.state.notice.noticeFromApi)) {
        const noticeDetails: NoticeDetails = getData(
          this.$store.state.notice.noticeFromApi,
        );
        if (noticeDetails) {
          return noticeDetails.notice;
        }
      }
      return undefined;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    onClose() {
      const noticeDetails: NoticeDetails = getData(
        this.$store.state.notice.noticeFromApi,
      );
      if (noticeDetails) {
        this.$store.dispatch('updateLastReadNoticeAt', noticeDetails.noticeAt);
      }
    },
    initialise() {
      this.$store.dispatch('getNotice');
      const { tenantSettings } = this.$store.getters;
      if (tenantSettings.pollForNotice) {
        logger.debug(
          `Started polling for notices, interval : ${tenantSettings.noticePollInterval}`,
        );
        setInterval(
          () => this.$store.dispatch('getNotice'),
          tenantSettings.noticePollInterval,
        );
      }
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      this.initialise();
    }
  },
  watch: {
    isLoggedIn(loggedIn) {
      if (loggedIn) {
        this.initialise();
      }
    },
  },
});
