


















import Vue from 'vue';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { BreadcrumbKey } from '@/models/site.model';
import { getData, hasData, LoadedData } from '@/api/data';
import { CreatedForm } from '@/models/form.model';

type Data = { formId?: string };
type Computed = {};
type Methods = { onBack: (evt: MouseEvent) => void };
type Props = { groupId: string; formCode: string; pathBack?: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ManageGroupDetailsForm',
  components: { RplTextLink, RplIcon },
  props: {
    groupId: String,
    formCode: String,
    pathBack: String,
  },
  data() {
    return { formId: undefined };
  },
  methods: {
    onBack(evt) {
      if (this.pathBack?.startsWith('/')) {
        evt.stopPropagation();
        evt.preventDefault();
        this.$router.push(this.pathBack);
      }
    },
  },
  created() {
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Group,
      breadcrumbs: {
        text: 'Manage groups',
        url: this.pathBack,
      },
    });
    this.$store
      .dispatch('createFormFromTemplate', {
        templateCode: this.formCode,
        initialValues: {
          groupId: this.groupId,
        },
      })
      .then((response: LoadedData<CreatedForm>) => {
        if (hasData(response)) {
          const formId = getData(response).id;
          const resolvedRoute = this.$router.resolve({
            name: this.$route.params.stageTypeId
              ? 'groupDetailsFormStage'
              : 'groupDetailsForm',
            params: { ...this.$route.params, formId },
            query: this.$route.query,
          });
          if (resolvedRoute.route.path !== this.$route.path) {
            this.$router.replace(resolvedRoute.location);
          }
          this.$nextTick(() => {
            this.formId = formId;
          });
        }
      });
  },
});
