



















import Vue from 'vue';
import DashboardTable from './DashboardTable.vue';
import {
  TableViewModel,
  TabViewModel,
} from '@/components/table/model/table.model';
import AppHubTabs from '@/components/ripple/AppHubTabs.vue';

type Data = { activeTab: string; filterTerm?: string };
type Props = { tables: TableViewModel<unknown>[] };
interface Methods {
  switchTab: (tab: string) => void;
  onFilter: (filterTerm: string) => void;
}
interface Computed {
  activeTable?: TableViewModel<unknown>;
  tabs: TabViewModel[];
}
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DashboardGroupTables',
  props: {
    tables: Array,
  },
  components: {
    AppHubTabs,
    DashboardTable,
  },
  data() {
    return {
      activeTab: this.tables[0].config.label,
      filterTerm: undefined,
    };
  },
  computed: {
    activeTable() {
      return this.tables.find(
        (table: TableViewModel<unknown>) =>
          table.config.label === this.activeTab,
      );
    },
    tabs() {
      return this.tables.map((table) => ({
        label: table.config.label,
        key: table.config.label,
      }));
    },
  },
  methods: {
    switchTab(tab: string) {
      this.activeTab = tab;
    },
    onFilter(filterTerm) {
      this.filterTerm = filterTerm || undefined;
    },
  },
});
