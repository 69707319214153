






























































































































































import { RplDocumentLink } from '@dpc-sdp/ripple-document-link';
import RplIcon from '@dpc-sdp/ripple-icon';
import Vue from 'vue';
import Confirm from '@/components/Confirm.vue';
import {
  Attachment,
  AttachmentStatus,
  formatBytes,
  Validation,
} from '@/models/form.model';
import IconButton from '@/components/IconButton.vue';
import EditIcon from '@/assets/pen-to-square-regular.svg?component';
import { formatDateTime } from '@/helpers/date';
import { getFormAttachmentDownloadURL } from '@/api/download';
import PopoverToggle from '@/components/PopoverToggle.vue';

type Data = {
  confirmRemove: boolean;
  confirmCancel: boolean;
  editName: boolean;
  renameError: boolean;
  modifiedName?: string;
  downloadUrl: string;
  errorMessage?: string;
};

type Computed = {
  preparing: boolean;
  uploading: boolean;
  complete: boolean;
  processing: boolean;
  deleting: boolean;
  error: boolean;
  infected: boolean;
  fileSizeString: string;
  progress: number;
  fileNameForEdit: string;
  canEdit: boolean;
  uploadedOn?: string;
  canDelete: boolean;
  withDetails: boolean;
  canClear: boolean;
  draft: boolean;
};

type Methods = {
  onFileRemove: () => void;
  onCancelUpload: () => void;
  onCancelRemove: () => void;
  onCancelCancelUpload: () => void;
  onConfirmRemove: () => void;
  onConfirmCancelUpload: () => void;
  onFileClear: () => void;
  onEditName: () => void;
  onRename: () => void;
  onClick: () => void;
  onCancelRename: () => void;
};

type Props = {
  attachment: Attachment;
  showDetails: boolean;
  editFileName: boolean;
  fileNameValidations: Validation[];
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Attachment',
  components: {
    PopoverToggle,
    IconButton,
    RplDocumentLink,
    RplIcon,
    Confirm,
    EditIcon,
  },
  props: {
    attachment: Object,
    showDetails: Boolean,
    editFileName: Boolean,
    fileNameValidations: Array,
  },
  data() {
    return {
      confirmRemove: false,
      confirmCancel: false,
      editName: false,
      renameError: false,
      modifiedName: undefined,
      downloadUrl: '',
      errorMessage: undefined,
    };
  },
  computed: {
    preparing() {
      return this.attachment.status === AttachmentStatus.Preparing;
    },
    uploading() {
      return this.attachment.status === AttachmentStatus.Uploading;
    },
    complete() {
      return this.attachment.status === AttachmentStatus.Complete;
    },
    processing() {
      return this.attachment.status === AttachmentStatus.Processing;
    },
    deleting() {
      return this.attachment.status === AttachmentStatus.Deleting;
    },
    error() {
      return (
        this.attachment.status === AttachmentStatus.Error ||
        !!this.attachment.nameError
      );
    },
    infected() {
      return this.attachment.status === AttachmentStatus.Infected;
    },
    fileSizeString() {
      return formatBytes(this.attachment.fileSize);
    },
    progress() {
      const progress =
        (this.attachment.progress || 0 / this.attachment.fileSize) * 100;
      return progress < 100 ? progress : 100;
    },
    fileNameForEdit() {
      const endIndex = this.attachment.fileName.lastIndexOf('.');
      return endIndex > 0
        ? this.attachment.fileName.substring(0, endIndex)
        : this.attachment.fileName;
    },
    canEdit() {
      return (
        this.draft &&
        this.editFileName &&
        (this.complete || !!this.attachment.nameError)
      );
    },
    uploadedOn() {
      return this.attachment.uploadedOn
        ? formatDateTime(this.attachment.uploadedOn)
        : undefined;
    },
    canDelete() {
      return (this.complete && this.draft) || this.infected;
    },
    withDetails() {
      return this.complete && this.showDetails;
    },
    canClear() {
      return !this.attachment.uploadedOn;
    },
    draft() {
      return this.attachment.draft === undefined || this.attachment.draft;
    },
  },
  methods: {
    onFileRemove() {
      this.confirmRemove = true;
    },
    onCancelUpload() {
      this.confirmCancel = true;
    },
    onCancelRemove() {
      this.confirmRemove = false;
    },
    onCancelCancelUpload() {
      this.confirmCancel = false;
    },
    onConfirmRemove() {
      this.$emit('file-delete', this.attachment.id);
      this.confirmRemove = false;
    },
    onConfirmCancelUpload() {
      this.$emit('file-delete', this.attachment.id);
      this.confirmCancel = false;
    },
    onFileClear() {
      this.$emit('file-delete', this.attachment.id);
    },
    onEditName() {
      if (this.canEdit) {
        this.modifiedName = this.fileNameForEdit;
        this.renameError = false;
        this.editName = true;
      }
    },
    onRename() {
      let name = (this.modifiedName || '').trim();
      if (name) {
        const extension = this.attachment.extension
          ? `.${this.attachment.extension}`
          : '';
        name = name.endsWith(extension) ? name : `${name}${extension}`;
        if (this.fileNameValidations) {
          const firstInvalidRule = this.fileNameValidations.find(
            (validation) =>
              validation.regexPattern &&
              !new RegExp(validation.regexPattern).test(name),
          );
          if (firstInvalidRule) {
            this.renameError = true;
            this.errorMessage = firstInvalidRule.message;
            return;
          }
        }
        this.$emit('file-rename', {
          id: this.attachment.id,
          name,
        });
        this.renameError = true;
        this.errorMessage = undefined;
        this.editName = false;
      } else {
        this.renameError = true;
        this.errorMessage = 'Required';
      }
    },
    async onClick() {
      if (this.complete) {
        await this.$store.dispatch(
          'getAuthCookie',
          this.$store.getters.tenantId,
        );
        const link = document.createElement('a');
        link.href = this.downloadUrl;
        link.setAttribute('download', this.attachment.fileName);
        link.click();
        link.remove();
      }
    },
    onCancelRename() {
      this.errorMessage = undefined;
      this.editName = false;
    },
  },
  watch: {
    editName() {
      this.$nextTick(() => {
        if (this.editName && this.$refs.rename) {
          (this.$refs.rename as HTMLInputElement).focus();
        }
      });
    },
  },
  async mounted() {
    this.downloadUrl = await getFormAttachmentDownloadURL(
      this.$store.getters.tenantId,
      this.attachment,
    );
  },
});
