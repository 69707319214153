import { ActionContext, Module } from 'vuex';
import { RootState } from '../types';
import { TenantState } from '@/store/modules/types';
import {
  ApiNeverLoaded,
  DataFromApi,
  getData,
  getErrorMessage,
  hasData,
  isApiError,
} from '@/api/data';
import { Tenant, TenantConfig, Theme } from '@/models/site.model';
import { recordApiCall } from '@/store/modules/apiCalls';
import { defaultBannerBackground, defaultLogo } from '@/themes';

const tenant: Module<TenantState, RootState> = {
  state: () => ({
    tenantFromApi: ApiNeverLoaded,
    tenantId: '',
    theme: { bannerImage: defaultBannerBackground },
    settings: {
      groupManagement: true,
      pollForNotice: false,
      noticePollInterval: 60000,
      documentAttributes: [],
      hideDraftsTable: false,
    },
  }),
  mutations: {
    setTenant(state, tenantResponse: DataFromApi<Tenant>) {
      state.tenantFromApi = tenantResponse;
      if (hasData(tenantResponse)) {
        const tnt = getData(tenantResponse);
        state.theme = { ...state.theme, ...tnt.theme };
        state.theme.logo = Object.assign(defaultLogo, tnt.theme?.logo);

        if (tnt.settings) {
          state.settings = Object.assign(state.settings, tnt.settings);
        }
      }
    },
    setTenantId(state, tenantId: string) {
      state.tenantId = tenantId;
    },
    setTheme(state, theme: Theme) {
      state.theme = theme;
    },
  },
  getters: {
    tenantId(state: TenantState): string {
      return state.tenantId;
    },
    tenantName(state: TenantState): string {
      if (hasData(state.tenantFromApi)) {
        return getData(state.tenantFromApi).name;
      }
      return isApiError(state.tenantFromApi)
        ? getErrorMessage(state.tenantFromApi)
        : '';
    },
    tenantBlurb(state: TenantState): string | undefined {
      return hasData(state.tenantFromApi)
        ? getData(state.tenantFromApi).blurb
        : '';
    },
    tenantBlurbBottom(state: TenantState): string | undefined {
      return hasData(state.tenantFromApi)
        ? getData(state.tenantFromApi).blurbBottom
        : '';
    },
    tenantTheme(state: TenantState): Theme | undefined {
      return state.theme;
    },
    tenantSettings(state: TenantState): TenantConfig {
      return state.settings;
    },
  },
  actions: {
    loadTenant(
      { commit, rootGetters, rootState }: ActionContext<TenantState, RootState>,
      tenantId: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getTenant',
        (client) => client.getTenant(tenantId),
      ).then((response) => {
        commit('setTenant', response);
      });
    },
    getAuthCookie(
      { commit, rootGetters, rootState }: ActionContext<TenantState, RootState>,
      tenantId: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getAuthCookie',
        (client) => client.getAuthCookie(tenantId),
      );
    },
  },
};

export default tenant;
