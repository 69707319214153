














import Vue from 'vue';
import Button from '@/components/ripple/Button.vue';
import Confirm from '@/components/Confirm.vue';

type Data = {
  showConfirm: boolean;
};

type Computed = {};

type Methods = {
  onReset: () => void;
  onConfirm: () => void;
  onCancel: () => void;
};

type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ResetForm',
  components: {
    Confirm,
    Button,
  },
  data() {
    return { showConfirm: false };
  },
  methods: {
    onReset() {
      this.showConfirm = true;
    },
    onConfirm() {
      this.showConfirm = false;
      this.$store.dispatch('resetForm');
    },
    onCancel() {
      this.showConfirm = false;
    },
  },
});
