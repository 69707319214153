const contactPlanningSupport = {
  text: 'Contact planning support',
  children: [
    {
      text: '1800 789 386 (select option 1)',
      url: 'tel:1800789386',
    },
    {
      text: 'planning.support@transport.vic.gov.au',
      url: 'mailto:planning.support@transport.vic.gov.au',
    },
    {
      text: 'Planning general enquiries',
      url: 'https://www.planning.vic.gov.au/contact-planning',
    },
  ],
};

const nationalRelay = {
  text: 'Deaf, hearing or speech impaired?',
  children: [
    {
      text: 'National Relay Service: 133 677',
      url: 'tel:133677',
    },
    {
      text: 'www.relayservice.gov.au',
      url: 'http://www.relayservice.gov.au',
    },
  ],
};

export const tenantDetails = {
  default: {
    contactUs: contactPlanningSupport,
    navNationalRelay: nationalRelay,
    links: [
      {
        text: 'Accessibility',
        url: 'https://www.vic.gov.au/accessibility',
      },
      {
        text: 'Copyright',
        url: 'https://www.vic.gov.au/copyright-1',
      },
      {
        text: 'Disclaimer',
        url: 'https://www.vic.gov.au/disclaimer',
      },
      {
        text: 'Privacy',
        url: 'https://www.vic.gov.au/privacy-vicgovau',
      },
    ],
  },
  hv: {
    contactUs: contactPlanningSupport,
    navNationalRelay: nationalRelay,
    about: {
      text: 'Heritage services',
      children: [
        {
          text: 'Heritage listings',
          url: 'https://www.heritage.vic.gov.au/heritage-listings',
        },
        {
          text: 'Protecting our heritage',
          url: 'https://www.heritage.vic.gov.au/protecting-our-heritage',
        },
        {
          text: 'Heritage grants',
          url: 'https://www.heritage.vic.gov.au/heritage-grants',
        },
        {
          text: 'Permits and approvals',
          url: 'https://www.heritage.vic.gov.au/permits-and-approvals',
        },
        {
          text: 'Resources and publications',
          url: 'https://www.heritage.vic.gov.au/resource-library',
        },
        {
          text: 'About Heritage Victoria',
          url: 'https://www.heritage.vic.gov.au/about-us',
        },
      ],
    },
    links: [
      {
        text: 'Accessibility',
        url: 'https://www.vic.gov.au/accessibility',
      },
      {
        text: 'Copyright',
        url: 'https://www.vic.gov.au/copyright-1',
      },
      {
        text: 'Disclaimer',
        url: 'https://www.vic.gov.au/disclaimer',
      },
      {
        text: 'Privacy',
        url: 'https://www.vic.gov.au/privacy-vicgovau',
      },
    ],
  },
  hc: {
    contactUs: contactPlanningSupport,
    navNationalRelay: nationalRelay,
    aboutUs: {
      text: 'About Heritage Council',
      children: [
        {
          text: 'Contact Us',
          url: 'https://heritagecouncil.vic.gov.au/contact-us/',
        },
        {
          text: 'What We Do',
          url: 'https://heritagecouncil.vic.gov.au/about-heritage-council/what-we-do/',
        },
        {
          text: 'Member Profiles',
          url: 'https://heritagecouncil.vic.gov.au/about-heritage-council/heritage-council/',
        },
        {
          text: 'Committees',
          url: 'https://heritagecouncil.vic.gov.au/about-heritage-council/committees/',
        },
        {
          text: 'Strategic Plan',
          url: 'https://heritagecouncil.vic.gov.au/about-heritage-council/strategic-plan/',
        },
        {
          text: 'Annual Reports and Corporate Plans',
          url: 'https://heritagecouncil.vic.gov.au/about-heritage-council/annual-reports/',
        },

        {
          text: 'Awards and Partnerships',
          url: 'https://heritagecouncil.vic.gov.au/about-heritage-council/awards-and-partnerships/',
        },
        {
          text: 'Public Interest Disclosures',
          url: 'https://heritagecouncil.vic.gov.au/about-heritage-council/public-interest-disclosures/',
        },
        {
          text: 'Secretariat',
          url: 'https://heritagecouncil.vic.gov.au/about-heritage-council/secretariat/',
        },
        {
          text: 'Policies',
          url: 'https://heritagecouncil.vic.gov.au/about-heritage-council/policies/',
        },
      ],
    },
    links: [
      {
        text: '© 2022 Heritage Council of Victoria',
        url: 'https://heritagecouncil.vic.gov.au/',
      },
      {
        text: 'State Government of Victoria',
        url: 'http://www.vic.gov.au/',
      },
      {
        text: 'Contact Us',
        url: 'https://heritagecouncil.vic.gov.au/contact-us/',
      },
      {
        text: 'Copyright',
        url: 'https://heritagecouncil.vic.gov.au/copyright/',
      },
      {
        text: 'Disclaimer',
        url: 'https://heritagecouncil.vic.gov.au/disclaimer/',
      },
      {
        text: 'Privacy Statement',
        url: 'https://heritagecouncil.vic.gov.au/privacy-statement/',
      },
      {
        text: 'Accessibility',
        url: 'https://heritagecouncil.vic.gov.au/accessibility/',
      },
    ],
  },
};
