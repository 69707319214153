







import RplTabs from '@dpc-sdp/ripple-tabs';
import Vue from 'vue';

type Data = {};
type Computed = {};
type Methods = {};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'AppHubTabs',
  components: {
    RplTabs,
  },
  props: {
    tabs: Array,
    activeTab: String,
  },
});
