import { RouteConfig } from 'vue-router';
import Login from '../components/pages/Login.vue';
import Logout from '../components/pages/Logout.vue';
import Dashboard from '../components/pages/Dashboard.vue';
import Form from '../components/pages/Form.vue';
import FormCategory from '../components/pages/FormCategory.vue';
import FormStage from '../components/pages/form/FormStage.vue';
import LoggedOut from '../components/pages/LoggedOut.vue';
import NotFound from '../components/pages/NotFound.vue';
import Entity from '../components/pages/Entity.vue';
import RootFormDefinitions from '../components/pages/RootFormDefinitions.vue';
import Tenant from '../components/TenantWrapper.vue';
import { authenticatedRoutes } from './authenticatedRoutes';
import { pathFragments, tenantBasePath } from './internal/paths';
import CreateForm from '@/components/CreateForm.vue';
import Theme from '@/components/Theme.vue';
import Profile from '@/components/pages/Profile.vue';
import ContactGroups from '@/components/pages/ContactGroups.vue';
import ContactGroup from '@/components/pages/ContactGroup.vue';
import AddGroupMember from '@/components/pages/AddGroupMember.vue';
import ChangePassword from '@/components/pages/ChangePassword.vue';
import EntityForm from '@/components/pages/EntityForm.vue';
import { BreadcrumbKey } from '@/models/site.model';
import Errors from '@/components/pages/Errors.vue';
import ManageGroupDetailsForm from '@/components/pages/ManageGroupDetailsForm.vue';
import Collaborators from '@/components/pages/Collaborators.vue';
import EntityCollaboration from '@/components/pages/EntityCollaboration.vue';
import CollaboratorForm from '@/components/pages/CollaboratorForm.vue';
import MFA from '@/components/pages/MFA.vue';
import Editors from '@/components/pages/Editors.vue';
import FormEditors from '@/components/pages/FormEditors.vue';
import EditorForm from '@/components/pages/EditorForm.vue';
import EntityFormEditors from '@/components/pages/EntityFormEditors.vue';

export const Routes: RouteConfig[] = [
  { path: '/', redirect: tenantBasePath('pol') },
  { path: '/application-hub/', redirect: tenantBasePath('pol') },
  {
    path: tenantBasePath(':tenantId'),
    props: true,
    component: Tenant,
    meta: { requireAuth: true },
    children: [
      {
        path: pathFragments.login(),
        component: Login,
        meta: { breadcrumb: [{ text: 'Login' }] },
        props: true,
      },
      {
        path: pathFragments.loggedOut(),
        name: 'loggedOut',
        component: LoggedOut,
        meta: { breadcrumb: [{ text: 'Logout' }] },
        props: true,
      },
      {
        path: 'theme',
        component: Theme,
      },
      ...authenticatedRoutes(
        {
          path: '',
          name: 'root',
          component: RootFormDefinitions,
          meta: { breadcrumb: [] },
        },
        {
          path: pathFragments.logout(),
          component: Logout,
          meta: { breadcrumb: [{ text: 'Logout' }] },
          props: true,
        },
        {
          path: pathFragments.mfa(),
          component: MFA,
          meta: { breadcrumb: [{ text: 'Manage two-step verification' }] },
          props: true,
        },
        {
          path: pathFragments.dashboard(),
          name: 'dashboard',
          component: Dashboard,
          meta: {
            breadcrumb: [{ text: 'My dashboard' }],
          },
        },
        {
          path: pathFragments.formCategory(':id'),
          component: FormCategory,
          props: true,
        },
        {
          path: pathFragments.createForm(':templateCode'),
          component: CreateForm,
          props: true,
        },
        {
          path: pathFragments.entityForm(':entityId', ':name'),
          component: EntityForm,
          props: true,
          meta: {
            breadcrumbKeys: [BreadcrumbKey.Dashboard, BreadcrumbKey.Entity],
          },
          children: [
            {
              path: pathFragments.error(),
              name: 'entityFormError',
              component: Errors,
              props: true,
              meta: {
                breadcrumbKeys: [BreadcrumbKey.Error],
              },
            },
            {
              path: pathFragments.form(':formId'),
              name: 'entityForm',
              component: Form,
              props: true,
              meta: {
                breadcrumbKeys: [BreadcrumbKey.Form],
                stageRouteName: 'entityFormStage',
              },
              children: [
                {
                  path: pathFragments.stage(':stageTypeId'),
                  name: 'entityFormStage',
                  component: FormStage,
                  props: true,
                  meta: {
                    editorsRouteName: 'entityFormEditors',
                  },
                },
              ],
            },
          ],
        },
        {
          path: pathFragments.entityCollaborators(':entityId', ':name'),
          component: EntityCollaboration,
          props: true,
          meta: {
            breadcrumbKeys: [BreadcrumbKey.Dashboard, BreadcrumbKey.Entity],
          },
          children: [
            {
              path: '',
              name: 'collaborators',
              component: Collaborators,
              props: true,
              meta: {
                breadcrumbKeys: [BreadcrumbKey.Team],
              },
            },
          ],
        },
        {
          path: pathFragments.collaboratorForm(':entityId', ':name'),
          name: 'collaboratorForm',
          component: CollaboratorForm,
          props: true,
          meta: {
            breadcrumbKeys: [
              BreadcrumbKey.Dashboard,
              BreadcrumbKey.Entity,
              BreadcrumbKey.Team,
              BreadcrumbKey.Leaf,
            ],
          },
        },
        {
          path: `${pathFragments.entityForm(
            ':entityId',
            ':name',
          )}/${pathFragments.formEditors(
            ':formId',
            ':stageTypeId',
            ':formName',
          )}`,
          component: EntityFormEditors,
          props: true,
          meta: {
            breadcrumbKeys: [
              BreadcrumbKey.Dashboard,
              BreadcrumbKey.Entity,
              BreadcrumbKey.Form,
            ],
          },
          children: [
            {
              path: 'list',
              name: 'entityFormEditors',
              component: Editors,
              props: true,
              meta: {
                breadcrumbKeys: [BreadcrumbKey.Leaf],
              },
            },
            {
              path: 'add',
              name: 'entityEditorForm',
              component: EditorForm,
              props: true,
              meta: {
                breadcrumbKeys: [BreadcrumbKey.Team, BreadcrumbKey.Leaf],
              },
            },
          ],
        },
        {
          path: pathFragments.formEditors(
            ':formId',
            ':stageTypeId',
            ':formName',
          ),
          component: FormEditors,
          props: true,
          meta: {
            breadcrumbKeys: [BreadcrumbKey.Form],
          },
          children: [
            {
              path: 'list',
              name: 'formEditors',
              component: Editors,
              props: true,
              meta: {
                breadcrumbKeys: [BreadcrumbKey.Leaf],
              },
            },
            {
              path: 'add',
              name: 'editorForm',
              component: EditorForm,
              props: true,
              meta: {
                breadcrumbKeys: [BreadcrumbKey.Team, BreadcrumbKey.Leaf],
              },
            },
          ],
        },
        {
          path: pathFragments.groupDetailsForm(
            ':groupId',
            ':formCode',
            ':pathBack',
          ),
          name: 'groupDetails',
          component: ManageGroupDetailsForm,
          props: true,
          meta: {
            breadcrumbKeys: [BreadcrumbKey.Group],
          },
          children: [
            {
              path: pathFragments.form(':formId'),
              name: 'groupDetailsForm',
              component: Form,
              props: (route) => ({
                formId: route.params.formId,
                layout: route.params.layout,
                hideTeam: true,
              }),
              meta: {
                breadcrumbKeys: [BreadcrumbKey.Form],
                stageRouteName: 'groupDetailsFormStage',
              },
              children: [
                {
                  path: pathFragments.stage(':stageTypeId'),
                  name: 'groupDetailsFormStage',
                  component: FormStage,
                  props: true,
                },
              ],
            },
          ],
        },
        {
          path: pathFragments.form(':formId'),
          name: 'form',
          component: Form,
          props: true,
          meta: {
            breadcrumbKeys: [BreadcrumbKey.Category, BreadcrumbKey.Form],
            stageRouteName: 'formStage',
          },
          children: [
            {
              path: pathFragments.stage(':stageTypeId'),
              name: 'formStage',
              component: FormStage,
              props: true,
              meta: {
                editorsRouteName: 'formEditors',
              },
            },
          ],
        },
        {
          path: pathFragments.entity(':id'),
          name: 'entity',
          component: Entity,
          props: true,
          meta: {
            breadcrumbKeys: [BreadcrumbKey.Dashboard, BreadcrumbKey.Entity],
          },
          children: [
            {
              path: pathFragments.inlineForm(':code', ':formId'),
              name: 'inlineEntityForm',
              component: Form,
              props: true,
              meta: {
                stageRouteName: 'inlineFormStage',
              },
              children: [
                {
                  path: pathFragments.stage(':stageTypeId'),
                  name: 'inlineFormStage',
                  component: FormStage,
                  props: true,
                },
              ],
            },
          ],
        },
        {
          path: pathFragments.profile(),
          name: 'profile',
          component: Profile,
          props: true,
          meta: { breadcrumb: [{ text: 'User profile' }] },
        },
        {
          path: pathFragments.groups(),
          name: 'groups',
          component: ContactGroups,
          props: true,
          meta: { breadcrumb: [{ text: 'Manage groups' }] },
          children: [
            {
              path: pathFragments.group(':id'),
              name: 'group',
              component: ContactGroup,
              props: true,
            },
          ],
        },
        {
          path: pathFragments.addGroupMember(':groupId'),
          name: 'addGroupMember',
          component: AddGroupMember,
          props: true,
          meta: {
            breadcrumb: [
              { text: 'Manage groups', url: '../groups' },
              { text: 'Add group member' },
            ],
          },
        },
        {
          path: pathFragments.changePassword(),
          name: 'changePassword',
          component: ChangePassword,
          props: true,
          meta: { breadcrumb: [{ text: 'Change password' }] },
        },
        {
          path: pathFragments.error(),
          name: 'error',
          component: Errors,
          meta: {
            breadcrumbKeys: [BreadcrumbKey.Error],
          },
        },
      ),
      {
        path: '*',
        props: true,
        component: NotFound,
      },
    ],
  },

  {
    path: '*',
    props: true,
    component: NotFound,
  },
];
