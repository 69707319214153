






import Vue from 'vue';
import { Field } from '@/models/form.model';
import Markdown from '@/components/markdown/Markdown.vue';

type Data = {};
type Methods = {};
type Computed = {};
type Props = { field: Field };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'MarkdownDescription',
  components: { Markdown },
  props: { field: Object },
});
