<template>
  <div class="field">
    <fieldset>
      <popover-label :field="field" :schema="schemaField" />
      <markdown-description :field="field" />
      <rpl-form :formData="formData" @keydown.native.enter.prevent />
      <data-item-delete :field="field" :dataItem="dataItem" />
      <save-status
        :touched="touched"
        :saved="saved"
        :saving="saving"
        :error="error"
      />
    </fieldset>
  </div>
</template>

<script>
import { RplForm } from '@dpc-sdp/ripple-form';
import Vue from 'vue';
import fieldMixin from './mixin/fieldMixin';
import { DataType, PresentationControl } from '@/models/form.model';
import FieldSelect from './FieldSelect.vue';
import FieldMarkdownRadio from '@/components/pages/form/fields/FieldMarkdownRadio.vue';
import PopoverLabel from '@/components/pages/form/fields/PopoverLabel.vue';
import MarkdownDescription from '@/components/pages/form/fields/MarkdownDescription.vue';

Vue.component('field-app-hub-select', FieldSelect);
Vue.component('field-markdown-radio', FieldMarkdownRadio);

export default {
  components: { MarkdownDescription, PopoverLabel, RplForm },
  name: 'EnumField',
  mixins: [fieldMixin],
  computed: {
    dataItemValue() {
      return this.dataItem.value;
    },
  },
  methods: {
    changed(model, newVal, oldVal, field) {
      if (newVal !== oldVal && this.isValid(newVal, field)) {
        this.save(newVal);
      }
    },
    possibleValues() {
      return this.field.dataType === DataType.CalculatedEnum &&
        this.dataItem.allowableValues.length
        ? this.dataItem.allowableValues
        : this.field.enumValues;
    },
    schema() {
      let type;
      const values = this.possibleValues().map((value) => ({
        id: value.value,
        value: value.value,
        name: value.label,
      }));
      if (
        this.field.presentationControls.includes(PresentationControl.RadioGroup)
      ) {
        if (
          this.field.presentationControls.includes(
            PresentationControl.MarkdownEnum,
          )
        ) {
          type = 'markdown-radio';
        } else {
          type = 'radios';
        }
      } else {
        type = 'app-hub-select';
        if (
          !this.field.presentationControls.includes(
            PresentationControl.PreSelect,
          )
        ) {
          values.unshift({ id: '', value: '', name: 'Select' });
        }
      }
      return {
        type,
        values,
      };
    },
  },
  mounted() {
    if (
      this.field.presentationControls.includes(PresentationControl.PreSelect) &&
      !this.dataItemValue
    ) {
      this.save(this.schema().values[0].id);
    }
  },
};
</script>
<style lang="scss" scoped>
fieldset {
  all: unset;
  display: block;
}
</style>
