















import Vue from 'vue';
import { RplSearchForm } from '@dpc-sdp/ripple-search';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import RplTabs from '@dpc-sdp/ripple-tabs';
import { RplCheckbox } from '@dpc-sdp/ripple-form';
import { RplPagination } from '@dpc-sdp/ripple-pagination';
import markdownIt from 'markdown-it';
import { getData, getErrorMessage, isApiError, LoadedData } from '@/api/data';
import externalComponent from '@/util/external-component';
import DataTable from '@/components/table/DataTable.vue';
import {
  ActionEvent,
  ActionResponse,
  DataItem,
  DataItemAction,
  Form,
  FormJob,
  JobStatus,
} from '@/models/form.model';
import Confirm from '@/components/Confirm.vue';

Vue.component('rpl-search-form', RplSearchForm);
Vue.component('rpl-icon', RplIcon);
Vue.component('data-table', DataTable);
Vue.component('rpl-tabs', RplTabs);
Vue.component('rpl-checkbox', RplCheckbox);
Vue.component('rpl-pagination', RplPagination);
Vue.component('confirm', Confirm);

type Data = {
  widgetName?: string;
  actionResponses: ActionResponse[];
  contentWindow?: Window;
};
type Methods = {
  handleWidgetEvent: (event: MessageEvent) => void;
  onSetValue: (data: unknown) => void;
  onPerformAction: (event: ActionEvent) => void;
  captureContentWindow: () => void;
};
type Computed = {
  dataItem: DataItem;
  dataItemValue?: string;
  isFragment: boolean;
  widgetConfig?: string;
  formJob?: FormJob;
};
type Props = { schema: { dataItem: DataItem; id: string } };

const md = markdownIt({ html: true, breaks: true });

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Widget',
  props: { schema: Object },
  data() {
    return {
      widgetName: undefined,
      actionResponses: [],
      contentWindow: undefined,
    };
  },
  computed: {
    dataItem() {
      return this.schema.dataItem;
    },
    dataItemValue() {
      return this.dataItem.value;
    },
    isFragment() {
      return !!this.widgetConfig && this.widgetConfig.startsWith('<');
    },
    widgetConfig() {
      return this.$store.getters.getWidgetConfig(this.dataItem.id);
    },
    formJob() {
      return (
        this.$store.state.form.formJob ||
        this.$store.state.form.widgetProcessingJob
      );
    },
  },
  methods: {
    handleWidgetEvent(event) {
      if (
        event.data.type === 'appHubWidget' &&
        this.dataItem.id === event.data.dataItemId
      ) {
        this.$emit('set-value', event.data.payload);
      } else if (
        event.data.type === 'startWidgetWorking' &&
        this.dataItem.id === event.data.dataItemId
      ) {
        const job: FormJob = {
          id: this.dataItem.id,
          status: JobStatus.Processing,
          description: event.data.message,
        };
        this.$store.commit('setWidgetProcessingJob', job);
      } else if (
        event.data.type === 'endWidgetWorking' &&
        this.dataItem.id === event.data.dataItemId
      ) {
        this.$store.commit('setWidgetProcessingJob', undefined);
      } else if (
        event.data.type === 'appHubWidgetLoaded' &&
        this.dataItem.id === event.data.dataItemId &&
        this.contentWindow
      ) {
        this.contentWindow.postMessage(
          { type: 'setValue', payload: this.dataItemValue },
          '*',
        );
      }
    },
    onSetValue(data) {
      this.$emit('set-value', data);
    },
    onPerformAction(event) {
      this.$store
        .dispatch('performDataItemAction', {
          dataItemId: this.dataItem.id,
          actionCode: event.actionCode,
          payload: event.payload || {},
        })
        .then(async (response: LoadedData<DataItemAction>) => {
          this.actionResponses = this.actionResponses.filter(
            (ar) => ar.actionCode !== event.actionCode,
          );
          if (isApiError(response)) {
            this.actionResponses.push({
              result: 'error',
              errorMessage: getErrorMessage(response),
              actionCode: event.actionCode,
              payload: [],
              requestPayload: event.payload,
            });
          } else {
            const dataItemAction = getData(response);
            if (dataItemAction.action === 'data' && dataItemAction.payload) {
              this.actionResponses.push({
                result: 'success',
                actionCode: event.actionCode,
                payload: JSON.parse(dataItemAction.payload),
                requestPayload: event.payload,
              });
            }
          }
        });
    },
    captureContentWindow() {
      this.$nextTick(() => {
        if (this.isFragment) {
          const fragment = this.$refs.fragment as HTMLElement;
          const iframe = fragment.querySelector('iframe');
          if (iframe && iframe.contentWindow) {
            this.contentWindow = iframe.contentWindow;
          }
        }
      });
    },
  },
  watch: {
    widgetConfig() {
      this.captureContentWindow();
    },
    dataItemValue() {
      if (this.contentWindow) {
        this.contentWindow.postMessage(
          { type: 'setValue', payload: this.dataItemValue },
          '*',
        );
      }
    },
    formJob() {
      if (this.contentWindow) {
        if (this.formJob) {
          const message = this.formJob.description
            ? md.render(this.formJob.description)
            : undefined;
          this.contentWindow.postMessage(
            { type: 'showOverlay', payload: message },
            '*',
          );
        } else {
          this.contentWindow.postMessage({ type: 'hideOverlay' }, '*');
        }
      }
    },
  },
  mounted() {
    if (this.widgetConfig) {
      this.captureContentWindow();
    }
  },
  created() {
    Vue.component(this.schema.id, () => externalComponent(this.widgetConfig));
    this.$store
      .dispatch('loadWidgetConfig', {
        formId: getData<Form>(this.$store.state.form.formFromApi).form.id,
        dataItemId: this.dataItem.id,
      })
      .then(() => {
        if (this.isFragment) {
          window.addEventListener('message', this.handleWidgetEvent);
        } else {
          this.widgetName = this.schema.id;
        }
      });
  },
  destroyed() {
    if (this.isFragment) {
      window.removeEventListener('message', this.handleWidgetEvent);
    }
  },
});
