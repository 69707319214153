<template>
  <RplCol
    class="breadCrumbWrapper"
    v-bind:class="{ root: isRoot, loggedOut: !isLoggedIn }"
  >
    <div v-on:click="catchTarget">
      <RplBreadcrumbs :crumbs="crumbs" />
    </div>
  </RplCol>
</template>

<script>
import RplBreadcrumbs from '@dpc-sdp/ripple-breadcrumbs';
import { RplCol } from '@dpc-sdp/ripple-grid';
import catchEventTarget from './mixin/catchEventTarget';

export default {
  name: 'BreadCrumbs',
  components: { RplBreadcrumbs, RplCol },
  mixins: [catchEventTarget],
  computed: {
    crumbs() {
      return this.$store.getters.breadcrumbs;
    },
    isRoot() {
      return this.crumbs.length === 1;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>
<style lang="scss">
.breadCrumbWrapper.rpl-col.rpl-col--full {
  margin: 0;
  width: 100%;

  &.root,
  &.loggedOut {
    visibility: hidden;
  }

  .rpl-breadcrumbs {
    display: inline-block;
    max-width: 100%;
  }
  .rpl-breadcrumbs__items {
    display: flex;
    flex-wrap: wrap;

    .rpl-breadcrumbs__item {
      display: flex;
      overflow: hidden;

      & > :first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }
}
</style>
