






























import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { Member } from '@/models/team.model';

type Data = {};
type Computed = {
  formattedTeam: (Member & { displayName: string })[];
  excessCount: number;
};
type Methods = { onShowList: () => void };
type Props = {
  loading: boolean;
  team: Member[];
  sharePath: string;
  allowInvite: boolean;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TeamCapsules',
  components: { RplIcon },
  props: {
    loading: Boolean,
    team: Array,
    sharePath: String,
    allowInvite: Boolean,
  },
  computed: {
    formattedTeam() {
      return this.team.slice(0, 4).map((member) => {
        const [first, second] = member.name.split(' ');
        return {
          ...member,
          displayName: `${first.substring(0, 1)}${
            second ? second.substring(0, 1) : ''
          }`,
        };
      });
    },
    excessCount() {
      return this.team.length - this.formattedTeam.length;
    },
  },
  methods: {
    onShowList() {
      if (this.allowInvite) {
        this.$router.push(this.sharePath);
      }
    },
  },
});
