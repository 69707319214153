














































































import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import Button from '@/components/ripple/Button.vue';
import { Team, Collaborator } from '@/models/team.model';
import {
  getData,
  getErrorMessage,
  hasData,
  isApiError,
  isLoading,
  isLoadingOrNeverLoaded,
} from '@/api/data';
import {
  ColumnDataType,
  RowViewModel,
  TableMetadata,
  TableViewModel,
} from '@/components/table/model/table.model';
import DataHubTable from '@/components/table/DataTable.vue';
import { paths } from '@/router/paths';
import { BreadcrumbKey } from '@/models/site.model';

type Data = {};
type Methods = {
  onAddCollaborator: () => void;
  onCloseMessage: () => void;
  onCloseError: () => void;
};
type Computed = {
  team: Team;
  isError: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  errorMessage: string;
  tableViewModel: TableViewModel<Collaborator>;
  tableData: RowViewModel<Collaborator>[];
  tableConfig: TableMetadata;
  message?: string;
  error?: string;
  linkText: string;
  linkPath: string;
  includeGroups: boolean;
};
type Props = { entityId: string; name: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Collaborators',
  components: { RplIcon, DataHubTable, Button, RplAlertBase },
  props: { entityId: String, name: String },
  computed: {
    isLoading() {
      return isLoadingOrNeverLoaded(this.$store.state.entity.teamFromApi);
    },
    isError() {
      return isApiError(this.$store.state.entity.teamFromApi);
    },
    team() {
      return getData(this.$store.state.entity.teamFromApi);
    },
    isLoaded() {
      return hasData(this.$store.state.entity.teamFromApi);
    },
    errorMessage() {
      return (
        getErrorMessage(this.$store.state.entity.teamFromApi) ||
        'Error loading team'
      );
    },
    tableData() {
      return [...this.team]
        .sort((c1, c2) => {
          if (c1.name === undefined && c2.name === undefined) {
            return 0;
          }
          if (c1.name === undefined) {
            return -1;
          }
          if (c2.name === undefined) {
            return 1;
          }
          return c1.name.localeCompare(c2.name);
        })
        .map((contact) => ({
          row: {
            id: contact.id,
            columns: [
              {
                key: 'name',
                value: `${contact.name}`,
              },
              { key: 'email', value: contact.email },
              {
                key: 'group',
                value: contact.isGroup ? 'true' : '',
              },
              {
                key: 'data',
                actions: [contact.canDelete ? 'delete-collaborator' : ''],
                value: this.entityId,
              },
            ],
          },
          data: contact,
        }));
    },
    tableConfig() {
      return {
        label: '',
        columns: [
          {
            label: 'Name',
            key: 'name',
            dataType: ColumnDataType.Text,
          },
          {
            label: 'Email',
            key: 'email',
            dataType: ColumnDataType.Text,
          },
          ...(this.includeGroups
            ? [
                {
                  label: 'Group',
                  key: 'group',
                  dataType: ColumnDataType.Component,
                  component: 'boolean-cell',
                },
              ]
            : []),
          {
            label: '',
            key: 'data',
            dataType: ColumnDataType.Action,
          },
        ],
        pageSize: 10,
        displayRank: 0,
      };
    },
    tableViewModel() {
      return { config: this.tableConfig, rows: this.tableData };
    },
    message() {
      return this.$route.query.message as string;
    },
    error() {
      return this.$store.state.group.error;
    },
    linkText() {
      return `Back to ${this.name}`;
    },
    linkPath() {
      return paths(this.$store.getters.tenantId).entity(this.entityId);
    },
    includeGroups() {
      return !this.$store.getters.getEntitySettings.hideCollaboratorGroup;
    },
  },
  methods: {
    onAddCollaborator() {
      this.$router.push({
        name: 'collaboratorForm',
        params: {
          entityId: this.entityId,
          name: this.name,
        },
      });
    },
    onCloseMessage() {
      this.$store.commit('setMessage', undefined);
    },
    onCloseError() {
      this.$store.commit('setError', undefined);
    },
  },
  mounted() {
    if (!this.isLoaded && !isLoading(this.$store.state.entity.teamFromApi)) {
      this.$store.dispatch('loadEntityCollaborators', this.entityId);
    }
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Team,
      breadcrumbs: {
        text: 'Team',
      },
    });
  },
});
