




import Vue from 'vue';
import { BreadcrumbKey } from '@/models/site.model';

type Data = {};
type Methods = {};
type Computed = {
  formPath: string;
};
type Props = { formId: string; stageTypeId: string; formName: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FormEditors',
  props: { formId: String, stageTypeId: String, formName: String },
  computed: {
    formPath() {
      return this.$router.resolve({
        name: 'formStage',
        params: { ...this.$route.params },
      }).route.path;
    },
  },
  mounted() {
    this.$store.commit('setHeaderText', 'Team');
    this.$store.commit('setSubTitle', this.formName);
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Form,
      breadcrumbs: {
        text: this.formName,
        url: this.formPath,
      },
    });
  },
  destroyed() {
    this.$store.commit('setSubTitle', undefined);
  },
});
