import { UserProfile } from '@/models/user.model';
import { Member } from '@/models/team.model';

export const formatBytes = (bytes: number) => {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(2))}${sizes[i]}`;
};

export interface CreatedForm {
  id: string;
}

export interface FormDefinitions {
  description: string;
  displayRank: string;
  formCategories: Array<FormCategorySummary>;
  formTemplates: Array<FormTemplate>;
}

export interface FormCategorySummary {
  id: string;
  code: string;
  label: string;
  description: string;
  displayRank: number;
}

export enum DataType {
  Record = 'Record',
  Attachment = 'Attachment',
  Enum = 'Enum',
  CalculatedEnum = 'CalculatedEnum',
  MultiValueEnum = 'MultiValueEnum',
  CalculatedMultiValueEnum = 'CalculatedMultiValueEnum',
  Text = 'Text',
  Integer = 'Integer',
  Real = 'Real',
  Boolean = 'Boolean',
  Date = 'Date',
  DateTime = 'DateTime',
  Label = 'Label',
  Currency = 'Currency',
  Action = 'Action',
  Widget = 'Widget',
  Table = 'Table',
}

export interface EnumValue {
  id: string;
  label: string;
  value: string;
  description?: string;
}

export interface Validation {
  id: string;
  type: string;
  message: string;
  regexPattern?: string;
}

export type PresentationControl =
  | 'textarea'
  | 'radiogroup'
  | 'checklist'
  | 'hidecents'
  | 'button'
  | 'downloadlink'
  | 'externallink'
  | 'readonlymarkdown'
  | 'accordion'
  | 'single'
  | 'numbered'
  | 'markdownenum'
  | 'preselect'
  | 'selectall'
  | 'editor'
  | 'collapsable'
  | 'rollup'
  | 'capsule'
  | 'paginated'
  | 'showdetails'
  | 'searchable'
  | 'hide_status_icon'
  | 'hide_repeating_add';

export const PresentationControl: Record<
  | 'TextArea'
  | 'RadioGroup'
  | 'CheckList'
  | 'HideCents'
  | 'Button'
  | 'DownloadLink'
  | 'ExternalLink'
  | 'ReadOnlyMarkdown'
  | 'Accordion'
  | 'Single'
  | 'Numbered'
  | 'MarkdownEnum'
  | 'PreSelect'
  | 'SelectAll'
  | 'Editor'
  | 'Collapsable'
  | 'Rollup'
  | 'Capsule'
  | 'Paginated'
  | 'ShowDetails'
  | 'Searchable'
  | 'HideStatusIcon'
  | 'HideRepeatingAdd',
  PresentationControl
> = {
  TextArea: 'textarea',
  RadioGroup: 'radiogroup',
  CheckList: 'checklist',
  HideCents: 'hidecents',
  Button: 'button',
  DownloadLink: 'downloadlink',
  ExternalLink: 'externallink',
  ReadOnlyMarkdown: 'readonlymarkdown',
  Accordion: 'accordion',
  Single: 'single',
  Numbered: 'numbered',
  MarkdownEnum: 'markdownenum',
  PreSelect: 'preselect',
  SelectAll: 'selectall',
  Editor: 'editor',
  Collapsable: 'collapsable',
  Rollup: 'rollup',
  Capsule: 'capsule',
  Paginated: 'paginated',
  ShowDetails: 'showdetails',
  Searchable: 'searchable',
  HideStatusIcon: 'hide_status_icon',
  HideRepeatingAdd: 'hide_repeating_add',
};

export interface Field {
  id: string;
  label: string;
  description?: string;
  readOnly: boolean;
  visible: boolean;
  required: boolean;
  repeating: boolean;
  minRepeat?: number;
  maxRepeat?: number;
  initialRepeat?: number;
  min?: string;
  max?: string;
  dataType: keyof typeof DataType;
  validationMessage?: string;
  presentationPlaceholder?: string;
  presentationHint?: string;
  presentationControls: PresentationControl[];
  enumValues: EnumValue[];
  validations: Validation[];
  fields: Field[];
  code: string;
  information?: string;
  autocomplete?: string;
  maxFileSize?: number;
  actionCode?: string;
}
export interface StageType {
  id: string;
  label: string;
  description?: string;
  fields: Field[];
}

export interface FormType {
  id: string;
  name: string;
  stages: StageType[];
  settings?: FormTypeSettings;
  readOnly?: boolean;
  canReset: boolean;
}

export interface FormTypeSettings {
  submitButtonLabel?: string;
}

export interface FormTemplate {
  id: string;
  label: string;
  description: string;
  displayRank: number;
  code: string;
}

export enum AttachmentStatus {
  Complete = 'Complete',
  Preparing = 'Preparing',
  Processing = 'Processing',
  Uploading = 'Uploading',
  Deleting = 'Deleting',
  Error = 'Error',
  Infected = 'Infected',
}

export enum FormStatus {
  Incomplete = 'Incomplete',
  Complete = 'Complete',
  Submitted = 'Submitted',
  Submitting = 'Submitting',
  Rejected = 'Rejected',
  Error = 'Error',
  Processing = 'Processing',
}

export interface Attachment {
  id: string;
  formId: string;
  dataItemId: string;
  fileName: string;
  extension: string;
  fileSize: number;
  status: AttachmentStatus;
  errorMessage?: string;
  progress?: number;
  draft: boolean;
  uploadedBy?: string;
  uploadedOn?: string;
  nameError?: boolean;
  renaming?: boolean;
}

export type InFlightAttachment = Attachment & {
  file: File;
};

export enum DataItemStatus {
  Incomplete = 'Incomplete',
  Complete = 'Complete',
  InError = 'InError',
}

export type DataItem = {
  id: string;
  fieldId: string;
  status: keyof typeof DataItemStatus;
  stageId?: string;
  dataItemId?: string;
  value?: string;
  index?: number;
  touched?: boolean;
  enumValueId?: string;
  allowableValues: EnumValue[];
  readOnly?: boolean;
  errorMessage?: string;
  required?: boolean;
};

export type DataItemAction = {
  action: string;
  payload: string;
};

export enum StageStatus {
  Incomplete = 'Incomplete',
  Complete = 'Complete',
  InError = 'InError',
}

export type FormStage = {
  id: string;
  stageTypeId: string;
  status: StageStatus;
};

export interface Form {
  form: {
    id: string;
    formTypeId: string;
    label: string;
    description: string;
    /** present if status === FormStatus.Error */
    errorMessage?: string;
    errorTitle?: string;
    /** present if status === FormStatus.Rejected */
    rejectionMessage?: string;
    status: keyof typeof FormStatus;
  };
  stage: FormStage[];
  dataItem: DataItem[];
  attachment: Attachment[];
  categories: FormCategorySummary[];
  lastEditTime?: string;
  shared: boolean;
  userDetails?: UserProfile;
  processingBackgroundJob?: number;
  inviteEditors?: boolean;
}

export type FormStatusInformation = {
  status: keyof typeof FormStatus;
  entityId?: string;
};

export type ActionEvent = { actionCode: string; payload?: unknown };

export type ActionResponse = {
  result: 'success' | 'error';
  errorMessage?: string;
  actionCode: string;
  requestPayload: unknown;
  payload: unknown;
};

export enum JobStatus {
  Processing = 'Processing',
  Complete = 'Complete',
  Error = 'Error',
}

export interface FormJob {
  id: string;
  status: JobStatus;
  description: string;
}

export interface EditorDto {
  id: string;
  familyName: string;
  givenName: string;
  email: string;
}

export type Editor = EditorDto & Member;
