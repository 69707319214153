

























import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import Confirm from '@/components/Confirm.vue';
import IconButton from '@/components/IconButton.vue';

import logger from '@/logger';
import { GroupMember } from '@/models/group.model';
import { RowViewModel } from '@/components/table/model/table.model';
import { columnValue } from '@/helpers/table';

type Data = { confirmDelete: boolean; working: boolean };
type Computed = { message: string; selfRemove: boolean };
interface Methods {
  onClick: (event: Event) => void;
  onCancel: () => void;
  onConfirm: () => void;
}
type Props = { rowViewModel: RowViewModel<GroupMember> };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DeleteGroupMember',
  components: { RplIcon, Confirm, IconButton },
  props: { rowViewModel: Object },
  data() {
    return {
      confirmDelete: false,
      working: false,
    };
  },
  computed: {
    message() {
      if (this.selfRemove) {
        return 'Please confirm you would like to remove yourself from this group.\nFollowing removal, you will no longer have access to this group.';
      }
      return `Please confirm you would like to remove ${this.rowViewModel.data?.firstName} ${this.rowViewModel.data?.lastName} from this group`;
    },
    selfRemove() {
      return (
        this.rowViewModel.data?.email ===
        this.$store.state.user.userProfile.email
      );
    },
  },
  methods: {
    onClick(event: Event) {
      event.stopPropagation();
      this.confirmDelete = true;
    },
    onConfirm() {
      logger.info(
        `Removing ${this.rowViewModel.data?.firstName} ${this.rowViewModel.data?.lastName}`,
      );
      this.working = true;
      this.confirmDelete = false;
      this.$store
        .dispatch('removeContactFromGroup', {
          contact: this.rowViewModel.data,
          groupId: columnValue(this.rowViewModel.row.columns, 'data'),
          self: this.selfRemove,
        })
        .finally(() => {
          this.working = false;
        });
    },
    onCancel() {
      this.confirmDelete = false;
    },
  },
});
