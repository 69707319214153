









import Vue from 'vue';
import { getData, isLoadingOrNeverLoaded } from '@/api/data';
import { Collaborator } from '@/models/team.model';
import CapsulePanel from '@/components/pages/CapsulePanel.vue';

type Data = {};
type Methods = {};
type Computed = {
  isLoading: boolean;
  team: Collaborator[];
  collaboratorsPath: string;
};
type Props = { entityId: string; entityName: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CollaboratorPanel',
  components: { CapsulePanel },
  props: { entityId: String, entityName: String },
  computed: {
    isLoading() {
      return isLoadingOrNeverLoaded(this.$store.state.entity.teamFromApi);
    },
    team() {
      return getData(this.$store.state.entity.teamFromApi) || [];
    },
    collaboratorsPath() {
      return this.$router.resolve({
        name: 'collaborators',
        params: {
          entityId: this.entityId,
          name: this.entityName,
        },
      }).route.path;
    },
  },
  methods: {},
  created() {
    this.$store.dispatch('loadEntityCollaborators', this.entityId);
  },
});
