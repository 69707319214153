














import Vue from 'vue';
import { RplTextLink } from '@dpc-sdp/ripple-link';

type Data = {};

type Computed = {
  label: string;
};

type Methods = {
  onClick: (evt: MouseEvent) => void;
};

type Props = {
  expandLabel: string;
  hideLabel: string;
  expanded: boolean;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ExpandToggle',
  components: { RplTextLink },
  props: {
    expandLabel: String,
    hideLabel: String,
    expanded: Boolean,
  },
  computed: {
    label() {
      return this.expanded ? this.hideLabel : this.expandLabel;
    },
  },
  methods: {
    onClick(evt) {
      this.$emit('click', evt);
    },
  },
});
