


































import Vue from 'vue';
import IconButton from '@/components/IconButton.vue';

type Data = { value: string; resultCount?: number };
type Computed = {};
type Methods = { onTab: (evt: KeyboardEvent) => void; onClear: () => void };
type Props = {
  initialValue?: string;
  placeholder?: string;
  ariaLabel?: string;
  emptyMessage?: string;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SelectSearch',
  components: { IconButton },
  props: {
    placeholder: { type: String, default: 'Search' },
    ariaLabel: { type: String, default: 'Search for items' },
    emptyMessage: { type: String, default: 'No results found' },
    initialValue: String,
  },
  data() {
    return { value: this.initialValue || '', resultCount: undefined };
  },
  methods: {
    onClear() {
      this.value = '';
      (this.$refs.input as HTMLInputElement).focus();
    },
    onTab(evt) {
      if (this.value) {
        evt.stopPropagation();
      }
    },
  },
  watch: {
    value() {
      this.$emit('search', this.value);
    },
    initialValue() {
      this.value = this.initialValue || '';
    },
  },
  beforeDestroy() {
    this.value = '';
    this.resultCount = undefined;
  },
});
