import { ActionContext, Module } from 'vuex';
import auth from '@/api/auth';
import { RootState } from '../types';
import { UserState } from './types';
import { recordApiCall } from '@/store/modules/apiCalls';
import { UserData, UserProfile } from '@/models/user.model';
import {
  DataFromApi,
  getData,
  getErrorMessage,
  hasData,
  isApiError,
} from '@/api/data';

const module: Module<UserState, RootState> = {
  state: () => ({
    userProfile: undefined,
  }),
  getters: {
    isLoggedIn: (state) => !!state.userProfile,
    userDisplayName: (state) =>
      state.userProfile
        ? `${state.userProfile.firstName} ${state.userProfile.lastName}`
        : '',
  },
  mutations: {
    setUser(state, user: UserProfile) {
      state.userProfile = { ...user, data: undefined };
    },
    setUserData(state, data: UserData) {
      if (state.userProfile) {
        state.userProfile.data = data;
      }
    },
    removeUser(state) {
      state.userProfile = undefined;
    },
  },
  actions: {
    updateUserProfile(
      {
        commit,
        dispatch,
        rootGetters,
        rootState,
      }: ActionContext<UserState, RootState>,
      profile: UserProfile,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'updateUserProfile',
        (client) => client.updateUserProfile(profile),
      ).then(async (response) => {
        if (!isApiError(response)) {
          dispatch('loadUserProfile');
        } else {
          throw new Error(getErrorMessage(response));
        }
      });
    },
    async changePassword(
      context: ActionContext<UserState, RootState>,
      args: { currentPassword: string; newPassword: string },
    ) {
      const authService = await auth();
      return authService.changePassword(args.currentPassword, args.newPassword);
    },
    findUser(
      { commit, rootGetters, rootState }: ActionContext<UserState, RootState>,
      email: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'findUser',
        (client) => client.findUser(email),
      );
    },
    loadUserProfile({
      state,
      dispatch,
      commit,
    }: ActionContext<UserState, RootState>) {
      dispatch('findUser', state.userProfile?.email).then(
        (response: DataFromApi<UserProfile>) => {
          // findUser returns an empty object if user not found, so ensure firstName and lastName are present in the response
          if (
            !isApiError(response) &&
            hasData(response) &&
            'firstName' in getData(response) &&
            'lastName' in getData(response)
          ) {
            const userProfile = getData(response);
            commit('setUserData', userProfile.data);
          }
        },
      );
    },
  },
};

export default module;
