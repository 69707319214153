import Vue from 'vue';
import { paths } from '@/router/paths';

export default Vue.extend({
  computed: {
    tenantId(): string {
      return this.$store.getters.tenantId;
    },
    basePath(): string {
      return paths(this.tenantId).base();
    },
  },
  methods: {
    catchTarget(event: Event): void {
      const elementWithPath = (
        event.composedPath() as HTMLAnchorElement[]
      ).find((element: HTMLAnchorElement) => element.pathname);
      if (
        elementWithPath !== undefined &&
        elementWithPath.pathname.startsWith(this.basePath)
      ) {
        event.preventDefault();
        this.$router.push({ path: elementWithPath.pathname });
      }
    },
  },
});
