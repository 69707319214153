














import Vue from 'vue';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import { paths } from '@/router/paths';
import { BreadcrumbKey } from '@/models/site.model';

type Data = {};
type Methods = {
  onBack: () => void;
};
type Computed = {
  linkText: string;
  linkPath: string;
};
type Props = { entityId: string; name: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EntityCollaboration',
  components: { RplTextLink },
  props: { entityId: String, name: String },
  computed: {
    linkText() {
      return `Back to ${this.name}`;
    },
    linkPath() {
      return paths(this.$store.getters.tenantId).entity(this.entityId);
    },
  },
  methods: {
    onBack() {
      this.$router.push({ name: 'entity', params: { id: this.entityId } });
    },
  },
  mounted() {
    this.$store.commit('setHeaderText', 'Team');
    this.$store.commit('setSubTitle', this.name);
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Dashboard,
      breadcrumbs: {
        text: 'My dashboard',
        url: paths(this.$store.getters.tenantId).dashboard(),
      },
    });
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Entity,
      breadcrumbs: {
        text: this.name,
        url: paths(this.$store.getters.tenantId).entity(this.entityId),
      },
    });
  },
  destroyed() {
    this.$store.commit('setSubTitle', undefined);
  },
});
