
import RplDatePicker from '@dpc-sdp/ripple-form/fields/fieldRpldatepicker.vue';
import Vue from 'vue';
import pikaday from './RplAppHubPikaday.vue';

type Data = {};
type Computed = {};
type Methods = {};
type Props = {};

/* this is an extension of fieldRpldatepicker, needed to import a customised
version of formPikaday, see ./RplAppHubPikaday */

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'RplAppHubDatePicker',
  extends: RplDatePicker,
  components: { pikaday },
});
