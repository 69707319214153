



















import Vue from 'vue';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import { RplForm } from '@dpc-sdp/ripple-form';
import VueFormGenerator from 'vue-form-generator';
import { BreadcrumbKey } from '@/models/site.model';
import {
  DataFromApi,
  getData,
  getErrorMessage,
  hasData,
  isApiError,
  isLoading,
} from '@/api/data';
import { UserProfile } from '@/models/user.model';
import FieldSelect from '@/components/pages/form/fields/FieldSelect.vue';
import { Editor } from '@/models/form.model';

Vue.component('field-app-hub-select', FieldSelect);

type Data = {
  working: boolean;
  model: {
    email: string;
    firstName?: string;
    lastName?: string;
  };
  captureUserDetails: boolean;
  response: { response?: { message: string; status: string } };
};
type Computed = {
  linkText: string;
  linkPath: string;
  editorFormData: object;
  team: Editor[];
  pathBack: string;
};
type Methods = {
  onBack: () => void;
  showFormMessage: (message: string, status: string) => void;
  onContinue: () => void;
  addExistingUserToTeam: () => void;
  addNewUserToTeam: () => void;
};
type Props = {
  formName: string;
  formId: string;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EditorForm',
  components: { RplTextLink, RplForm },
  props: {
    formName: String,
    formId: String,
  },
  data() {
    return {
      working: false,
      model: {
        email: '',
        firstName: '',
        lastName: '',
      },
      captureUserDetails: false,
      response: {},
    };
  },
  computed: {
    linkText() {
      return `Back to team`;
    },
    linkPath() {
      return this.pathBack;
    },
    team() {
      return getData(this.$store.state.form.editorsFromApi);
    },
    editorFormData() {
      return {
        model: this.model,
        tag: 'rpl-fieldset',
        schema: {
          fields: [
            {
              type: 'input',
              inputType: 'email',
              label: 'Email address of new member',
              model: 'email',
              required: true,
              max: 2048,
              validator: VueFormGenerator.validators.email.locale({
                fieldIsRequired: 'Email is required',
                invalidEmail: 'Invalid email address',
              }),
              disabled: this.captureUserDetails,
            },
            {
              type: 'input',
              inputType: 'text',
              label: 'First name',
              model: 'firstName',
              required: true,
              max: 2048,
              visible: this.captureUserDetails,
              validator: VueFormGenerator.validators.string.locale({
                fieldIsRequired: 'First name is required',
              }),
            },
            {
              type: 'input',
              inputType: 'text',
              label: 'Last name',
              model: 'lastName',
              required: true,
              max: 2048,
              visible: this.captureUserDetails,
              validator: VueFormGenerator.validators.string.locale({
                fieldIsRequired: 'Last name is required',
              }),
            },
          ],
          groups: [
            {
              inline: true,
              fields: [
                {
                  type: 'done-button',
                  buttonText: 'Cancel',
                  clickHandler: this.onBack,
                  styleClasses: 'form-group--inline',
                },
                {
                  type: 'rplsubmitloader',
                  buttonText: 'Add',
                  loading: this.working,
                  autoUpdate: true,
                  styleClasses: 'form-group--inline',
                },
              ],
            },
          ],
        },
        formState: this.response,
        formOptions: {
          validateAfterChanged: false,
          validateOnSubmit: true,
          validateAfterLoad: false,
        },
      };
    },
    pathBack() {
      return this.$router.resolve('list').route.path;
    },
  },
  methods: {
    onBack() {
      this.$router.push(this.pathBack);
    },
    showFormMessage(message, status) {
      this.response = { response: { message, status } };
    },
    onContinue() {
      if (
        this.team.some(
          (member) =>
            member.email.toLowerCase().trim() ===
            this.model.email.toLowerCase().trim(),
        )
      ) {
        this.showFormMessage(
          `User ${this.model.email} is already a team member.`,
          'error',
        );
        return;
      }
      this.working = true;
      if (this.captureUserDetails) {
        this.addNewUserToTeam();
      } else {
        this.addExistingUserToTeam();
      }
    },
    addNewUserToTeam() {
      this.$store
        .dispatch('addNewUserToEditors', {
          ...this.model,
          formId: this.formId,
        })
        .then((addUserResponse: DataFromApi<Editor[]>) => {
          if (!isApiError(addUserResponse)) {
            this.$router.push({
              path: this.pathBack,
              query: {
                message: `${this.model.email} has been added to the team`,
              },
            });
          } else {
            this.showFormMessage(getErrorMessage(addUserResponse), 'error');
          }
          this.working = false;
        })
        .finally(() => {
          this.working = false;
        });
    },
    addExistingUserToTeam() {
      this.showFormMessage('Searching for user', 'info');
      this.$store
        .dispatch('findUser', this.model.email)
        .then((response: DataFromApi<UserProfile>) => {
          if (!isApiError(response)) {
            if (hasData(response) && getData(response).firstName) {
              this.showFormMessage('User found, adding to team', 'info');
              this.$store
                .dispatch('addExistingUserToEditors', {
                  email: this.model.email,
                  formId: this.formId,
                })
                .then((addUserResponse: DataFromApi<Editor[]>) => {
                  if (!isApiError(addUserResponse)) {
                    this.$router.push({
                      path: this.pathBack,
                      query: {
                        message: `${this.model.email} has been added to the team`,
                      },
                    });
                  } else {
                    this.showFormMessage(
                      getErrorMessage(addUserResponse),
                      'error',
                    );
                  }
                })
                .finally(() => {
                  this.working = false;
                });
            } else {
              this.showFormMessage(
                'User not found, we need a few more details',
                'info',
              );
              this.captureUserDetails = true;
              this.working = false;
            }
          } else {
            this.showFormMessage(getErrorMessage(response), 'error');
            this.working = false;
          }
        });
    },
  },
  mounted() {
    this.$store.commit('setHeaderText', 'Share with others');
    this.$store.commit('setSubTitle', this.formName);
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Team,
      breadcrumbs: {
        text: 'Team',
        url: this.linkPath,
      },
    });
    this.$store.commit('addBreadcrumbData', {
      key: BreadcrumbKey.Leaf,
      breadcrumbs: {
        text: 'Share with others',
      },
    });
  },
  destroyed() {
    this.$store.commit('setSubTitle', undefined);
  },
  created() {
    const { editorsFromApi } = this.$store.state.form;
    if (!hasData(editorsFromApi) && !isLoading(editorsFromApi)) {
      this.$store.dispatch('loadFormEditors', this.formId);
    }
  },
});
