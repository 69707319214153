














import Vue from 'vue';
import DataHubTable from '@/components/table/DataTable.vue';
import {
  RowViewModel,
  TableGroupViewModel,
  TableViewModel,
} from '@/components/table/model/table.model';
import LinkCell from '@/components/table/components/dashboard/LinkCell.vue';

type Props = {
  viewModel: TableViewModel<unknown>;
  hideTableHeader?: boolean;
  filterTerm?: string;
};

type Data = {
  initialFilterTerm: string | undefined;
};

interface Computed {
  draftViewModel: TableGroupViewModel<unknown>;
}

interface Methods {
  onRowClicked: (rowViewModel: RowViewModel<unknown>) => void;
  getInitialFilterTerm: (
    viewModel: TableViewModel<unknown>,
  ) => string | undefined;
  onFilter: (filterTerm: string) => void;
}
Vue.component('link-cell', LinkCell);

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DashboardTable',
  props: {
    viewModel: Object,
    hideTableHeader: Boolean,
    filterTerm: String,
  },
  data() {
    return {
      initialFilterTerm:
        this.filterTerm || this.$route.query.filter?.toString(),
    };
  },
  components: {
    DataHubTable,
  },
  computed: {
    draftViewModel() {
      return this.$store.getters.getDraftViewModel;
    },
  },
  methods: {
    onRowClicked(rowViewModel: RowViewModel<unknown>) {
      if (this.viewModel.viewPath) {
        this.$router.push(this.viewModel.viewPath(rowViewModel));
      }
    },
    getInitialFilterTerm(viewModel) {
      if (
        !this.draftViewModel ||
        this.draftViewModel.tables.length === 0 ||
        viewModel !== this.draftViewModel.tables[0]
      ) {
        return this.initialFilterTerm;
      }
      return undefined;
    },
    onFilter(filterTerm) {
      this.$emit('filter', filterTerm);
    },
  },
});
