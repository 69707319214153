




import Vue from 'vue';
import authServiceFactory from '../../api/auth';

type Data = unknown;
type Computed = unknown;
type Methods = unknown;
type Props = unknown;

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Logout',
  created() {
    authServiceFactory().then((auth) => {
      auth.logout().then(() => {
        this.$store.commit('removeUser');
        this.$router.push({ name: 'loggedOut' });
      });
    });
  },
});
