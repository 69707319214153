import axios, { AxiosError, AxiosResponse } from 'axios';
import logger from '@/logger';
import { UserTokenService, Cancellable } from './types';
import { RedirectPayload } from './types.dto';
import {
  ConnectorForbiddenError,
  LinkUserError,
  NoTenantError,
  NotFoundError,
  REQUEST_CANCELLED_ERROR,
  TenantAccessError,
  UserMigrationFailedError,
} from './errors';
import {
  ApiError,
  UnexpectedApiError,
  ApiResponse,
  hasData,
  LoadedData,
  ApiPromise,
} from './data';

type ServerError = {
  exceptionType?: string;
  exceptionMessage?: string;
  errorCode?: string;
  description: string;
};

const errorHandler = <T>(error: AxiosError<ServerError>): ApiError => {
  if (axios.isAxiosError(error) && error.response) {
    // Marshal expected errors here
    if (error.response.status === 404 && error.response.data.description) {
      return NotFoundError(error.response.data.description);
    }
    if (
      error.response.status === 503 &&
      error.response.data.errorCode === 'LINK_USER_FAILURE'
    ) {
      return LinkUserError(error.response.data.description);
    }
    if (error.response.data.errorCode === 'NO_TENANT') {
      return NoTenantError(error.response.data.description);
    }
    if (error.response.status === 403) {
      if (error.response.data.errorCode === 'CONNECTOR_FEEDBACK') {
        return ConnectorForbiddenError(error.response.data.description);
      }
      if (error.response.data.errorCode === 'TENANT_ACCESS') {
        return TenantAccessError(error.response.data.description);
      }
    }
    if (error.response.data.errorCode === 'MIGRATION_FAILED') {
      return UserMigrationFailedError(error.response.data.description);
    }

    const message = `API responded with an error [${error.response.status}]${
      error.response.data.description
        ? `: ${error.response.data.description}`
        : ''
    }`;
    logger.error(message);
    return UnexpectedApiError(message);
  }

  if (axios.isCancel(error)) {
    logger.error('Request cancelled');
    return REQUEST_CANCELLED_ERROR;
  }
  const message = `Unable to send request: ${error.message}`;
  logger.error(message);
  return UnexpectedApiError(message);
};

const successHandler = <ResponseT>(
  response: AxiosResponse<ResponseT>,
): LoadedData<ResponseT> => LoadedData(response.data);

const path = (baseUrl: string, basePath: string, requestPath: string) =>
  `${baseUrl}${basePath}${requestPath}`;

const headers = (authToken?: string) => ({
  ...(authToken
    ? {
        Authorization: `Bearer ${authToken}`,
      }
    : {}),
});

const appHubContentType = (contentSuffix?: string) =>
  `application/${contentSuffix ? `apphub-${contentSuffix}+` : ''}json`;

const appHubAccept = (contentSuffix?: string) =>
  `${appHubContentType(contentSuffix)},application/json`;

const requestWithBody = <ResponseT, RequestBody>(
  method: 'post' | 'patch' | 'put',
  tokenService: UserTokenService,
  baseUrl: string,
  basePath: string,
  requestPath: string,
  body: RequestBody,
  contentSuffix?: string,
): ApiPromise<ResponseT> =>
  tokenService.getAccessToken().then((authToken?: string) =>
    axios
      .request<ResponseT>({
        url: path(baseUrl, basePath, requestPath),
        method,
        data: body,
        headers: {
          ...headers(authToken),
          ...(body !== undefined
            ? {
                'Content-Type': appHubContentType(contentSuffix),
              }
            : {}),
        },
      })
      .then(successHandler, errorHandler),
  );

export default (
  baseUrl: string,
  basePath: string,
  tokenService: UserTokenService,
) => ({
  get: <ResponseT>(
    requestPath: string,
    contentSuffix?: string,
  ): ApiPromise<ResponseT> =>
    tokenService
      .getAccessToken()
      .then((authToken?: string) =>
        axios
          .request<ResponseT>({
            method: 'get',
            withCredentials: true,
            url: path(baseUrl, basePath, requestPath),
            headers: {
              ...headers(authToken),
              Accept: appHubAccept(contentSuffix),
            },
          })
          .then(successHandler, errorHandler),
      )
      .catch((e) => {
        logger.error(e);
        throw e;
      }),

  delete: <ResponseT>(
    requestPath: string,
    contentSuffix?: string,
  ): ApiPromise<ResponseT> =>
    tokenService.getAccessToken().then((authToken?: string) =>
      axios
        .request<ResponseT>({
          method: 'delete',
          url: path(baseUrl, basePath, requestPath),
          headers: {
            ...headers(authToken),
            ...(contentSuffix
              ? { 'Content-Type': appHubContentType(contentSuffix) }
              : {}),
          },
          ...(contentSuffix ? { data: {} } : {}),
        })
        .then(successHandler, errorHandler),
    ),

  post: <ResponseT, RequestBody>(
    requestPath: string,
    body?: RequestBody,
    contentSuffix?: string,
  ): ApiPromise<ResponseT> =>
    requestWithBody(
      'post',
      tokenService,
      baseUrl,
      basePath,
      requestPath,
      body,
      contentSuffix,
    ),

  putFile: <ResponseT, RequestBody>(
    requestPath: string,
    progressHandler: (progress: number) => void,
    file: File,
  ): Promise<Cancellable<string>> =>
    tokenService
      .getAccessToken()
      .then((authToken?: string) =>
        axios.request<RedirectPayload>({
          url: path(baseUrl, basePath, requestPath),
          method: 'put',
          headers: {
            ...headers(authToken),
          },
          data: {
            fileName: file.name,
            contentType: file.type || 'application/octet-stream',
            fileSize: file.size,
          },
        }),
      )
      .then(
        (r: AxiosResponse<RedirectPayload>) => successHandler(r),
        errorHandler,
      )
      .then((payload: ApiResponse<RedirectPayload>) => {
        if (!hasData(payload)) {
          throw new Error(
            `Invalid payload during upload ${
              'message' in payload ? `: ${payload.message}` : ''
            }`,
          );
        }
        const source = axios.CancelToken.source();
        return {
          id: payload.data.attachmentId,
          cancel: () => source.cancel(),
          promise: axios
            .put(payload.data.redirectUrl, file, {
              onUploadProgress: (progress: ProgressEvent) =>
                progressHandler(progress.loaded),
              cancelToken: source.token,
              headers: payload.data.headers,
            })
            .then(successHandler)
            .then(() => LoadedData(payload.data.attachmentId), errorHandler),
        };
      }),

  patch: <ResponseT, RequestBody>(
    requestPath: string,
    body: RequestBody,
    contentSuffix?: string,
  ): ApiPromise<ResponseT> =>
    requestWithBody(
      'patch',
      tokenService,
      baseUrl,
      basePath,
      requestPath,
      body,
      contentSuffix,
    ),

  put: <ResponseT, RequestBody>(
    requestPath: string,
    body: RequestBody,
    contentSuffix?: string,
  ): ApiPromise<ResponseT> =>
    requestWithBody(
      'put',
      tokenService,
      baseUrl,
      basePath,
      requestPath,
      body,
      contentSuffix,
    ),
});
