















import Vue from 'vue';
import { RplForm } from '@dpc-sdp/ripple-form';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import VueFormGenerator from 'vue-form-generator';
import { UserData, UserProfile } from '@/models/user.model';
import FormButton from '@/components/ripple/form/FormButton.vue';

Vue.component('field-done-button', FormButton);

type Data = {
  loading: boolean;
  model: Omit<UserProfile, 'email'>;
  working: boolean;
  response: { response?: { message: string; status: string } };
};
type Methods = { onUpdate: () => void };
interface Computed {
  user?: UserProfile;
  formData: object;
  userDetailsFormConfig: unknown[];
  userData?: UserData;
}
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Profile',
  components: { RplForm, RplIcon },
  data() {
    return {
      model: {
        firstName: '',
        lastName: '',
        data: {},
      },
      working: false,
      response: {},
      loading: true,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.userProfile;
    },
    userData() {
      return this.user?.data;
    },
    formData() {
      const validator = VueFormGenerator.validators.string.locale({
        fieldIsRequired: 'Required',
        textTooBig:
          'Must be no more than {1} characters, currently {0} characters',
      });
      return {
        model: this.model,
        tag: 'rpl-fieldset',
        schema: {
          groups: [
            {
              fields: [
                {
                  type: 'input',
                  inputType: 'text',
                  label: 'Given name',
                  model: 'firstName',
                  required: true,
                  max: 2048,
                  validator,
                  styleClasses: 'form-group--col-two',
                },
                {
                  type: 'input',
                  inputType: 'text',
                  label: 'Family name',
                  model: 'lastName',
                  required: true,
                  max: 2048,
                  validator,
                  styleClasses: 'form-group--col-two',
                },
              ],
            },
            ...this.userDetailsFormConfig,
            {
              fields: [
                {
                  type: 'done-button',
                  buttonText: 'Back',
                  clickHandler: () => this.$router.back(),
                  styleClasses: 'form-group--inline',
                },
                {
                  type: 'rplsubmitloader',
                  buttonText: 'Update',
                  loading: this.working,
                  autoUpdate: true,
                  styleClasses: 'form-group--inline',
                },
              ],
            },
          ],
        },
        formState: this.response,
        formOptions: {
          validateAfterChanged: true,
        },
      };
    },
    userDetailsFormConfig() {
      if (this.user?.data) {
        return [
          {
            legend: 'User details',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: 'Organisation name',
                model: 'data.organisationName',
                max: 2048,
                styleClasses: 'form-group--col-two',
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Role',
                model: 'data.role',
                max: 2048,
                styleClasses: 'form-group--col-two',
              },
              {
                type: 'input',
                inputType: 'tel',
                label: 'Phone number',
                model: 'data.phoneNumber',
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Street address',
                model: 'data.streetAddress',
                max: 2048,
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Suburb',
                model: 'data.suburb',
                max: 2048,
              },
              {
                type: 'rplselect',
                label: 'State',
                model: 'data.state',
                styleClasses: 'form-group--col-two',
                values: [
                  { name: 'VIC', id: 'VIC' },
                  { name: 'ACT', id: 'ACT' },
                  { name: 'NSW', id: 'NSW' },
                  { name: 'NT', id: 'NT' },
                  { name: 'QLD', id: 'QLD' },
                  { name: 'TAS', id: 'TAS' },
                  { name: 'WA', id: 'WA' },
                ],
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Postcode',
                model: 'data.postcode',
                styleClasses: 'form-group--col-two',
              },
            ],
          },
        ];
      }
      return [];
    },
  },
  methods: {
    onUpdate() {
      this.working = true;
      this.response = {};
      this.$store
        .dispatch('updateUserProfile', this.model)
        .then(() => {
          this.response = {
            response: { message: 'Profile updated', status: 'success' },
          };
        })
        .catch((error: Error) => {
          this.response = {
            response: {
              message: `Update failed: ${error.message}`,
              status: 'error',
            },
          };
        })
        .finally(() => {
          this.working = false;
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });
    },
  },
  watch: {
    userData() {
      if (this.user && this.userData) {
        this.model = { ...this.user, data: { ...this.userData } };
      }
    },
  },
  mounted() {
    this.$store.commit('setHeaderText', 'User profile');
    if (this.user) {
      this.model = { ...this.user, data: { ...this.user.data } };
    }
  },
  created() {
    this.$store.dispatch('loadUserProfile').then(() => {
      this.loading = false;
    });
  },
});
