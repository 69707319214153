






import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import PopoverToggle from '@/components/PopoverToggle.vue';
import { Column, RowViewModel } from '@/components/table/model/table.model';

type Data = {};

type Computed = {
  message?: string;
};

type Methods = {};

type Props = {
  rowViewModel: RowViewModel<unknown>;
  column: Column;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FailedEmail',
  components: { PopoverToggle, RplIcon },
  props: { rowViewModel: Object, column: Object },
  computed: {
    message() {
      return 'Email failed to send.\nPlease contact <a href="mailto:planning.support@dewlp.vic.com.au">planning.support@dewlp.vic.com.au</a>';
    },
  },
});
