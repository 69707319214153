<template>
  <div class="markdown-radiogroup radio-list">
    <div
      class="radio-button"
      v-for="possibleValue in schema.values"
      :key="possibleValue.value"
    >
      <label>
        <input
          :id="schema.id"
          type="radio"
          :name="`field_${schema.id}`"
          :value="possibleValue.value"
          v-model="value"
          :disabled="schema.disabled"
        />
        <markdown class="markdown-label" :source="possibleValue.name" />
      </label>
    </div>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import Markdown from '@/components/markdown/Markdown.vue';

export default {
  name: 'FieldMarkdownRadio',
  components: { Markdown },
  mixins: [abstractField],
};
</script>
<style lang="scss">
.fieldComponent .form-group .markdown-radiogroup.radio-list {
  margin-bottom: 0;
}

.markdown-radiogroup {
  display: flex;
  flex-direction: column;
  margin: 0;

  .radio-button {
    display: flex;
    align-items: center;
    white-space: pre-line;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    label {
      margin: 0;

      .markdown-label {
        display: flex;
        & > p {
          margin: 0;
        }
      }
    }
  }
}
</style>
