











import Vue from 'vue';
import { RplDocumentLink } from '@dpc-sdp/ripple-document-link';
import { EmailAttachment } from '@/models/entity.model';
import { formatBytes } from '@/models/form.model';
import { getEntityDocumentDownloadURL } from '@/api/download';

type Data = {
  downloadUrl: string;
};
type Computed = {
  extension: string;
  fileSize: string;
};
type Methods = {
  onClick: () => void;
};
type Props = { attachment: EmailAttachment };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EmailAttachment',
  components: { RplDocumentLink },
  data() {
    return { downloadUrl: '' };
  },
  props: {
    attachment: Object,
  },
  computed: {
    extension() {
      return this.attachment.fileName.substring(
        this.attachment.fileName.lastIndexOf('.') + 1,
      );
    },
    fileSize() {
      return formatBytes(this.attachment.fileSize);
    },
  },
  methods: {
    async onClick() {
      await this.$store.dispatch('getAuthCookie', this.$store.getters.tenantId);
      const link = document.createElement('a');
      link.href = this.downloadUrl;
      link.setAttribute('download', this.attachment.fileName);
      link.click();
    },
  },
  async created() {
    this.downloadUrl = await getEntityDocumentDownloadURL(
      this.$store.getters.tenantId,
      this.$store.getters.entityId,
      this.attachment.id,
      this.attachment.fileName,
    );
  },
});
