<template>
  <div class="field">
    <popover-label :field="field" :schema="schemaField" />
    <markdown-description :field="field" />
    <rpl-form :formData="formData" @keydown.native.enter.prevent />
    <data-item-delete :field="field" :dataItem="dataItem" />
    <save-status
      :touched="touched"
      :saved="saved"
      :saving="saving"
      :error="error"
    />
  </div>
</template>

<script>
import { RplForm } from '@dpc-sdp/ripple-form';
import VueFormGenerator from 'vue-form-generator';
import Vue from 'vue';
import debounce from 'lodash.debounce';
import fieldMixin from './mixin/fieldMixin';
import RegexInput from '@/components/pages/form/fields/RegexInput.vue';
import PopoverLabel from '@/components/pages/form/fields/PopoverLabel.vue';
import MarkdownDescription from '@/components/pages/form/fields/MarkdownDescription.vue';

Vue.component('field-regex-input', RegexInput);

const DEBOUNCE_DELAY_MS = 500;

export default {
  components: { MarkdownDescription, PopoverLabel, RplForm },
  name: 'RealField',
  mixins: [fieldMixin],
  computed: {
    dataItemValue() {
      return this.dataItem.value;
    },
  },
  methods: {
    changed(model, newVal, oldVal, field) {
      if (this.isValid(newVal, field)) {
        this.$store.commit('setUpdatingFromUi', true);
        this.debounceSaveFunc();
      } else {
        this.$store.commit('setUpdatingFromUi', false);
        this.debounceSaveFunc.cancel();
      }
    },
    schema() {
      return {
        type: 'regex-input',
        validator: [
          (val, field, model) => {
            if (
              val === '' &&
              !(this.field.required || this.dataItem.required)
            ) {
              return [];
            }
            const result = VueFormGenerator.validators.number.locale({
              invalidInteger: 'Must be a number',
            })(Number.parseFloat(val), field, model);
            if (result && result.length !== 0) {
              return result;
            }
            return this.validators().flatMap((validator) => {
              const validationResult = validator(val, field, model);
              return validationResult || [];
            });
          },
        ],
        pattern: this.pattern(),
        regex: /^(-)|(\.)|(\d)$/,
        validateDebounceTime: DEBOUNCE_DELAY_MS,
      };
    },
  },
  mounted() {
    this.debounceSaveFunc = debounce(
      () => this.save(this.model.value),
      DEBOUNCE_DELAY_MS,
    );
  },
};
</script>
