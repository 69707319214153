import { ActionContext, GetterTree, Module } from 'vuex';

import { applicationHubClient } from '@/api';
import {
  Attachment,
  AttachmentStatus,
  DataItem,
  Editor,
  Field,
  Form,
  formatBytes,
  FormJob,
  FormStage,
  FormStatus,
  FormType,
  FormTypeSettings,
  InFlightAttachment,
  JobStatus,
  StageType,
  Validation,
} from '@/models/form.model';
import { RootState } from '../types';
import { CancelAction, FormState } from './types';
import logger from '@/logger';
import { EntityIdentifier } from '@/models/entity.model';
import {
  ApiErrorType,
  ApiLoading,
  ApiNeverLoaded,
  ApiResponse,
  DataFromApi,
  getData,
  hasData,
  isApiError,
  LoadedData,
} from '@/api/data';
import { recordApiCall } from './apiCalls';

interface DataItemValue {
  id: string;
  value: string;
}

interface ProgressAttachment {
  progress: number;
  attachment: Attachment;
}

interface UploadedAttachment {
  attachment: Attachment;
}

interface CommencedAttachment {
  attachmentId: string;
  attachment: Attachment;
}

interface StatusAttachment {
  status: AttachmentStatus;
  attachment: Attachment;
}

interface ErrorAttachment {
  errorMessage: string;
  attachment: Attachment;
}

interface ErrorInFlightAttachment {
  errorMessage: string;
  attachment: InFlightAttachment;
}

const formGetters: GetterTree<FormState, RootState> = {
  form: (state: FormState) => state.formFromApi,
  formStage:
    (state: FormState) =>
    (stageTypeId: string): FormStage | undefined => {
      if (state.formFromApi && hasData(state.formFromApi)) {
        return getData(state.formFromApi).stage.find(
          (formStage) => formStage.stageTypeId === stageTypeId,
        );
      }
      return undefined;
    },
  readOnlyForm: (state: FormState, getter, rootState): boolean => {
    if (hasData(rootState.formType.formTypeFromApi)) {
      return !!getData(rootState.formType.formTypeFromApi).readOnly;
    }
    return false;
  },
  hasNextStage: (state: FormState, getter) => !!getter.getNextStage,
  hasPrevStage: (state: FormState, getter) => !!getter.getPrevStage,
  getNextStage: (state, getter, rootState) => {
    if (!hasData(rootState.formType.formTypeFromApi)) {
      logger.error("No form type, so can't get next stage");
      return undefined;
    }
    const { stages } = getData(rootState.formType.formTypeFromApi);

    if (state.stageType) {
      const stageIndex = stages.indexOf(state.stageType);
      return stages.length > stageIndex + 1 ? stages[stageIndex + 1] : null;
    }
    logger.error("No stage type, so can't get next stage");
    return undefined;
  },
  getPrevStage: (state, getter, rootState) => {
    if (!hasData(rootState.formType.formTypeFromApi)) {
      logger.error("No form type, so can't get next stage");
      return undefined;
    }
    const { stages } = getData(rootState.formType.formTypeFromApi);

    if (state.stageType) {
      const stageIndex = stages.indexOf(state.stageType);
      return stageIndex > 0 ? stages[stageIndex - 1] : null;
    }

    logger.error("No stage type, so can't get previous stage");
    return undefined;
  },
  canSubmit: (state, getters) => {
    if (!hasData(state.formFromApi)) {
      return false;
    }
    const complete = getData(state.formFromApi).stage.every(
      (stage) => stage.status.toLowerCase() === 'complete',
    );
    return (
      complete &&
      getters.attachments.every(
        (attachment: Attachment) =>
          attachment.status === AttachmentStatus.Complete,
      )
    );
  },
  formTypeSettings: (
    state: FormState,
    getter,
    rootState,
  ): FormTypeSettings | undefined => {
    if (hasData(rootState.formType.formTypeFromApi)) {
      return getData(rootState.formType.formTypeFromApi as LoadedData<FormType>)
        .settings;
    }
    return undefined;
  },
  attachments: (state) =>
    [...state.formAttachments].concat(state.inFlightAttachments),
  attachmentsForDataItem: (state, getters) => (dataItemId: string) =>
    getters.attachments.filter(
      (attachment: Attachment) => attachment.dataItemId === dataItemId,
    ),
  stage: (state) => {
    if (hasData(state.formFromApi)) {
      const stage = getData(state.formFromApi).stage.find(
        (stg) => stg.stageTypeId === state.stageType?.id,
      );
      if (!stage) {
        throw new TypeError(`Stage with type ${state.stageType?.id} not found`);
      }
      return stage;
    }
    return undefined;
  },
  formIsSubmitting: (state) =>
    hasData(state.formFromApi) &&
    getData(state.formFromApi).form.status === FormStatus.Submitting,
  uploadingCount: (state, getters) =>
    getters.attachments.filter(
      (attachment: Attachment) =>
        attachment.status === AttachmentStatus.Uploading,
    ).length,
  hasActiveUploadsForStage: (state, getters) => (stageId: string) =>
    hasData(state.formFromApi) &&
    getData(state.formFromApi)
      .dataItem.filter((dataItem) => dataItem.stageId === stageId)
      .flatMap((dataItem) => getters.dataItemAndDescendants(dataItem))
      .some((dataItem) =>
        getters.attachments.some(
          (attachment: Attachment) =>
            attachment.dataItemId === dataItem.id &&
            attachment.status !== AttachmentStatus.Complete,
        ),
      ),
  dataItemAndDescendants: (state, getters) => (dataItem: DataItem) => {
    if (hasData(state.formFromApi)) {
      return [
        dataItem,
        ...getData(state.formFromApi)
          .dataItem.filter((di) => dataItem.id === di.dataItemId)
          .flatMap((di) => getters.dataItemAndDescendants(di)),
      ];
    }
    return [];
  },
  inviteFormEditors: (state) =>
    hasData(state.formFromApi)
      ? getData(state.formFromApi).inviteEditors
      : false,
};

const formModule: Module<FormState, RootState> = {
  state: () => ({
    formFromApi: ApiNeverLoaded,
    stageType: undefined,
    inFlightAttachments: [],
    cancelActions: [],
    entityId: undefined,
    updatingFromUi: false,
    resetting: false,
    attachmentPolling: false,
    message: undefined,
    highlight: false,
    formAttachments: [],
    formJob: undefined,
    editorsFromApi: ApiNeverLoaded,
    widgetProcessingJob: undefined,
  }),
  mutations: {
    setHighlight(state, highlight: boolean) {
      state.highlight = highlight;
    },
    setForm(state: FormState, payload: DataFromApi<Form | EntityIdentifier>) {
      if (hasData(payload)) {
        const data = getData(payload);
        if ('entityId' in data) {
          state.entityId = data.entityId;
          state.message = data.message;
          return;
        }
        state.formAttachments = data.attachment.map((a) => ({
          ...a,
          nameError: false,
          renaming: false,
        }));
        if (data.processingBackgroundJob !== undefined) {
          if (
            !state.formJob ||
            state.formJob.id !== data.processingBackgroundJob.toString()
          ) {
            state.formJob = {
              id: data.processingBackgroundJob.toString(),
              status: JobStatus.Processing,
              description: '',
            };
          }
        } else if (state.formJob) {
          state.formJob = undefined;
        }
      }
      state.entityId = undefined;
      state.message = undefined;
      state.formFromApi = payload as DataFromApi<Form>; // We returned above if it was an EntityIdentifier, so we know it's a Form
    },
    setFormJob(state: FormState, formJob: FormJob) {
      state.formJob = formJob;
    },
    setStageType(state: FormState, data: StageType) {
      state.stageType = data;
    },
    addInFlightAttachment(state: FormState, attachment: InFlightAttachment) {
      state.inFlightAttachments.push(attachment);
    },
    removeInFlightAttachment(state: FormState, attachment: InFlightAttachment) {
      const index = state.inFlightAttachments.indexOf(attachment);
      if (index >= 0) {
        state.inFlightAttachments.splice(index, 1);
      }
    },
    updateInFlightAttachment(state: FormState, attachment: InFlightAttachment) {
      state.inFlightAttachments = state.inFlightAttachments.map((a) =>
        a.id === attachment.id ? attachment : a,
      );
    },
    clearInFlightAttachments(state: FormState) {
      state.inFlightAttachments = [];
    },
    /** Removes an attachment in place in the form */
    removeAttachment(state: FormState, attachment: Attachment) {
      if (hasData(state.formFromApi)) {
        const formState = getData(state.formFromApi);

        if (formState.form.id === attachment.formId) {
          state.formAttachments = state.formAttachments?.filter(
            (a) => a.id !== attachment.id,
          );
        }
      }
    },
    /** Marks an attachment as complete, and moves it to the dataItem on the form */
    attachmentUploadComplete(
      state: FormState,
      uploadedAttachment: UploadedAttachment,
    ) {
      const { attachment } = uploadedAttachment;

      // Now add the attachment in to the form
      if (hasData(state.formFromApi)) {
        const formState = getData(state.formFromApi);
        if (formState.form.id === attachment.formId) {
          logger.debug('Adding attachment', attachment);

          if (
            // Make sure we don't already have it
            !state.formAttachments?.find((a) => a.id === attachment.id)
          ) {
            state.formAttachments.push(attachment);
          }
        }
      }
    } /** Marks an attachment as uploading */,
    attachmentUploadCommenced(
      state: FormState,
      commencedAttachment: CommencedAttachment,
    ) {
      const { attachment, attachmentId } = commencedAttachment;
      attachment.id = attachmentId;
      attachment.status = AttachmentStatus.Uploading;
    },
    updateAttachment(state: FormState, attachment: Attachment) {
      state.formAttachments = state.formAttachments.map((a) =>
        a.id === attachment.id ? attachment : a,
      );
    },
    addCancelAction(state: FormState, cancelAction: CancelAction) {
      state.cancelActions.push(cancelAction);
    },
    removeCancelAction(state: FormState, id: string) {
      state.cancelActions = state.cancelActions.filter(
        (cancelAction) => cancelAction.id !== id,
      );
    },
    updateUploadProgress(
      state: FormState,
      progressAttachment: ProgressAttachment,
    ) {
      const { attachment } = progressAttachment;
      attachment.progress = progressAttachment.progress;
    },
    updateAttachmentStatus(
      state: FormState,
      statusAttachment: StatusAttachment,
    ) {
      const { attachment } = statusAttachment;
      attachment.status = statusAttachment.status;
    },
    setAttachmentErrorStatusAndMessage(
      state: FormState,
      errorAttachment: ErrorAttachment,
    ) {
      const { attachment } = errorAttachment;
      attachment.errorMessage = errorAttachment.errorMessage;
      attachment.status = AttachmentStatus.Error;
    },
    setAttachmentNameErrorMessage(
      state: FormState,
      errorAttachment: ErrorInFlightAttachment,
    ) {
      const { attachment } = errorAttachment;
      attachment.errorMessage = errorAttachment.errorMessage;
      attachment.nameError = true;
    },
    clearAttachmentNameError(state, attachment: Attachment) {
      const att = attachment;
      att.nameError = false;
      att.errorMessage = undefined;
    },
    setAttachmentRenaming(
      state: FormState,
      renamingAttachment: { attachment: Attachment; renaming: boolean },
    ) {
      const { attachment, renaming } = renamingAttachment;
      attachment.renaming = renaming;
    },
    setUpdatingFromUi(state: FormState, updating: boolean) {
      state.updatingFromUi = updating;
    },
    setResetting(state: FormState, resetting: boolean) {
      state.resetting = resetting;
    },
    clearFormState(state: FormState) {
      state.formFromApi = ApiNeverLoaded;
      state.stageType = undefined;
      state.inFlightAttachments = [];
      state.cancelActions = [];
      state.entityId = undefined;
      state.updatingFromUi = false;
      state.resetting = false;
      state.attachmentPolling = false;
      state.message = undefined;
      state.editorsFromApi = ApiNeverLoaded;
    },
    setAttachmentPolling(state, polling: boolean) {
      state.attachmentPolling = polling;
    },
    setEditors(state, editors: ApiResponse<Editor[]>) {
      state.editorsFromApi = editors;
    },
    setWidgetProcessingJob(state, job: FormJob) {
      state.widgetProcessingJob = job;
    },
  },
  actions: {
    createFormFromTemplate(
      { rootGetters, rootState, commit }: ActionContext<FormState, RootState>,
      payload: { templateCode: string; initialValues?: {} },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'createFormFromTemplate',
        (client) =>
          client.createFormFromTemplate(
            payload.templateCode,
            payload.initialValues,
          ),
      );
    },
    createFormFromAction(
      { rootGetters, rootState, commit }: ActionContext<FormState, RootState>,
      payload: { actionCode: string; entityId: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'createFormFromAction',
        (client) =>
          client.createFormFromAction(payload.actionCode, payload.entityId),
      );
    },
    deleteForm(
      {
        dispatch,
        rootGetters,
        rootState,
        commit,
      }: ActionContext<FormState, RootState>,
      id: string,
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'deleteForm',
        (client) => client.deleteForm(id),
      ).then(() => {
        dispatch('loadDashboard');
      });
    },
    loadForm(
      {
        commit,
        state,
        rootGetters,
        rootState,
      }: ActionContext<FormState, RootState>,
      id: string,
    ) {
      // We should probably roll loadForm and reloadForm into one function
      // Having two feels like it might make it easy to introduce inconsistent state
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getForm',
        (client) => client.getForm(id),
      ).then((data) => {
        if (
          hasData(state.formFromApi) &&
          getData(state.formFromApi).form.id !== id
        ) {
          commit('clearInFlightAttachments');
        }
        commit('setForm', data);
      });
    },
    reloadForm({
      commit,
      state,
      rootGetters,
      rootState,
    }: ActionContext<FormState, RootState>) {
      if (hasData(state.formFromApi)) {
        const { id } = getData(state.formFromApi).form;
        return recordApiCall(
          { rootState, rootGetters, commit },
          'getForm',
          (client) => client.getForm(id),
        ).then((data) => commit('setForm', data));
      }
      return Promise.resolve();
    },
    moveForward({
      commit,
      state,
      rootState,
    }: ActionContext<FormState, RootState>) {
      if (!hasData(rootState.formType.formTypeFromApi)) {
        logger.error("No form type, so can't move forward");
        return;
      }
      const { stages } = getData(rootState.formType.formTypeFromApi);

      if (!state.stageType) {
        logger.error("No stage type, so can't move forward");
        return;
      }
      const stageIndex = stages.indexOf(state.stageType);
      if (stageIndex < stages.length - 1) {
        commit('setStageType', stages[stageIndex + 1]);
      }
    },
    moveBack({
      commit,
      state,
      rootState,
    }: ActionContext<FormState, RootState>) {
      if (!hasData(rootState.formType.formTypeFromApi)) {
        logger.error("No form type, so can't move back");
        return;
      }
      const { stages } = getData(rootState.formType.formTypeFromApi);
      if (!state.stageType) {
        logger.error("No stage type, so can't move back");
        return;
      }
      const stageIndex = stages.indexOf(state.stageType);
      if (stageIndex > 0) {
        commit('setStageType', stages[stageIndex - 1]);
      }
    },
    uploadAttachments(
      {
        commit,
        state,
        dispatch,
        rootGetters,
      }: ActionContext<FormState, RootState>,
      args: {
        dataItemId: string;
        files: FileList;
        maxFiles?: number;
        maxFileSize: number;
        fileNameValidations: Validation[];
        attachmentId?: string;
      },
    ) {
      if (!hasData(state.formFromApi) || !getData(state.formFromApi).form) {
        const errorMessage = "No form type, so can't save";
        logger.error(errorMessage);
        return Promise.reject(new Error(errorMessage));
      }

      const formId = getData(state.formFromApi).form.id;
      if (formId === undefined) {
        throw new Error(
          "Form Id can't be undefined when sending an attachment",
        );
      }

      const files = [...args.files];
      const uploadPromises: Promise<unknown>[] = [];
      // use reduce to sequentially initialise uploads while allowing actual file transfer to take place in parallel
      const pr = files.reduce(
        (p, file) =>
          p.then(() =>
            dispatch('uploadAttachment', {
              attachmentId: args.attachmentId,
              dataItemId: args.dataItemId,
              file,
              formId,
              maxFiles: args.maxFiles,
              maxFileSize: args.maxFileSize,
              fileNameValidations: args.fileNameValidations,
            }).then((result) => {
              if (result) {
                const { response, attachment } = result;
                if ('promise' in response) {
                  uploadPromises.push(response.promise);
                  response.promise.then(
                    (uploadResponse: ApiResponse<unknown>) => {
                      if (isApiError(uploadResponse)) {
                        if (
                          uploadResponse.type !== ApiErrorType.RequestCancelled
                        ) {
                          commit('setAttachmentErrorStatusAndMessage', {
                            attachment,
                            errorMessage: `Not uploaded - ${uploadResponse.message}`,
                          });
                        }
                      } else {
                        // Here we know the upload has finished, call complete then patch the local state
                        // polling will reload the form when attachment moves out of processing
                        applicationHubClient(rootGetters.tenantId).then(
                          (client) => {
                            client
                              .completeAttachment(attachment)
                              .then((completeResponse) => {
                                if (!isApiError(completeResponse)) {
                                  commit('attachmentUploadComplete', {
                                    attachment: getData(completeResponse),
                                  });
                                  commit(
                                    'removeInFlightAttachment',
                                    attachment,
                                  );
                                  commit('removeCancelAction', attachment.id);
                                }
                              });
                          },
                        );
                      }
                    },
                  );
                }
              }
            }),
          ),
        Promise.resolve(),
      );
      return pr.then(() => Promise.all(uploadPromises));
    },
    async uploadAttachment(
      { commit, getters, rootGetters }: ActionContext<FormState, RootState>,
      args: {
        dataItemId: string;
        file: File;
        formId: string;
        maxFiles?: number;
        maxFileSize: number;
        fileNameValidations: Validation[];
        attachmentId?: string;
      },
    ) {
      let tmpId = new Date().getTime();
      const { file } = args;
      tmpId += 1;
      const extensionStartIndex = file.name.lastIndexOf('.');
      const extension =
        extensionStartIndex >= 0
          ? file.name.substring(file.name.lastIndexOf('.') + 1)
          : '';
      const attachment: InFlightAttachment = {
        formId: args.formId,
        id: args.attachmentId || tmpId.toString(),
        dataItemId: args.dataItemId,
        fileName: file.name,
        status: AttachmentStatus.Preparing,
        fileSize: file.size,
        extension,
        progress: 0,
        file: args.file,
        draft: true,
      };
      if (args.attachmentId) {
        commit('updateInFlightAttachment', attachment);
      } else {
        commit('addInFlightAttachment', attachment);
      }

      if (file.size > args.maxFileSize) {
        commit('setAttachmentErrorStatusAndMessage', {
          attachment,
          errorMessage: `Not uploaded - file exceeds maximum size of ${formatBytes(
            args.maxFileSize,
          )} per file`,
        });
        return Promise.resolve();
      }
      if (file.size === 0) {
        commit('setAttachmentErrorStatusAndMessage', {
          attachment,
          errorMessage: 'Not uploaded - file is empty',
        });
        return Promise.resolve();
      }
      if (args.fileNameValidations) {
        const firstInvalidRule = args.fileNameValidations.find(
          (validation) =>
            validation.regexPattern &&
            !new RegExp(validation.regexPattern).test(file.name),
        );
        if (firstInvalidRule) {
          commit('setAttachmentNameErrorMessage', {
            attachment,
            errorMessage: firstInvalidRule.message,
          });
          commit('updateAttachmentStatus', {
            attachment,
            status: AttachmentStatus.Error,
          });
          return Promise.resolve();
        }
      }
      const validAttachments = getters
        .attachmentsForDataItem(args.dataItemId)
        .filter((a: Attachment) => a.status !== AttachmentStatus.Error);
      if (args.maxFiles && args.maxFiles < validAttachments.length) {
        commit('setAttachmentErrorStatusAndMessage', {
          attachment,
          errorMessage: `Not uploaded - exceeds maximum number of uploads (${args.maxFiles})`,
        });
        return Promise.resolve();
      }

      const progressHandler = (progress: number) =>
        commit('updateUploadProgress', { attachment, progress });
      const client = await applicationHubClient(rootGetters.tenantId);
      const res = await client.uploadAttachment(
        args.formId,
        attachment.dataItemId,
        file,
        progressHandler,
      );
      commit('attachmentUploadCommenced', {
        attachment,
        attachmentId: res.id,
      });
      commit('addCancelAction', { id: res.id, cancel: res.cancel });
      return { response: res, attachment };
    },
    deleteAttachment(
      {
        state,
        commit,
        getters,
        rootGetters,
        dispatch,
        rootState,
      }: ActionContext<FormState, RootState>,
      args: { attachmentId: string },
    ) {
      const attachment = getters.attachments.find(
        (a: Attachment) => a.id === args.attachmentId,
      );

      if (attachment?.status === AttachmentStatus.Error) {
        return new Promise<void>((resolve) => {
          commit('removeInFlightAttachment', attachment);
          resolve();
        });
      }

      if (attachment?.status === AttachmentStatus.Uploading) {
        const cancelAction = state.cancelActions.find(
          (action) => action.id === attachment.id,
        );
        if (cancelAction) {
          cancelAction.cancel();
          commit('removeInFlightAttachment', attachment);
          commit('removeCancelAction', attachment.id);
        }
      }

      commit('updateAttachmentStatus', {
        attachment,
        status: AttachmentStatus.Deleting,
      });

      return recordApiCall(
        { commit, rootGetters, rootState },
        'deleteAttachment',
        (client) => client.deleteAttachment(attachment),
      ).then((response) => {
        if (isApiError(response) && response.type !== ApiErrorType.NotFound) {
          commit('setAttachmentErrorStatusAndMessage', {
            attachment,
            errorMessage: `Unable to delete - ${response.message}`,
          });
        } else {
          commit('removeInFlightAttachment', attachment);
          commit('removeAttachment', attachment);
        }
        dispatch('reloadForm');
      });
    },
    renameAttachment(
      {
        commit,
        getters,
        rootGetters,
        dispatch,
        rootState,
      }: ActionContext<FormState, RootState>,
      args: {
        attachmentId: string;
        name: string;
        maxFiles?: number;
        maxFileSize: number;
        fileNameValidations: Validation[];
      },
    ) {
      const attachment = getters.attachments.find(
        (a: Attachment) => a.id === args.attachmentId,
      );
      if (!attachment) {
        throw Error(`Attachment with id: ${args.attachmentId} not found`);
      }
      if (attachment.status === AttachmentStatus.Complete) {
        if (args.fileNameValidations) {
          const firstInvalidRule = args.fileNameValidations.find(
            (validation) =>
              validation.regexPattern &&
              !new RegExp(validation.regexPattern).test(args.name),
          );
          if (firstInvalidRule) {
            commit('setAttachmentNameErrorMessage', {
              attachment,
              errorMessage: firstInvalidRule.message,
            });
            return Promise.resolve();
          }
          commit('clearAttachmentNameError', attachment);
        }
        commit('setAttachmentRenaming', { attachment, renaming: true });
        return recordApiCall(
          { commit, rootGetters, rootState },
          'renameAttachment',
          (client) => client.renameAttachment(attachment, args.name),
        )
          .then((response) => {
            if (!isApiError(response)) {
              return dispatch('reloadForm');
            }
            commit('setAttachmentNameErrorMessage', {
              attachment,
              errorMessage: `Rename failed - ${response.message}`,
            });
            return undefined;
          })
          .finally(() =>
            commit('setAttachmentRenaming', { attachment, renaming: false }),
          );
      }
      return dispatch('uploadAttachments', {
        attachmentId: args.attachmentId,
        dataItemId: attachment.dataItemId,
        files: [
          new File([attachment.file], args.name, {
            type: attachment.file.type,
          }),
        ],
        maxFiles: args.maxFiles,
        maxFileSize: args.maxFileSize,
        fileNameValidations: args.fileNameValidations,
      });
    },
    async pollProcessingAttachments({
      state,
      getters,
      rootGetters,
      rootState,
      commit,
      dispatch,
    }: ActionContext<FormState, RootState>) {
      if (!state.attachmentPolling) {
        const processingAttachments = getters.attachments.filter(
          (attachment: Attachment) =>
            attachment.status === AttachmentStatus.Processing,
        );
        if (processingAttachments.length === 0 || !hasData(state.formFromApi)) {
          return Promise.resolve();
        }
        logger.debug(
          `Polling for ${processingAttachments.length} processing attachments`,
        );
        const formId = getData(state.formFromApi).form.id;
        commit('setAttachmentPolling', true);
        return recordApiCall(
          { commit, rootGetters, rootState },
          'getAttachments',
          (client) =>
            client.getAttachments(
              processingAttachments.map((a: Attachment) => a.id),
              formId,
            ),
        ).then((attachmentsResponse) => {
          commit('setAttachmentPolling', false);
          if (hasData(attachmentsResponse)) {
            const attachments = getData(attachmentsResponse);
            attachments.forEach((a) => {
              if (a.status !== AttachmentStatus.Processing) {
                commit('updateAttachment', a);
              }
            });
            if (
              attachments.every((a) => a.status !== AttachmentStatus.Processing)
            ) {
              dispatch('reloadForm');
            }
          }
        });
      }
      return Promise.resolve();
    },
    addDataItem(
      {
        state,
        rootGetters,
        rootState,
        commit,
      }: ActionContext<FormState, RootState>,
      args: {
        field: Field;
        stage: FormStage;
        parentDataItem: DataItem | undefined;
      },
    ) {
      if (
        !hasData(state.formFromApi) ||
        getData(state.formFromApi).form === undefined
      ) {
        const errorMessage = "No form type, so can't add data item";
        logger.error(errorMessage);
        return Promise.reject(new Error(errorMessage));
      }

      const { form } = getData(state.formFromApi);

      return recordApiCall(
        { rootState, rootGetters, commit },
        'addDataItem',
        (client) =>
          client.addDataItem(
            form.id,
            args.field.id,
            args.stage.id,
            args.parentDataItem?.id,
          ),
      );

      // Leave this for testing purposes until back end present
      /* return new Promise<void>((resolve) => {
        setTimeout(() => {
          if (state.form) {
            const siblings = state.form.dataItem.filter(
              (dataItem) =>
                dataItem.fieldId === args.field.id &&
                dataItem.stageId === args.stage.id &&
                dataItem.dataItemId === args.parentDataItem?.id,
            );
            const dataItem = {
              id: args.field.id + args.stage.id + siblings.length,
              stageId: args.stage.id,
              fieldId: args.field.id,
              status: 'Incomplete',
              dataItemId: args.parentDataItem?.id,
              index: siblings.length,
            };
            state.form.dataItem.push(dataItem);
            if (args.field.dataType === DataType.Record) {
              // eslint-disable-next-line no-unused-expressions
              args.field.fields?.forEach((field) =>
                dispatch('addDataItem', {
                  field,
                  stage: args.stage,
                  parentDataItem: dataItem,
                }),
              );
            }
          }
          resolve();
        }, 1000);
      }); */
    },
    deleteDataItem(
      {
        state,
        rootGetters,
        rootState,
        commit,
      }: ActionContext<FormState, RootState>,
      args: {
        dataItem: DataItem;
      },
    ) {
      if (
        !hasData(state.formFromApi) ||
        getData(state.formFromApi).form === undefined
      ) {
        const errorMessage = "No form type, so can't add data item";
        logger.error(errorMessage);
        return Promise.reject(new Error(errorMessage));
      }

      const { form } = getData(state.formFromApi);
      return recordApiCall(
        { rootState, rootGetters, commit },
        'deleteDataItem',
        (client) => client.deleteDataItem(form.id, args.dataItem.id),
      );

      // Leave this for testing purposes until back end present
      /* return new Promise<void>((resolve) => {
        setTimeout(() => {
          const { form } = state;
          if (form) {
            form.dataItem.splice(form.dataItem.indexOf(args.dataItem));
          }
          resolve();
        }, 1000);
      }); */
    },
    saveDataItemValue(
      {
        rootGetters,
        state,
        rootState,
        commit,
      }: ActionContext<FormState, RootState>,
      args: DataItemValue,
    ) {
      if (
        !hasData(state.formFromApi) ||
        getData(state.formFromApi).form === undefined
      ) {
        const errorMessage = "No form type, so can't save";
        logger.error(errorMessage);
        return Promise.reject(new Error(errorMessage));
      }

      // the `form` variable is extracted because typescript 3.9
      // can't tell that this code is only reachable if it's defined
      const { form } = getData(state.formFromApi);

      return recordApiCall(
        { rootState, rootGetters, commit },
        'saveDataItemValue',
        (client) => client.saveDataItemValue(form.id, args.id, args.value),
      );
    },
    async pollSubmittingForm({
      rootGetters,
      state,
      dispatch,
      commit,
      rootState,
    }: ActionContext<FormState, RootState>) {
      if (
        !hasData(state.formFromApi) ||
        getData(state.formFromApi).form === undefined
      ) {
        return Promise.resolve();
      }
      const form = getData(state.formFromApi);

      if (form.form.status !== FormStatus.Submitting || state.entityId) {
        return Promise.resolve();
      }
      const { id } = form.form;
      logger.debug(`Polling for status of form '${id}'`);
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getFormStatus',
        (client) => client.getFormStatus(id),
      ).then((formStatusResponse) => {
        if (hasData(formStatusResponse)) {
          const formStatusInfo = getData(formStatusResponse);
          if (formStatusInfo.status === FormStatus.Submitted) {
            dispatch('clearForm');
            commit(
              'setForm',
              LoadedData({ entityId: formStatusInfo.entityId }),
            );
          } else if (formStatusInfo.status !== FormStatus.Submitting) {
            dispatch('reloadForm');
          }
        }
      });
    },
    async pollFormJob({
      rootGetters,
      state,
      dispatch,
      commit,
      rootState,
    }: ActionContext<FormState, RootState>) {
      if (!state.formJob || state.formJob.status !== JobStatus.Processing) {
        return Promise.resolve();
      }
      const { id } = state.formJob;
      const form = hasData(state.formFromApi)
        ? getData(state.formFromApi)
        : undefined;
      if (!form) {
        return Promise.reject(new Error('Form not found'));
      }
      logger.debug(`Polling for status of job '${id}'`);
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getFormJob',
        (client) => client.getFormJob(form.form.id, id),
      ).then((formJobResponse) => {
        if (hasData(formJobResponse)) {
          const formJob = getData(formJobResponse);
          if (formJob.status === JobStatus.Complete && !formJob.description) {
            commit('setFormJob', undefined);
            dispatch('reloadForm');
          } else {
            commit('setFormJob', formJob);
          }
        }
      });
    },
    submitForm({
      commit,
      rootGetters,
      rootState,
      state,
    }: ActionContext<FormState, RootState>) {
      if (
        !hasData(state.formFromApi) ||
        getData(state.formFromApi).form === undefined
      ) {
        const errorMessage = "No form type, so can't submit";
        logger.error(errorMessage);
        return Promise.reject(new Error(errorMessage));
      }
      const { form } = getData(state.formFromApi);

      return recordApiCall(
        { rootState, rootGetters, commit },
        'submitForm',
        (client) => client.submitForm(form.id),
      ).then((response) => {
        commit('setForm', response);
      });
    },
    resetForm({
      commit,
      rootGetters,
      rootState,
      state,
    }: ActionContext<FormState, RootState>) {
      commit('setResetting', true);
      if (
        !hasData(state.formFromApi) ||
        getData(state.formFromApi).form === undefined
      ) {
        const errorMessage = "No form type, so can't reset";
        logger.error(errorMessage);
        return Promise.reject(new Error(errorMessage));
      }
      const { form } = getData(state.formFromApi);
      return recordApiCall(
        { rootState, rootGetters, commit },
        'resetForm',
        (client) => client.resetForm(form.id),
      ).then((response) => {
        commit('setResetting', false);
        commit('setForm', response);
      });
    },
    getDataItemAction(
      {
        rootGetters,
        state,
        rootState,
        commit,
      }: ActionContext<FormState, RootState>,
      args: { dataItemId: string; actionCode?: string },
    ) {
      if (
        !hasData(state.formFromApi) ||
        getData(state.formFromApi).form === undefined
      ) {
        const errorMessage = "No form type, so can't perform action";
        logger.error(errorMessage);
        return Promise.reject(new Error(errorMessage));
      }

      // the `form` variable is extracted because typescript 3.9
      // can't tell that this code is only reachable if it's defined
      const { form } = getData(state.formFromApi);

      return recordApiCall(
        { rootState, rootGetters, commit },
        'getDataItemAction',
        (client) =>
          client.getDataItemAction(
            form.id,
            args.dataItemId,
            args.actionCode || 'default',
          ),
      );
    },
    performDataItemAction(
      {
        rootGetters,
        state,
        rootState,
        commit,
      }: ActionContext<FormState, RootState>,
      args: { dataItemId: string; actionCode?: string; payload: unknown },
    ) {
      if (
        !hasData(state.formFromApi) ||
        getData(state.formFromApi).form === undefined
      ) {
        const errorMessage = "No form type, so can't perform action";
        logger.error(errorMessage);
        return Promise.reject(new Error(errorMessage));
      }

      // the `form` variable is extracted because typescript 3.9
      // can't tell that this code is only reachable if it's defined
      const { form } = getData(state.formFromApi);

      return recordApiCall(
        { rootState, rootGetters, commit },
        'performDataItemAction',
        (client) =>
          client.performDataItemAction(
            form.id,
            args.dataItemId,
            args.actionCode || 'default',
            args.payload,
          ),
      );
    },
    clearForm({
      getters,
      dispatch,
      commit,
    }: ActionContext<FormState, RootState>) {
      getters.attachments
        .filter(
          (attachment: Attachment) =>
            attachment.status === AttachmentStatus.Uploading,
        )
        .forEach((attachment: Attachment) =>
          dispatch('deleteAttachment', { attachmentId: attachment.id }),
        );
      commit('clearFormState');
      commit('clearFormTypeState');
    },
    loadFormEditors(
      {
        state,
        commit,
        rootGetters,
        rootState,
      }: ActionContext<FormState, RootState>,
      formId: string,
    ) {
      commit('setEditors', ApiLoading(state.editorsFromApi));
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getFormEditors',
        (client) => client.getFormEditors(formId),
      ).then((response) => {
        commit('setEditors', response);
      });
    },
    addExistingUserToEditors(
      { commit, rootGetters, rootState }: ActionContext<FormState, RootState>,
      args: { email: string; formId: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'addExistingUserToEditors',
        (client) => client.addExistingUserToEditors(args.email, args.formId),
      ).then((response: DataFromApi<Editor[]>) => {
        if (!isApiError(response)) {
          commit('setEditors', response);
        }
        return response;
      });
    },
    addNewUserToEditors(
      { commit, rootGetters, rootState }: ActionContext<FormState, RootState>,
      args: {
        email: string;
        firstName: string;
        lastName: string;
        formId: string;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'addNewUserToEditors',
        (client) =>
          client.addNewUserToEditors(
            args.email,
            args.firstName,
            args.lastName,
            args.formId,
          ),
      ).then((response: DataFromApi<Editor[]>) => {
        if (!isApiError(response)) {
          commit('setEditors', response);
        }
        return response;
      });
    },
    removeUserFromEditors(
      { commit, rootGetters, rootState }: ActionContext<FormState, RootState>,
      args: { editorId: string; formId: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'removeUserFromEditors',
        (client) => client.removeUserFromEditors(args.editorId, args.formId),
      ).then((response: DataFromApi<Editor[]>) => {
        if (!isApiError(response)) {
          commit('setEditors', response);
        }
        return response;
      });
    },
  },
  getters: formGetters,
};

export default formModule;
