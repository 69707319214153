












import Vue from 'vue';
import { EntityAttribute, EntityAttributeType } from '@/models/entity.model';

type Data = {};
type Computed = { value: string; isHTML: boolean };
type Methods = {};
type Props = { attribute: EntityAttribute };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EntityAttribute',
  props: {
    attribute: Object,
  },
  computed: {
    value() {
      switch (this.attribute.dataType) {
        case EntityAttributeType.Date:
          return this.$options.filters?.formatDate(this.attribute.value);
        case EntityAttributeType.DateTime:
          return this.$options.filters?.formatDateTime(this.attribute.value);
        case EntityAttributeType.Text:
        case EntityAttributeType.Number:
        default:
          return this.attribute.value;
      }
    },
    isHTML() {
      return this.attribute.dataType === EntityAttributeType.HTML;
    },
  },
});
