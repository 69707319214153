<template>
  <FormDefinitions />
</template>

<script>
import FormDefinitions from './formDefinitions/FormDefinitions.vue';
import { paths } from '@/router/paths';

export default {
  name: 'FormCategory',
  props: ['id'],
  components: {
    FormDefinitions,
  },
  computed: {
    formCategory() {
      return this.$store.getters.formCategory;
    },
  },
  created() {
    this.$store.dispatch('loadFormCategory', this.$props.id);
  },
  watch: {
    formCategory(val) {
      if (val && val.data) {
        const formCategory = val.data;
        this.$store.commit('setHeaderText', formCategory.label);
        this.$store.commit('setBreadcrumbs', [
          ...formCategory.parentCategories.map((parentCategory) => ({
            text: parentCategory.label,
            url: paths(this.$store.getters.tenantId).formCategory(
              parentCategory.id,
            ),
          })),
          { text: formCategory.label },
        ]);
      }
    },
  },
};
</script>
