











import Vue from 'vue';
import IconButton from '@/components/IconButton.vue';

type Data = {};
type Computed = {};
type Methods = {};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DisabledDeleteEditor',
  components: { IconButton },
});
