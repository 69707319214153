var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataItem)?_c('div',{staticClass:"fieldComponent",class:{ 'no-label': !_vm.field.label, capsule: _vm.isCapsule }},[(_vm.isCollapsable)?_c('icon-button',{staticClass:"expand action",class:{ collapsed: _vm.collapsed },attrs:{"title":_vm.collapsed ? 'Expand section' : 'Collapse section'},on:{"click":_vm.onToggleCollapse},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('expand-icon')]},proxy:true}],null,false,1427196771)}):_vm._e(),_vm._t("default"),(_vm.isAccordion)?[_c('div',{key:_vm.field.id,staticClass:"label"},[_c('accordion-field',{attrs:{"field":_vm.field}})],1)]:(_vm.isLabelOnly)?[(_vm.labelForDisplay)?_c('div',{key:_vm.field.id,staticClass:"label"},[_c('markdown',{attrs:{"source":_vm.labelForDisplay}})],1):_vm._e()]:(_vm.isRecord)?[_c('div',{key:_vm.field.id,staticClass:"record highlight rpl-form rpl-form--full-width",class:{
        collapsable: _vm.isCollapsable,
        collapsed: _vm.collapsed,
      }},[_c('label',{staticClass:"field-label",attrs:{"title":_vm.isCollapsable
            ? _vm.collapsed
              ? 'Expand section'
              : 'Collapse section'
            : ''},on:_vm._d({},[_vm.toggleCollapseEvent,_vm.onToggleCollapse])},[_vm._v(_vm._s(_vm.field.label))]),_c('transition-expand',[(_vm.collapsed)?_c('div',{staticClass:"rollup",attrs:{"title":'Expand section'},on:{"click":_vm.onToggleCollapse}},[(_vm.showStatusIcon)?_c('record-status-icon',{attrs:{"data-items":_vm.dataItems}}):_vm._e(),_c('markdown',{attrs:{"source":_vm.rollupText}})],1):_vm._e()]),_c('transition-expand',[(!_vm.collapsed)?_c('div',[_c('fieldset',[(_vm.field.description)?_c('legend',{staticClass:"record-legend"},[_vm._v(" "+_vm._s(_vm.field.description)+" ")]):_vm._e(),_c('fields',{attrs:{"fields":_vm.field.fields,"dataItems":_vm.dataItems,"parentDataItem":_vm.dataItem,"readOnlyForm":_vm.readOnlyForm}})],1)]):_vm._e()])],1)]:_c('data-type-field',{key:_vm.field.id,attrs:{"field":_vm.field,"dataItem":_vm.dataItem,"readOnlyForm":_vm.readOnlyForm}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }