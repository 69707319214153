










































import Vue from 'vue';
import { RplCardNav } from '@dpc-sdp/ripple-card';
import { RplCol, RplContainer, RplRow } from '@dpc-sdp/ripple-grid';
import FolderIcon from '@/assets/folder-regular.svg';
import { paths } from '@/router/paths';
import CreateFormLink from '@/components/CreateFormLink.vue';
import { FormCategorySummary, FormTemplate } from '@/models/form.model';

type Link = { text: string; url: string };
type Summary = { link: { url: string }; title: string; summary: string };

type Data = {
  folderImage: {
    src: SVGElement;
  };
};
type Methods = {};
type Computed = { links: Link[]; categories: Summary[]; templates: Summary[] };
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FormDefinitions',
  components: {
    CreateFormLink,
    RplCardNav,
    RplContainer,
    RplRow,
    RplCol,
  },
  data() {
    return {
      folderImage: { src: FolderIcon },
    };
  },
  computed: {
    links() {
      return this.$store.state.formDefinitions.formCategories.map(
        (category: FormCategorySummary) => ({
          text: category.label,
          url: paths(this.$store.getters.tenantId).formCategory(category.id),
        }),
      );
    },
    categories() {
      return this.$store.state.formDefinitions.formCategories.map(
        (category: FormCategorySummary) => ({
          title: category.label,
          summary: category.description,
          link: {
            url: paths(this.$store.getters.tenantId).formCategory(category.id),
          },
        }),
      );
    },
    templates() {
      return this.$store.state.formDefinitions.formTemplates.map(
        (template: FormTemplate) => ({
          title: template.label,
          summary: template.description,
          link: {
            url: paths(this.$store.getters.tenantId).createForm(
              encodeURIComponent(template.code),
            ),
          },
          template,
        }),
      );
    },
  },
});
