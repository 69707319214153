






import Vue from 'vue';
import { RplTimeline } from '@dpc-sdp/ripple-timeline';
import { EntityTimelineEntry } from '@/models/entity.model';

type Data = {};
type Computed = {};
type Methods = {};
type Props = { timeline: EntityTimelineEntry[] };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EntityTimeline',
  components: { RplTimeline },
  props: { timeline: [] },
});
