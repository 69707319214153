

















import { RplForm } from '@dpc-sdp/ripple-form';
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import fieldMixin from './mixin/fieldMixin';
import { DataType, EnumValue, PresentationControl } from '@/models/form.model';
import FieldSelect from '@/components/pages/form/fields/FieldSelect.vue';
import FieldMarkdownMultiSelect from '@/components/pages/form/fields/FieldMarkdownMultiSelect.vue';
import PopoverLabel from '@/components/pages/form/fields/PopoverLabel.vue';
import MarkdownDescription from '@/components/pages/form/fields/MarkdownDescription.vue';

Vue.component('field-app-hub-select', FieldSelect);
Vue.component('field-markdown-multiselect', FieldMarkdownMultiSelect);

type Data = {};
type Computed = {
  allowSelectAll: boolean;
  dataItemValue: string[];
  possibleValues: EnumValue[];
  sanitisedPossibleValues: EnumValue[];
  allSelected: boolean;
};
type Methods = {
  changed: (
    model: unknown,
    newVal: string[],
    oldVal: string[],
    field: unknown,
  ) => void;
  schema: () => unknown;
};
type Props = {};
const SELECT_ALL_VALUE = '@select_all';

export default mixins(fieldMixin).extend<Data, Methods, Computed, Props>({
  components: { MarkdownDescription, PopoverLabel, RplForm },
  name: 'MultiValueEnumField',
  mixins: [fieldMixin],
  computed: {
    allowSelectAll() {
      return (
        this.field.presentationControls.includes(
          PresentationControl.SelectAll,
        ) || this.possibleValues.some((pv) => pv.value === SELECT_ALL_VALUE)
      );
    },
    dataItemValue() {
      const { value } = this.dataItem;
      if (value) {
        const values = JSON.parse(value);
        if (this.allowSelectAll && this.allSelected) {
          return [SELECT_ALL_VALUE, ...values];
        }
        return values;
      }
      return [];
    },
    possibleValues() {
      return this.field.dataType === DataType.CalculatedMultiValueEnum &&
        this.dataItem.allowableValues.length
        ? this.dataItem.allowableValues
        : this.field.enumValues;
    },
    sanitisedPossibleValues() {
      return this.possibleValues.filter((pv) => pv.value !== SELECT_ALL_VALUE);
    },
    allSelected() {
      const { value } = this.dataItem;
      if (value) {
        const values = JSON.parse(value);
        return this.sanitisedPossibleValues.every((pv) =>
          values.includes(pv.value),
        );
      }
      return false;
    },
  },
  methods: {
    changed(model, newVal, oldVal) {
      if (newVal.includes(SELECT_ALL_VALUE)) {
        if (this.allSelected) {
          const values = [...newVal];
          values.splice(newVal.indexOf(SELECT_ALL_VALUE), 1);
          this.save(JSON.stringify(values));
        } else {
          this.save(
            JSON.stringify(this.sanitisedPossibleValues.map((pv) => pv.value)),
          );
        }
      } else if (oldVal.includes(SELECT_ALL_VALUE)) {
        this.save(JSON.stringify([]));
      } else {
        this.save(JSON.stringify(newVal));
      }
    },
    schema() {
      let type;
      if (
        this.field.presentationControls.includes(
          PresentationControl.MarkdownEnum,
        )
      ) {
        type = 'markdown-multiselect';
      } else if (
        this.field.presentationControls.includes(PresentationControl.CheckList)
      ) {
        type = 'rplchecklist';
      } else {
        type = 'app-hub-select';
      }
      const additionalValues = [];
      if (
        this.field.presentationControls.includes(PresentationControl.SelectAll)
      ) {
        additionalValues.push({
          id: SELECT_ALL_VALUE,
          value: SELECT_ALL_VALUE,
          name: 'Select all',
        });
      }
      return {
        type,
        multiselect: true,
        listBox: true,
        values: [
          ...additionalValues,
          ...this.possibleValues.map((value) => ({
            id: value.value,
            value: value.value,
            name: value.label,
          })),
        ],
      };
    },
  },
});
