





















import Vue from 'vue';
import { RplLink } from '@dpc-sdp/ripple-link';
import TeamCapsules from '@/components/pages/TeamCapsules.vue';
import { Member } from '@/models/team.model';

type Data = {};
type Methods = {
  onShare: () => void;
};
type Computed = {
  hasTeam: boolean;
};
type Props = {
  loading: boolean;
  team: Member[];
  sharePath: string;
  allowInvite: boolean;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CapsulePanel',
  components: { TeamCapsules, RplLink },
  props: {
    loading: Boolean,
    team: Array,
    sharePath: String,
    allowInvite: Boolean,
  },
  computed: {
    hasTeam() {
      return !!this.team.length;
    },
  },
  methods: {
    onShare() {
      this.$router.push(this.sharePath);
    },
  },
});
