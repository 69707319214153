


























import Vue from 'vue';
import { Email } from '@/models/entity.model';
import { formatDateTime } from '@/helpers/date';
import EmailAttachments from '@/components/pages/EmailAttachments.vue';

type Data = {};

type Computed = {
  date: string;
  body: string;
};

type Methods = {};

type Props = { email: Email };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EmailDetails',
  components: { EmailAttachments },
  props: {
    email: Object,
  },
  computed: {
    date() {
      return formatDateTime(this.email.createdAt);
    },
    body() {
      return `<div><style>body{margin:0}</style>${this.email.body}</div>`;
    },
  },
});
