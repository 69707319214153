








































import Vue from 'vue';

import { RplBaseLayout, RplPageLayout } from '@dpc-sdp/ripple-layout';
import RplSiteHeader from '@dpc-sdp/ripple-site-header';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import authFactory from '@/api/auth';
import defaultGetConfig from '@/config';

import Breadcrumb from './components/Breadcrumb.vue';
import Footer from './components/footer/Footer.vue';
import { paths } from './router/paths';

import catchEventTarget from '@/components/mixin/catchEventTarget';
import ErrorList from '@/components/errorHandler/ErrorList.vue';
import { RplImage } from '@/models/site.model';
import { applySiteTheme } from '@/themes';
import { ApiError, ApiErrorType, hasData, isApiError } from '@/api/data';
import HeroBanner from '@/components/ripple/HeroBanner.vue';
import Notice from '@/components/Notice.vue';
import Downloads from '@/components/Downloads.vue';
import HeaderBg from '@/assets/header-background.png';

interface Data {
  breakpoint: number;
  polling?: NodeJS.Timeout;
  homeUrl: string;
  headerBg: string;
}

interface Computed {
  tenantId: string;
  links: { text: string; url?: string }[];
  userDisplayName: string;
  siteTitle: string;
  notLinkedError: boolean;
  showErrors: boolean;
  logo: RplImage;
  awaitingTenant: boolean;
  isTenantError: boolean;
  tenantFromApi: ApiError;
  hasSiteErrors: boolean;
  bannerBackground: string;
}

type Methods = {
  pollApi: () => void;
};
type Props = unknown;

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'App',
  mixins: [catchEventTarget],
  components: {
    Downloads,
    Notice,
    RplBaseLayout,
    RplSiteHeader,
    HeroBanner,
    RplPageLayout,
    ErrorList,
    Breadcrumb,
    Footer,
    RplIcon,
  },

  data() {
    return {
      breakpoint: 992,
      homeUrl: '',
      headerBg: HeaderBg,
    };
  },
  beforeDestroy() {
    if (this.polling) {
      clearInterval(this.polling);
    }
  },
  methods: {
    pollApi() {
      if (this.polling) {
        clearInterval(this.polling);
      }
      this.polling = setInterval(() => {
        this.$store.dispatch('pollApi');
      }, 1000);
    },
  },
  created() {
    this.$store.dispatch('loadTenant', this.tenantId).then(() => {
      this.$store.commit('setSiteName', this.$store.getters.tenantName);
      applySiteTheme(this.$store.getters.tenantTheme);
    });
    defaultGetConfig().then((c) => {
      this.homeUrl = c.cognito.authService;
    });
  },
  mounted() {
    authFactory()
      .then((authService) => authService.getUser())
      .then((user) => {
        if (user) {
          this.$store.commit('setUser', user);
        }
      });
    window.addEventListener('beforeunload', (event) => {
      const { uploadingCount } = this.$store.getters;
      if (uploadingCount > 0) {
        const message = `There ${
          uploadingCount === 1 ? 'is' : 'are'
        } ${uploadingCount} file${
          uploadingCount === 1 ? '' : 's'
        } currently uploading. If you leave now the uploads will be cancelled.`;
        // eslint-disable-next-line no-param-reassign
        (event || window.event).returnValue = message;
        return message;
      }
      return undefined;
    });
    this.pollApi();
    this.$store.dispatch('loadFeatureFlags');
  },
  computed: {
    tenantId() {
      return this.$store.getters.tenantId;
    },
    links() {
      return this.$store.getters.isLoggedIn
        ? [
            {
              text: 'Home',
              url: this.homeUrl,
            },
            {
              text: 'My dashboard',
              url: paths(this.tenantId).dashboard(),
            },
            {
              text: this.userDisplayName,
              children: [
                {
                  text: 'Update profile',
                  url: paths(this.tenantId).profile(),
                },
                ...(this.$store.state.tenant.settings.groupManagement
                  ? [
                      {
                        text: 'Manage groups',
                        url: paths(this.tenantId).groups(),
                      },
                    ]
                  : []),
                {
                  text: 'Change password',
                  url: paths(this.tenantId).changePassword(),
                },
                ...(this.$store.state.feature.optional2fa
                  ? [
                      {
                        text: 'Manage two-step verification',
                        url: paths(this.tenantId).mfa(),
                      },
                    ]
                  : []),
                {
                  text: 'Sign out',
                  url: paths(this.tenantId).logout(),
                },
              ],
            },
          ]
        : [{ text: 'Sign in', url: paths(this.tenantId).base() }];
    },
    userDisplayName() {
      return this.$store.getters.userDisplayName;
    },
    siteTitle() {
      const { breadcrumbs } = this.$store.getters;
      if (breadcrumbs && breadcrumbs.length > 0) {
        return breadcrumbs[breadcrumbs.length - 1].text;
      }
      return 'Application Hub';
    },
    notLinkedError() {
      return this.$store.getters.apiErrors.some(
        (error: ApiError) => error.type === ApiErrorType.UserNotLinked,
      );
    },
    showErrors() {
      return this.$store.state.feature.showErrors;
    },
    logo() {
      return this.$store.getters.tenantTheme.logo;
    },
    awaitingTenant() {
      return (
        !hasData(this.$store.state.tenant.tenantFromApi) &&
        !isApiError(this.$store.state.tenant.tenantFromApi)
      );
    },
    isTenantError() {
      return isApiError(this.$store.state.tenant.tenantFromApi);
    },
    tenantFromApi() {
      return this.$store.state.tenant.tenantFromApi;
    },
    hasSiteErrors() {
      return this.$store.getters.apiSiteErrors.length > 0;
    },
    bannerBackground() {
      return this.$store.getters.tenantTheme.bannerBackground || this.headerBg;
    },
  },
  watch: {
    siteTitle: {
      immediate: true,
      handler(title) {
        document.title = title;
      },
    },
    hasSiteErrors(errors) {
      if (errors) {
        this.$router.push({ name: 'error' }).catch((e) => {
          // ignore navigation cancelled errors
          if (!e.message.toLowerCase().startsWith('navigation cancelled')) {
            throw e;
          }
        });
      }
    },
  },
});
