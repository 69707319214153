<template>
  <div>
    <rpl-checkbox
      :inputId="getFieldID(schema, true)"
      v-model="value"
      :inputAutocomplete="schema.autocomplete"
      :inputDisabled="disabled"
      :inputName="schema.inputName"
      :inputClass="schema.fieldClasses"
      :inputRequired="schema.required"
      :inlineLabel="schema.inlineLabel"
    />
    <popover-toggle
      class="boolean-popover-toggle"
      :markdown="schema.information"
    />
  </div>
</template>

<script>
import RplCheckbox from '@dpc-sdp/ripple-form/Checkbox.vue';
import { abstractField } from 'vue-form-generator';
import PopoverToggle from '@/components/PopoverToggle.vue';

export default {
  mixins: [abstractField],
  components: {
    PopoverToggle,
    RplCheckbox,
  },
};
</script>
<style lang="scss" scoped>
.boolean-popover-toggle {
  display: inline-flex;
  margin-left: 0.3rem;
}
</style>
