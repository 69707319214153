

























import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import Confirm from '@/components/Confirm.vue';
import IconButton from '@/components/IconButton.vue';

import logger from '@/logger';
import { RowViewModel } from '@/components/table/model/table.model';
import { columnValue } from '@/helpers/table';
import { Editor } from '@/models/form.model';
import { isApiError } from '@/api/data';

type Data = { confirmDelete: boolean; working: boolean };
type Computed = { message: string; selfRemove: boolean };
interface Methods {
  onClick: (event: Event) => void;
  onCancel: () => void;
  onConfirm: () => void;
}
type Props = { rowViewModel: RowViewModel<Editor> };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DeleteEditor',
  components: { RplIcon, Confirm, IconButton },
  props: { rowViewModel: Object },
  data() {
    return {
      confirmDelete: false,
      working: false,
    };
  },
  computed: {
    message() {
      return this.selfRemove
        ? 'If you remove yourself as an editor you will be redirected to your dashboard and no longer have access to this form.'
        : `Please confirm you would like to remove ${this.rowViewModel.data?.name} from this team`;
    },
    selfRemove() {
      return (
        this.rowViewModel.data?.email ===
        this.$store.state.user.userProfile.email
      );
    },
  },
  methods: {
    onClick(event: Event) {
      event.stopPropagation();
      this.confirmDelete = true;
    },
    onConfirm() {
      const editor = this.rowViewModel.data;
      logger.info(`Removing ${editor?.name}}`);
      this.working = true;
      this.confirmDelete = false;
      this.$store
        .dispatch('removeUserFromEditors', {
          editorId: editor?.id,
          formId: columnValue(this.rowViewModel.row.columns, 'actions'),
        })
        .then((response) => {
          if (!isApiError(response)) {
            if (this.selfRemove) {
              this.$router.push({ name: 'dashboard' });
            } else {
              this.$router.push({
                query: { message: `${editor?.name} has been removed.` },
              });
            }
          }
        })
        .finally(() => {
          this.working = false;
        });
    },
    onCancel() {
      this.confirmDelete = false;
    },
  },
});
