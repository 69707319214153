var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasData)?_c('div',[_c('RplRow',{class:{ reloadable: _vm.reloading }},[_c('RplCol',{attrs:{"colsBp":{
        m: 12,
        l: 8,
      }}},[(_vm.status && _vm.message)?_c('RplForm',{attrs:{"formData":_vm.formData}}):_vm._e(),_vm._l((_vm.displayAttributes),function(attribute){return _c('entity-attribute-component',{key:attribute.label,attrs:{"attribute":attribute}})})],2),_c('RplCol',{attrs:{"colsBp":{
        m: 12,
        l: 4,
      }}},[(_vm.showCollaborators)?_c('collaborator-panel',{attrs:{"entity-id":_vm.id,"entity-name":_vm.label}}):_vm._e(),_c('entity-timeline',{attrs:{"timeline":_vm.timeline}})],1),(_vm.reloading)?_c('div',{staticClass:"reloading"},[_c('loading',[_vm._v("Reloading")])],1):_vm._e()],1),_c('RplRow',[_c('RplCol',{attrs:{"colsBp":{
        m: 12,
        l: 12,
      }}},[_c('entity-tabs',{attrs:{"label":_vm.label}})],1)],1),_c('RplRow',[_c('RplCol',{attrs:{"colsBp":{
        m: 12,
        l: 12,
      }}},[(_vm.showMessages)?_c('entity-conversation'):_vm._e(),_c('entity-tables')],1)],1)],1):(_vm.isLoading)?_c('div',{staticClass:"info-message"},[_c('h4',[_vm._v("Loading")]),_c('p',[_vm._v("Please wait")]),_c('RplIcon',{staticClass:"spin",attrs:{"symbol":"loading_star","color":"primary","size":"xxl"}})],1):_c('div',[(_vm.errorMessage)?_c('h4',[_vm._v(" "+_vm._s(_vm.errorMessage)+" "),_c('br'),_c('a',{staticClass:"error-contact",attrs:{"href":("mailto:" + _vm.contactEmail)}},[_vm._v(_vm._s(_vm.contactDescription))])]):_c('h4',[_vm._v(" Unable to load application, please try refreshing the page, if the problem persists "),_c('a',{attrs:{"href":"mailto:planning.support@transport.vic.gov.au"}},[_vm._v("please contact support")]),_vm._v(". ")])])}
var staticRenderFns = []

export { render, staticRenderFns }