






















































import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { EntityAction, EntityActionGroupType } from '@/models/entity.model';
import Button from '@/components/ripple/Button.vue';
import { getData, hasData, isApiError, LoadedData } from '@/api/data';
import { CreatedForm } from '@/models/form.model';
import Markdown from '@/components/markdown/Markdown.vue';

type Data = { loadingForm: boolean };
type Computed = { theme: string; inlineForm: boolean };
type Methods = {
  performAction: () => void;
};
type Props = {
  action: EntityAction;
  entityId: string;
  label: string;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EntityAction',
  components: { Markdown, Button, RplIcon },
  data() {
    return {
      loadingForm: false,
    };
  },
  props: {
    action: Object,
    entityId: String,
    label: String,
  },
  computed: {
    theme() {
      return this.action.group === EntityActionGroupType.Secondary
        ? 'secondary'
        : 'primary';
    },
    inlineForm() {
      return (
        this.$route.params.code === this.action.actionCode &&
        !!this.$route.params.formId
      );
    },
  },
  methods: {
    performAction() {
      if (this.inlineForm) {
        this.$router.push({
          name: 'entity',
          params: {
            entityId: this.entityId,
          },
        });
        return;
      }
      this.$emit('action-start');
      this.loadingForm = true;
      this.$store
        .dispatch('createFormFromAction', {
          actionCode: this.action.actionCode,
          entityId: this.entityId,
        })
        .then((response: LoadedData<CreatedForm>) => {
          this.$emit('action-end');
          this.loadingForm = false;
          if (hasData(response)) {
            if (this.action.inline) {
              this.$router.push({
                name: 'inlineEntityForm',
                params: {
                  code: this.action.actionCode,
                  formId: getData(response).id,
                  layout: 'inline',
                },
              });
            } else {
              this.$router.push({
                name: 'entityForm',
                params: {
                  entityId: this.entityId,
                  name: this.label,
                  formId: getData(response).id,
                },
              });
            }
          } else if (isApiError(response)) {
            this.$router.push({
              name: 'entityFormError',
              params: { entityId: this.entityId, name: this.label },
            });
          }
        });
    },
  },
});
