















import { RplForm } from '@dpc-sdp/ripple-form';
import Vue from 'vue';
import VueFormGenerator from 'vue-form-generator';
import moment from 'moment';
import mixins from 'vue-typed-mixins';
import fieldMixin, { Schema } from './mixin/fieldMixin';
import PopoverLabel from '@/components/pages/form/fields/PopoverLabel.vue';
import FieldDateTimePicker from '@/components/pages/form/fields/FieldDateTimePicker.vue';
import MarkdownDescription from '@/components/pages/form/fields/MarkdownDescription.vue';

Vue.component('field-datetimepicker', FieldDateTimePicker);

type Data = {};
type Computed = { tag: string; dataItemValue: unknown };
type Methods = {
  changed: (
    model: unknown,
    newVal: string,
    oldVal: string,
    field: Schema,
  ) => void;
  schema: () => Schema;
};
type Props = {};

export default mixins(fieldMixin).extend<Data, Methods, Computed, Props>({
  components: {
    MarkdownDescription,
    FieldDateTimePicker,
    PopoverLabel,
    RplForm,
  },
  name: 'DateField',
  mixins: [fieldMixin],
  computed: {
    tag() {
      return 'rpl-fieldset';
    },
    dataItemValue() {
      if (this.dataItem.value) {
        if (this.readOnlyMarkdown) {
          return moment(this.dataItem.value, moment.ISO_8601).format(
            'DD/MM/YYYY HH:mm',
          );
        }
        return moment(this.dataItem.value, moment.ISO_8601).format();
      }
      return undefined;
    },
  },
  methods: {
    changed(model, newVal, oldVal, field) {
      if (this.isValid(newVal, field)) {
        this.save(newVal);
      }
    },
    schema() {
      return {
        type: this.readOnlyMarkdown ? 'markdown' : 'datetimepicker',
        validator: [VueFormGenerator.validators.date, ...this.validators()],
        pattern: this.pattern(),
      };
    },
  },
});
