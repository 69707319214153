

































































import Vue from 'vue';
import { RplTextLink } from '@dpc-sdp/ripple-link';
import { RplPagination } from '@dpc-sdp/ripple-pagination';
import pluralize from 'pluralize';
import {
  DataItem,
  DataType,
  EnumValue,
  Field,
  PresentationControl,
} from '@/models/form.model';
import FieldComponent from './Field.vue';
import DataItemDelete from './fields/actions/DataItemDelete.vue';
import SelectSearch from '@/components/ripple/Search.vue';
import logger from '@/logger';
import { dataItemAndDescendants } from '@/helpers/form';

const PAGE_SIZE = 5;

type Data = { working: boolean; page: number; searchTerm?: string };

type Computed = {
  filteredRepeatingDataItems: DataItem[];
  repeatingDataItems: DataItem[];
  dataItemsForDisplay: DataItem[];
  canAdd: boolean;
  errorMessage: string | undefined;
  visible: boolean;
  canDelete: boolean;
  readOnlyForm: boolean;
  isPaginated: boolean;
  totalPages: number;
  isSearchable: boolean;
  searchPlaceholder: string;
  filterSummary: string;
  itemCountMessage: string;
};

type Methods = {
  dataItemAndDescendants: (dataItem: DataItem) => DataItem[];
  addMessage: () => string;
  onAdd: () => void;
  changePage: (page: number) => void;
  onSearch: (searchTerm: string) => void;
  lookupField: (id: string) => Field;
  lookupEnum: (enumValues: EnumValue[], enumId: string) => EnumValue;
  purgeHighlights: () => void;
};

type Props = {
  field: Field;
  dataItems: DataItem[];
  parentDataItem?: DataItem;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'RepeatingFields',
  components: {
    SelectSearch,
    FieldComponent,
    RplTextLink,
    DataItemDelete,
    RplPagination,
  },
  data() {
    return { working: false, page: 1, searchTerm: undefined };
  },
  props: {
    field: Object,
    dataItems: Array,
    parentDataItem: Object,
  },
  computed: {
    repeatingDataItems() {
      return this.dataItems
        .filter((dataItem) => dataItem.fieldId === this.field.id)
        .sort((d1, d2) => {
          if (d1.index === undefined) {
            throw new TypeError(`DataItem id: ${d1.id} missing index.`);
          }
          if (d2.index === undefined) {
            throw new TypeError(`DataItem id: ${d2.id} missing index.`);
          }
          return d1.index - d2.index;
        });
    },
    filteredRepeatingDataItems() {
      const { searchTerm } = this;
      if (this.isSearchable && searchTerm) {
        return this.repeatingDataItems.filter((dataItem) =>
          this.dataItemAndDescendants(dataItem)
            .filter((di) => di.value)
            .some((di) => {
              try {
                const regExp = new RegExp(searchTerm.trim(), 'i');
                let valueToTest: string;
                const field = this.lookupField(di.fieldId);
                if (field.dataType === DataType.CalculatedEnum) {
                  valueToTest = this.lookupEnum(
                    di.allowableValues,
                    di.value || '',
                  ).label;
                } else if (
                  field.dataType === DataType.CalculatedMultiValueEnum
                ) {
                  valueToTest = (JSON.parse(di.value || '[]') as string[])
                    .map(
                      (value) =>
                        this.lookupEnum(di.allowableValues, value).label,
                    )
                    .join(' ');
                } else if (field.dataType === DataType.Enum) {
                  valueToTest = this.lookupEnum(
                    field.enumValues,
                    di.value || '',
                  ).label;
                } else if (field.dataType === DataType.MultiValueEnum) {
                  valueToTest = (JSON.parse(di.value || '[]') as string[])
                    .map(
                      (value) => this.lookupEnum(field.enumValues, value).label,
                    )
                    .join(' ');
                } else {
                  valueToTest = di.value || '';
                }
                return regExp.test(valueToTest);
              } catch (e) {
                logger.error(e.message);
              }
              return false;
            }),
        );
      }
      return this.repeatingDataItems;
    },
    dataItemsForDisplay() {
      return this.isPaginated
        ? this.filteredRepeatingDataItems.slice(
            (this.page - 1) * PAGE_SIZE,
            this.page * PAGE_SIZE,
          )
        : this.filteredRepeatingDataItems;
    },
    canAdd() {
      return (
        !this.field.readOnly &&
        (this.field.maxRepeat === undefined ||
          this.repeatingDataItems.length < this.field.maxRepeat) &&
        !this.field.presentationControls.includes(
          PresentationControl.HideRepeatingAdd,
        )
      );
    },
    errorMessage() {
      const { minRepeat, maxRepeat } = this.field;
      const dataItemCount = this.repeatingDataItems.length;
      let errorMsg =
        minRepeat !== undefined && dataItemCount < minRepeat
          ? `Must be at least ${minRepeat} ${pluralize(
              this.field.label.toLowerCase(),
              minRepeat,
            )}`
          : undefined;
      if (!errorMsg && maxRepeat !== undefined) {
        const excessItems = dataItemCount - maxRepeat;
        errorMsg =
          excessItems > 0
            ? `Maximum of ${maxRepeat} allowed, please remove ${excessItems} ${pluralize(
                this.field.label?.toLowerCase() || 'item',
                excessItems,
              )}`
            : undefined;
      }
      return errorMsg;
    },
    visible() {
      return (
        this.repeatingDataItems.length > 0 ||
        this.field.minRepeat === 0 ||
        this.field.initialRepeat === 0
      );
    },
    canDelete() {
      return (
        !this.field.readOnly &&
        (this.field.minRepeat === undefined ||
          this.repeatingDataItems.length > this.field.minRepeat ||
          this.field.initialRepeat === 0)
      );
    },
    readOnlyForm() {
      return this.$store.getters.readOnlyForm;
    },
    isPaginated() {
      return (
        this.filteredRepeatingDataItems.length > PAGE_SIZE &&
        this.field.presentationControls.includes(PresentationControl.Paginated)
      );
    },
    totalPages() {
      return Math.ceil(this.filteredRepeatingDataItems.length / PAGE_SIZE);
    },
    isSearchable() {
      return this.field.presentationControls.includes(
        PresentationControl.Searchable,
      );
    },
    searchPlaceholder() {
      return `Filter ${pluralize(this.field.label).toLowerCase()}`;
    },
    filterSummary() {
      const { searchTerm } = this;
      if (searchTerm) {
        return `showing ${this.filteredRepeatingDataItems.length} of ${
          this.repeatingDataItems.length
        } ${pluralize(this.field.label).toLowerCase()}`;
      }
      return '';
    },
    itemCountMessage() {
      const total = this.filteredRepeatingDataItems.length;
      const startCount = this.page * PAGE_SIZE - PAGE_SIZE + 1;
      const endCount =
        total / PAGE_SIZE < this.page ? total : this.page * PAGE_SIZE;
      return `Displaying ${startCount} - ${endCount} of ${total} items`;
    },
  },
  methods: {
    dataItemAndDescendants(dataItem) {
      return dataItemAndDescendants(dataItem, this.dataItems);
    },
    addMessage() {
      return `Add${
        this.repeatingDataItems.length > 0 ? ' another' : ''
      } ${this.field.label.toLowerCase()}`;
    },
    onAdd() {
      this.working = true;
      this.$store
        .dispatch('addDataItem', {
          field: this.field,
          stage: this.$store.getters.stage,
          parentDataItem: this.parentDataItem,
        })
        .then((form) => {
          this.searchTerm = undefined;
          this.$store.commit('setForm', form);
          this.$nextTick(() => {
            if (this.totalPages > this.page) {
              this.page = this.totalPages;
            }
          });
        })
        .finally(() => {
          this.$nextTick(() => {
            if (this.$refs.fields) {
              const fields = this.$refs.fields as Vue[];
              if (fields.length > 0) {
                const field = fields[fields.length - 1];
                if (field) {
                  const firstFocusable = field.$el.querySelector(
                    'input, select, textarea, [tabindex]:not([tabindex="-1"])',
                  ) as HTMLElement;
                  if (firstFocusable) {
                    firstFocusable.focus();
                  }
                }
              }
            }
          });
          this.working = false;
        });
    },
    changePage(page) {
      this.page = page;
    },
    onSearch(searchTerm) {
      this.purgeHighlights();
      this.searchTerm = searchTerm;
      this.page = 1;
    },
    lookupField(id) {
      const field = this.field.fields.find((f) => f.id === id);
      if (field) {
        return field;
      }
      throw Error(`Field with id ${id} not found`);
    },
    lookupEnum(enumValues, enumId) {
      const enumValue = enumValues.find((ev) => ev.value === enumId);
      if (enumValue) {
        return enumValue;
      }
      throw Error(`Enum value with id ${enumId} not found`);
    },
    purgeHighlights() {
      this.$el.querySelectorAll('.contains-highlight').forEach((highlight) => {
        const originalText = highlight.getAttribute('data-original');
        const parent = highlight.parentElement;
        if (parent && originalText) {
          parent.replaceChild(document.createTextNode(originalText), highlight);
        }
      });
      this.$el.querySelectorAll('.input-highlight').forEach((highlight) => {
        highlight.classList.remove('input-highlight');
      });
    },
  },
  updated() {
    const { searchTerm } = this;
    if (this.isSearchable && searchTerm) {
      const searchRegExp = new RegExp(searchTerm, 'ig');
      const fieldElems = (this.$refs.fields as Vue[]).map((field) => field.$el);
      fieldElems.forEach((elem) => {
        const walker = document.createTreeWalker(
          elem,
          // eslint-disable-next-line no-bitwise
          NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
          {
            acceptNode(node: Node): number {
              const el = node as Element;
              if (el.classList?.contains('dropZone')) {
                return NodeFilter.FILTER_REJECT;
              }
              if (
                node.nodeType === Node.TEXT_NODE ||
                el.tagName?.toLowerCase() === 'input' ||
                el.tagName?.toLowerCase() === 'textarea'
              ) {
                return NodeFilter.FILTER_ACCEPT;
              }
              return NodeFilter.FILTER_SKIP;
            },
          },
        );
        let node = walker.nextNode();
        const nodeReplacements: {
          parent: Element;
          node: Node;
          replacement: Element;
        }[] = [];
        while (node) {
          const textContent = node.textContent || '';
          const parent = node.parentElement;
          if (node.nodeType === Node.TEXT_NODE) {
            if (textContent && parent && parent.closest('form')) {
              const matches = [...textContent.matchAll(searchRegExp)];
              if (matches.length) {
                const fragments: string[] = [];
                let start = 0;
                matches.forEach((match) => {
                  fragments.push(textContent.slice(start, match.index));
                  fragments.push(
                    `<span class="search-highlight">${match[0]}</span>`,
                  );
                  start = (match.index || 0) + match[0].length;
                });
                const lastMatch = matches[matches.length - 1];
                fragments.push(
                  textContent.slice(
                    (lastMatch.index || 0) + lastMatch[0].length,
                  ),
                );
                const highlight = document.createElement('span');
                highlight.classList.add('contains-highlight');
                highlight.setAttribute('data-original', textContent || '');
                highlight.innerHTML = fragments.join('');
                nodeReplacements.push({ parent, node, replacement: highlight });
              }
            }
          } else {
            const el = node as Element;
            const inputEl = el as HTMLElement & { value: string }; // could be input or textarea
            const startIndex = inputEl.value
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase());
            if (startIndex > -1) {
              inputEl.classList.add('input-highlight');
            }
          }
          node = walker.nextNode();
        }
        nodeReplacements.forEach((nr) =>
          nr.parent.replaceChild(nr.replacement, nr.node),
        );
      });
    }
  },
  watch: {
    totalPages() {
      if (this.page > this.totalPages) {
        // don't allow this.page to go down to 0
        this.page = this.totalPages || 1;
      }
    },
  },
});
