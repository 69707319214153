import moment from 'moment';

const FORMATS = { date: 'DD MMM YYYY', datetime: 'DD MMM YYYY h:mm A' };

export const formatDate = (value: string) => {
  if (value) {
    const dateObj = moment(value, moment.ISO_8601);
    if (dateObj.isValid()) {
      return dateObj.format(FORMATS.date);
    }
  }
  return '';
};

export const formatDateTime = (value: string) => {
  if (value) {
    const dateObj = moment(value, moment.ISO_8601);
    if (dateObj.isValid()) {
      return dateObj.format(FORMATS.datetime);
    }
  }
  return '';
};

export const formatDateOrDateTime = (value: string) => {
  if (value && /\d{2}:\d{2}/.test(value)) {
    return formatDateTime(value);
  }
  return formatDate(value);
};

export const isSameOrBefore = (date: string, testDate: string) =>
  moment(date).isSameOrBefore(testDate, 'day');

export const isSameOrAfter = (date: string, testDate: string) =>
  moment(date).isSameOrAfter(testDate, 'day');

export const isBetween = (date: string, startDate: string, endDate: string) =>
  moment(date).isBetween(startDate, endDate, 'day', '[]');
