

























import Vue from 'vue';
import RplIcon from '@dpc-sdp/ripple-icon';
import Confirm from '@/components/Confirm.vue';
import IconButton from '@/components/IconButton.vue';

import logger from '@/logger';
import { Collaborator } from '@/models/team.model';
import { RowViewModel } from '@/components/table/model/table.model';
import { columnValue } from '@/helpers/table';

type Data = { confirmDelete: boolean; working: boolean };
type Computed = { message: string };
interface Methods {
  onClick: (event: Event) => void;
  onCancel: () => void;
  onConfirm: () => void;
}
type Props = { rowViewModel: RowViewModel<Collaborator> };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DeleteCollaborator',
  components: { RplIcon, Confirm, IconButton },
  props: { rowViewModel: Object },
  data() {
    return {
      confirmDelete: false,
      working: false,
    };
  },
  computed: {
    message() {
      return `Please confirm you would like to remove ${this.rowViewModel.data?.name} from this team`;
    },
  },
  methods: {
    onClick(event: Event) {
      event.stopPropagation();
      this.confirmDelete = true;
    },
    onConfirm() {
      logger.info(`Removing ${this.rowViewModel.data?.name}}`);
      this.working = true;
      this.confirmDelete = false;
      const member = this.rowViewModel.data;
      this.$store
        .dispatch(
          member?.isGroup ? 'removeGroupFromTeam' : 'removeUserFromTeam',
          {
            member,
            entityId: columnValue(this.rowViewModel.row.columns, 'data'),
          },
        )
        .finally(() => {
          this.working = false;
        });
    },
    onCancel() {
      this.confirmDelete = false;
    },
  },
});
