











import Vue from 'vue';
import { Column, RowViewModel } from './model/table.model';
import TotalCell from '@/components/table/TotalCell.vue';

type Data = {};

type Computed = {};

type Methods = {};

type Props = { columns?: Column[]; rows?: RowViewModel<unknown>[] };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TotalsRow',
  components: { TotalCell },
  props: { columns: Array, rows: Array },
});
