












import Vue from 'vue';
import { RplAlert } from '@dpc-sdp/ripple-alert';
import { ApiError } from '@/api/data';

type Data = {
  alertType: string;
  link: { text: string; url: string };
  duration: number;
};
type Methods = {
  onBack: EventListener;
  close: () => unknown;
  dismiss: () => void;
};
type Computed = {
  errorMessage?: string;
  errorTitle?: string;
};

type Props = {
  error: ApiError;
  alertId: string;
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ErrorMessage',
  components: { RplAlert },
  props: {
    error: {
      type: Object,
    },
    alertId: {
      type: String,
    },
  },
  data() {
    return {
      alertType: 'Emergency',
      link: { text: 'Please folow this link ', url: 'https://' },
      duration: 3000,
    };
  },
  computed: {
    errorTitle() {
      return this.$props.error.message;
    },
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    },
    close() {
      const wrapper = this.$refs[this.alertId];
      if (wrapper !== undefined && 'remove' in wrapper) {
        this.$destroy();
        wrapper.remove();
      }
    },
    dismiss() {
      this.close();
    },
  },
});
