









import Vue from 'vue';
import {
  DataItem,
  Field,
  Form,
  FormStage,
  StageType,
} from '@/models/form.model';
import Fields from './Fields.vue';
import { getData, hasData } from '@/api/data';

type Data = {};

type Computed = {
  form: Form | undefined;
  stageType: StageType | undefined;
  fields: Field[];
  stage: FormStage;
  dataItems: DataItem[];
  readOnlyForm: boolean;
};

type Methods = {};

type Props = unknown;

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'StageFields',
  components: {
    Fields,
  },
  props: ['stageTypeId'],
  computed: {
    form() {
      if (hasData(this.$store.state.form.formFromApi)) {
        return getData(this.$store.state.form.formFromApi);
      }
      return undefined;
    },
    stageType() {
      return this.$store.state.form.stageType;
    },
    fields() {
      return this.stageType ? this.stageType.fields : [];
    },
    stage() {
      return this.$store.getters.stage;
    },
    dataItems() {
      const { stage } = this;
      return this.form
        ? this.form.dataItem
            .filter((dataItem) => dataItem.stageId === stage?.id)
            .flatMap((dataItem) =>
              this.$store.getters.dataItemAndDescendants(dataItem),
            )
        : [];
    },
    readOnlyForm() {
      return this.$store.getters.readOnlyForm;
    },
  },
});
