import { ActionContext, Module } from 'vuex';

import { RootState } from '../types';

// The polling module isn't stateful
type PollingState = unknown;

const pollingModule: Module<PollingState, RootState> = {
  actions: {
    async pollApi({ dispatch }: ActionContext<PollingState, RootState>) {
      dispatch('pollProcessingAttachments');
      dispatch('pollSubmittingForm');
      dispatch('pollFormJob');
      dispatch('pollBulkDownloads');
    },
  },
};

export default pollingModule;
