

















import Vue from 'vue';
import Popover from '@/components/Popover.vue';
import { AnchorPoint } from '@/models/site.model';
import InfoIcon from '@/assets/info-circle-regular.svg?component';

type Data = { open: boolean; popupAnchorPoint: AnchorPoint };
type Methods = { onClick: (evt: MouseEvent) => void; onClose: () => void };
type Computed = {};
type Props = { markdown: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'PopoverToggle',
  components: { Popover, InfoIcon },
  data() {
    return {
      open: false,
      popupAnchorPoint: { x: 'right', y: 'top' },
    };
  },
  props: { markdown: String },
  methods: {
    onClick(evt) {
      if (!this.open) {
        this.popupAnchorPoint.x =
          window.innerWidth / 2 > evt.clientX ? 'left' : 'right';
        this.popupAnchorPoint.y =
          window.innerHeight / 2 > evt.clientY ? 'top' : 'bottom';
      }
      this.open = !this.open;
    },
    onClose() {
      this.open = false;
    },
  },
});
