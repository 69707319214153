import { ActionContext, Module } from 'vuex';
import { RootState } from '../types';
import { GroupState } from './types';
import { recordApiCall } from '@/store/modules/apiCalls';
import {
  ApiLoading,
  ApiNeverLoaded,
  DataFromApi,
  isApiError,
} from '@/api/data';
import { Group, GroupMember } from '@/models/group.model';

const module: Module<GroupState, RootState> = {
  state: () => ({
    groupsFromApi: ApiNeverLoaded,
    groupFromApi: ApiNeverLoaded,
    message: undefined,
    error: undefined,
  }),
  mutations: {
    setGroups(state, groups: DataFromApi<Group[]>) {
      state.groupsFromApi = groups;
    },
    setGroup(state, group: DataFromApi<Group>) {
      state.groupFromApi = group;
      state.message = undefined;
      state.error = undefined;
    },
    setMessage(state, message: string) {
      state.message = message;
    },
    setError(state, error: string) {
      state.error = error;
    },
  },
  actions: {
    loadGroups({
      commit,
      rootGetters,
      rootState,
    }: ActionContext<GroupState, RootState>) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getGroups',
        (client) => client.getGroups(),
      ).then((response) => {
        commit('setGroups', response);
      });
    },
    reloadGroups({ dispatch, commit }: ActionContext<GroupState, RootState>) {
      commit('setGroups', ApiNeverLoaded);
      commit('setGroup', ApiNeverLoaded);
      commit('setMessage', undefined);
      commit('setError', undefined);
      return dispatch('loadGroups');
    },
    getGroup(
      {
        state,
        commit,
        rootGetters,
        rootState,
      }: ActionContext<GroupState, RootState>,
      groupId: string,
    ) {
      commit('setGroup', ApiLoading(state.groupFromApi));
      return recordApiCall(
        { rootState, rootGetters, commit },
        'getGroup',
        (client) => client.getGroup(groupId),
      ).then((response) => {
        commit('setGroup', response);
      });
    },
    addExistingUserToGroup(
      { commit, rootGetters, rootState }: ActionContext<GroupState, RootState>,
      args: { email: string; groupId: string },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'addExistingUserToGroup',
        (client) => client.addExistingUserToGroup(args.email, args.groupId),
      ).then((response: DataFromApi<Group>) => {
        if (!isApiError(response)) {
          commit('setGroup', response);
          commit('setMessage', `${args.email} added to group`);
        }
        return response;
      });
    },
    addNewUserToGroup(
      { commit, rootGetters, rootState }: ActionContext<GroupState, RootState>,
      args: {
        email: string;
        firstName: string;
        lastName: string;
        groupId: string;
      },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'addNewUserToGroup',
        (client) =>
          client.addNewUserToGroup(
            args.email,
            args.firstName,
            args.lastName,
            args.groupId,
          ),
      ).then((response: DataFromApi<Group>) => {
        if (!isApiError(response)) {
          commit('setGroup', response);
          commit('setMessage', `${args.email} added to group`);
        }
        return response;
      });
    },
    removeContactFromGroup(
      {
        commit,
        dispatch,
        rootGetters,
        rootState,
      }: ActionContext<GroupState, RootState>,
      args: { contact: GroupMember; groupId: string; self: boolean },
    ) {
      return recordApiCall(
        { rootState, rootGetters, commit },
        'removeContactFromGroup',
        (client) =>
          client.removeContactFromGroup(args.contact.id, args.groupId),
      ).then((response: DataFromApi<Group>) => {
        if (!isApiError(response)) {
          if (args.self) {
            dispatch('reloadGroups');
          }
          commit('setGroup', response);
          commit(
            'setMessage',
            `${args.contact.firstName || ''} ${
              args.contact.lastName || ''
            } removed from group`,
          );
        } else {
          commit(
            'setError',
            `Error removing ${args.contact.firstName || ''} ${
              args.contact.lastName || ''
            } from group.`,
          );
        }
      });
    },
  },
};

export default module;
