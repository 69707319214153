







import { RplIcon } from '@dpc-sdp/ripple-icon';
import Vue from 'vue';

type Data = {};
type Computed = {};
type Methods = {};
type Props = {};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Loading',
  components: {
    RplIcon,
  },
});
