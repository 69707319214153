

























































import Vue from 'vue';
import { RplIcon } from '@dpc-sdp/ripple-icon';
import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import Button from '@/components/ripple/Button.vue';
import { Group, GroupMember } from '@/models/group.model';
import {
  ApiNeverLoaded,
  getData,
  getErrorMessage,
  hasData,
  isApiError,
  isLoadingOrNeverLoaded,
} from '@/api/data';
import {
  ColumnDataType,
  RowViewModel,
  TableMetadata,
  TableViewModel,
} from '@/components/table/model/table.model';
import DataHubTable from '@/components/table/DataTable.vue';

type Data = {};
type Methods = {
  onAddMember: () => void;
  onCloseMessage: () => void;
  onCloseError: () => void;
};
type Computed = {
  group: Group;
  isError: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  errorMessage: string;
  tableViewModel: TableViewModel<GroupMember>;
  tableData: RowViewModel<GroupMember>[];
  tableConfig: TableMetadata;
  message?: string;
  error?: string;
};
type Props = { id: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ContactGroup',
  components: { RplIcon, DataHubTable, Button, RplAlertBase },
  props: { id: String },
  computed: {
    isLoading() {
      return isLoadingOrNeverLoaded(this.$store.state.group.groupFromApi);
    },
    isError() {
      return isApiError(this.$store.state.group.groupFromApi);
    },
    group() {
      return getData(this.$store.state.group.groupFromApi);
    },
    isLoaded() {
      return hasData(this.$store.state.group.groupFromApi);
    },
    errorMessage() {
      return (
        getErrorMessage(this.$store.state.group.groupFromApi) ||
        'Error loading group'
      );
    },
    tableData() {
      return [...this.group.contacts]
        .sort((c1, c2) => {
          if (c1.firstName === undefined && c2.firstName === undefined) {
            return 0;
          }
          if (c1.firstName === undefined) {
            return -1;
          }
          if (c2.firstName === undefined) {
            return 1;
          }
          return c1.firstName.localeCompare(c2.firstName);
        })
        .map((contact) => ({
          row: {
            id: contact.id,
            columns: [
              {
                key: 'name',
                value: `${contact.firstName || ''} ${contact.lastName || ''}`,
              },
              { key: 'email', value: contact.email || '' },
              {
                key: 'data',
                actions: ['delete-group-member'],
                value: this.group.id,
              },
            ],
          },
          data: contact,
        }));
    },
    tableConfig() {
      return {
        label: `Members of ${this.group.name}`,
        columns: [
          {
            label: 'Name',
            key: 'name',
            dataType: ColumnDataType.Text,
          },
          {
            label: 'Email',
            key: 'email',
            dataType: ColumnDataType.Text,
          },
          {
            label: '',
            key: 'data',
            dataType: ColumnDataType.Action,
          },
        ],
        pageSize: 10,
        displayRank: 0,
      };
    },
    tableViewModel() {
      return { config: this.tableConfig, rows: this.tableData };
    },
    message() {
      return this.$store.state.group.message;
    },
    error() {
      return this.$store.state.group.error;
    },
  },
  methods: {
    onAddMember() {
      this.$router.push({
        name: 'addGroupMember',
        params: { groupId: this.group.id },
      });
    },
    onCloseMessage() {
      this.$store.commit('setMessage', undefined);
    },
    onCloseError() {
      this.$store.commit('setError', undefined);
    },
  },
  mounted() {
    this.$store.dispatch('getGroup', this.id);
  },
  destroyed() {
    this.$store.commit('setGroup', ApiNeverLoaded);
  },
  watch: {
    id() {
      this.$store.dispatch('getGroup', this.id);
    },
  },
});
